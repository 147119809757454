import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useDispatch, useSelector } from 'react-redux';
import { startUpdateSettings } from '../../redux/actions/settings';
import { LoadingButton } from '@mui/lab';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';


export const EditorConvert = ({ text, field }) => {
    
    const dispatch = useDispatch();
    const [texto, setText] = useState(text);
    const settings = useSelector(state => state.settings)
    const handleEdit = (values, {setSubmitting}) => {
        let obj = {};
        obj[field] =  texto
        obj['VersionFila'] = settings.VersionFila
        dispatch( startUpdateSettings(obj, setSubmitting))
    }
    const initialValues = {
        text: text
    }
    return (
      <div style={{ padding: '3rem'}}>
        <Formik initialValues={initialValues} onSubmit={handleEdit}>
        {({ isSubmitting, isValid, setFieldValue }) => (
        <Form>
        <CKEditor
            config = {{
                toolbar: {
                    items: [ 'bold', 'italic', '|', 'undo', 'redo', '-', 'numberedList', 'bulletedList' ],
            
                    shouldNotGroupWhenFull: true
                }
            }
            }
            editor={ ClassicEditor }
            data={ text }
            onReady={ editor => {
                // You can store the "editor" and use when it is needed.
                console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( _, editor ) => {
                const data = editor.getData();
                setText( data )
                setFieldValue('text', data)
            } }
            
        />

        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
        >

            <LoadingButton
                loadingPosition="start"
                variant="contained"
                style={{
                    backgroundColor: '#E5824F',

                }}
                loading={ isSubmitting }
                disabled={ !isValid }
                type='submit'
            >
                    Editar
            </LoadingButton>

        </Box>
        </Form>
        )}
        </Formik>
      </div>
    );
  
}
