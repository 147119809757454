import { AppBar, Box, Tab, Tabs,  useMediaQuery,  useTheme } from '@material-ui/core'
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout } from '../../../components/ui/Layout'
import { setActiveInvoice } from '../../../redux/actions/sales'
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { Spinner } from '../../../components/ui/Spinner'
import { Action, Fab } from 'react-tiny-fab'
import { Add, Create, CreateRounded } from '@material-ui/icons'
import { actionAddFreeOrderLineToOrder, actionAddOrderLineToOrder } from '../../../redux/actions/actions'
import { TransitionsModal } from '../../../components/ui/TransitionModal'
import { ROLREVENDEDOR, ROLADMIN } from '../../../utils/constants'
import { startGatheringDataFromServicesReseller } from '../../../redux/actions/supplierServices'
import { startLogOutCleanSuppliers } from '../../../redux/actions/suppliers'
import { startLogOutActiveRate } from '../../../redux/actions/exchangeRate'
import { navigate, useParams } from '@reach/router'
import { Detail } from '../../../components/invoices/detail/Detail';
import { Info } from '../../../components/invoices/detail/Info';
import { EstadoVenta } from '../../../components/invoices/detail/EstadoVenta';
import { startLoadingComisionistas } from '../../../redux/actions/comisionistas';

import { setActiveUserState } from '../../../redux/actions/users';

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
                { children}
            </Box>
        )}
    </div>
    );
}

TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};


const SaleDetailScreen = () => {
    const mainButtonStyles = {backgroundColor: '#E6824F'};
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const { menuState, loading } = useSelector(state => state.ui);
    const { activeEdit } = useSelector(state => state.invoice)
    const {modal} = useSelector(state => state.modal)
    const { route } = useSelector(state => state.route);
    const { idRol, id: loggedInUser, idAgencia } = useSelector(state => state.auth)
    const [value, setValue] = useState(0);
    const params = useParams()

    const dispatch = useDispatch();
    
    const isAdminEditingResellerOrder = () => {
        return idRol === ROLADMIN && activeEdit?.Usuario?.FkRoles === ROLREVENDEDOR;
    }

    useEffect(() => {
        dispatch(setActiveUserState( loggedInUser ))
    }, [ dispatch, loggedInUser ]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleAddRowService = () => {
        dispatch(actionAddOrderLineToOrder());
 
     }
     const handleAddRowCustomService = () => {
        dispatch(actionAddFreeOrderLineToOrder())
    }
    
    
    useEffect(() => {
        dispatch(startLoadingComisionistas() )
    }, [dispatch]);

    useEffect(() => {
        dispatch(setActiveInvoice(params.PkVentas))
    }, [ dispatch, params.PkVentas ]);

    useEffect(() => {
        if( route ){
           navigate( route )
        }
    }, [route ])

    useEffect(() => {
        if( (idRol === ROLREVENDEDOR) && modal){
            dispatch(startGatheringDataFromServicesReseller( (idAgencia>0)?idAgencia:loggedInUser ))
        }
        if( (isAdminEditingResellerOrder()) && modal){
            dispatch(startGatheringDataFromServicesReseller( 
                (activeEdit?.Usuario?.FkUsuarios_Agencia>0)?activeEdit?.Usuario?.FkUsuarios_Agencia:activeEdit?.Usuario?.PkUsuarios ))
        }
    }, [idRol, modal, loggedInUser, dispatch])

    useEffect(() => {
        if( !modal ){
            dispatch(startLogOutCleanSuppliers())
            dispatch(startLogOutActiveRate())
        }
    }, [ modal, dispatch ])
    return (
        <Layout>
            <Helmet>
            <title>Venta en Detalle | Xplora</title>
            </Helmet>
            {
                (loading && !modal)
                ?
                <Spinner />
                :
                <>
                <Box sx={{  width: `${ matchesXS ? '85vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`, mt: 4, bgcolor: 'background.paper' }}>
                    <AppBar position="static" color='transparent'>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                        <Tab label="Venta" {...a11yProps(0)} />
                        <Tab label="Info" {...a11yProps(1)} />
                        <Tab label="Estado" {...a11yProps(2)} />
                        
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Detail />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <Info />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <EstadoVenta />
                        </TabPanel>
                    </SwipeableViews>
                </Box>
                    {
                        (!activeEdit?.FechaFactura && !modal //&& !(idRol === ROLADMIN && activeEdit?.Usuario?.FkRoles === ROLREVENDEDOR)
                        )
                        &&
                        (
                            <>
                                <Fab
                                    mainButtonStyles={mainButtonStyles}
                                    icon={ <Add />}
                                    alwaysShowTitle={true}
                                >
                                    <Action
                                        text="Nueva Línea de Venta"
                                        onClick={ handleAddRowService }
                                    >
                                        <Create />
                                    </Action>

                                    {
                                        (idRol !== ROLREVENDEDOR && activeEdit?.Usuario?.FkRoles !== ROLREVENDEDOR)
                                        &&
                                        <Action
                                        text="Nueva Línea de Venta Libre"
                                        onClick={ handleAddRowCustomService }
                                        >
                                            <CreateRounded />
                                        </Action>
                                    }
                        

                                
                                </Fab>
                            </>
                        )
                    }
                    
                    </>
                
            }
            <TransitionsModal />
            
        </Layout>
    )
}


export default SaleDetailScreen;