export const types = {

    currentUser: '[Auth] Current User',

    authLogin: '[Auth] Login',
    authLogOut: '[Auth] Logout',
    uiManageModal: '[UI] Menu State',
    uiManageModalCollectionTypes: '[UI] Open Modal',
    uiManageModalNotifications: '[UI] Open Notification Modal',
    uiStartLoading: '[UI] Start Loading',
    uiFinishLoading: '[UI] Finish Loading',
    uiModalContentStartLoading: '[UI Modal] Start Loading',
    uiModalContentFinishLoading: '[UI Modal] Finish Loading',
    uiStartError: '[UI] Start Error',
    uiFinishError: '[UI] Finish Error',
    uiStartSuccess: '[UI] Start Success',
    uiFinishSuccess: '[UI] Finish Success',
    uiLogout: '[UI] Logout UI',

    //User Management
    usersAddNew: '[Users] New User',
    usersLoad: '[User] Load Users',
    userActive: '[Users] Detail User',
    usersDelete: '[Users] Delete User',
    usersUpdated: '[Users] Update User',
    isLoadingActive: '[User] Loading Active User',
    usersLogOutCleaning: '[Users] Clean State',
    userAcceptPoliticas: '[User] Accept Terms & Conditions',
    userAcceptContratoTratamientoDatos:'[User] Accept Contract',
    userChangePassword: '[User] Change Password',
    rolesLoad: '[Rols] Roles Load',

    //ModalContent Management
    userAddContentModal: '[Modal] Content Add User',
    userEditContentModal: '[Modal] Content Edit User',
    userDetailContentModal: '[Modal] Content Detail User',

    supplierAddContentModal: '[Modal] Content Add Supplier',
    supplierEditContentModal: '[Modal] Content Edit Supplier',
    supplierDetailContentModal: '[Modal] Content Detail Supplier',

    clientAddContentModal: '[Modal] Content Add Client',
    clientEditContentModal: '[Modal] Content Edit Client',
    
    comisionistaAddContentModal: '[Modal] Content Add Comisionista',
    comisionistaEditContentModal: '[Modal] Content Edit Comisionista',

    exchangeRateAddContentModal: '[Modal] Content Add Exchange Rate',
    exchangeRateDetailContentModal: '[Modal] Content Detail Exchange Rate',

    serviceSupplierAddContentModal: '[Modal] Content Service Supplier Add',
    serviceSupplierDeleteContentModal: '[Modal] Content Service Supplier Delete',
    serviceSupplierEditContentModal: '[Modal] Content Service Supplier Edit',

    serviceResellerAddContentModal: '[Modal] Content Reseller Service Add',
    serviceResellerEditContentModal: '[Modal] Content Reseller Service Edit',
    serviceResellerDeleteContentModal: '[Modal] Content Reseller Service Delete',
    supplierCoordinatorAddContentModal: '[Modal] Content Coordinator Supplier Add',
    supplierCoordinatorEditContentModal: '[Modal] Content Coordinator Supplier Edit',
    supplierCoordinatorDeleteContentModal: '[Modal] Content Modal Delete Coordinator',

    orderLineAddContentModal: '[Modal] Content Modal Add Order Line to Budget',
    orderLineEditContentModal: '[Modal] Content Modal Edit Order Line to Budget',
    orderLineEditResellerContentModal: '[Modal] Content Modal Edit Order Line to Reseller',
    pdfContentInvoice: '[Modal] Content Invoice PDF',

    
    orderLineEditStateContentModal: '[Modal] Content Modal Line From State',
    orderFreeLineStateContentModal: '[Modal] Content Modal Line Free From State',
    orderLineEditStateLoadingSuppliersAndServices: '[Modal] Loading Suppliers And its Services',
    orderLineStateSetExchangerate: '[Modal] Load Exchange Rate',
    resetActiveLineFromState: '[Modal] Reset Active Line',
    
    freeOrderLineAddContentModal: '[Modal] Content Modal Add Free Order Line',

    settingsEditContentModal: '[Modal] Content Edit Settings',
    settingsEditVoucher: '[Modal] Content Edit Voucher',

    lineDetailContentModal: '[Modal] Order Line Detail Content',

    logOutContentModal: '[Modal] LogOut Modal',
    invoiceAddContentModalConfirm: '[Modal] Content Modal Invoice',
    invoiceDeleteContentModal: '[Modal] Content Modal Delete Invoice',
    loadServicesResellerEdit: '[Modal] Load Services Reseller Edit',
    //NotificationModal Management
    uiNotificationDeleteModal: '[UI] Content Notification Delete User',
    uiNotificationDeleteModalSupplier: '[UI] Content Notification Delete Supplier',
    uiNotificationDeleteModalClient: "[UI] Content Notifications Delete Client",
    uiNotificationDeleteModalComisionista: "[UI] Content Notifications Delete Comisionista",
    uiNotificationDeleteExchangeRate: "[UI] Content Delete Exchange Rate",
    uiNotificationDeleteLineFromInvoice: '[UI] Content Delete Line From Invoice',
    uiNotificationSendEmailModal: '[UI] Content Modal Send Email',
    uiNotificationSendEmailModalVoucher: '[UI] Content Modal Send Email Voucher',
    lineContentGetVoucher: '[Line] Get PDF Voucher',
    uiFacturarContentModal: '[UI] Content Modal Facturar',
    
    //Suppliers Management
    suppliersAddNew: '[Suppliers] New Supplier',
    suppliersLoad: '[Suppliers] Load Suppliers',
    supplierActive: '[Suppliers] Detail Supplier',
    supplierDelete: '[Suppliers] Delete Supplier',
    supplierUpdated: '[Suppliers] Update Supplier',
    suppliersLogOutCleaning: '[Suppliers] Clean Suppliers State',
    supplierDeleteCoordinator: '[Suppliers] Delete Coordinator',
    supplierServicesLoad: '[Suppliers] Load Services',

    //Clients Management
    clientsAddNew: '[Clients] New Client',
    clientsLoad: '[Clients] Load Clients',
    clientActive: '[Clients] Detail Client',
    clientDelete: '[Clients] Delete Client',
    clientUpdated: '[Clients] Update Client',
    clientsLogOutCleaning: '[Clients] Clean Clients State',

    
    // Comisionistas
    comisionistasAddNew: '[Comisionistas] New Comisionista',    
    comisionistasLoad: '[Comisionistas] Load Comisionistas',
    comisionistaActive: '[Comisionistas] Detail Comisionista',
    comisionistaDelete: '[Comisionistas] Delete Comisionista',
    comisionistaUpdated: '[Comisionistas] Update Comisionista',
    comisionistasLogOutCleaning: '[Comisionistas] Clean Comisionistas State',

    
    
    // Vendedores Agencia
    sellersAgencyLoad: '[Vendedores Agencia] Load Vendedores agencia',
    vendedorAgenciaDelete: '[Vendedores Agencia] Delete vendedor agencia',
    
    agencySellerAddContentModal: '[Modal] Content Seller Agency Add',
    vendedorAgenciaDeleteContentModal: '[Modal] Content Seller Agency Delete',

    vendedorAgenciaActivo: '[Vendedores] Detail Vendedor',

    vendedorAgenciaEditContentModal: '[Modal] Content Vendedor Edit',

    //comisionistasAddNew: '[Comisionistas] New Comisionista',    
    //comisionistaActive: '[Comisionistas] Detail Comisionista',
    //comisionistaDelete: '[Comisionistas] Delete Comisionista',
    //comisionistaUpdated: '[Comisionistas] Update Comisionista',
    //comisionistasLogOutCleaning: '[Comisionistas] Clean Comisionistas State',


    //Rate Exchange
    exchangeRateAddNew: '[Exchange Rate] New Rate',
    exchangeRateLoad: '[Exchange Rate] Load Rates',
    exchangeRateActive: '[Exchange Rate] Deatil Rate',
    exchangeRateDelete: '[Exchange Rate] Delete Rate',
    exchangeRateUpdated: '[Exchange Rate] Update Rate',
    exchangeRateLogOutCleaning: '[Exchange Rate] Clean Rate State',
    exchangeRateLogOutActive : '[Exchange Rate] Clean Active',
    //Services

    servicesAddNew: '[Services] New Service',
    servicesLoad: '[Services] Load Services',
    servicesActive: '[Services] Deatil Service',
    servicesDelete: '[Services] Delete Service',
    servicesResellerDelete: '[Services] Delete Service Reseller',
    servicesResellerUpdate: '[Services] Update Service Reseller',
    servicesUpdated: '[Services] Update Service',
    servicesLogOutCleaning: '[Services] Clean Service State',
    setActiveRate: '[Services] Set Active Rate',
    serviceRateLogOut: '[Service Rate] LogOut',

    //Invoices Management
    changeFilter: 'CHANGE_FILTER',
    changeFilterDates: 'CHANGE_FILTER_DATES',
    invoicesAddNew: '[Invoices] New Invoice',
    invoicesLoad: '[Invoices] Load Invoices',
    invoiceActive: '[Invoices] Detail Invoice',
    invoiceDelete: '[Invoices] Delete Invoice',
    invoiceUpdated: '[Invoices] Update Invoice',
    invoicesLogOutCleaning: '[Invoices] Clean Invoices State',
    invoiceActiveEdit: '[Invoice] Set Edit Invoice',
    invoiceShare: '[Invoice] Set Active Invoice Share',
    linesAddNew: '[Lines] New Line',
    lineDelete: '[Lines] Delete Line',
    invoiceLineStateLogOut: '[Invoice] Line State Logout',
    invoiceDeletebyId: '[Invoice] Delete Invoice by Id',
    getTotalInvoicesFiltered: '[Invoice] Sum Total Invoices',

    //Lines Management
    changeLinesFilter: 'CHANGE_LINES_FILTER',
    changeLinesFilterDates: 'CHANGE_LINES_FILTER_DATES',
    linesLoad: '[Lines] Load Lines',
    lineActive: '[Lines] Detail Line',
    lineDeleteFromInvoice: '[Lines] Delete Line From Invoice',
    linesLogOutCleaning: '[Lines] Clean Lines State',
    lineActiveUpdatePrices: '[Lines] Update Prices According to Supplier/Service',
    lineChangeDataPricesAndLine: '[Lines] Update Data Line',
    lineCurrentActive: '[Lines] Active From List Active Current Line',
    lineActiveEditLogOut: '[Lines] Logout Line Active Edit',
    lineEditFromInvoiceState: '[Lines] Edit Line State',
    lineUpdateState: '[Lines] Update Line State',
    lineSumTotal: '[Lines] Sum Total Lines',
    
    //Settings
    settingsLoad: '[Settings] Load Settings',


    //API REQUEST MANAGEMENT
    SUCCESS_API_REQUEST: '[API REQUEST] Succesfull API Request',
    FAILED_API_REQUEST: '[API REQUEST] Failed API Request',

    //ROUTE REDIRECT MANAGEMENT
    ROUTE_REDIRECT: '[ROUTE] CHANGE ROUTE',
    ROUTE_REDIRECT_LOGOUT: '[ROUTE] LOGOUT REDIRECT',

    //TABLE INVOICES NUMBER OF ITEMS
    getNumberOfSInvoices: '[INVOICES] Number of Invoices',
    getNumberOfLines: '[Lines] Number of Lines',
    resetPage: '[Page] Reset Page Invoices',
    resetPageLine: '[Page] Reset Page Lines',


    //DASHBOARD DATA
    loadDashboard: '[Dashboard] Load Dashboard Data',
    logOutDashboard: '[Dashboard] LogOut Dashboard',
    
}