

    import { types } from "../types/types";

    const initialState = {
        cards: null,
        graphic: null

    }
    
    export const dashboardReducer = ( state = initialState, action ) => {
        switch (action.type) {
            
            case types.loadDashboard:
                return {
                    ...state,
                    cards: {
                        ...action.cards
                    },
                    graphic: {
                        ...action.graphic
                    }
                }
            case types.logOutDashboard:
                return initialState;

            
            default:
                return state;
        }
    }
    