import { Checkbox, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { DeleteOutlineOutlined, WhatsApp } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from '../../components/ui/Layout';
import { NotificationModal } from '../../components/ui/NotificationModal';
import { TransitionsModal } from '../../components/ui/TransitionModal';
import { startLoadingAllComisionistas, startLoadingComisionistas } from '../../redux/actions/comisionistas';
import { Helmet } from 'react-helmet'
import { ROLADMIN, WHATSLINK } from '../../utils/constants';
import { useTableParams } from '../../hooks/useTableParams';
import { actionAddComisionista, actionDeleteComisionista, actionEditComisionista } from '../../redux/actions/actions';
import { startLogOutRedirect } from '../../redux/actions/routeManagement';
import { Box } from '@mui/system';
import { ButtonFloatNoOptions } from '../../components/ui/ButtonFloatNoOptions';



const ComisionistasScreen = () => {
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const { idRol, id } = useSelector(state => state.auth);
    const { menuState } = useSelector(state => state.ui)
    let data = [];
    const showColumnAction = false;
    const { comisionistas } = useSelector(state => state.comisionistas);
    const dispatch = useDispatch()
    const comisionistaOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: 'Comisionistas', rowsPerPage: 5};
    const comisionistaColumnsDeskTop =  [['Nombre', true], ['Email', true, !matchesXS], ['Telefono', false, !matchesXS]];
    const actions = [
        { isShown: true, type: 'delete', color: '#000', icon: <DeleteOutlineOutlined />, eject: ( dataIndex, e ) => handleDelete( dataIndex, e )},
       // { isShown: true, color: '#000', type: 'custom', icon: <WhatsApp />, eject: ( dataIndex, e ) => handleSendWhatsApp( dataIndex, e )},
        // { isShown: true, type: 'edit', data: comisionistas, link: 'comisionistas', key: 'PkComisionistas', color: '#000', icon: <EditOutlined />, eject: ( dataIndex ) => handleEdit( dataIndex )},
    ]
    const { options, columns } = useTableParams( comisionistaOptions,  comisionistaColumnsDeskTop , actions, idRol !== ROLADMIN ? showColumnAction : true, (dataIndex, e) => handleEdit(dataIndex, e));
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };


    useEffect(() => {
        if( idRol === ROLADMIN){
            if(!checked){ 
                dispatch(startLoadingComisionistas());
            }
            else{
                dispatch(startLoadingAllComisionistas());
            }
        }
    }, [ dispatch, idRol, id, checked ]);

    useEffect(() => {
        dispatch(startLogOutRedirect())
    }, [ dispatch ])

     

    
    data = comisionistas.map( comisionista => { return [ comisionista.Nombre, comisionista.Email ? comisionista.Email : '-', comisionista.Telefono ? comisionista.Telefono : '-', comisionista.FechaBaja]; });

    options.setRowProps = (row, dataIndex, rowIndex) => { 
        if (data[rowIndex][3] !== null) {
          return {
            style: { backgroundColor: 'salmon' }
          };
        }
      };

    const handleAddComisionista = () => {
        dispatch(actionAddComisionista())
    }

   const handleDelete = ( dataIndex, e ) => {
    if (e) e.stopPropagation();
    dispatch(actionDeleteComisionista( comisionistas[ dataIndex ]?.PkComisionistas ))
   }

   const handleSendWhatsApp = ( dataIndex, e ) => {
    if (e) e.stopPropagation();
    window.open(WHATSLINK.concat(`${comisionistas[ dataIndex ]?.Telefono}`))
   }

   const handleEdit = ( dataIndex, e ) => {
    if (e) e.stopPropagation();
    dispatch(actionEditComisionista( comisionistas[ dataIndex ].PkComisionistas))
   }
    
    return (
        <Layout>
            <Helmet>
                <title>Comisionistas | Xplora</title>
            </Helmet>
            <div style={{
                    marginTop: '2rem',
                    width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}>
                {
                    !matchesXS
                    &&
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>COMISIONISTAS</Typography>
                }

                <MUIDataTable
                    title={ !matchesXS 
                            ?
                            <Box sx={{ display: 'flex'}}> 
                            
                            <FormControlLabel
                                    label='Incluir dados de baja'
                                    control={
                                        <Checkbox
                                            style={{ marginLeft: '1.7rem'}}
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled', 'label': 'Todos' }}
                                        />
                                    }
                            />
                            </Box>
                            : 
                            (   <Typography variant='h2' style={{ marginLeft: '0.7rem'}}>
                                    Comisionistas 
                                </Typography>
                            )
                        }
                    {...{data, columns, options}}
                    
                />

                <TransitionsModal />
                <NotificationModal />

                <ButtonFloatNoOptions 
                action={ handleAddComisionista }
               />

            </div>
        </Layout>
    )
}


export default ComisionistasScreen;