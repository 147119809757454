import Swal from "sweetalert2";
import { url } from "../api/api";
import { loadJWT } from "./loadJWT";




export const loadComisionistas = async() => {
    let comisionistas = [];
    const { token } = loadJWT();
    await url.get('/comisionistas?filter={"where": {"FechaBaja": {"eq": null}}}', {headers:{
        'Authorization': `Bearer ${ token }`,
    }})
    .then( response => {
        comisionistas = response.data.map( resp => resp);
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        return;
    })
    
    return comisionistas;
}




