import { types } from "../types/types"


const initialState = {
    services: [],
    active: null,
    rate: null,
}

export const servicesSuppliersReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case types.servicesActive:
            return {
                ...state,
                active: {
                    ...action.payload
                },
            }
        case types.setActiveRate:
            return {
                ...state,
                rate: {
                    ...action.payload[0]
                }
            }
        case types.servicesLoad:
            return {
                ...state,
                services: [...action.payload]
            }
        
        case types.servicesDelete:
            return {
                ...state,
                active: null,
                services: state.services.filter( service => service.PkServiciosProveedores != action.payload )
            }
        case types.servicesResellerDelete:
            return {
                ...state,
                active: null,
                services: state.services.filter( service => service.ServicioProveedor.PkServiciosProveedores !== action.payload )
            }
        case types.servicesResellerUpdate:
            return {
                ...state,
                services: state.services.map(
                    service => service.ServicioProveedor.PkServiciosProveedores == action.payload.id
                    ?
                    action.payload.service
                    :
                    service
                )
            }
        case types.serviceRateLogOut:
            return {
                ...state,
                rate: null
            }
        case types.servicesLogOutCleaning:
            return {
                ...state,
                active: null
            }

        default:
            return state;
    }
}
