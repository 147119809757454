
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { ROLADMIN, ROLCOLABORADOR, ROLREVENDEDOR, ROLVENDEDOR } from '../../../utils/constants';
import { Check } from '@material-ui/icons';

export const Payments = ( props) => {
    const { cards } = useSelector(state => state.dashboard);
    const { idRol } = useSelector( state => state.auth);
    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardContent sx={{ minHeight: 140 }}>
            <Grid
                container
                spacing={3}
                sx={{ justifyContent: 'space-between' }}
            >
                <Grid item>
                {
                    idRol === ROLADMIN
                    &&
                    <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="overline"
                    >
                        Presupuestos Ptes de Envío
                    </Typography>
                }
                {
                    (idRol === ROLREVENDEDOR || idRol === ROLVENDEDOR)
                    &&
                    <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="overline"
                    >
                        Número de Ventas
                    </Typography>
                }
                {
                    idRol === ROLCOLABORADOR
                    &&
                    <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="overline"
                    >
                        Número de Líneas Confirmadas
                    </Typography>
                }
                {
                    idRol === ROLADMIN
                    &&
                    (
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {
                                cards?.NumeroPresupuestosPendientesEnviar || 0
                            }
                        </Typography>
                    )
                }
                {
                     (idRol === ROLREVENDEDOR || idRol === ROLVENDEDOR)
                    &&
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {
                                cards?.NumeroVentas || 0
                            }
                        </Typography>
                    
                }
                {
                    idRol === ROLCOLABORADOR
                    &&
                    (
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {
                                cards?.NumeroLineasConfirmadas || 0
                            }
                        </Typography>
                    )
                }
                </Grid>
                <Grid item>
                <Avatar
                    sx={{
                    backgroundColor: 'success.main',
                    height: 56,
                    width: 56
                    }}
                >
                    <Check />
                </Avatar>
                </Grid>
            </Grid>
            </CardContent>
        </Card>
    )
}
