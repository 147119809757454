
//Third-party imports
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { useFormStyles } from '../../styles/formStyles';
import { Field, Form, Formik } from 'formik';
import { validationSchemaSupplier } from '../../utils/schemaValidationForm';
import { loadGridSupplier } from '../../helpers/loadGrid';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { startNewSupplier } from '../../redux/actions/suppliers';
import { FormikDatePicker } from '../ui/FormikDatePicker';
import { Box } from '@mui/system';
import { startLogOutContentModal } from "../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../redux/actions/ui";
import { SwitchMUI } from '../ui/Switch';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

/**
 * 
 * Author: QBK
 * Date: 30-09-2021
 */
export const ContentModalSupplierAdd = () => {
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions 
    const { data } = loadGridSupplier();
    const { id: idLoggedIn } = useSelector(state => state.auth)
    const result = data.filter( item => item?.type !== 'detail');

    const initialValues = {
        Nombre: '',
        Email: '',
        Telefono: '',
        PaginaWeb: '',
        Observaciones: '',
        date: null,
        UsaTextoVoucher: false,
        TextoVoucher: ""
    };

    

    //Submit form logic
   
    const handleSubmit = ( values, {setSubmitting} ) => {
        const newSupplier = {
            "Nombre": values.Nombre,
            "Email": values.Email,
            "Telefono": values.Telefono,
            "PaginaWeb": values.PaginaWeb,
            "Observaciones": values.Observaciones ? values.Observaciones : "",
            "FechaBaja": values.date,
            "UsuarioAlta": idLoggedIn,
            "VersionFila": 0,
            "UsaTextoVoucher": values.UsaTextoVoucher,
            "TextoVoucher": values.TextoVoucher || ""
        }

        dispatch( startNewSupplier( newSupplier, setSubmitting ) )
    }

    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };

    //HTML Grid Container
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik initialValues={initialValues} validationSchema={ validationSchemaSupplier }   onSubmit={handleSubmit}>
        {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid , setFieldValue}) => (
        <Form>
        <Grid item style={{ padding: '2rem'}}>
            <Grid container direction='column'>
                
                <Grid item classes={{root: classes.container }}>
                    <Typography variant='h1'>Agregar Proveedor</Typography>
                </Grid>

                {
                        result.map( element => {
                            return(
                                <Grid item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name={element.name}
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-${element.name}`}  
                                        label={ element.label }
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { element.startAdornment }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values[element.name]}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched[element.name] && Boolean(errors[element.name])}
                                        helperText={touched[element.name] && errors[element.name]}
                                        disabled={ isSubmitting }
                                        multiline={ element.name === 'Observaciones' ? true : false}
                                        maxRows={ element.name === 'Observaciones' ? 10 : undefined }
                                        minRows={ element.name === 'Observaciones' ? 5 : undefined }
                                    />
                                </Grid>
                            )
                        })
                    }


<Grid
                container
                spacing={3}
                
            >
                
                <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        
                    <SwitchMUI 
                        label='Utilizar texto Voucher personalizado'
                        name='UsaTextoVoucher'
                        values={ values }
                        setFieldValue={ setFieldValue }
                    />

                    </Grid>


                    </Grid>

                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                                         
                        <CKEditor                   
                            config = {{
                                toolbar: {
                                    items: [ 'bold', 'italic', '|', 'undo', 'redo', '-', 'numberedList', 'bulletedList' ],
                            
                                    shouldNotGroupWhenFull: true
                                }
                            }
                            }
                            editor={ ClassicEditor }
                            data={ values.TextoVoucher }
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                
                            } }
                            onChange={ ( _, editor ) => {
                                const data = editor.getData();
                                setFieldValue('TextoVoucher', data);
                            } }
                            
                        />

 
            
                    </Grid>


                        <Grid item  classes={{root: classes.container }}>
                            <Field style={{fontSize: '0.8em'}} component={FormikDatePicker} name="date" textfield='date' label='Fecha Baja' isSubmitting={ isSubmitting } />
                        </Grid> 
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Button 
                            style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                            onClick={ handleCancel }>
                                <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                        </Button>

                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            type='submit'
                            loading={ isSubmitting }
                            disabled={ !isValid }
                            style={{
                                backgroundColor: '#E5824F',
            
                            }}
                        >
                                Agregar
                        </LoadingButton>
                    </Box>



            </Grid>
        </Grid>
        </Form>
        )}
        </Formik>
        </LocalizationProvider>
    )
}
