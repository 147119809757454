import { Fab } from 'react-tiny-fab';
import React from 'react'
import { Add } from '@material-ui/icons';
import { useSelector } from 'react-redux';



export const ButtonFloatNoOptions = ({ action }) => {
    const mainButtonStyles = {backgroundColor: '#E6824F'};
    const { modal } = useSelector(state => state.modal)
    return (
        <>
            {
                modal
                ?
                <></>
                :
                <Fab
                    mainButtonStyles={mainButtonStyles}
                    icon={ <Add />}
                    onClick={ action }
                >
                </Fab> 
            }
             
        </>
    )   
}
