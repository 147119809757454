import { types } from "../types/types";

const initialState = {
    roles: []
}

export const rolesReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case types.rolesLoad:
            return {
                ...state,
                roles: [...action.payload]
            }
        default:
            return state;
    }
}