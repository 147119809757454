import { types } from '../types/types';


const initialState = {
    modalNotification: false
}
export const notificationReducer = ( state = initialState, action) => {
    
    switch (action.type) {
        case types.uiManageModalNotifications:
            return {
                ...state,
                modalNotification: !state.modalNotification   
            }
            
    
        default:
            return state
    }

}
