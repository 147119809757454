
import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadExchangeRates } from "../../helpers/loadExhangeRates";
import { loadJWT } from "../../helpers/loadJWT";
import { types } from "../types/types";
import { startLogOutContentModal } from "./contentModal";
import { setActiveSupplier } from "./suppliers";
import { finishLoading, finishLoadingModal, startLoading, startLoadingModal, uiManageModalCollectionTypes } from "./ui";




export const startNewExchangeRate = ( rate, setSubmitting ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const active = getState().supplier.active;
        await url.post('/tipos-cambio-proveedores', rate, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            if( active ){
                dispatch(setActiveSupplier(active.PkProveedores));
                Swal.fire('Success', 'Tipo de cambio añadido', 'success');
                return;
            }
            dispatch(startLoadingRates());
            Swal.fire('Success', 'Tipo de cambio añadido', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se ha podido añadir el tipo de cambio', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(uiManageModalCollectionTypes()); // we close the modal
            dispatch(startLogOutContentModal())
        })
    }
}

export const startDelete = ( id ) => {
    return async(dispatch) => {
        const { token } = loadJWT();
        await url.delete(`/tipos-cambio-proveedores/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            dispatch((deleteRate(id)));
            Swal.fire('Success', 'Tipo de cambio eliminado', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error al eliminar el tipo de cambio', 'error')
            return;
        })
    }
}



export const setActiveExchangeRate = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.get(`/tipos-cambio-proveedores/${ id }`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            }
        })
        .then( ( resp ) => {
            dispatch( setActive( resp.data ));
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error', 'error')
        })
    }
}



export const setActive = ( rate ) => ({
    type: types.exchangeRateActive,
    payload: rate,
})

export const startLoadingRates = () => {
    return async(dispatch) => {
        dispatch(startLoading())
        const rates = await loadExchangeRates();
        dispatch(setRates(rates));
        dispatch(finishLoading())
    }
}


export const setActiveExchangeRateToSupplier = ( idProveedor, typeService ) => {
    const { token } = loadJWT();
    return async( dispatch, getState ) => {
        const { modal } = getState().modal
        dispatch(startLoadingModal())
        await url.get(`/tipos-cambio-proveedores?filter={"where": {"FechaBaja": null, "FkProveedores": ${ idProveedor }, "FkTiposServicio": ${ typeService }}}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActive(resp.data[0] || null))
        })
        .catch( e => {
            if( modal ){
                Promise.resolve( dispatch(uiManageModalCollectionTypes()))
                .then( () => {
                    dispatch(startLogOutContentModal())
                })
                .then( () => {
                    Swal.fire('Error',  e?.response?.data?.error?.message || 'Error al cargar el tipo de cambio', 'error')
                })
            }
            else{
                Swal.fire('Error',  e?.response?.data?.error?.message || 'Error al cargar el tipo de cambio', 'error')
            }
            
        })
        .finally( () => {
            dispatch(finishLoadingModal())
        }
        )
    }
}

export const setCurrentLineRate = ( rate ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {

        await url.get(`/tipos-cambio-proveedores/${ rate }`, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActive(resp.data));
        })
    }
}

export const setActiveExchangeRateAdd = ( idProveedor ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.get(`/tipos-cambio-proveedores?filter={"where": {"FechaBaja": null, "FkProveedores": ${ idProveedor }}}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActive(resp.data || null))
        })
        .catch( e => {
            Promise.resolve(dispatch(uiManageModalCollectionTypes()))
            .then( () => {
                dispatch(startLogOutContentModal())
            })
            .then( () => {
                Swal.fire('Error',  e?.response?.data?.error?.message || 'Error al cargar el tipo de cambio', 'error')
            })
        })
    }
}

export const setRates = (rates) => ({
    type: types.exchangeRateLoad,
    payload: rates,
})

const deleteRate = ( id ) => ({
    type: types.exchangeRateDelete,
    payload: id
}) 

export const startLogOutActiveRate = () => {
    return async( dispatch ) => {
        dispatch(logOutActiveRate())
    }
}

const logOutActiveRate = () => ({
    type: types.exchangeRateLogOutActive
})