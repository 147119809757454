import {  Checkbox, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {  DeleteOutlineOutlined, Web } from '@material-ui/icons';
import { Box } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonFloatNoOptions } from '../../components/ui/ButtonFloatNoOptions';
import { Layout } from '../../components/ui/Layout'
import { NotificationModal } from '../../components/ui/NotificationModal';
import { TransitionsModal } from '../../components/ui/TransitionModal';
import { ServicesResellers } from '../../components/users/resellers/servicesResellers';
import { useTableParams } from '../../hooks/useTableParams';
import { actionAddSupplierService, actionDeleteService, actionEditService } from '../../redux/actions/actions';
import { startLogOutCleanSuppliers } from '../../redux/actions/suppliers';
import { startLoadingAllServices, startLoadingServices, startLoadingServicesReseller } from '../../redux/actions/supplierServices';
import { finishError } from '../../redux/actions/ui';
import { ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR } from '../../utils/constants';

import moment from 'moment-timezone';

moment.tz.setDefault("Europe/Madrid");

const ServicesScreen = () => {
    let data = [];
    const showColumnAction = false;
    const theme = useTheme();
    const dispatch = useDispatch();
    const {  idRol, id, idAgencia } = useSelector(state => state.auth);
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const { menuState } = useSelector(state => state.ui);
    const { modal } = useSelector(state => state.modal)
    const { services } = useSelector(state => state.service)
    const userOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: 'Servicios Proveedores', rowsPerPage: 5};
    const userColumnsDeskTop =  [
        ['Tipo de Servicio', true, !matchesXS], ['Nombre', true],  
        ['Coste($)', false, !matchesXS], ['Coste(€)', false, !matchesXS], 
        ['Reserva(€)', false, !matchesXS], ['Reserva($)', false, !matchesXS], 
        ['Precio Venta(€)', false, !matchesXS], ['Precio Venta($)', false, !matchesXS],
        ['Tasa ($)', false, !matchesXS], 
        ['Proveedor', true],['Fecha Baja', false, false],['Id', false, false]];        
    const actions = [
        { isShown: true, type: 'delete',  color: '#000', icon: <DeleteOutlineOutlined />, eject: ( dataIndex, e ) => handleDelete( dataIndex, e )},
        { isShown: true, type: 'custom', color: '#000', icon: <Web />, eject: ( dataIndex, e ) => handleOpenNavigator( dataIndex, e )},
    ]
    const { options, columns } = useTableParams( userOptions,  userColumnsDeskTop , actions, idRol !== ROLADMIN ? showColumnAction : true, ( idRol === ROLADMIN ) && ((dataIndex, e) => handleEdit(dataIndex, e)));
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    useEffect(() => {
        if( idRol === ROLADMIN || idRol === ROLVENDEDOR ){
            if( !checked ){
                dispatch( startLoadingServices() )
            }
            else{
                dispatch(startLoadingAllServices())
            }
        }
        else if( idRol === ROLREVENDEDOR ){
            dispatch( startLoadingServicesReseller( (idAgencia>0)?idAgencia:id ) );
        }
    }, [ dispatch, idRol, id, checked ]);

    useEffect(() => {
        dispatch(startLogOutCleanSuppliers())
    }, [ dispatch ]);


    useEffect(() => {
        dispatch(finishError())
    }, [dispatch, modal])
 
    
    data = services.map( service => { 
        return [ 
            service.NombreTipoServicio, service.NombreServicio, 
            service?.CosteDolares + ' $', service?.InSituEuros + ' €', 
            service?.ReservaEuros + ' €', service?.ReservaDolares + ' $', 
            service?.PrecioVentaEuros + ' €', service?.PrecioVentaDolares + ' $', service?.TasaDolares + ' $', 
            service?.NombreProveedor, service?.FechaBaja ? moment(service?.FechaBaja).format('DD/MM/YYYY HH:mm') : '',
            service?.PkServiciosProveedores    
        ];
    });

    options.setRowProps = (row, dataIndex, rowIndex) => {        
        const id = row[11];
        const servicio = services.filter( service => service.PkServiciosProveedores==id);
        if (servicio.length>0 && servicio[0].FechaBaja) {
          return {
            style: { backgroundColor: 'salmon' }
          };
        }
      };

    const handleAdd = () => {
        dispatch( actionAddSupplierService() );
    }
    const handleDelete = (dataIndex, e ) => {
        if (e) e.stopPropagation();
        dispatch( actionDeleteService( services[ dataIndex ].PkServiciosProveedores ) )
    }
    const handleEdit = (dataIndex, e ) => {
        if (e) e.stopPropagation();
        dispatch( actionEditService( services[ dataIndex ].PkServiciosProveedores ) )
        
    }

    const handleOpenNavigator = ( dataIndex, e) => {
        if (e) e.stopPropagation();
        console.log(dataIndex);
        console.log(services);
        console.log(services[ dataIndex ]);
        

        window.open(services[dataIndex].PaginaWeb)
    } 
    return (
        <Layout>
            {
                idRol === ROLREVENDEDOR

                &&

                <ServicesResellers />

                
            }

            {
                ( idRol === ROLADMIN || idRol === ROLVENDEDOR )

                &&

            <>
               <Helmet>
                <title>Servicios | Xplora</title>
                </Helmet>

            <div style={{
                    marginTop: '2rem',
                    width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}>

                 {
                    idRol === ROLADMIN
                    &&
                    (
                            !matchesXS
                            &&
                            <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>SERVICIOS</Typography>
            
                            
                    )
                   
                }

                <MUIDataTable
                    title={  idRol === ROLADMIN && (!matchesXS 
                        ?
                        <Box sx={{ display: 'flex'}}> 
                        <FormControlLabel
                                    label='Incluir dados de baja'
                                    control={
                                        <Checkbox
                                            style={{ marginLeft: '1.7rem'}}
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled', 'label': 'Todos' }}
                                        />
                                    }
                        />
                        </Box>
                        :  
                        (   <Typography variant='h2' style={{ marginLeft: '0.7rem'}}>
                                Servicios 
                            </Typography>
                        )) }
                    {...{data, columns, options}}
                    
                />

                <TransitionsModal/>
                <NotificationModal />
                {
                    idRol === ROLADMIN
                    &&
                    <ButtonFloatNoOptions 
                        action={ handleAdd }
                    />
                }
                

            </div>
            </>

            }
            
            
        </Layout>
    )
}



export default ServicesScreen;