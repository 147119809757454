import { Checkbox, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons';
import { Box } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonFloatNoOptions } from '../../components/ui/ButtonFloatNoOptions';
import { Layout } from '../../components/ui/Layout';
import { NotificationModal } from '../../components/ui/NotificationModal';
import { TransitionsModal } from '../../components/ui/TransitionModal';
import { CoordinatorSuppliers } from '../../components/users/coordinator/coordinatorSuppliers';
import { useTableParams } from '../../hooks/useTableParams';
import { actionAddSupplier, actionDeleteSupplier, actionEditSupplier } from '../../redux/actions/actions';
import { startLogOutRedirect } from '../../redux/actions/routeManagement';
import { startLoadingAllSuppliers, startLoadingSuppliers, startLoadingSuppliersFromCoordinator, startLogOutCleanSuppliers } from '../../redux/actions/suppliers';
import { ROLADMIN, ROLCOLABORADOR } from '../../utils/constants';

const SuppliersScreen = () => {
    const theme = useTheme();
    let data = [];
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const { suppliers } = useSelector(state => state.supplier );
    const showColumnAction = false;
    const {  idRol, id } = useSelector(state => state.auth);
    const { menuState } = useSelector(state => state.ui)
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch();
    const supplierOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: 'Proveedores', rowsPerPage: 5 };
    const supplierColumnsDesktop =  [['Nombre', true], ['Telefono', true, !matchesXS], ['Página Web', false, !matchesXS], ['Cambio Dólares Tour', false, !matchesXS], ['Cambio Euros Tour', false, !matchesXS], ['Cambio Dólares Parque', false, !matchesXS], ['Cambio Euros Parque', false, !matchesXS], ];
    const actions = [
        { isShown: true, type: 'delete', color: '#000', icon: <DeleteOutlineOutlined />, eject: ( dataIndex, e) => handleDelete( dataIndex, e)},
       //{ isShown: true, type: 'edit', data: suppliers, link: 'suppliers', key: 'PkProveedores',  color: '#000', icon: <EditOutlined />, eject: ( dataIndex ) => handleEdit( dataIndex )},
       ]
    const { options, columns } = useTableParams( supplierOptions, supplierColumnsDesktop, actions, idRol !== ROLADMIN ? showColumnAction : true, (dataIndex, e) => handleEdit(dataIndex, e));
    

    

    useEffect(() => {
        if( idRol === ROLADMIN ){
            if(!checked){
                dispatch(startLoadingSuppliers());
            }
            else{
                dispatch(startLoadingAllSuppliers())
            }
        }
        else if( idRol === ROLCOLABORADOR ){
            dispatch(startLoadingSuppliersFromCoordinator( id ))
        }
        
    }, [dispatch, idRol, id, checked]);

    useEffect(() => {
        dispatch(startLogOutRedirect())
    }, [ dispatch ])

    useEffect(() => {
        dispatch(startLogOutCleanSuppliers())
    }, [ dispatch ])

    const handleAdd = () => {
        dispatch(actionAddSupplier());
    }
    
    const handleDelete = (dataIndex, e ) => {
        if (e) e.stopPropagation();
        dispatch(actionDeleteSupplier( suppliers[ dataIndex ].PkProveedores ))
    }

    const handleEdit = (dataIndex, e ) => {
        if (e) e.stopPropagation();
        dispatch( actionEditSupplier( suppliers[ dataIndex ].PkProveedores ) )
        
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    
    
     
    
    data = suppliers.map( supplier => { return [supplier?.Nombre, supplier?.Telefono || '-', supplier?.PaginaWeb || '-', supplier?.CambioDolaresTour  || '-', supplier?.CambioEurosTour || '-', supplier?.CambioDolaresParque || '-', supplier?.CambioEurosParque || '-', supplier?.FechaBaja]; });

    options.setRowProps = (row, dataIndex, rowIndex) => { 
        if (data[rowIndex][7] !== null) {
          return {
            style: { backgroundColor: 'salmon' }
          };
        }
      };
    
    
    
    
    
    return (
        <Layout>
            <Helmet>
                <title>Proveedores | Xplora</title>
            </Helmet>

            {
                idRol === ROLCOLABORADOR

                &&

                <CoordinatorSuppliers />

                
            }

            {
                idRol === ROLADMIN
                &&
            
            <div style={{
                    marginTop: '2rem',
                    width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}>
                {
                    !matchesXS
                    &&
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>PROVEEDORES</Typography>
                }

                <MUIDataTable
                    title={ !matchesXS ?
                        <Box sx={{ display: 'flex'}}>
                            
                            <FormControlLabel
                                    label='Incluir dados de baja'
                                    control={
                                        <Checkbox
                                            style={{ marginLeft: '1.7rem'}}
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled', 'label': 'Todos' }}
                                        />
                                    }
                            />
                        </Box>
                            :  
                            (   <Typography variant='h2' style={{ marginLeft: '0.7rem'}}>
                                    Proveedores 
                                </Typography>
                            ) }
                    {...{data, columns, options}}
                    
                />

                <TransitionsModal />
                <NotificationModal />

                <ButtonFloatNoOptions 
                action={ handleAdd }
               />

            </div>
            }
        </Layout>
    )
}

export default SuppliersScreen;