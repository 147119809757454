import React, { useEffect } from 'react'

//Componentes visuales
import {
    Box,
    Container,
} from '@material-ui/core';

//Dependencias funcionales
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import jwt_decode from "jwt-decode";
import { startVerifyingToken, loginUserWithToken } from '../../redux/actions/auth';

import { useSelector } from 'react-redux';

//Dependencias estilos
import { Helmet } from 'react-helmet';
import { useLoginStyles } from '../../styles/loginStyles';

//Imagenes
import logo from '../../images/logo.png'
import backImage from '../../images/back-login.png';

import moment from 'moment-timezone';

const LoginDirecto = props => {

    const classes = useLoginStyles();
    const dispatch = useDispatch();
    const { id } = useSelector(state => state.auth);

    useEffect(() => {
        if(id) {
            navigate('/');
        }

        let token = props.location.search.slice(3); // props.t;
        token = atob(token)
        let decodedJWT = '';

        try {
            decodedJWT = jwt_decode(token);
        }
        catch (err) {
            navigate('/login');
        }

        if (decodedJWT.exp * 1000 < Date.now()) {
            console.log('Error de tiempo')
            navigate('/login');
            return;
        }

        dispatch(startVerifyingToken(token)).then(
            () => {
                dispatch(loginUserWithToken(token, decodedJWT.id)).then(  
                    () => {
                        navigate('/');
                    }
                )
            }
        ).catch( (e) => {
            navigate('/login');
        } ) 
        
    }, [props.location.search, dispatch]);
    moment.tz.setDefault("Europe/Madrid");



    return (

        <div
            style={{
                backgroundImage: `url(${backImage}) `,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                objectFit: 'cover'

            }}
            className='body'
        >
            <Helmet>
                <title>Login | Xplora</title>
            </Helmet>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10rem',
                }}
            >
                <Box style={{
                    zIndex: 999,
                }}>
                    <img src={logo} alt='logo' className={classes.logo} style={{ marginLeft: '2rem' }} />
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.85)',
                        borderRadius: 7,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '35vh',
                        width: '55vw',
                        justifyContent: 'center',
                        minWidth: 330.4,
                        maxWidth: 700,
                        maxHeight: 400,
                        minHeight: 300,
                    }}

                >
                    <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <h1 style={{ fontFamily: 'Roboto', fontSize: '34px', lineHeight: '24px' }}>Iniciando sesión, por favor espere</h1>
                    </Container>
                </Box>
            </div>
        </div>
    )
}

export default LoginDirecto;
