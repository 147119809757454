import React from 'react';

import { AccountCircle, CardTravel, DashboardOutlined, DragHandle, Group, RecordVoiceOver, SettingsApplicationsRounded, ShoppingCart } from '@material-ui/icons';
import { ROLADMIN, ROLCOLABORADOR, ROLREVENDEDOR, ROLVENDEDOR } from '../utils/constants';


export const menuItems = [
    {
        name: 'Dashboard',
        route: '/',
        role: [ROLADMIN, ROLCOLABORADOR, ROLREVENDEDOR, ROLVENDEDOR],
        icon: <DashboardOutlined />  
    },
    {
        name: 'Usuarios',
        route: '/users',
        role: [ROLADMIN],
        icon: <AccountCircle /> 
    },
    {
        name: 'Proveedores',
        route: '/suppliers',
        role: [ROLADMIN, ROLCOLABORADOR],
        icon: <RecordVoiceOver /> 

    },    
    {
        name: 'Servicios',
        route: '/services',
        role: [ROLADMIN, ROLVENDEDOR, ROLREVENDEDOR],
        icon: <CardTravel /> 
    },
    {
        name: 'Clientes',
        route: '/clients',
        role: [ROLADMIN],
        icon: <Group/> 
    },
    {
        name: 'Comisionistas',
        route: '/comisionistas',
        role: [ROLADMIN],
        icon: <Group/> 
    },
    {
        name: 'Ventas',
        route: '/sales',
        role: [ROLADMIN, ROLVENDEDOR, ROLREVENDEDOR],
        icon: <ShoppingCart /> 
    },
    {
        name: 'Líneas',
        route: '/lines',
        role: [ROLADMIN, ROLVENDEDOR, ROLREVENDEDOR, ROLCOLABORADOR],
        icon: <DragHandle />

    },
    /*{
        name: 'Tipos Cambio',
        route: '/exchanges',
        role: [ROLADMIN],
        icon: <MonetizationOnOutlined /> 

    },*/
    {
        name: 'Ajustes',
        route: '/settings',
        role: [ROLADMIN],
        icon: <SettingsApplicationsRounded /> 

    },

]






