import axios from "axios";
import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadJWT } from "../../helpers/loadJWT";
import { ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR } from "../../utils/constants";
import { types } from "../types/types";

export const startLoadingDashboardData = () => {
    return async (dispatch, getState) => {
        const { token  } = loadJWT();
        const { idRol } = getState().auth;
        let data1 = null;
        let data2 = null;

        switch(idRol) {
            case ROLADMIN:
                data1 =  url.get('/estadisticas-admin', { headers: { Authorization: `Bearer ${token}` } });
                data2 = url.get('/estadisticas-admin-meses', { headers: { Authorization: `Bearer ${token}` } });
                break;
            case ROLVENDEDOR:
                data1 =  url.get('/estadisticas-vendedor', { headers: { Authorization: `Bearer ${token}` } });
                data2 = url.get('/estadisticas-vendedor-meses', { headers: { Authorization: `Bearer ${token}` } });
                break;
            case ROLREVENDEDOR:
                data1 =  url.get('/estadisticas-vendedor', { headers: { Authorization: `Bearer ${token}` } });
                data2 = url.get('/estadisticas-vendedor-meses', { headers: { Authorization: `Bearer ${token}` } });
                break;
            default:
                data1 =  url.get('/estadisticas-coordinador', { headers: { Authorization: `Bearer ${token}` } });
                data2 = url.get('/estadisticas-coordinador-meses', { headers: { Authorization: `Bearer ${token}` } });
            break;

        }

        await axios.all([data1, data2])
        .then(([res1, res2]) => {
            dispatch(loadData(res1.data, res2.data))
        })
        .catch(err => {
            Swal.fire('Error', err?.response?.data?.message || 'No se han podido cargar los datos', 'error')
        }
        )
        
    }
}

const loadData = ( data1, data2 ) => ({
    type: types.loadDashboard,
    cards: {
        ...data1
    },
    graphic: {
        ...data2
    }
})


export const startLogOutDashBoard = () => {
    return async( dispatch ) => {
        dispatch(logOutDashboard());
    }
}

const logOutDashboard = () => ({
    type: types.logOutDashboard
})



