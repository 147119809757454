
export const obtenerPrecioEuros = (precioDolares, cambioEuros, cambioDolares) => {
    console.log('obtenerPrecioEuros');
    console.log({precioDolares, cambioEuros, cambioDolares});
    return Math.round(precioDolares * cambioDolares / cambioEuros);
}

export const obtenerPrecioDolares = (precioEuros, cambioEuros, cambioDolares) => {
    console.log('obtenerPrecioDolares');
    console.log({precioEuros, cambioEuros, cambioDolares});
    return Math.round(precioEuros * cambioEuros / cambioDolares);
}