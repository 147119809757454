import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadJWT } from "../../helpers/loadJWT";
import { loadServicesReseller } from "../../helpers/loadServicesReseller";
import { loadServicesSupplier } from "../../helpers/loadServicesSupplier"
import { types } from "../types/types";
import { finishError, finishLoading, finishLoadingModal, startError, startLoading, startLoadingModal, uiManageModalCollectionTypes } from "./ui";
import { actionEditSupplier } from '../actions/actions'
import { startLogOutContentModal } from "./contentModal";
import { navigate } from "@reach/router";
import { setActiveSupplierState } from "./suppliers";
import { startContentModalEditResellerService } from "./contentModal";


export const startNewService = ( service, setSubmitting )   => {
    const { token } = loadJWT();
    return async(dispatch, getState) => {
        const active = getState().supplier.active;
        await url.post('/servicios-proveedores', service, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            setSubmitting( false )
            if( active ){
                Swal.fire('Éxito', 'Servicio Proveedor Añadido', 'success');
                dispatch(actionEditSupplier( active.PkProveedores ))
                return;
            }
            dispatch(startLoadingServices());
            dispatch(logOutRate());
            Swal.fire('Éxito', 'Servicio Añadido', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
            return;
        })
        .finally( () =>{ 
            dispatch(uiManageModalCollectionTypes()); // we close the modal
            dispatch(startLogOutContentModal())           
            setSubmitting( false );
        })

    }
}


export const setActiveService = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch, getState ) => {
        dispatch(startLoading());
        await url.get(`/servicios-proveedores/${ id }?filter[include][]=TipoServicio&filter[include][]=ProveedorServicio`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            }
        })
        .then( ( resp ) => {
            if( resp.status === 204){
                navigate('/services');
                dispatch(finishLoading());
            }
            dispatch( setActive( resp.data ));
        })
        .then(() => {
            const idProveedor = getState().service.active.ProveedorServicio.PkProveedores;
            const idTipoServicio = getState().service.active.TipoServicio.PkTiposServicio;
            dispatch(startExchangeRateToSupplier( idProveedor, idTipoServicio ))
        })
        .then( () => {
            navigate(`/services/${ id }`)
            dispatch(finishLoading());
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'El recurso solicitado no está disponible', 'error')
            .then( () => { 
                navigate('/services');
                dispatch(finishLoading()); 
            })
        })
    }
}

export const setActiveServiceState = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.get(`/servicios-proveedores/${ id }?filter[include][]=TipoServicio&filter[include][]=ProveedorServicio`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            }
        })
        .then( ( resp ) => {
            dispatch( setActive( resp.data ));
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'El recurso solicitado no está disponible', 'error')
            
        })
    }
}

export const setActiveServiceReseller = ( idService, isEdit ) => {
    const { token } = loadJWT();
    return async( dispatch, getState ) => {
        dispatch(startLoadingModal())
        const idUsuario = getState().user.active.PkUsuarios;
        await url.get(`/servicios-revendedores/${ idUsuario }/${ idService }`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            }
        })
        .then( ( resp ) => {
            url.get(`/servicios-proveedores/${ idService }`, {
                headers: {
                    Authorization: `Bearer ${ token }`,
                }
            })
            .then( ( respServicio ) => {

                resp.data.Servicio = respServicio.data;
                dispatch( setActive( resp.data ));
                if (isEdit) dispatch(startContentModalEditResellerService())
            })
            .catch( e => {
                Swal.fire('Error', e?.response?.data?.error?.message, 'error');
            })
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
        })
        .finally( () => {
            dispatch(finishLoadingModal())
        })
    }
}


export const setActive = ( service ) => ({
    type: types.servicesActive,
    payload: service,
})


export const startLoadingServices = () => {
    return async( dispatch ) => {
        dispatch(startLoading())
        const services = await loadServicesSupplier();
        dispatch( setServices( services ));
        dispatch(logOutRate());
        dispatch(finishLoading())
    }
}



export const startLoadingAllServices = () => {
    return async(dispatch) => {
        const { token } = loadJWT();
        dispatch(startLoading())
        await url.get('/servicios-proveedores-completos', {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( ( resp ) => {
            dispatch(setServices(resp.data));
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
        })
        .finally( () => {
            dispatch(finishLoading())
        })
        
        
    }
}



export const startDeleteService = ( id ) => {
    const { token } = loadJWT();
    return async(dispatch, getState) => {
        const active = getState().supplier.active;
        await url.delete(`/servicios-proveedores/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            if( active ){
                dispatch(actionEditSupplier(active.PkProveedores));
                Swal.fire('Éxito', 'Servicio eliminado', 'success');
                return;
            }
            dispatch((deleteService(id)));
            Swal.fire('Éxito', 'Servicio eliminado', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
            return;
        })
    }
}

export const startUpdateServiceSupplier = ( service, setSubmitting ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.put(`/servicios-proveedores/${ service.PkServiciosProveedores }`, service, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            Swal.fire('Éxito', 'Servicio Proveedor Editado', 'success')
            .then( () => {
                navigate('/services');
            })
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'no se ha podido eidtar el proveedor', 'error')
            .then( () => {
                navigate('/services');
            } )
            return;
        })
        .finally( () => {
            setSubmitting( false)
        })

    }
}

export const startUpdateServiceSupplierFromSupplier = ( service, setSubmitting ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.put(`/servicios-proveedores/${ service.PkServiciosProveedores }`, service, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch(uiManageModalCollectionTypes())
            dispatch(startLogOutContentModal())
            Swal.fire('Éxito', 'Servicio Proveedor Editado', 'success')
            .then( () => {
                dispatch(setActiveSupplierState(service.FkProveedores))
            })
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'no se ha podido eidtar el proveedor', 'error')
            .then( () => {
                navigate('/services');
            } )
            return;
        })
        .finally( () => {
            setSubmitting( false)
        })

    }
}

export const startDeleteServiceReseller = ( idUsuario, idService ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.delete(`/servicios-revendedores/${ idUsuario }/${ idService }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            dispatch((deleteServiceReseller(idService)));
            Swal.fire('Éxito', 'Servicio eliminado', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error')
            return;
        })
    }
}

export const startExchangeRateToSupplier = ( idProveedor, idTipoServicio ) => {
    const { token } = loadJWT();
    return async ( dispatch, getState ) => {
        const { modal } = getState().modal
        await url.get(`/proveedores/${ idProveedor }/tipos-cambio-proveedores?filter={"where":{"FechaBaja":{"eq":null}, "FkTiposServicio": {"eq": ${ idTipoServicio }}}}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( ( resp ) => {
            if( resp.data.length === 0){
                dispatch(startError('No hay ningún tipo de cambio activo para el proveedor seleccionado'));
                dispatch(gatherDataRate(resp.data));
                return;
            }
            dispatch(finishError())
            dispatch(gatherDataRate(resp.data))
        })
        .catch( e => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes())
                dispatch(startLogOutContentModal())
            }
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
        })
    }
}

export const startAddServiceReseller = ( service, setSubmitting ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.post('/servicios-revendedores', service, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch(startLoadingServicesReseller(service.FkUsuarios));
            Swal.fire('Éxito', 'Servicio Añadido al Vendedor Externo', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false )
            dispatch(uiManageModalCollectionTypes()); // we close the modal
            dispatch(startLogOutContentModal())
        })

    }
}

export const startEditServiceReseller = ( service, setSubmitting ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.put(`/servicios-revendedores`, service, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch( startLoadingServicesReseller( service.FkUsuarios ) );
            Swal.fire('Éxito', 'Servicio Revendedor Editado', 'success');
            return;
        })
        .catch( (e) => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(uiManageModalCollectionTypes())
            dispatch(startLogOutContentModal())
            dispatch(setActive(null))
        })

    }
}

export const startLoadingServicesReseller = ( id ) => {
    return async( dispatch ) => {
        dispatch(startLoading())
        const services = await loadServicesReseller( id );
        dispatch( setServices( services ));
        dispatch(finishLoading())
       
    }
}

export const startGatheringDataFromServicesReseller = ( id ) => {
    console.log('startGatheringDataFromServicesReseller');
    console.log(id);
    return async( dispatch ) => {
        const { token } = loadJWT();
        dispatch(startLoading());
        await url.get(`/usuarios/${ id }/servicios-revendedores?filter={"include": [{"relation":"ServicioProveedor"}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setServices(resp.data));
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
        })
        .finally( () => {
            dispatch(finishLoading())
        })
    }
}

export const setServices = ( services ) => ({
    type: types.servicesLoad,
    payload: services
})

const gatherDataRate = ( rate ) => ({
    type: types.setActiveRate,
    payload: rate
})

const deleteService = (id) =>({
    type: types.servicesDelete,
    payload: id
})

const deleteServiceReseller = (id) =>({
    type: types.servicesResellerDelete,
    payload: id
})

const logOutRate = () => ({
    type: types.serviceRateLogOut
})

export const startLogOutServicesActive = () => {
    return async( dispatch ) => {
        dispatch(logOutServices());
    }
}

const logOutServices = () => ({
    type: types.servicesLogOutCleaning
})

/*
const getUpdated = ( id, service ) => ({
    type: types.servicesResellerUpdate,
    payload: {
        id,
        service: {
            id,
            ...service
        }
    }
})
*/
