import { Box, Grid } from '@material-ui/core'
import { useParams } from '@reach/router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmLineScreen } from '../../components/lines/ConfirmLineScreen'
import { Layout } from '../../components/ui/Layout'
import { Spinner } from '../../components/ui/Spinner'
import { startGettingLine } from '../../redux/actions/lines'
import { Helmet } from 'react-helmet';

const LineDetailScreen = () => {

    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.ui);
    const params = useParams();
    useEffect(() => {
        dispatch(startGettingLine(params.PkLineas))
    }, [ dispatch, params.PkLineas ]);
    return (
        <Layout>
            {
                !loading

                ?
                <>
                <Helmet>
                <title>Confirmación Línea | Xplora</title>
                </Helmet>
                <Box sx={{ flexGrow: 1 , width: '90vw'}}>
                    <Grid container spacing={3} >
                        <Grid item xs={11} md={6} lg={ 6 }>
                            <ConfirmLineScreen />
                        </Grid>
                        <Grid item xs={11} md={8}>
                            
                        </Grid>
                    </Grid>
                </Box>
                </>

            :

            <Spinner />

            }
            
        </Layout>
    )
}


export default LineDetailScreen;