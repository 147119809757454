import React from 'react'
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { EuroOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR } from '../../../utils/constants';


export const TotalProfit = ( props ) => {
    const  {cards}  = useSelector(state => state.dashboard)
    const { idRol } = useSelector( state => state.auth )
    return (
        <Card {...props}>
            <CardContent sx={{ minHeight: 140 }}>
            <Grid
                container
                spacing={3}
                sx={{ justifyContent: 'space-between' }}
            >
                <Grid item>
                <Typography color="textSecondary" gutterBottom variant="overline">
                    Ventas Pendientes de Pago
                </Typography>
                {
                    (idRol === ROLADMIN )
                    &&
                    (<Typography
                        color="textPrimary"
                        variant="h4"
                    >
                        { Math.round(cards?.TotalVentasPendientePago) || 0 }
                    
                    </Typography>)
                }

                {
                    (idRol === ROLVENDEDOR || idRol === ROLREVENDEDOR )
                    &&
                    (<Typography
                        color="textPrimary"
                        variant="h4"
                    >
                       { cards?.NumeroVentasPendientesPago || 0}
                    
                    </Typography>)
                }
               
                </Grid>
                <Grid item>
                <Avatar
                    sx={{
                    backgroundColor: 'primary.main',
                    height: 56,
                    width: 56
                    }}
                >
                    <EuroOutlined />
                </Avatar>
                </Grid>
            </Grid>
           
            </CardContent>
        </Card>
    )
}
