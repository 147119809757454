import { Button, Box, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field, Form, Formik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormStyles } from '../../../styles/formStyles'
import Autocomplete from '@mui/material/Autocomplete';
import { setExchangerateLineState, startGettingServicesResellerWhenUpdatingState, startResetActiveLine, startSettingExchangeRateOrderLineState, startSettingSuppliersAndServicesRelatedWithOrderLineState, startUpdatingLineState } from '../../../redux/actions/sales'
import { validationSchemaEditLine, validationSchemaEditLineReseller } from '../../../utils/schemaValidationForm'
import { Euro, EuroOutlined, Info, Loyalty, MonetizationOn, MonetizationOnOutlined, People, RemoveRedEye } from '@material-ui/icons'
import { calcularInSituVentaRevendedorDolares, calcularReservaLineaVentaRevendedorDolares, calcularReservaLineaVentaRevendedorEuros, calculateFromDollarsToEuro, calculateFromEuroToDollars } from '../../../utils/utils'
import { startLogOutContentModal } from '../../../redux/actions/contentModal'
import { uiManageModalCollectionTypes } from '../../../redux/actions/ui'
import Swal from 'sweetalert2'
import { ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR, SERVICE } from '../../../utils/constants'
import esLocale from 'date-fns/locale/es'
import { FormikDatePicker } from '../../ui/FormikDatePicker'
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../../utils/exchange'
import { actionSetCurrentUser } from '../../../redux/actions/actions';


export const ContentModalEditLineState = () => {
    const { current : currentUser } = useSelector(state => state.user);
    const { active: { activeLine, services, supplier } } = useSelector(state => state.invoice);
    const { idRol, id: loggedInUser, moneda: monedaUsuario } = useSelector(state => state.auth)
    const { services: currentResellerServices } = useSelector( state => state.service )
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const [servicesArray, setServices] = useState([]);
    const dispatch = useDispatch()
    const [valueService, setValueService] = useState('');
    const [typeService, setTypeService] = useState('');
    const { rate: activeRate } = useSelector(state => state.invoice.active)


    useEffect(() => {        
        dispatch(actionSetCurrentUser(loggedInUser))
    }, [ dispatch, loggedInUser ]);

    //loading suppliers and services
    useEffect(() => {
        if( idRol === ROLADMIN || idRol === ROLVENDEDOR ){
        dispatch( startSettingSuppliersAndServicesRelatedWithOrderLineState( activeLine.FkProveedores ))
        }
        else{
            dispatch(startGettingServicesResellerWhenUpdatingState())
        }
    }, [])

    useEffect(() => {
        if( idRol === ROLADMIN || idRol === ROLVENDEDOR ){
        setServices([])
        services.map( service => {
            setServices( prev => prev.concat({label: service?.Nombre, value: service?.PkServiciosProveedores, tipo: service?.FkTiposServicio }))
            return null;
        })
        }
        else{
        setServices([])
            services.map( service => {
                setServices( prev => prev.concat({
                    label: service?.ServicioProveedor?.Alias ? service?.ServicioProveedor?.Alias : service?.ServicioProveedor?.Nombre,
                    value: service?.ServicioProveedor?.PkServiciosProveedores, tipo: service?.ServicioProveedor?.FkTiposServicio
                }))
                return null;
            })
        }
    }, [ services, idRol ])

    useEffect(() => {
        if( idRol === ROLADMIN || idRol === ROLVENDEDOR ){
            if( typeService && valueService ){
                Promise.resolve(dispatch( startSettingExchangeRateOrderLineState( activeLine.FkProveedores, typeService) ))
                .then( () => {
                    dispatch( startResetActiveLine( valueService ) )
                })
                .catch( () => {
                    Promise.resolve( dispatch(startLogOutContentModal()))
                    .then( () => { dispatch(uiManageModalCollectionTypes()) })
                    .then( () => { Swal.fire('Error', 'Error al cargar la información', 'error') })
                })
            
            }
        }
        else{
            //TODO: revisar esto
        }
    }, [ valueService, dispatch ])

    useEffect( () => {
        dispatch(setExchangerateLineState( activeLine.FkTiposCambioProveedores ))
    }, [ dispatch, activeLine.FkTiposCambioProveedores ])



    const initialValues = {
        FkProveedores: activeLine.FkProveedores,
        FkServiciosProveedores: activeLine.FkServiciosProveedores,
        
        ReservaLineaEuros:  activeLine.ReservaLineaEuros,
             
        PrecioUnitarioEuros: activeLine.ReservaLineaEuros + 
            +calculateFromDollarsToEuro( activeRate, activeLine.InSituLineaDolares), 
        

        InSituLineaDolares: Math.round(activeLine.InSituLineaDolares),

        Unidades: activeLine.Unidades,
        Concepto: activeLine.Concepto,
        Observaciones: activeLine.Observaciones,
        FechaRecogida: activeLine.FechaRecogida,
        PorcDescuento: activeLine.PorcDescuento,
        TasaDolares: activeLine.TasaDolares,
        SuplementoTransporteDolares: activeLine.SuplementoTransporteDolares??0,
                
    }

    const initialValuesReSeller = {
        FkProveedores: activeLine?.FkProveedores,
        FkServiciosProveedores: activeLine?.FkServiciosProveedores,
        Concepto: activeLine?.Concepto,
        
        Unidades: activeLine?.Unidades,
        Observaciones: activeLine?.Observaciones,
        FechaRecogida: activeLine.FechaRecogida,
        PorcDescuento: activeLine.PorcDescuento,
        TasaDolares: activeLine.TasaDolares,
        SuplementoTransporteDolares: activeLine.SuplementoTransporteDolares??0,
        
        ReventaLineaEuros: activeLine?.ReventaLineaEuros,
        //ReventaLineaDolares: +calculateFromEuroToDollars( activeRate, activeLine.ReventaLineaEuros),
        ReventaLineaDolares: +activeLine.ReventaLineaDolares,
    }

    const handleSubmit = ( values, { setSubmitting }) => {
        const id = localStorage.getItem('indexOrderLine')
        if( idRol !== ROLREVENDEDOR ){
        const payload = {
            ...values,
            
            // ReservaLineaEuros: Math.round(+values.ReservaLineaEuros),
            // ReservaDescuento: Math.round(+values.ReservaLineaEuros - (+values.ReservaLineaEuros * (+values.PorcDescuento / 100))),
            ReservaLineaEuros: (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)),
            ReservaDescuento: Math.round(
                (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) 
                - ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) * (+values.PorcDescuento/100))),


            InSituLineaDolares: Math.round(+values.InSituLineaDolares),
            NombreProveedor: supplier.Nombre,
            NombreServicio: servicesArray.find( service => service.value === values.FkServiciosProveedores )?.label,
            FkTiposLineaVenta:  SERVICE,
            FkTiposCambioProveedores: activeRate.PkTiposCambioProveedores,
            CosteDolares: Math.round(+activeLine.CosteDolares),
            ReservaEuros: Math.round(+activeLine.ReservaEuros),
            FkTiposServicio: activeLine.FkTiposServicio,
            Unidades: +values.Unidades,
            InSituLineaEuros: obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares),
            FechaRecogida: values.FechaRecogida,
            PorcDescuento: +values.PorcDescuento,
            TasaDolares: +values.TasaDolares,
            SuplementoTransporteDolares: +values.SuplementoTransporteDolares,
            TasaEuros: obtenerPrecioEuros(values.TasaDolares, activeRate.CambioEuros, activeRate.CambioDolares),

            
            ReventaLineaEuros: values.ReventaLineaEuros??0,
            

            SuplementoTransporteEuros: +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),


        }
        dispatch( startUpdatingLineState( id, payload, setSubmitting ) )
        }
        else{
            // USUARIO REVENDEDOR
            // CÁLCULO EN FUNCIÓN DE LA MONEDA CON LA QUE SE CREA LA VENTA, ES DECIR, SI LA MONEDA ES DÓLARES,
            let _reventaLineaEuros = (monedaUsuario==='EUR') ? 
                Math.round(+values.ReventaLineaEuros) :
                // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                Math.round(100*(+values.ReventaLineaDolares * +activeRate.CambioDolares / +activeRate.CambioEuros))/100;
            
 
            // PRECIO DE COSTE DEL SERVICIO 
            let costeDolares = Math.round(+activeLine.CosteDolares);
            // RESERVA XPLORA DEL SERVICIO --> PRECIO VENTA - COSTE - MARGEN (EN EL SERVICIO DEL REVENDEDOR)
            let reservaEuros = +activeLine.ReservaEuros;
            // REVENTA EUROS: SE ESTÁ COGIENDO REVENTALINEAEUROS -- DEBE COGER EL MARGEN DEFINIDO PARA EL REVENDEDOR EN SU SERVICIO
            let reventaEuros = _reventaLineaEuros; //+activeLine.ReventaEuros;
            // INSITULINEA: IMPORTE QUE VA INSITU EN LA VENTA (sin tener en cuenta el precio de transporte)
            let insituLineaEuros = 
                currentUser.MargenConfiguradoEnServicios ? 
                (+calculateFromDollarsToEuro(activeRate, activeLine?.CosteDolares) +activeLine?.ReservaEuros ) :
                (currentUser.Comisiona ? 
                    (+activeLine.CosteDolares)
                    : 
                    // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                    (+activeLine.CosteDolares)
                    );
            
            let insituLineaDolares = 
                    currentUser.MargenConfiguradoEnServicios ? 
                    (+values.ReventaLineaDolares +activeLine?.CosteDolares  ) :
                    (currentUser.Comisiona ? 
                        (+activeLine.CosteDolares)
                        : 
                        // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                        (+activeLine.CosteDolares)
                        );
            // RESERVALINEA: IMPORTE QUE VA DE RESERVA EN LA LINEA
            let reservaLineaEuros = 
                currentUser.MargenConfiguradoEnServicios ? 
                    +_reventaLineaEuros :
                        (currentUser.Comisiona ? 
                            (+activeLine.ReservaEuros) :
                            (+activeLine.ReservaEuros + +_reventaLineaEuros)
                        );
            let reservaLineaDolares = 
                currentUser.MargenConfiguradoEnServicios ? 
                    + calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) :
                        (currentUser.Comisiona ? 
                            (+calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) ) :
                            (+calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) + +values.ReventaLineaDolares)
                        );
            // REVENTALINEA: EL MARGEN MARCADO EN LA LINEA
            let reventaLineaEuros = 
            currentUser.MargenConfiguradoEnServicios ? 
                +_reventaLineaEuros :
                    (currentUser.Comisiona ? 
                        (0) :
                        (+_reventaLineaEuros)
                    );
            let reventaLineaDolares = 
                currentUser.MargenConfiguradoEnServicios ? 
                    +values.ReventaLineaDolares :
                        (currentUser.Comisiona ? 
                            (0) :
                            (+values.ReventaLineaDolares)
                        );


            const payload = {
                ...values,
                NombreServicio: servicesArray.find( service => service.value === values.FkServiciosProveedores )?.label,
                FkTiposLineaVenta:  SERVICE,
                FkTiposCambioProveedores: activeRate.PkTiposCambioProveedores,
                
            /*ReservaLineaEuros: Math.round(+activeLine.ReservaLineaEuros),  
            ReservaLineaDolares: obtenerPrecioDolares (+activeLine.ReservaLineaEuros, 
                activeRate?.CambioEuros, activeRate?.CambioDolares),          
            InSituLineaDolares: Math.round(+activeLine.InSituLineaDolares),
                InSituLineaEuros: obtenerPrecioEuros(activeLine.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares),

            CosteDolares: Math.round(+activeLine.CosteDolares),
            ReservaEuros: Math.round(+activeLine.ReservaEuros), 

            ReventaLineaEuros: (monedaUsuario==='EUR') ? 
                Math.round(+values.ReventaLineaEuros) :
                // NO REDONDEAMOS sin decimales AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                Math.round(100*(+values.ReventaLineaDolares * activeRate.CambioDolares / activeRate.CambioEuros))/100
            ,    
            ReventaEuros: Math.round(activeLine.ReventaEuros),*/
             
            
            CosteDolares: costeDolares,
            ReservaEuros:reservaEuros,
            ReventaEuros:reventaEuros,
            InSituLineaDolares:insituLineaDolares,
            InSituLineaEuros:insituLineaEuros,
            ReservaLineaEuros:reservaLineaEuros,
            ReservaLineaDolares:reservaLineaDolares,
            ReventaLineaDolares:reventaLineaDolares,
            ReventaLineaEuros:reventaLineaEuros,

                FkTiposServicio: activeLine.FkTiposServicio,
                Unidades: +values.Unidades,
                FechaRecogida: values.FechaRecogida,
                PorcDescuento: +values.PorcDescuento,
                TasaDolares: +values.TasaDolares,
                SuplementoTransporteDolares: +values.SuplementoTransporteDolares,

                
                SuplementoTransporteEuros: +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),

            }

            dispatch( startUpdatingLineState( id, payload, setSubmitting ) )
        }
    }

    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };
    
    console.log('Active line ', activeLine)

    return (
        <Fragment>
             <Formik enableReinitialize validationSchema={ idRol !== ROLREVENDEDOR ? validationSchemaEditLine : validationSchemaEditLineReseller } initialValues={
                 idRol !== ROLREVENDEDOR ? initialValues : initialValuesReSeller } onSubmit={ handleSubmit }>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, values, setFieldValue }) => (
                <Form>
                    <Grid item style={{padding: '2rem'}}>

                                <Grid 
                                container
                                spacing={3}>
                            <Grid item classes={{root: classes.container }}>
                                <Typography variant='h3'>Editar Línea de Venta.</Typography>
                            </Grid>
                            {
                                (idRol === ROLADMIN || idRol === ROLVENDEDOR)
                                &&
                                (
                                    <Grid xs={12} item  classes={{root: classes.container }}>
                                    <TextField  style={{fontSize: '0.8em'}}
                                        fullWidth
                                        label='Proveedor'
                                        size='small'
                                        variant='outlined'
                                        classes={{ root: classes.inputText }}
                                        value={ supplier?.Nombre || 'No se ha podido cargar el nombre del proveedor'}
                                        disabled
                                    />
                                </Grid>
                                ) 
                            }
                            
                            <Grid xs={12} item  classes={{root: classes.container }}>
                            <Autocomplete style={{fontSize: '0.8em'}}
                                    options={servicesArray}
                                    noOptionsText={"No hay servicios"}
                                    getOptionLabel={option => option.label}
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    renderInput={(params) => ( 
                                        <TextField 
                                            {...params}
                                            name='FkServiciosProveedores' 
                                            variant='outlined' 
                                            label="Servicio" 
                                            placeholder='Busca...'
                                            disabled={ isSubmitting }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched.FkServiciosProveedores && Boolean(errors.FkServiciosProveedores)}
                                            helperText={touched.FkServiciosProveedores && errors.FkServiciosProveedores}
                                            

                                        />
                                    )}
                                    sx={{ marginBottom: '0.5rem'}}
                                    name="FkServiciosProveedores"
                                    defaultValue={
                                        idRol === ROLREVENDEDOR ?
                                        //Todo este rollo es para que por defecto enseñe el alias del servicio (no está incluido en el estado de activeLine el alias)
                                        {label: currentResellerServices.reduce( (acc, service) => 
                                            service.FkServiciosProveedores === activeLine.FkServiciosProveedores ?
                                            service.ServicioProveedor.Alias ? service.ServicioProveedor.Alias : service.ServicioProveedor.Nombre :
                                            acc
                                            ,'' 
                                        )
                                        , value: activeLine.FkServiciosProveedores } :
                                        {label: activeLine.NombreServicio, value: activeLine.FkServiciosProveedores }
                                    }
                                    onChange={(e, value) => {
                                        setTypeService('');
                                        setFieldValue(
                                        "FkServiciosProveedores",
                                        value?.value 
                                        );
                                        setValueService(value?.value);
                                        setTypeService(value?.tipo)
                                        

                                    }}
                            />
                            </Grid>

                            {
                                 idRol === ROLADMIN || idRol === ROLVENDEDOR
                                 ?
                                (values.FkServiciosProveedores
                                &&
                                (
                                    <Grid 
                                    container
                                    spacing={3}>

                                        <Grid xs={12} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Concepto'
                                                size='small'
                                                value={values.Concepto}
                                                variant='outlined'
                                                name='Concepto'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Info />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Concepto && Boolean(errors.Concepto)}
                                                helperText={touched.Concepto && errors.Concepto}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        <Grid md={ 6} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Tasa'
                                                name='TasaDolares'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.TasaDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                                helperText={touched.TasaDolares && errors.TasaDolares}
                                                disabled
                                            />
                                        </Grid>
                                        
                                            <Grid md={6} xs={6} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Tasa'
                                                    size='small'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EuroOutlined />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    value={ +calculateFromDollarsToEuro( activeRate, values.TasaDolares) }
                                                    disabled
                                                />
                                            </Grid>
                                        

                                        <Grid xs={12} item  classes={{root: classes.container }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
                                        <Field component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida   }  />
                                        </LocalizationProvider>
                                        </Grid>

                                    
                                        <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth
                                                name='InSituLineaDolares' 
                                                label='In situ'
                                                size='small'
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.InSituLineaDolares && Boolean(errors.InSituLineaDolares)}
                                                helperText={touched.InSituLineaDolares && errors.InSituLineaDolares}
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ values.InSituLineaDolares }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOnOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        {
                                            activeRate && values.InSituLineaDolares != null && values.InSituLineaDolares != undefined 
                                            ?
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth
                                                        label=''
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ calculateFromDollarsToEuro( activeRate, values.InSituLineaDolares) }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Euro />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                            )
                                            :
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                </Grid>
                                            )
                                        }

                                        <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth
                                                name='ReservaLineaEuros' 
                                                label='Reserva'
                                                size='small'
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.ReservaLineaEuros && Boolean(errors.ReservaLineaEuros)}
                                                helperText={touched.ReservaLineaEuros && errors.ReservaLineaEuros}
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                // value={ values.ReservaLineaEuros }
                                                value={ 
                                                    values.PrecioUnitarioEuros 
                                                        - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)
                                                    }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Euro />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled
                                            />
                                        </Grid>

                                        {
                                            activeRate && values.ReservaLineaEuros
                                            ?
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth
                                                        label=''
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        //value={ calculateFromEuroToDollars( activeRate, values.ReservaLineaEuros) }
                                                        value={ obtenerPrecioDolares(
                                                            values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares), 
                                                            activeRate.CambioEuros, activeRate.CambioDolares) }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOnOutlined />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        disabled
                                                    />
                                                </Grid>
                                            )
                                            :
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                </Grid>
                                            )
                                        }
                                       


                                       <Grid md={3} xs={6}  item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Nº personas'
                                                size='small'
                                                name='Unidades'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <People />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={values.Unidades}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Unidades && Boolean(errors.Unidades)}
                                                helperText={touched.Unidades && errors.Unidades}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                        </Grid>

                                        



                                        {
                                            activeRate && values.InSituLineaDolares != null && values.InSituLineaDolares != undefined && values.ReservaLineaEuros
                                            &&
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth
                                                        label='Importe'
                                                        name='PrecioUnitarioEuros'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        /*value={  (
                                                            +(values.Unidades)*
                                                            Math.round(parseFloat(
                                                                obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares) + 
                                                                (+values.ReservaLineaEuros - (+values.ReservaLineaEuros * (+values.PorcDescuento/100)))))
                                                            )
                                                        }*/
                                                        value={ values.PrecioUnitarioEuros }                    
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Euro />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        onBlur={ handleBlur }
                                                        onChange={handleChange}
                                                        disabled={ isSubmitting }
                                                        />
                                                </Grid>
                                            )
                                        }

                                        {
                                            activeRate && values.InSituLineaDolares != null && values.InSituLineaDolares != undefined && values.ReservaLineaEuros 
                                            ?
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth
                                                        label=''
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        /*value={ values.Unidades *
                                                            (obtenerPrecioDolares(
                                                                values.ReservaLineaEuros - Math.round(values.ReservaLineaEuros*values.PorcDescuento/100),
                                                                activeRate.CambioEuros,activeRate.CambioDolares
                                                            ) + values.InSituLineaDolares)
                                                        }*/
                                                        value={ obtenerPrecioDolares(values.PrecioUnitarioEuros, activeRate.CambioEuros, activeRate.CambioDolares) }                            
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOnOutlined />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                            )
                                            :
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                </Grid>
                                            )
                                        }




                                        <Grid md={ 6} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Supl. Transporte'
                                                name='SuplementoTransporteDolares'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.SuplementoTransporteDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.SuplementoTransporteDolares && Boolean(errors.SuplementoTransporteDolares)}
                                                helperText={touched.SuplementoTransporteDolares && errors.SuplementoTransporteDolares}                                                
                                            />
                                        </Grid>
                                        
                                            <Grid md={6} xs={6} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Supl. Transporte'
                                                    size='small'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EuroOutlined />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    value={ +calculateFromDollarsToEuro( activeRate, values.SuplementoTransporteDolares) }
                                                    disabled
                                                />
                                            </Grid>
                                        

                                            <Grid  item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='%Descuento sobre reserva'
                                                        size='small'
                                                        name='PorcDescuento'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Loyalty />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        value={ values.PorcDescuento}
                                                        onBlur={ handleBlur }
                                                        onChange={handleChange}
                                                        error={touched.PorcDescuento && Boolean(errors.PorcDescuento)}
                                                        helperText={touched.PorcDescuento && errors.PorcDescuento}
                                                        disabled={ isSubmitting }
                                                    />
                                                </Grid>
                                                {
                                                (values.PorcDescuento > 0 && values.ReservaLineaEuros && values.PorcDescuento <= 100)
                                                &&
                                                (
                                                    <>
                                                        <Grid item  classes={{root: classes.container }}>
                                                            <TextField 
                                                                fullWidth   
                                                                label='Reserva tras descuento'
                                                                size='small'
                                                                variant='outlined'
                                                                classes={{ root: classes.inputText }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Euro />
                                                                        </InputAdornment>
                                                                    ),

                                                                    classes: {
                                                                        input: classes.resize
                                                                    }
                                                                }}
                                                                InputLabelProps={
                                                                    {style: {fontSize: '0.8em'}}
                                                                }
                                                                value={ Math.round(+values.ReservaLineaEuros - (+values.ReservaLineaEuros * (+values.PorcDescuento/100))) }
                                                                disabled
                                                            />
                                                        </Grid>

                                                        <Grid item  classes={{root: classes.container }}>
                                                            <TextField 
                                                                fullWidth   
                                                                label='Reserva tras descuento'
                                                                size='small'
                                                                variant='outlined'
                                                                classes={{ root: classes.inputText }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <MonetizationOn />
                                                                        </InputAdornment>
                                                                    ),

                                                                    classes: {
                                                                        input: classes.resize
                                                                    }
                                                                }}
                                                                InputLabelProps={
                                                                    {style: {fontSize: '0.8em'}}
                                                                }
                                                                value={ obtenerPrecioDolares(
                                                                            Math.round(                                                                    
                                                                                values.ReservaLineaEuros
                                                                                - values.ReservaLineaEuros * (values.PorcDescuento / 100)
                                                                            )
                                                                            , activeRate?.CambioEuros, activeRate?.CambioDolares)
                                                                      }
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        
                                        <Grid xs={12} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Observaciones'
                                                size='small'
                                                name='Observaciones'
                                                variant='outlined'
                                                value={values.Observaciones}
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <RemoveRedEye />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Observaciones && Boolean(errors.Observaciones)}
                                                helperText={touched.Observaciones && errors.Observaciones}
                                                disabled={ isSubmitting }
                                                multiline
                                                maxRows={ 10 }
                                                minRows={ 5 }
                                            />
                                        </Grid>
                                    </Grid>
                                ))

                                :

                                <>
                                    {
                                        values.FkServiciosProveedores
                                        &&
                                        (
                                            <>
                                            
                                                <Grid xs={12} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Concepto'
                                                        size='small'
                                                        value={values.Concepto}
                                                        variant='outlined'
                                                        name='Concepto'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Info />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        onBlur={ handleBlur }
                                                        onChange={handleChange}
                                                        error={touched.Concepto && Boolean(errors.Concepto)}
                                                        helperText={touched.Concepto && errors.Concepto}
                                                        disabled={ isSubmitting }
                                                    />
                                                </Grid>


                                                { (monedaUsuario === 'EUR') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Neto/PAX'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ calculateFromDollarsToEuro(activeRate, activeLine?.CosteDolares) +activeLine?.ReservaEuros }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EuroOutlined/>
                                                                </InputAdornment>
                                                            ),
            
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                                }

                                                { (monedaUsuario === 'USD') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                        <TextField 
                                                            fullWidth   
                                                            label='Neto/PAX'
                                                            size='small'
                                                            variant='outlined'
                                                            classes={{ root: classes.inputText }}
                                                            value={activeLine?.CosteDolares + calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) }
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <MonetizationOn/>
                                                                    </InputAdornment>
                                                                ),

                                                                classes: {
                                                                    input: classes.resize
                                                                }
                                                            }}
                                                            InputLabelProps={
                                                                {style: {fontSize: '0.8em'}}
                                                            }
                                                        disabled
                                                        />
                                                </Grid>
                                                }

                            {
        !currentUser.Comisiona &&
        (
        <>
                                                
                                                { (monedaUsuario === 'EUR') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Margen/PAX'
                                                        name='ReventaLineaEuros'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ values.ReventaLineaEuros}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EuroOutlined />
                                                                </InputAdornment>
                                                            ),
            
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        onBlur={ handleBlur }
                                                        onChange={handleChange}
                                                        error={touched.ReventaLineaEuros && Boolean(errors.ReventaLineaEuros)}
                                                        helperText={touched.ReventaLineaEuros && errors.ReventaLineaEuros}
                                                        disabled={ isSubmitting }
                                                    />
                                                </Grid>
                                                }
            
            
                                                { (monedaUsuario === 'USD') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Margen/PAX'
                                                        name='ReventaLineaDolares'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ values.ReventaLineaDolares }
                                                        //value={ calculateFromEuroToDollars( activeRate, values.ReventaLineaEuros)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOn />
                                                                </InputAdornment>
                                                            ),
            
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        
                                                        onBlur={ handleBlur }
                                                        onChange={handleChange}
                                                        disabled={ isSubmitting }

                                                    />
                                                </Grid>
                                                }
            
                                                </>)

}

        
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='PAX'
                                                        size='small'
                                                        name='Unidades'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <People />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        value={values.Unidades}
                                                        onBlur={ handleBlur }
                                                        onChange={handleChange}
                                                        error={touched.Unidades && Boolean(errors.Unidades)}
                                                        helperText={touched.Unidades && errors.Unidades}
                                                        disabled={ isSubmitting }
                                                    />
                                                </Grid>


                                                { (monedaUsuario === 'USD') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Tasa Dólares'
                                                        name='TasaDolares'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ values.TasaDolares }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOn />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        onBlur={ handleBlur }
                                                        onChange={handleChange}
                                                        error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                                        helperText={touched.TasaDolares && errors.TasaDolares}
                                                        disabled
                                                    />
                                                </Grid>
                                                }
                                                { (monedaUsuario === 'EUR') &&

                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Tasa Euros'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ +calculateFromDollarsToEuro(activeRate, values.TasaDolares) }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EuroOutlined />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        disabled
                                                    />
                                                </Grid>
                                                }

                                                { (monedaUsuario === 'EUR') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                        <TextField 
                                                            fullWidth   
                                                            label='PVP/PAX'
                                                            size='small'
                                                            variant='outlined'
                                                            classes={{ root: classes.inputText }}
                                                            value={
                                                                //Math.round(calculateFromDollarsToEuro(activeRate, activeLine?.InSituLineaDolares) +activeLine?.ReservaLineaEuros + +activeLine?.ReventaLineaEuros) * values.Unidades
                                                                +calculateFromDollarsToEuro(activeRate, activeLine?.CosteDolares) +activeLine?.ReservaEuros
                                                                 + +activeLine?.ReventaLineaEuros
                                                            }
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EuroOutlined/>
                                                                    </InputAdornment>
                                                                ),

                                                                classes: {
                                                                    input: classes.resize
                                                                }
                                                            }}
                                                        disabled
                                                        />
                                                </Grid>
                                                }


                                                { (monedaUsuario === 'USD') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                        <TextField 
                                                            fullWidth   
                                                            label='PVP/PAX'
                                                            size='small'
                                                            variant='outlined'
                                                            classes={{ root: classes.inputText }}
                                                            value={
                                                                //Math.round(+values.ReventaLineaDolares + activeLine?.InSituLineaDolares + calculateFromEuroToDollars( activeRate,+activeLine.ReservaLineaEuros) ) * values.Unidades
                                                                +values.ReventaLineaDolares + +activeLine?.CosteDolares +
                                                                    calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) 
                                                            }
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <MonetizationOn/>
                                                                    </InputAdornment>
                                                                ),

                                                                classes: {
                                                                    input: classes.resize
                                                                }
                                                            }}
                                                        disabled
                                                        />
                                                </Grid>
                                                }
                                                


                                                { (monedaUsuario === 'EUR') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                        <TextField 
                                                            fullWidth   
                                                            label='PVP Total'
                                                            size='small'
                                                            variant='outlined'
                                                            classes={{ root: classes.inputText }}
                                                            value={
                                                                //Math.round(calculateFromDollarsToEuro(activeRate, activeLine?.InSituLineaDolares) +activeLine?.ReservaLineaEuros + +activeLine?.ReventaLineaEuros) * values.Unidades
                                                                (+calculateFromDollarsToEuro(activeRate, activeLine?.CosteDolares) +activeLine?.ReservaEuros  + +activeLine?.ReventaLineaEuros) * values.Unidades
                                                            }
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EuroOutlined/>
                                                                    </InputAdornment>
                                                                ),

                                                                classes: {
                                                                    input: classes.resize
                                                                }
                                                            }}
                                                        disabled
                                                        />
                                                </Grid>
                                                }


                                                { (monedaUsuario === 'USD') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                        <TextField 
                                                            fullWidth   
                                                            label='PVP Total'
                                                            size='small'
                                                            variant='outlined'
                                                            classes={{ root: classes.inputText }}
                                                            value={
                                                                //Math.round(+values.ReventaLineaDolares + activeLine?.InSituLineaDolares + calculateFromEuroToDollars( activeRate,+activeLine.ReservaLineaEuros) ) * values.Unidades
                                                                (+values.ReventaLineaDolares + +activeLine?.CosteDolares + calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) ) * values.Unidades
                                                            }
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <MonetizationOn/>
                                                                    </InputAdornment>
                                                                ),

                                                                classes: {
                                                                    input: classes.resize
                                                                }
                                                            }}
                                                        disabled
                                                        />
                                                </Grid>
                                                }


                                                { (monedaUsuario === 'USD') &&
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Supl. Transporte'
                                                        name='SuplementoTransporteDolares'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOn />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        value={ values.SuplementoTransporteDolares}
                                                        onBlur={ handleBlur }
                                                        onChange={handleChange}
                                                        error={touched.SuplementoTransporteDolares && Boolean(errors.SuplementoTransporteDolares)}
                                                        helperText={touched.SuplementoTransporteDolares && errors.SuplementoTransporteDolares}
                                                        disabled={ isSubmitting }
                                                    />
                                                </Grid>
                                                }
                                                
                                                { (monedaUsuario === 'EUR') &&
                                                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                        <TextField 
                                                            fullWidth   
                                                            label='Supl. Transporte'
                                                            size='small'
                                                            variant='outlined'
                                                            classes={{ root: classes.inputText }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EuroOutlined />
                                                                    </InputAdornment>
                                                                ),

                                                                classes: {
                                                                    input: classes.resize
                                                                }
                                                            }}
                                                            InputLabelProps={
                                                                {style: {fontSize: '0.8em'}}
                                                            }
                                                            value={ +calculateFromDollarsToEuro( activeRate, +activeLine.SuplementoTransporteDolares) }
                                                            disabled
                                                        />
                                                    </Grid>
                                                    }
                                                





                                                { /* IN SITU */ (monedaUsuario === 'USD') && 
                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                    <TextField 
                        fullWidth   
                        label={ `Pago in situ`}
                        size='small'
                        name='InSituLineaDolares'
                        variant='outlined'
                        classes={{ root: classes.inputText }}
                        value={ 
                            (
                                currentUser.MargenConfiguradoEnServicios ? 
                                (+values.SuplementoTransporteDolares +activeLine?.CosteDolares + calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) ) :
                                (currentUser.Comisiona ? 
                                    (+activeLine.CosteDolares + +values.SuplementoTransporteDolares)
                                    : 
                                    // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                                    (+activeLine.CosteDolares + +values.SuplementoTransporteDolares)
                                    )                                
                            )
                             * values.Unidades }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MonetizationOn />
                                </InputAdornment>
                            ),

                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        onBlur={ handleBlur }
                        onChange={handleChange}
                        error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                        helperText={touched.TasaDolares && errors.TasaDolares}
                        disabled
                />
            </Grid>
            }
            { /* IN SITU */ (monedaUsuario === 'EUR') &&  
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label={ `Pago in situ`}
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            value={ 
                                (currentUser.MargenConfiguradoEnServicios ? 
                                    (+calculateFromDollarsToEuro(activeRate, activeLine?.CosteDolares) +activeLine?.ReservaEuros  
                                        + +calculateFromDollarsToEuro(activeRate, values.SuplementoTransporteDolares)
                                    ) :
                                    (currentUser.Comisiona ? 
                                        (+activeLine.CosteDolares)
                                        : 
                                        // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                                        (+activeLine.CosteDolares)
                                        ))
                                    * values.Unidades }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            disabled
                        />
                    </Grid>
            }

            { /* RESERVA */ (monedaUsuario === 'USD') && 
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label={ `Pago en reserva`}
                    size='small'
                    name='ReservaLineaDolares'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ 
                        (currentUser.MargenConfiguradoEnServicios ? 
                            +values.ReventaLineaDolares :
                            (currentUser.Comisiona ? 
                                (+calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) ) :
                                (
                                    +calculateFromEuroToDollars( activeRate, +activeLine?.ReservaEuros) + 
                                    +values.ReventaLineaDolares
                                    )
                            ))
                             * values.Unidades 
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                    helperText={touched.TasaDolares && errors.TasaDolares}
                    disabled
                />
            </Grid>
            }
            { /* RESERVA */ (monedaUsuario === 'EUR') &&  
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label={ `Pago en reserva`}
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            value={ 
                                (
                                    currentUser.MargenConfiguradoEnServicios ? 
                                    ((monedaUsuario==='EUR') ? 
                                        Math.round(+values.ReventaLineaEuros) :
                                        // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                                        Math.round(100*(+values.ReventaLineaDolares * +activeRate.CambioDolares / +activeRate.CambioEuros))/100) :
                                    (currentUser.Comisiona ? 
                                        (+activeLine.ReservaEuros) :
                                        (+activeLine.ReservaEuros + +((monedaUsuario==='EUR') ? 
                                        Math.round(+values.ReventaLineaEuros) :
                                        // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                                        Math.round(100*(+values.ReventaLineaDolares * +activeRate.CambioDolares / +activeRate.CambioEuros))/100)
                                        )
                                    )
                                ) * values.Unidades 
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            disabled
                        />
                    </Grid>
            }
            



                     
            <Grid xs={12} item  classes={{root: classes.container }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}  locale={ esLocale }>
                    <Field component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida   }  />
                </LocalizationProvider>
            </Grid>
                           
        
                                            
    
                                            <Grid xs={12} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Observaciones'
                                                    size='small'
                                                    name='Observaciones'
                                                    variant='outlined'
                                                    value={values.Observaciones}
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <RemoveRedEye />
                                                            </InputAdornment>
                                                        ),
    
                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    onBlur={ handleBlur }
                                                    onChange={handleChange}
                                                    error={touched.Observaciones && Boolean(errors.Observaciones)}
                                                    helperText={touched.Observaciones && errors.Observaciones}
                                                    disabled={ isSubmitting }
                                                    multiline
                                                    maxRows={ 10 }
                                                    minRows={ 5 }
                                                />
                                            </Grid>
                                            </>
                                        )
                                    }
                                   
                                </>
                                
                            }
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >

                                <Button 
                                    style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                    onClick={ handleCancel }>
                                        <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                </Button>

                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    type='submit'
                                    loading={ isSubmitting }
                                    disabled={ !isValid }
                                    style={{
                                        backgroundColor: '#E5824F',
                    
                                    }}
                                >
                                        Editar
                                </LoadingButton>

                            </Box>

                        </Grid>
                    </Grid>
            </Form>
            )}
            </Formik>
        </Fragment>
    )
}
