import { Checkbox, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { DeleteOutlineOutlined, WhatsApp } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from '../../components/ui/Layout';
import { NotificationModal } from '../../components/ui/NotificationModal';
import { TransitionsModal } from '../../components/ui/TransitionModal';
import { startLoadingAllClients, startLoadingClients } from '../../redux/actions/clients';
import { Helmet } from 'react-helmet'
import { ROLADMIN, WHATSLINK } from '../../utils/constants';
import { useTableParams } from '../../hooks/useTableParams';
import { actionAddCustomer, actionDeleteCustomer, actionEditCustomer } from '../../redux/actions/actions';
import { startLogOutRedirect } from '../../redux/actions/routeManagement';
import { Box } from '@mui/system';
import { ButtonFloatNoOptions } from '../../components/ui/ButtonFloatNoOptions';



const ClientsScreen = () => {
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const { idRol, id } = useSelector(state => state.auth);
    const { menuState } = useSelector(state => state.ui)
    let data = [];
    const showColumnAction = false;
    const { clients } = useSelector(state => state.client);
    const dispatch = useDispatch()
    const clientOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: 'Clientes', rowsPerPage: 5};
    const clientColumnsDeskTop =  [['Nombre', true], ['Apellidos', true, !matchesXS], ['Email', true, !matchesXS], ['Telefono', false, !matchesXS]];
    const actions = [
        { isShown: true, type: 'delete', color: '#000', icon: <DeleteOutlineOutlined />, eject: ( dataIndex, e ) => handleDelete( dataIndex, e )},
        { isShown: true, color: '#000', type: 'custom', icon: <WhatsApp />, eject: ( dataIndex, e ) => handleSendWhatsApp( dataIndex, e )},
        // { isShown: true, type: 'edit', data: clients, link: 'clients', key: 'PkClientes', color: '#000', icon: <EditOutlined />, eject: ( dataIndex ) => handleEdit( dataIndex )},
    ]
    const { options, columns } = useTableParams( clientOptions,  clientColumnsDeskTop , actions, idRol !== ROLADMIN ? showColumnAction : true, (dataIndex, e) => handleEdit(dataIndex, e));
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };


    useEffect(() => {
        if( idRol === ROLADMIN){
            if(!checked){ 
                dispatch(startLoadingClients());
            }
            else{
                dispatch(startLoadingAllClients());
            }
        }
    }, [ dispatch, idRol, id, checked ]);

    useEffect(() => {
        dispatch(startLogOutRedirect())
    }, [ dispatch ])

     

    
    data = clients.map( client => { return [ client.Nombre, client.Apellidos ? client.Apellidos : '-', client.Email ? client.Email : '-', client.Telefono ? client.Telefono : '-', client.FechaBaja]; });

    options.setRowProps = (row, dataIndex, rowIndex) => { 
        if (data[rowIndex][4] !== null) {
          return {
            style: { backgroundColor: 'salmon' }
          };
        }
      };

    const handleAddClient = () => {
        dispatch(actionAddCustomer())
    }

   const handleDelete = ( dataIndex, e ) => {
    if (e) e.stopPropagation();
    dispatch(actionDeleteCustomer( clients[ dataIndex ]?.PkClientes ))
   }

   const handleSendWhatsApp = ( dataIndex, e ) => {
    if (e) e.stopPropagation();
    window.open(WHATSLINK.concat(`${clients[ dataIndex ]?.Telefono}`))
   }

   const handleEdit = ( dataIndex, e ) => {
    if (e) e.stopPropagation();
    dispatch(actionEditCustomer( clients[ dataIndex ].PkClientes))
   }
    
    return (
        <Layout>
            <Helmet>
                <title>Clientes | Xplora</title>
            </Helmet>
            <div style={{
                    marginTop: '2rem',
                    width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}>
                {
                    !matchesXS
                    &&
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>CLIENTES</Typography>
                }

                <MUIDataTable
                    title={ !matchesXS 
                            ?
                            <Box sx={{ display: 'flex'}}> 
                            
                            <FormControlLabel
                                    label='Incluir dados de baja'
                                    control={
                                        <Checkbox
                                            style={{ marginLeft: '1.7rem'}}
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled', 'label': 'Todos' }}
                                        />
                                    }
                            />
                            </Box>
                            : 
                            (   <Typography variant='h2' style={{ marginLeft: '0.7rem'}}>
                                    Clientes 
                                </Typography>
                            )
                        }
                    {...{data, columns, options}}
                    
                />

                <TransitionsModal />
                <NotificationModal />

                <ButtonFloatNoOptions 
                action={ handleAddClient }
               />

            </div>
        </Layout>
    )
}


export default ClientsScreen;