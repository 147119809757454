import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Box } from '@mui/system'
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import { useFormStyles } from '../../../../styles/formStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveExchangeRateToSupplier } from '../../../../redux/actions/exchangeRate';
import { EuroOutlined, EuroRounded, Info, MonetizationOn, People, RemoveRedEye } from '@material-ui/icons';
import { CUSTOMSERVICE } from '../../../../utils/constants';
import { startAddOrderLineToState } from '../../../../redux/actions/sales';
import { startNewOrderLine } from '../../../../redux/actions/lines';
import { setActiveSupplierState } from '../../../../redux/actions/suppliers';
import { startLogOutContentModal } from "../../../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../../../redux/actions/ui";
import { startLoadingSuppliers } from '../../../../redux/actions/suppliers';
import { FormikDatePicker } from '../../../ui/FormikDatePicker';
import esLocale from 'date-fns/locale/es'
import { calculateFromDollarsToEuro } from  '../../../../utils/utils'
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../../../utils/exchange';

const isEmpty = (obj) => {
    return Object.keys( obj || {} ).length === 0;
}

export const ContentModalAddFreeOrderLine = () => {
    //const [ search, debounceSearchResult ] = useDebounce('/proveedores?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkProveedores"});
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const [supplierValue, setSupplierValue] = useState('');
    const [typeService, setTypeService] = useState('');
    const { activeEdit } = useSelector(state => state.invoice);
    const refServiceType = useRef();
    const { active: activeRate } = useSelector(state => state.exchangeRate);
    const { active: activeSupplier } = useSelector(state => state.supplier)
    const dispatch = useDispatch();
    const { suppliers } = useSelector(state => state.supplier );

    useEffect(() => {
        if( supplierValue && typeService){
            dispatch(setActiveExchangeRateToSupplier( supplierValue, typeService ))
            dispatch(setActiveSupplierState(supplierValue))
        }
    }, [supplierValue, dispatch, typeService])

   

    
    useEffect(() => {
        dispatch(startLoadingSuppliers())
    }, [ dispatch ]);
    
    const suppliersArray = suppliers.map ( supplier => { return { label: supplier.Nombre, value: supplier.PkProveedores }});
    


    const validationSchemaFreeOrderLine = () => {
        return yup.object().shape({
            proveedor: yup.number().required('Proveedor es requerido'),
            tipo_servicio: yup.number().required('Tipo de servicio requerido'),
            ReservaEuros: yup.number().required('Reserva es obligatoria'),
            CosteDolares: yup.number().required('Coste es requerido'),
            Concepto: yup.string().required('Debe indicar el concepto'),
            Unidades: yup.number().required('Unidades es requerido'),
            Observaciones: yup.string().optional(),
            TasaDolares: yup.number('Debe ser un entero').required('Tasa es requerida'),

        })
    }

    const initialValues = {
        proveedor: '',
        tipo_servicio: '',
        ReservaEuros: '',
        CosteDolares: '',
        Concepto: '',
        Unidades: 1,
        Observaciones: '',
        FechaRecogida: null,
        TasaDolares: 0,
        SuplementoTransporteDolares: 0
    }

    const handleSubmit = (values, {setSubmitting}) => {

        if( !activeEdit ) {
            const payload = {
                FkTiposLineaVenta: CUSTOMSERVICE,
                FkProveedores: supplierValue,
                FkTiposCambioProveedores: activeRate.PkTiposCambioProveedores, //Pendiente
                CosteDolares: Math.round(+values.CosteDolares),
                ReservaEuros: Math.round(+values.ReservaEuros),
                InSituLineaDolares: Math.round(+values.CosteDolares),
                
                ReservaLineaEuros: Math.round(+values.ReservaEuros),
                ReservaDescuento:  Math.round(+values.ReservaEuros),
                
                Unidades: +values?.Unidades,
                ReventaEuros: 0,
                ReventaLineaEuros: 0,
                NombreServicio: values.Concepto,
                NombreProveedor: activeSupplier.Nombre,
                Concepto:  values.Concepto,
                Observaciones: values.Observaciones??'',
                FkTiposServicio: values.tipo_servicio,
                // InSituLineaEuros: obtenerPrecioEuros(values.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares),
                FechaRecogida: values.FechaRecogida ? values.FechaRecogida : null,
                TasaDolares: +values.TasaDolares,
                //TasaEuros: obtenerPrecioEuros(values.TasaDolares, activeRate.CambioEuros, activeRate.CambioDolares ),
                SuplementoTransporteDolares: +values.SuplementoTransporteDolares,
                SuplementoTransporteEuros: +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),

                TasaEuros: obtenerPrecioEuros(values.TasaDolares, activeRate.CambioEuros, activeRate.CambioDolares ),
                InSituLineaEuros: obtenerPrecioEuros(Math.round(+values.CosteDolares), activeRate.CambioEuros, activeRate.CambioDolares),
                
            }
            dispatch(startAddOrderLineToState( payload, setSubmitting ))
        }
        else{
            const payload = {
                "Concepto": values.Concepto,
                "CosteDolares":  Math.round(+values.CosteDolares),
                "ReservaEuros": Math.round(+values.ReservaEuros),
                "InSituLineaDolares": Math.round(+values.CosteDolares),
                "ReservaLineaEuros": Math.round(+values.ReservaEuros),
                //"ReservaDescuento": Math.round(+values.ReservaEuros),
                "ReventaEuros": 0,
                "ReventaLineaEuros": 0,
                "Unidades": +values.Unidades,
                "Observaciones": values.Observaciones,
                "FkVentas": activeEdit?.PkVentas,
                "FkTiposLineaVenta": CUSTOMSERVICE,
                "FkTiposCambioProveedores": activeRate.PkTiposCambioProveedores,
                "FkProveedores": supplierValue,
                "FkTiposServicio": values.tipo_servicio,
                //"InSituLineaEuros": obtenerPrecioEuros(values.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares),
                "FechaRecogida": values.FechaRecogida ? values.FechaRecogida : null,
                "TasaDolares": +values.TasaDolares,
                "SuplementoTransporteDolares": +values.SuplementoTransporteDolares,
                "SuplementoTransporteEuros": +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),

                //"TasaEuros": obtenerPrecioEuros(values.TasaDolares, activeRate.CambioEuros, activeRate.CambioDolares ),
                //"InSituLineaEuros": obtenerPrecioEuros(Math.round(+values.CosteDolares), activeRate.CambioEuros, activeRate.CambioDolares),
                
            }

            dispatch(startNewOrderLine(payload))
        }
        
    }

    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };

    return (
        <>
         <Formik initialValues={ initialValues } validationSchema={ validationSchemaFreeOrderLine }   onSubmit={handleSubmit}>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, values, setFieldValue }) => (
                <Form>
                    <Grid item style={{padding: '2rem'}}>
                        <Grid container direction='column'>
                            <Grid item classes={{root: classes.container }}>
                                <Typography variant='h3'>Agregar Línea de Venta Libre</Typography>
                            </Grid>

                            { 
                                isEmpty(activeRate) && values.proveedor && values.tipo_servicio && (<Alert sx={{ marginBottom: '2rem' }} variant="outlined" severity="error">
                                                    Este proveedor no tiene tipo de cambio para el tipo de servicio seleccionado
                                                </Alert>
                                )
                            }

                            <Autocomplete style={{fontSize: '0.8em'}}
                                name='proveedor'
                                autoHighlight
                                fullWidth
                                disablePortal
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                id="proveedor-autocomplete-exchange-rate"
                                renderInput={(params) => ( 
                                    <TextField 
                                        {...params}
                                        name='proveedor' 
                                        variant='outlined' 
                                        label="Localizar proveedor" 
                                        placeholder='Busca...'
                                        disabled={ isSubmitting }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.proveedor && Boolean(errors.proveedor)}
                                        helperText={touched.proveedor && errors.proveedor}
                                        

                                    />)}
                                //onInputChange={ debounceSearchResult }
                                sx={{ marginBottom: '2rem'}}
                                options={ suppliersArray  }
                                onChange={(_, value) => {
                                    setFieldValue(
                                    "proveedor",
                                    value?.value !== null ? value?.value : initialValues.proveedor
                                    );
                                    if(!value?.value){
                                        Object.entries(values).map(([key, value]) => {
                                            setFieldValue(key, "")
                                            return null;
                                        })
                                        if( values.tipo_servicio ){
                                            refServiceType.current.getElementsByClassName('MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input').value = '';
                                        }
                                    }
                                    setSupplierValue(value?.value)
                                }}
                            
                            />
                            {
                                supplierValue
                                &&
                                <FormControl
                                    style={{
                                        marginBottom: '2rem'
                                    }}
                                    disabled={ isSubmitting }
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.tipo_servicio && Boolean(errors.tipo_servicio)}
                                    helperText={touched.tipo_servicio && errors.tipo_servicio}
                                                
                                >
                                    <InputLabel style={{fontSize: '0.8em'}} id="select-label-type-services">Tipo Servicio</InputLabel>
                                    <Select style={{fontSize: '0.8em'}}
                                        ref={ refServiceType }
                                        name='tipo_servicio'
                                        displayEmpty
                                        value={values.tipo_servicio}
                                        onChange={e => {
                                            setFieldValue("tipo_servicio", e.target.value);
                                            setTypeService(e.target.value);
                                        }}
                                        variant='outlined'
                                    >
                                        <MenuItem style={{fontSize: '0.7em'}} value={2}>Parque</MenuItem>
                                        <MenuItem style={{fontSize: '0.7em'}} value={1}>Tour</MenuItem>

                                    </Select>
                                </FormControl>
                            }
                                    
                                
                            
                            {
                                (values.proveedor && values.tipo_servicio && activeRate) 
                                &&
                                (
                                    <Grid 
                                    container
                                    spacing={3}>



                                        <Grid xs={12} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Concepto'
                                                size='small'
                                                variant='outlined'
                                                name='Concepto'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Info />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Concepto && Boolean(errors.Concepto)}
                                                helperText={touched.Concepto && errors.Concepto}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>

                                        <Grid xs={6} md={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Tasa'
                                                size='small'
                                                variant='outlined'
                                                name='TasaDolares'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.TasaDolares }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                                helperText={touched.TasaDolares && errors.TasaDolares}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        <Grid xs={6} md={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Tasa'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EuroRounded />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ calculateFromDollarsToEuro( activeRate, values.TasaDolares) }
                                                disabled
                                            />
                                        </Grid>

                                        <Grid xs={12} item  classes={{root: classes.container }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
            <Field component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida }  />
            </LocalizationProvider>
            </Grid>

                                            
                                        <Grid md={3} xs={6}  item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='In situ'
                                                size='small'
                                                name='CosteDolares'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.CosteDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.CosteDolares && Boolean(errors.CosteDolares)}
                                                helperText={touched.CosteDolares && errors.CosteDolares}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>

                                        {
                                            values.CosteDolares && !isEmpty(activeRate)
                                            ?
                                            (
                                                <Grid md={3} xs={6}  item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label=''
                                                        size='small'
                                                        name='CosteEuros'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EuroOutlined />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        value={ obtenerPrecioEuros(values.CosteDolares, activeRate?.CambioEuros, activeRate?.CambioDolares) || 0 }
                                                        disabled={ true }
                                                    />
                                                </Grid>
                                            )
                                            :
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                </Grid>
                                            )
                                        }

                                        
                                        <Grid md={3} xs={6}  item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Reserva Línea'
                                                name='ReservaEuros'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ values.ReservaEuros}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EuroOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.ReservaEuros && Boolean(errors.ReservaEuros)}
                                                helperText={touched.ReservaEuros && errors.ReservaEuros}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>

                                        {
                                            values.ReservaEuros && !isEmpty( activeRate )
                                            ?
                                            (
                                                <Grid md={3} xs={6}  item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label=''
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ obtenerPrecioDolares(values.ReservaEuros, activeRate?.CambioEuros, activeRate?.CambioDolares) || 0}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOn />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                            )
                                            :
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                </Grid>
                                            )
                                        }

                                        

                                        <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Unidades'
                                                size='small'
                                                name='Unidades'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <People />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.Unidades }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Unidades && Boolean(errors.Unidades)}
                                                helperText={touched.Unidades && errors.Unidades}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>



                                        <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Supl. Transporte'
                                                name='SuplementoTransporteDolares'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.SuplementoTransporteDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.SuplementoTransporteDolares && Boolean(errors.SuplementoTransporteDolares)}
                                                helperText={touched.SuplementoTransporteDolares && errors.SuplementoTransporteDolares}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        
                                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Supl. Transporte'
                                                    size='small'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EuroOutlined />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    value={ +calculateFromDollarsToEuro( activeRate, values.SuplementoTransporteDolares??0) }
                                                    disabled
                                                />
                                            </Grid>
                                        
                                        

                                        {
                                            values.ReservaEuros && !isEmpty( activeRate )
                                            &&
                                            (
                                                <>
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Importe'
                                                        size='small'
                                                        name='venta'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EuroOutlined />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        value={ 
                                                            +((values.Unidades)*
                                                            (obtenerPrecioEuros(values.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares) + 
                                                                parseFloat(values.ReservaEuros)))
                                                        }
                                                        disabled={ true }
                                                    />
                                                </Grid>

                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Importe'
                                                        size='small'
                                                        name='venta'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOn />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        value={ Math.round(values.Unidades * (
                                                            obtenerPrecioDolares(values.ReservaEuros, activeRate.CambioEuros, activeRate.CambioDolares) + 
                                                            parseFloat(values.CosteDolares)) 
                                                        ) }
                                                        disabled={ true }
                                                    />
                                                </Grid>
                                                </>

                                                
                                            )
                                        }
                                        

                                        

                                        <Grid  xs={12}  item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Observaciones'
                                                size='small'
                                                name='Observaciones'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <RemoveRedEye />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Observaciones && Boolean(errors.Observaciones)}
                                                helperText={touched.Observaciones && errors.Observaciones}
                                                disabled={ isSubmitting }
                                                multiline
                                                maxRows={ 10 }
                                                minRows={ 5 }
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            }

                           
                            
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >


                                <Button 
                                    style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                    onClick={ handleCancel }>
                                        <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                </Button>

                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    type='submit'
                                    loading={ isSubmitting }
                                    disabled={ !isValid || isEmpty(activeRate) }
                                    style={{
                                        backgroundColor: '#E5824F',
                    
                                    }}
                                >
                                        Añadir
                                </LoadingButton>

                            </Box>

                        </Grid>
                    </Grid>
            </Form>
            )}
            </Formik>   
        </>
    )
}
