
//Third-party packages
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeleteInvoice } from '../../redux/actions/sales';


//Custom imports
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';


/**
 * 
    Author: QBK
    Date: 11-01-2021
 */
export const ConfirmDeleteInvoice = () => {

    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { activeEdit } = useSelector(state => state.invoice) //we get the users from the state 


    const handleSuccess = () => {
        dispatch( uiManageModalNotification()); //we close the confirmati
        dispatch(startDeleteInvoice(activeEdit.PkVentas)); //we delete the invoice
       
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ '¿Quieres borrar la venta?' }
                text={ 'Si la venta está facturado se dará de baja del sistema' }
                action={ handleSuccess }
            />
    )
}
