
import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DashboardAdmin } from '../components/ui/dashboard/DashboardAdmin';
import { DashboardSeller } from '../components/ui/dashboard/DashboardSeller';
import { useSelector } from 'react-redux';

import { Layout } from "../components/ui/Layout"
import { useDispatch } from 'react-redux';
import { startLoadingDashboardData } from '../redux/actions/dashboard';
import { ROLADMIN, ROLCOLABORADOR, ROLREVENDEDOR, ROLVENDEDOR } from '../utils/constants';
import { navigate } from '@reach/router';
import { DashboardColaborator } from '../components/ui/dashboard/DashboardColaborator';

const Dashboard = () => {
    const { idRol, id, cambiaPassword, aceptaPoliticas, esAgencia , aceptaContratoTratamientoDatos } = useSelector(state => state.auth)
    //let name = nombre.charAt(0).toUpperCase() + nombre.slice(1);
    const dispatch = useDispatch();

    

    useEffect(() => {
        if( id && cambiaPassword && aceptaPoliticas && (!esAgencia || (esAgencia && aceptaContratoTratamientoDatos))  ){
            dispatch(startLoadingDashboardData())
        }
        else{
            if( !id ){
                navigate('/login/')
            }
            else if(!aceptaPoliticas ){
                navigate('/politicas/')
            }
            else if(esAgencia && !aceptaContratoTratamientoDatos){
                navigate('/politicas_agencia/')
            }
            else {
                navigate('/auth/change/')
            }
            
        }
       
    }, [ dispatch, id, cambiaPassword, aceptaPoliticas ])
    
    return (
        <>
            {
                id && cambiaPassword && aceptaPoliticas 
                ?
                <>
                    <Helmet>
                        <title>Xplora</title>
                    </Helmet>
                    <Layout>
                        <Box
                            component="main"
                            sx={{
                            flexGrow: 1,
                            marginTop: '1rem'
                            }}
                        >
                            {(idRol===ROLADMIN) && <DashboardAdmin  />}
                            {(idRol===ROLVENDEDOR || idRol === ROLREVENDEDOR) && <DashboardSeller />}
                            {(idRol===ROLCOLABORADOR) && <DashboardColaborator  />}
                        </Box>

                    </Layout>
                </>
                :
                <Typography variant='h3'>Cargando...</Typography>
            }
            
        </>
    )
}

export default Dashboard;