import { navigate } from "@reach/router"
import axios from "axios"
import Swal from "sweetalert2"
import { url } from "../../api/api"
import { loadJWT } from "../../helpers/loadJWT"
import { loadSales } from "../../helpers/loadSales"
import { buildFilterCVS, buildFilterInvoice, buildFilterInvoiceAgencia } from "../../utils/buildFilterInvoice"
import { CUSTOMSERVICE, isBrowser, ROLREVENDEDOR, SERVICE } from "../../utils/constants"
import { types } from "../types/types"
import { actionLogOutModal } from "./actions"
import { startLogOutContentModal } from "./contentModal"
import { startChangeRoute } from "./routeManagement"
import { setActive } from "./supplierServices"
import { finishLoading, startLoading, startSuccess, uiManageModalCollectionTypes } from "./ui"
import FileSaver from 'file-saver';



export const startNewBudgetAndAssociatedLines = ( invoice, setSubmitting ) => {
    const { token } = loadJWT();
    return async( dispatch, getState ) => {
        const lines = getState().invoice.active.orderLines;
        const rol = getState().auth.idRol;
        const lineas = []
        lines.map( line => {
            let payloadCurrentOrderLine = {
                "Concepto": line?.Concepto,
                "CosteDolares": line?.CosteDolares,
                "ReservaEuros": line?.ReservaEuros,
                "ReventaEuros": (rol !== ROLREVENDEDOR) ? 0 : line?.ReventaEuros,
                "InSituLineaDolares": line?.InSituLineaDolares,
                "ReservaLineaEuros": line?.ReservaLineaEuros,
                "ReventaLineaEuros": (rol !== ROLREVENDEDOR) ? 0 : line?.ReventaLineaEuros,
                "Unidades": line?.Unidades,
                "Observaciones": line?.Observaciones,
                "ConfirmadaProveedor": false,
                "FkTiposLineaVenta": line?.FkTiposLineaVenta,
                "FkTiposCambioProveedores": line?.FkTiposCambioProveedores,
                "FkProveedores": line?.FkProveedores,
                "PorcDescuento": line?.PorcDescuento || 0,
                "TasaDolares": line?.TasaDolares || 0,
                "FechaRecogida": line?.FechaRecogida,
                "SuplementoTransporteDolares": line?.SuplementoTransporteDolares,
            }
            if( line?.FkTiposLineaVenta === SERVICE){
                payloadCurrentOrderLine["FkServiciosProveedores"] = line?.FkServiciosProveedores
            }
            if(line?.FkTiposLineaVenta === CUSTOMSERVICE){
                payloadCurrentOrderLine['FkTiposServicio'] = line?.FkTiposServicio
            }
            lineas.push(payloadCurrentOrderLine);
            return null;
        });
        invoice['LineasVenta'] = lineas
        await url.post('/ventas', invoice, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            Swal.fire('Éxito', 'Se acaba de dar de alta un nuevo presupuesto', 'success')
            .then(() =>{
                dispatch(setActiveInvoice(resp.data.PkVentas));
                //navigate(`/sales/invoice/${resp.data.PkVentas}`)
            })

        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido crear el presupuesto', 'error')
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(startLogOutContentModal())
        })
    }
}


export const startAddingOrderLines = ( orderLine ) => {
    const { token } = loadJWT();
    return async() => {
        await url.post('/lineas-ventas', orderLine, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al añadir líneas de venta', 'error')
        })
        .finally( () =>  {
            console.log('error linea')
            //si una venta tiene 3 líneas y sólo se han podido insertar 2, lo ideal sería eliminar las líneas y la venta
        })
    }
}

export const startLoadSales = ( page, dateRange ) => {
    console.log('startLoadSales');
    console.log(page);
    return async( dispatch, getState ) => {
        const {idRol: rol, id: loggedInUser} = getState().auth
        dispatch(startLoading())
        const [sales, total, sumTotal, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares] = await loadSales(page, rol, loggedInUser, dateRange)
        dispatch(setSales( sales, page ));
        dispatch(setNumberOfItems( total ));
        dispatch( setTotalSum( sumTotal, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares ));
        dispatch(finishLoading())
    
    }
}


export const setTotalSum = ( sum, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares  ) => ({
    type: types.getTotalInvoicesFiltered,
    payload: {
        sum: sum,
        sumInSituEuros: sumInSituEuros,
        sumReservaEuros: sumReservaEuros,
        sumDescuentoEuros: sumDescuentoEuros,
        sumReventaEuros: sumReventaEuros,
        sumBalanceDolares: sumBalanceDolares
      }   
})


export const setNumberOfItems = ( count ) => ({
    type: types.getNumberOfSInvoices,
    payload: count
})

export const setSales = ( invoices, page ) => ({
    type: types.invoicesLoad,
    payload: invoices,
    page: page
})


export const changeFilter = (filterList, page, dateRange) => {
    return async(dispatch) => {
        //localStorage.setItem('currenFilter', JSON.stringify(filter ?? []));
        await dispatch(changeFilterAction(filterList));
        dispatch(startHandlingFilter (filterList, 0, dateRange));
    }
}

const changeFilterAction = ( filter ) => ({
    type: types.changeFilter,
    payload: filter
});



const changeFilterDateAction = ( dateRange ) => ({
    type: types.changeFilterDates,
    payload: dateRange
});

export const changeFilterDates = (filterList, page, dateRange) => {
    return async(dispatch) => {
        //localStorage.setItem('currenFilter', JSON.stringify(filter ?? []));
        await dispatch(changeFilterDateAction(dateRange));
        dispatch(startHandlingFilter (filterList, 0, dateRange));
    }
}

export const startHandlingFilter = (filterList, page, dateRange) => {
    console.log('startHandlingFilter');
    console.log(filterList);
    console.log(page);

    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const {idRol: rol, id: loggedUser, esAgencia, idAgencia} = getState().auth
        dispatch(startLoading())

        console.log('FILTER!!!');

        let [ query, queryCount, sumCount ] = buildFilterInvoice( filterList, page, dateRange, rol, loggedUser );
       
        
        const salesQuery = url.get(`${ query }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        });

        const countQuery = url.get(`${ queryCount }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })

        const sumQuery = url.get(`${ sumCount }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        await axios.all([salesQuery, countQuery, sumQuery]).then(([res1, res2, res3]) => {
            dispatch(setSales(res1.data, page))
            dispatch(setNumberOfItems(res2.data.count)) 
            dispatch(setTotalSum(res3.data.sum, res3.data.sumInSituEuros, res3.data.sumReservaEuros, res3.data.sumDescuentoEuros, res3.data.sumReventaEuros, res3.data.sumBalanceDolares))
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se han podido cargar las ventas', 'error')
        })
        .finally( () => {
            dispatch(finishLoading());
        })
    }
}

export const startAddOrderLine = ( orderData ) => {
    return async( dispatch ) => {
        dispatch(addOrderLine(orderData))
    }
}

export const startGatheringServiceData = ( id ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal
        dispatch(startLoading());
        await url.get(`/servicios-proveedores/${ id }?filter={"include": [{"relation":"TipoServicio"},{"relation":  "ProveedorServicio", "scope": {"include": [{"relation": "TiposCambioProveedor"}]}}]}`, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setServiceDataSync(resp.data))
        })
        .catch( e => {
            if( modal ){
                Promise.resolve(dispatch(uiManageModalCollectionTypes()))
                .then(dispatch(startLogOutContentModal()))
                .then( () => {
                    Swal.fire('Error', e?.response?.data?.error?.message || 'No se han podido cargar las ventas', 'error')
                })
            }
        })
        .finally( () => {
            dispatch(finishLoading())
        })
    }
}


const setServiceDataSync = ( data ) => {
    return async( dispatch ) => {
        dispatch(setActive(data))   
    }
}

export const startGatheringServiceDataForReseller = ( id, idService ) => {
    return async( dispatch, geState ) => {
        const { token } = loadJWT();
        const { modal } = geState().modal
        dispatch(startLoading());
        await url.get(`/usuarios/${ id }/servicios-revendedores-compuestos?filter={"include": [{"relation": "ServicioProveedor","scope": {"include": [{"relation": "ProveedorServicio"}]}}], "where": {"FkServiciosProveedores": ${ idService }} }`, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActive(resp.data[0]))
        })
        .catch( e => {
            if( modal ){
                Promise.resolve(dispatch(uiManageModalCollectionTypes()))
                .then(dispatch(startLogOutContentModal()))
                .then( () => {
                    Swal.fire('Error', e?.response?.data?.error?.message || 'No se han podido cargar las ventas', 'error')
                })
            }
            
        })
        .finally( () => {
            dispatch(finishLoading())
        })
    }
}


export const startUpdateInvoice = ( id, values, setSubmitting ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        dispatch(startLoading());
        await url.put(`/ventas/${ id }`, values , {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            Swal.fire('Exito', 'Venta Actualizada', 'success')
            .then( () => {
                navigate(`/sales`)
            })
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al editar una venta', 'error')
        })
        .finally( () => {
            setSubmitting( false )
            dispatch(finishLoading())

        })
    }
}


export const startUpdateInvoiceFromInvoice = ( id, values, setSubmitting ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        dispatch(startLoading());
        await url.put(`/ventas/${ id }`, values , {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            Swal.fire('Exito', 'Venta Actualizada', 'success')
            .then( () => {
                dispatch(setActiveInvoice(id))
            })
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al editar una venta', 'error')
        })
        .finally( () => {
            setSubmitting( false )
            dispatch(finishLoading())

        })
    }
}

export const setActiveInvoice = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        dispatch(startLoading());
        await url.get(`/ventas/${ id }?filter={"include": [{"relation": "Usuario"}, {"relation": "Comisionista"}, {"relation": "Cliente"}, {"relation": "LineasVentaCompuestas", "scope": {"include": [{"relation": "ServicioProveedor"},{"relation": "Proveedor"}, {"relation": "TipoCambioProveedor"}]}}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            if( resp.status === 204){
                dispatch(finishLoading())
                dispatch(startChangeRoute('sales'))
                return;
            }
            dispatch(setActiveInvoiceEdit( resp.data ));
            navigate(`/sales/invoice/${ resp.data.PkVentas }`)
            dispatch(finishLoading());
            //dispatch(resetPage())
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data.message || 'No se ha podido cargar la venta', 'error')
            .then( () => {
                navigate('/sales')
            })
        })
    }
}


export const setActiveInvoiceToExport = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.get(`/ventas/${ id }?filter={"include": [{"relation": "Usuario"}, {"relation": "Cliente"}, {"relation": "LineasVentaCompuestas", "scope": {"include": [{"relation": "ServicioProveedor"},{"relation": "Proveedor"}, {"relation": "TipoCambioProveedor"}]}}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActiveInvoiceShare( resp.data ));
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido ejecutar la acción', 'error')
        })
    }
}


export const setActiveLineFromInvoiceState = ( payload ) => {
    return async( dispatch ) => {
        dispatch( setActiveLineStateInvoice( payload ) );
    }
}

const setActiveLineStateInvoice = ( payload ) => ({
    type: types.lineEditFromInvoiceState,
    payload: payload
})

export const startSettingSuppliersAndServicesRelatedWithOrderLineState = ( supplier) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal
        await url.get(`/proveedores/${ supplier }?filter={"include": [{"relation": "ServiciosProveedor", "scope": {"where": {"FechaBaja": null}}}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            const supplier = {
                "PkProveedores": resp.data.PkProveedores,
                "Nombre": resp.data.Nombre,
            };
            let services = []
            if( resp.data.ServiciosProveedor ){
                services = [...resp.data.ServiciosProveedor]
            }

            dispatch(setSupplierAndServices( services, supplier ))
        })
        .catch( e => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes())
                dispatch(startLogOutContentModal())
            }
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido cargar la información asociada al proveedor', 'error')
        })
    }
}

export const startGettingServicesResellerWhenUpdatingState = () => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal
        await url.get(`/servicios-revendedores?filter={"include": [{"relation": "ServicioProveedor"}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            let services = [...resp.data]
            dispatch(setSupplierAndServices( services, {} ))
        })
        .catch( e => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes())
                dispatch(startLogOutContentModal())
            }
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido cargar la información asociada al proveedor', 'error')
        })

    }
}
export const setSupplierAndServices = ( services, supplier ) => ({
    type: types.orderLineEditStateLoadingSuppliersAndServices,
    payload: [ services, supplier ],
})


export const startSettingExchangeRateOrderLineState = ( idProveedor, tipo ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.get(`/tipos-cambio-proveedores?filter={"where": {"FechaBaja": null, "FkTiposServicio": ${ tipo }, "FkProveedores": ${ idProveedor }}}`, { headers:{ Authorization: `Bearer ${ token }` }})
        .then( ( resp ) => {
            dispatch(setExchangeRate( resp.data[0] ))
        })
    }
}

export const setExchangerateLineState = ( rate ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.get(`/tipos-cambio-proveedores/${ rate }`, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setExchangeRate(resp.data));
        })
    }

}


export const startResetActiveLine = ( idServicio ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { PkTiposCambioProveedores } = getState().invoice.active.rate
        await url.get(`/servicios-proveedores/${ idServicio }?filter={"include": [{"relation": "ProveedorServicio"}]}`, { headers:{ Authorization: `Bearer ${ token }` }})
        .then( ( resp ) => {
            dispatch(resetActiveLineFromState( resp.data, PkTiposCambioProveedores ))
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido cargar el servicio', 'error')
        })
    }
}



export const startUpdatingLineState = ( id, payload, setSubmitting ) => {
    return async( dispatch, getState ) => {
        setSubmitting( false );
        const { modal } = getState().modal;
        Promise.resolve(dispatch(updateLineState( id, payload )))
        .then( () => { 
            Swal.fire('Éxito', 'La línea se ha actualizado correctamente', 'success')
        })
        .then( () => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes())
                dispatch(startLogOutContentModal())
            }
        })
        .finally( () => {
            dispatch( logOutLineActiveAfterupdate() );
            
        })
    }   
}


export const startLoadingServicesResellerFromEdit = ( id ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal
        await url.get(`/servicios-revendedores?filter={"include": [{"relation": "ServicioProveedor"}], "where": {"FkUsuarios": ${ id }, "FechaBaja": null, "FechaBajaServicio": null}}`, { headers:{ Authorization: `Bearer ${ token }` }})
        .then( ( resp ) => {
            dispatch(setServicesReseller( resp.data ))
        })
        .catch( e => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes())
                dispatch(startLogOutContentModal())
            }
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido cargar los servicios del revendedor. Inténtelo de nuevo más tarde', 'error')
        })
    }
}


export const handleSendBudget = ( id ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        await url.post(`/sendEmailPresupuesto/${ id }`, {} ,{ headers:{ Authorization: `Bearer ${ token }` }})
        .then( ( resp ) => {
            Swal.fire('Exito', 'Email enviado', 'success')
            .then( () => {
                dispatch(setActiveInvoice( id ))
            })
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error en el envío. Contacte con el administrador', 'error')
            
        })
    }
}



const setServicesReseller = ( payload ) => ({   
    type: types.loadServicesResellerEdit,
    payload: payload
})

const logOutLineActiveAfterupdate = () => ({ 
    type: types.invoiceLineStateLogOut,
})
const updateLineState = ( id, payload ) => ({
    type: types.lineUpdateState,
    payload: {
        id,
        line: {
            ...payload
        }
    }
})

const resetActiveLineFromState = ( payload, rate ) => ({
    type: types.resetActiveLineFromState,
    payload: payload,
    rate: rate
})
const setExchangeRate = ( payload ) => ({
    type: types.orderLineStateSetExchangerate,
    payload: payload
})
export const setActiveInvoiceEdit = ( payload ) => ({
    type: types.invoiceActiveEdit,
    payload: payload
})

export const setActiveInvoiceShare = ( payload ) => ({
    type: types.invoiceShare,
    payload: payload
})

export const startAddOrderLineToState = ( payload, setSubmitting ) => {
    return async( dispatch ) => {
        setSubmitting( false )
        dispatch(actionLogOutModal())
        dispatch(startSuccess('Línea de Venta Añadida'))
        dispatch(addOrderLine( payload ))
    }
}

export const startDeleteInvoice = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.delete(`/ventas/${ id }`, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(deleteInvoice( id ))
            Swal.fire('Éxito', 'La venta se ha eliminado correctamente', 'success')
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido eliminar la venta', 'error')
        })
        .finally( () => {
            dispatch( startLogOutContentModal() )
        })
    }
}


export const startFacturar = () => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const idVenta = getState().invoice.activeEdit.PkVentas;
        const concepto  = localStorage.getItem('ConceptoFactura')
        await url.post(`/facturar/${ idVenta }`, {ConceptoFactura: concepto}, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActiveInvoice( idVenta ))
            Swal.fire('Éxito', 'La venta se ha facturado correctamente', 'success')
            localStorage.setItem('ConceptoFactura', '')
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido facturar la venta', 'error')
        })
        .finally( () => {
            dispatch( startLogOutContentModal() )
        })
    }
}


export const startDownloadCSVInvoices = ( filterList, page, dateRange ) => {
    return async( dispatch, getState ) => {
        const {idRol, id} = getState().auth;
        const query = buildFilterInvoice( filterList, page, dateRange, idRol, id );
        const { token } = loadJWT();
        await url.get(`${ query[3] }`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            },
            responseType: 'blob'
        })
        .then( res => {
                let reader = new FileReader()
                reader.readAsDataURL(res.data)
                reader.onload = (e) => {
                let a = document.createElement('a')
                a.download = `ventas.csv`
                a.href = e.target.result
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                }
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se han podido cargar las ventas', 'error')
                
        })
    }
}

export const startDownloadCSVInvoicesWithOutFilter = ( dateRange ) => {
    return async( dispatch ) => {
        const [ csvQuery ] = buildFilterCVS(dateRange);
        const { token } = loadJWT();
        await url.get(`${ csvQuery }`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            },
            responseType: 'blob'
        })
        .then( res => {
                let reader = new FileReader()
                reader.readAsDataURL(res.data)
                reader.onload = (e) => {
                    let a = document.createElement('a')
                    a.download = `ventas.csv`
                    a.href = e.target.result
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                }
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se han podido cargar las ventas', 'error')
                
        })
    }
}



const deleteInvoice = ( id ) => ({
    type: types.invoiceDeletebyId,
    payload: id
})
const addOrderLine = ( payload ) => ({
    type: types.linesAddNew,
    payload: payload,
})


export const startDeleteOrderLine = ( index ) => {
    return async( dispatch ) => {
        dispatch(deleteOrderLine( index ))
    }
}

const deleteOrderLine = ( index ) => ({
    type: types.lineDelete,
    payload: index
})

export const startLogOutSalesCleaning = () => {
    return async( dispatch ) => {
        dispatch(logOut());
    }
}

const logOut = () => ({
    type: types.invoicesLogOutCleaning
})

export const startResetPage = () => {
    return async( dispatch ) => {
        dispatch(resetPage());
    }
}
const resetPage = () => ({
    type: types.resetPage,
})


export const startDownloadingPDFBudget = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.get(`/ventas/downloadPresupuestoPdf/${ id }`, {
            headers: {
                Authorization: `Bearer ${ token }`
            },
            responseType: 'arraybuffer'
        })
        .then( ( res ) => {
            var file = new Blob([res.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            if (isBrowser) window.open(fileURL);
            dispatch(startLogOutContentModal())
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido descargar el presupuesto', 'error')

        })

    } 
}


export const startDownloadingVoucher = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.get(`/lineas-ventas/downloadPdfVoucher/${id}`, {
            headers: {
                Authorization: `Bearer ${ token }`
            },
            responseType: 'arraybuffer'
        })
        .then( ( res ) => {

            var file = new Blob([res.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);

            const k = document.createElement('a');
            k.setAttribute('href', fileURL);
            k.setAttribute('target', '_blank');
            k.click();

            /*if ( isBrowser ){
                window.open(fileURL);
            }*/

            /*var file = new Blob([res.data], {type: 'application/pdf'});
            FileSaver.saveAs(file, "voucher.pdf");*/


            /*var reader = new FileReader();
            var out = new Blob([res.data], {type: 'application/pdf'});
            reader.onload = function(e){
                if ( isBrowser ) {
                    window.open (reader.result);
                }
                dispatch(startLogOutContentModal())
            }
            reader.readAsDataURL(out);      */

            // window.open(URL.createObjectURL(res.data));

            //var file = new Blob([res.data], {type: 'application/pdf'});
            //download(file, "voucher.pdf", "application/pdf");

            
        })
        .catch( e => {
            console.log(e);
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido descargar el voucher', 'error')

        })

    }
}

export const startDownloadingFactura = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.get(`/ventas/downloadFactura/${id}`, {
            headers: {
                Authorization: `Bearer ${ token }`
            },
            responseType: 'arraybuffer'
        })
        .then( ( res ) => {
            var file = new Blob([res.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            if ( isBrowser ){
                window.open(fileURL);
            }
            
            dispatch(startLogOutContentModal())
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido descargar el voucher', 'error')

        })

    }
}

export const handleSendFactura = ( id ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        await url.post(`/sendEmailFactura/${ id }`, {} ,{ headers:{ Authorization: `Bearer ${ token }` }})
        .then( ( resp ) => {
            Swal.fire('Exito', 'Factura enviada', 'success')
            .then( () => {
                dispatch(setActiveInvoice( id ))
            })
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error en el envío. Contacte con el administrador', 'error')
            
        })
    }
}