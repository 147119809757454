import { useCallback, useState } from "react";
import { url } from "../api/api";
import { loadJWT } from "../helpers/loadJWT";

export const useDebounce = ( endPoint, fields ) => {

    
    const [search, setSearch] = useState([]);
    const { start, end, id } = fields;
    const { token } = loadJWT();

    const debounce = ( func ) => {
        let timer;
        return function( ...args ){
            const context = this;
            if( timer ){
                clearTimeout( timer )
            }
            timer = setTimeout( () => {
                timer = null;
                func.apply( context, args );
            }, 500);
        }
    }
    const handleChangeInput = async( e ) => {
        const { value } = e.target;
        let data = [];
        if( value?.length > 0 ){

            await url.get(`${ endPoint }=%${ value }%`, {
                headers:{
                    Authorization: `Bearer ${ token }`
                }
            })
            .then( res => {
                data = res.data.map( e => { return {label: `${e[start]}, ${e[end]}` , value: e[id]}});
                setSearch(data)
            })
            .catch( e => {
                console.log( e );
            });
        }


    }

    const debounceSearchResult = useCallback( debounce( handleChangeInput ), [] );
    
    return [search, debounceSearchResult]
}
