    import { types } from "../types/types";

    const initialState = {
        vendedores: [],
        active: null,
    }
    
    export const vendedoresReducer = ( state = initialState, action ) => {
        switch (action.type) {

            case types.sellersAgencyLoad:
                return {
                    ...state,
                    vendedores: [...action.payload]
                }

   
                case types.vendedorAgenciaDelete:
                    return {
                        ...state,
                        active: null,
                        vendedores: state.vendedores.filter( vendedor => vendedor.PkUsuarios !== action.payload )
                    }

                case types.vendedorAgenciaActivo:
                    return {
                        ...state,
                        active: {
                            ...action.payload
                        }
                    }

            /* case types.comisionistaActive:
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            
         
            case types.comisionistasLogOutCleaning:
                return initialState;
     */
            default:
                return state;
        }
    }
    