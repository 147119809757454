import React from 'react'
import { useSelector } from 'react-redux'
import { navigate } from "@reach/router"
import { isBrowser } from '../utils/constants';


export const PrivateRoute = ({
    component: Component,
    ...rest
}) => {
    const { aceptaPoliticas, cambiaPassword, id , esAgencia , aceptaContratoTratamientoDatos } = useSelector(state => state.auth);
    if (!id) {
        if (isBrowser) navigate("/login")
        return null
    }
    else if(!aceptaPoliticas){
        if (isBrowser) navigate("/politicas");
        return null;
    }
    else if(!cambiaPassword){
        if (isBrowser) navigate("/auth/change")
        return null;
    }
    else if(esAgencia && !aceptaContratoTratamientoDatos){
        if (isBrowser) navigate("/politicas_agencia")
        return null;
    }
    return <Component {...rest} />
    
}
