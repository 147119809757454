
//Third-party imports
import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { loadGridCustomer } from '../../helpers/loadGrid'
import { startNewClient } from '../../redux/actions/clients'
import { useFormStyles } from '../../styles/formStyles'
import { validationSchemaCustomer } from '../../utils/schemaValidationForm'
import { startLogOutContentModal } from "../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../redux/actions/ui";


/**
 * 
 * Author: QBK
 * Date: 30-09-2021
 */
export const ContentModalClientAdd = ({simple}) => {
    
    const { data } = loadGridCustomer(simple)
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const dispatch = useDispatch()
    const initialValues =  {
        Nombre: '',
        Apellidos: '',
        Email: '',
        Telefono: '',
        NumeroIdentificacionFiscal: '',
        Pais: '',
        Provincia: '',
        Localidad: '',
        CodigoPostal: '',
        Observaciones: '',
    }


    const handleSubmit = ( values, {setSubmitting} ) => {
        const customer = {
            ...values
        }
        dispatch(startNewClient( customer, setSubmitting ));
    }

    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };

    //HTML Grid Container
    return (
        <Formik initialValues={initialValues} validationSchema={ validationSchemaCustomer }   onSubmit={handleSubmit}>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid }) => (
            <Form>
                <Grid item style={{padding: '2rem'}}>
                    <Grid container direction='column'>
                        <Grid item classes={{root: classes.container }}>
                            <Typography variant='h1'>Agregar Cliente</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}> 
                            {
                                data.map( element => {
                                    return(
                                        <Grid 
                                            md={ (element.name === 'Direccion' || element.name === 'Observaciones' || element.name === 'Email') ? 12 : 6 }
                                            xs={12}
                                            item  
                                            classes={{root: classes.container }}
                                        >
                                            <TextField
                                                fullWidth
                                                name={element.name}
                                                size='small'
                                                classes={{ root: classes.inputText }}
                                                id={`outlined-basic-${element.name}`}  
                                                label={ element.label }
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            { element.startAdornment }
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched[element.name] && Boolean(errors[element.name])}
                                                helperText={touched[element.name] && errors[element.name]}
                                                disabled={ isSubmitting }
                                                multiline={ element.name === 'Observaciones' ? true : false}
                                                maxRows={ element.name === 'Observaciones' ? 10 : undefined }
                                                minRows={ (element.name === 'Observaciones' || element.name === 'Direccion') ? 3 : undefined }
                                            />
                                        </Grid>
                                    )
                                })
                            }

                    </Grid>
                    <Grid container spacing={3}> 
                           

                        <Grid  xs={12}
                            fullWidth
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >


                            <Button 
                                style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                onClick={ handleCancel }>
                                    <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                            </Button>

                            <LoadingButton
                                 loadingPosition="start"
                                 variant="contained"
                                 type='submit'
                                 loading={ isSubmitting }
                                 disabled={ !isValid }
                                 style={{
                                     backgroundColor: '#E5824F',
                 
                                 }}
                            >
                                    Agregar
                            </LoadingButton>
                        </Grid>

                    </Grid>
                </Grid>
        </Form>
        )}
        </Formik>
    )
}
