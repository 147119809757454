import { useEffect, useState } from "react"

import { url } from "../../api/api"
import { loadJWT } from "../../helpers/loadJWT"

export const useGetHoteles = () => {
  const { token } = loadJWT();
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect( () => {
    const getHoteles = async () => {
      try {
        const hoteles = await url.get( "usuarios/hoteles", {
          headers: {
            'Authorization': `Bearer ${ token }`
          }
        } )
        setData( hoteles.data )
      } catch {
        setError( "Ha ocurrido un error al cargar los hoteles disponibles" )
      } finally {
        setIsLoading( false )
      }
    }
    
    getHoteles()
  }, [])

  return { isLoading, error, data }
}