import { navigate } from "@reach/router";
import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadClients } from "../../helpers/loadClients"
import { loadJWT } from "../../helpers/loadJWT";
import { types } from "../types/types";
import { startLogOutContentModal } from "./contentModal";
import { finishLoading, startLoading, uiManageModalCollectionTypes } from "./ui";

export const startNewClient = ( client, setSubmitting ) => {
    
    return async(dispatch) => {
        const { token } = loadJWT();
        await url.post('/clientes', client, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        } )
        .then( (res) => {
            dispatch(startLoadingClients());
            Swal.fire('Éxito', 'Cliente añadido', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'El cliente ya existe', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(uiManageModalCollectionTypes());
            dispatch(startLogOutContentModal())
        })

    }
}


export const startUpdateClient = (client, setSubmitting) => {
    return async(dispatch) => {
        const { token } = loadJWT();
            await url.put(`/clientes/${ client.PkClientes }`, client ,{
                headers:{
                    Authorization: `Bearer ${ token }`
                }
            })
            .then( () => {
                Swal.fire('Éxito', 'Cliente modificado con éxito', 'success')
                .then(() => {
                    navigate(`/clients`);
                })
                return;
            })
            .catch( e => {
                Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido editar el cliente', 'error');
                return;
            })
            .finally( () => {
                setSubmitting( false );
            })
        }
        
    
}


export const startDeleteCustomer = ( id ) => {
    return async(dispatch) => {
        const { token } = loadJWT();
            await url.delete(`/clientes/${ id }`, {
                headers:{
                    Authorization: `Bearer ${ token }`
                }
            })
            .then(() => {
                dispatch((deleteClient(id)));
                Swal.fire('Éxito', 'Cliente eliminado', 'success');
                return;
            })
            .catch( e => {
                Swal.fire('Error', e?.response?.data?.error?.message || 'Error al eliminar el cliente', 'error')
                return;
            })
    }
}


export const setActiveCustomer = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/clientes/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            if( resp.status === 204){
                navigate('/clients');
                dispatch(finishLoading());
                return;
            }
            dispatch((setActive(resp.data)));
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al eliminar el cliente', 'error')
            .then( () => {
                navigate('/clients');
            })
            .then( () => {
                dispatch(finishLoading());
            })
            return;
        })
    }
    
}

export const setActiveCustomerState = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/clientes/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch((setActive(resp.data)));
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al eliminar el cliente', 'error')
            return;
        })
    }
    
}

export const startLoadingClients = () => {
    return async(dispatch) => {
        dispatch(startLoading());
        const clients = await loadClients();
        dispatch(setClients(clients));
        dispatch(finishLoading())
    }
}

export const startLoadingAllClients = () => {
    return async(dispatch) => {
        const { token } = loadJWT();
        dispatch(startLoading())
        await url.get('/clientes', {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( ( resp ) => {
            dispatch(setClients(resp.data));
           
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al cargar los clientes', 'error');
        })
        .finally( () => {
            
            dispatch(finishLoading());
        })
        
        
    }
}

export const setActive = ( client ) => ({
    type: types.clientActive,
    payload: client,
})
export const setClients = (clients) => ({
    type: types.clientsLoad,
    payload: clients,
})


export const deleteClient = (id) =>({
    type: types.clientDelete,
    payload: id
})

const logOut = () => ({
    type: types.clientsLogOutCleaning
})

export const startLogOutClients = () => {
    return async( dispatch ) => {
        dispatch( logOut() )
    }
}