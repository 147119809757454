
//Third-party packages
import React from 'react'

import { useDispatch, useSelector } from 'react-redux';


//Custom imports
import { uiManageModalNotification } from '../../redux/actions/ui';
import { startDeleteSupplier } from '../../redux/actions/suppliers';
import { Content } from './Content';


/**
 * 
    Author: QBK
    Date: 01-10-2021
 */
export const ConfirmDeleteSupplier = () => {

    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { active } = useSelector(state => state.supplier) //we get the users from the state 


    const handleSuccess = () => {
        dispatch( uiManageModalNotification()); //we close the confirmation modal
        dispatch(startDeleteSupplier(active.PkProveedores)); //We dispatch the action passing as a parameter the user id
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ `¿Quieres borrar el proveedor?` }
                text={ `El proveedor quedará eliminado definitivamente del sistema` }
                action={ handleSuccess }
            />
    )
}
