import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import Alert from '@mui/material/Alert';
import * as yup from 'yup'
import { useFormStyles } from '../../../styles/formStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { startAddOrderLineToState, startGatheringServiceData, startGatheringServiceDataForReseller } from '../../../redux/actions/sales';
import { FormLinesSeller } from './formLinesSeller';
import { ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR, SERVICE } from '../../../utils/constants';
import { FormLinesReseller } from './formLinesReseller';
import { startLoadingServicesSupplier } from '../../../redux/actions/suppliers';
import { setActiveExchangeRateToSupplier } from '../../../redux/actions/exchangeRate';
import { startNewOrderLine } from '../../../redux/actions/lines';
import { startLogOutServicesActive } from '../../../redux/actions/supplierServices';
import { calculateFromDollarsToEuro } from '../../../utils/utils';
import { startLogOutContentModal } from "../../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../../redux/actions/ui";
import { startLoadingSuppliers } from '../../../redux/actions/suppliers';
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../../utils/exchange';

import { actionSetCurrentUser } from '../../../redux/actions/actions';


const isEmpty = (obj) => {
    return Object.keys( obj || {} ).length === 0;
}


export const ContentModalAddOrderLine = () => {

    const classes = useFormStyles(); //the classes variable to handle the form styles
    //const [ search, debounceSearchResult ] = useDebounce('/proveedores?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkProveedores"});
    const [valueService, setValueService] = useState('');
    const [supplierValue, setSupplierValue] = useState('')
    const dispatch = useDispatch();
    const { active: activeService } = useSelector(state => state.service);
    const { active: activeRate } = useSelector(state => state.exchangeRate)
    const { idAgencia, idRol, id: loggedInUser, moneda: monedaUsuario } = useSelector(state => state.auth);
    const { services } = useSelector(state => state.service);
    const [conceptoLine, setConceptoLine] = useState('');
    const [tasaLine, SetTasaLine] = useState('');    
    const { current : currentUser } = useSelector(state => state.user);

    const { services: servicesSupplier } = useSelector(state => state.supplier)
    const { modal } = useSelector(state => state.modal);
    const { activeEdit } = useSelector(state => state.invoice)
    const refService = useRef();
    const servicesReseller = [];
    const servicesAutoComplete = [];
    const { suppliers } = useSelector(state => state.supplier );
    const { loadingModal } = useSelector( state=> state.ui)

    const isAdminEditingResellerOrder = () => {
        return idRol === ROLADMIN && activeEdit?.Usuario?.FkRoles === ROLREVENDEDOR;
    }

    useEffect(() => { 
        console.log('actionSetCurrentUser');      
        dispatch(actionSetCurrentUser(loggedInUser))
    }, []);


    const validationSchemaOrderLineSeller = () => {
        return yup.object().shape({
            FkProveedores: yup.number().required('Proveedor Requerido'),
            FkServiciosProveedores: yup.number().required('Servicio es requerido'),
            FechaRecogida: yup.date().nullable(),
            InSituLineaDolares: yup.number().required('Coste Dólares es requerido'),
            Concepto: yup.string().optional(),
            ReservaLineaEuros: yup.number().required('Reserva en euros es requerido'),
            Unidades: yup.number().required('Unidades es requerido'),
            Observaciones: yup.string().optional(),
            PorcDescuento: yup.number('Debe ser un entero').integer('Debe ser un entero').min(0).max(100).optional(),

        })
    }

    const validationSchemaOrderLineReSeller = () => {
        return yup.object().shape({
            FkProveedores: yup.number().required(),
            FkServiciosProveedores: yup.number().required('Servicio es requerido'),
            FechaRecogida: yup.date().nullable(),
            Concepto: yup.string().optional(),
            ReventaLineaEuros: yup
                                .number()
                                .required('El importe de reventa es requerido')
                                .min(0,'El margen no puede ser negativo'),
            Unidades: yup.number().required('Unidades es requerido'),
            Observaciones: yup.string().optional(),
            PorcDescuento: yup.number('Debe ser un entero').integer('Debe ser un entero').min(0, 'El descuento no puede ser negativo').max(100, 'El descuento no puede ser superior al 100%').optional(),

        })
    }

    // Si hay un proveedor seleccionado, cargamos los servicios del proveedor
    useEffect(() => {
        if( supplierValue ){
            dispatch(startLoadingServicesSupplier( supplierValue ))
        }
        else{
            dispatch(startLogOutServicesActive())
        }
    }, [ supplierValue, dispatch ])


    // Si hay un servicio seleccionado, cargamos la información en el estado
    useEffect(() => {
        if( valueService !== '' && (idRol !== ROLREVENDEDOR && !isAdminEditingResellerOrder()) && modal){
            dispatch(startGatheringServiceData(valueService))
        }
        else if( valueService !== '' && idRol === ROLREVENDEDOR && modal){
            dispatch(startGatheringServiceDataForReseller((idAgencia>0)?idAgencia:loggedInUser, valueService))
        }
        else if( valueService !== '' && isAdminEditingResellerOrder() && modal){
            dispatch(startGatheringServiceDataForReseller(
                (activeEdit?.Usuario?.FkUsuarios_Agencia>0)?activeEdit?.Usuario?.FkUsuarios_Agencia:activeEdit?.Usuario?.PkUsuarios
                , valueService))
        }
        else{
            dispatch(startLogOutServicesActive())
        }
    }, [ valueService, dispatch, idRol, loggedInUser, modal ])

   
    // Si tenemos proveedor y servicio seleccionados, cargamos los tipos de cambio en el estado
    useEffect(() => {
        if( supplierValue && valueService && activeService){
            dispatch(setActiveExchangeRateToSupplier(supplierValue, activeService.TipoServicio.PkTiposServicio))
        }
       
    }, [supplierValue, valueService, activeService, dispatch])

    // Si el usuario es revendedor y hay un servicio seleccionados, cargamos los tipos de cambio en el estado
    useEffect(() => {   
        if( valueService 
            && (idRol === ROLREVENDEDOR || isAdminEditingResellerOrder()) 
            && activeService){
            dispatch(setActiveExchangeRateToSupplier(activeService?.ServicioProveedor?.FkProveedores, activeService?.ServicioProveedor?.FkTiposServicio))
        }
    }, [valueService, activeService, idRol, dispatch])

    // Obtenemos los proveedores y los cargamos en el estado
    useEffect(() => {
        dispatch(startLoadingSuppliers())
    }, [ dispatch ]);
    
    const suppliersArray = suppliers.map ( supplier => { return { label: supplier.Nombre, value: supplier.PkProveedores }});
    


    const initialValuesSeller = {
        FkProveedores: activeService?.ProveedorServicio?.PkProveedores,
        FkServiciosProveedores: activeService?.PkServiciosProveedores,
        InSituLineaDolares: activeService?.CosteDolares,
        Concepto: conceptoLine,
        ReservaLineaEuros: activeService?.PrecioVentaEuros - +calculateFromDollarsToEuro( activeRate, activeService?.CosteDolares), //activeService?.ReservaEuros,
        PrecioUnitarioEuros: activeService?.PrecioVentaEuros, // + +calculateFromDollarsToEuro( activeRate, activeService?.CosteDolares),
        Unidades: 1,
        Observaciones: '',
        FechaRecogida: null,
        PorcDescuento: 0,
        TasaDolares: tasaLine??0,
        SuplementoTransporteDolares: 0,
        ReventaLineaEuros:  0, 
        ReventaLineaDolares:  0,
    }

    const initialValuesReSeller = {
        FkProveedores: activeService?.ServicioProveedor?.FkProveedores,
        FkServiciosProveedores: activeService?.ServicioProveedor?.PkServiciosProveedores,
        Concepto: conceptoLine,
        ReventaLineaEuros:  activeService?.ImporteReventaEuros,
        ReventaLineaDolares:  activeService?.ImporteReventaDolares,
        Unidades: 1,
        Observaciones: '',
        FechaRecogida: null,
        PorcDescuento: 0,
        TasaDolares: tasaLine??0,
        SuplementoTransporteDolares: 0,
    }

    const handleSubmit = ( values, {setSubmitting}) => {
        if( idRol === ROLVENDEDOR || (idRol === ROLADMIN && !isAdminEditingResellerOrder()) ){
            if( !activeEdit ){
                const payload = {
                    ...values,
                    FkTiposLineaVenta: SERVICE,
                    FkProveedores: activeService?.ProveedorServicio?.PkProveedores,
                    FkTiposCambioProveedores: activeRate.PkTiposCambioProveedores, //Pendiente
                    CosteDolares: Math.round(activeService?.CosteDolares),
                    ReservaEuros: Math.round(activeService?.PrecioVentaEuros) - obtenerPrecioEuros(activeService?.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares),
                    Unidades: +values?.Unidades,
                    InSituLineaDolares: Math.round(+values?.InSituLineaDolares),
                    FkTiposServicio: activeService?.FkTiposServicio,
                    FechaRecogida: values.FechaRecogida ? values.FechaRecogida : null,
                    PorcDescuento: +values.PorcDescuento,
                    TasaDolares: +values.TasaDolares,

                    SuplementoTransporteDolares: +values.SuplementoTransporteDolares,
                    SuplementoTransporteEuros: +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),

                    ReservaLineaEuros: (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)),

                    NombreServicio: activeService?.Nombre,
                    NombreProveedor: activeService?.ProveedorServicio?.Nombre,                    
                    InSituLineaEuros: obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares),
                    ReservaDescuento: Math.round(
                        (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) 
                        - ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) * (+values.PorcDescuento/100))),
                    TasaEuros: obtenerPrecioEuros(values.TasaDolares, activeRate.CambioEuros, activeRate.CambioDolares ),
                    
                    ReventaLineaEuros: +values.ReventaLineaEuros??0,

                    

                }
                dispatch(startAddOrderLineToState( payload, setSubmitting ));
            }
            else{
                const payload = {
                    "FkTiposLineaVenta": SERVICE,
                    "FkProveedores": activeService?.FkProveedores,
                    "FkTiposCambioProveedores": activeRate.PkTiposCambioProveedores,
                    "CosteDolares":  Math.round(activeService?.CosteDolares),
                    "ReservaEuros": Math.round(activeService?.PrecioVentaEuros) - obtenerPrecioEuros(activeService?.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares),
                    "Unidades": +values?.Unidades,
                    "InSituLineaDolares": Math.round(+values?.InSituLineaDolares),
                    "FkTiposServicio": activeService?.FkTiposServicio,
                    "FechaRecogida": values.FechaRecogida ? values.FechaRecogida : null,
                    "PorcDescuento": +values.PorcDescuento,
                    "TasaDolares": +values.TasaDolares,
                    "SuplementoTransporteDolares": +values.SuplementoTransporteDolares,

                    "ReservaLineaEuros": (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)),

                    "Concepto": values.Concepto,                    
                    "Observaciones": values.Observaciones??'',
                    "ConfirmadaProveedor": false,
                    "FkVentas": activeEdit?.PkVentas,
                    "FkServiciosProveedores": values?.FkServiciosProveedores,
                    
                    "InSituLineaEuros": obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares),
                    
                    "ReventaLineaEuros": +values.ReventaLineaEuros??0,
                    "SuplementoTransporteEuros": +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),


                }
                dispatch(startNewOrderLine(payload))
            }
                
        }
        else{
            const monedaUsuarioVentaEditada = activeEdit ? (activeEdit?.Usuario?.Moneda) : monedaUsuario;
            const usuarioVentaEditada = activeEdit ? (activeEdit?.Usuario) : currentUser;
            // TODO: CÁLCULO EN FUNCIÓN DE LA MONEDA CON LA QUE SE CREA LA VENTA, ES DECIR, SI LA MONEDA ES DÓLARES,
            // NO TENEMOS VALOR EN VALUES.IMPORTEREVENTAEUROS, ...
            let _reventaLineaEuros = (monedaUsuarioVentaEditada==='EUR') ? 
                Math.round(+values.ReventaLineaEuros) :
                // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                Math.round(100*(+values.ReventaLineaDolares * +activeRate.CambioDolares / +activeRate.CambioEuros))/100;
        

            // PRECIO DE COSTE DEL SERVICIO 
            let costeDolares = Math.round(+activeService?.ServicioProveedor?.CosteDolares);
            // RESERVA XPLORA DEL SERVICIO --> PRECIO VENTA - COSTE - MARGEN (EN EL SERVICIO DEL REVENDEDOR)
            let reservaEuros =  
                (monedaUsuarioVentaEditada==='EUR') ? +activeService.ReservaEuros : 
                (
                    (+activeService.PrecioVentaDolares - +activeService?.ServicioProveedor?.CosteDolares - +activeService.ImporteReventaDolares) 
                    * +activeRate.CambioDolares / +activeRate.CambioEuros )   
            ;

            // REVENTA EUROS: SE ESTÁ COGIENDO REVENTALINEAEUROS -- DEBE COGER EL MARGEN DEFINIDO PARA EL REVENDEDOR EN SU SERVICIO
            let reventaEuros = +activeService.ImporteReventaEuros;
            let reventaDolares = +activeService.ImporteReventaDolares;
            // INSITULINEA: IMPORTE QUE VA INSITU EN LA VENTA (sin tener en cuenta el precio de transporte)
            let insituLineaEuros = 
                usuarioVentaEditada.MargenConfiguradoEnServicios ? 
                    (+activeService.PrecioVentaEuros - +reventaEuros) :
                (usuarioVentaEditada.Comisiona ? 
                    obtenerPrecioEuros(activeService?.ServicioProveedor?.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares)
                    : 
                    // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                    obtenerPrecioEuros(activeService?.ServicioProveedor?.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares)
                    );
            
            let insituLineaDolares = 
                    usuarioVentaEditada.MargenConfiguradoEnServicios ? 
                        (+activeService.PrecioVentaDolares - +reventaDolares) :
                    (usuarioVentaEditada.Comisiona ? 
                        (+activeService?.ServicioProveedor?.CosteDolares)
                        : 
                        // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                        (+activeService?.ServicioProveedor?.CosteDolares)
                        );
            // RESERVALINEA: IMPORTE QUE VA DE RESERVA EN LA LINEA
            let reservaLineaEuros = 
                usuarioVentaEditada.MargenConfiguradoEnServicios ? 
                reventaEuros :
                    (usuarioVentaEditada.Comisiona ? 
                        (+activeService.ReservaEuros) :
                        (+activeService.ReservaEuros + +_reventaLineaEuros)
                    );
            let reservaLineaDolares = 
                usuarioVentaEditada.MargenConfiguradoEnServicios ? 
                reventaDolares :
                        (usuarioVentaEditada.Comisiona ? 
                            (+activeService.ReservaDolares) :
                            (+activeService.ReservaDolares + +values.ReventaLineaDolares)
                        );
            // REVENTALINEA: EL MARGEN MARCADO EN LA LINEA
            let reventaLineaEuros = 
            usuarioVentaEditada.MargenConfiguradoEnServicios ? 
                +activeService.ImporteReventaEuros :
                    (usuarioVentaEditada.Comisiona ? 
                        (0) :
                        (+_reventaLineaEuros)
                    );
            let reventaLineaDolares = 
                usuarioVentaEditada.MargenConfiguradoEnServicios ? 
                    +activeService.ImporteReventaDolares :
                        (usuarioVentaEditada.Comisiona ? 
                            (0) :
                            (+values.ReventaLineaDolares)
                        );
            // TASA: SE COGE DEL SERVICIO
            // SUPLEMENTOTRANSPORTE: EL QUE SE DEFINA POR EL VENDEDOR


            if( !activeEdit ){
                const payload = {
                    Concepto: values.Concepto,
                    FkTiposLineaVenta: SERVICE,
                    FkProveedores: activeService?.ServicioProveedor?.ProveedorServicio?.PkProveedores,
                    FkTiposCambioProveedores: activeRate.PkTiposCambioProveedores, //Pendiente

                    CosteDolares: costeDolares,
                    ReservaEuros:reservaEuros,
                    ReventaEuros:reventaEuros,
                    InSituLineaDolares:insituLineaDolares,
                    InSituLineaEuros:insituLineaEuros,
                    ReservaLineaEuros:reservaLineaEuros,
                    ReservaLineaDolares:reservaLineaDolares,
                    ReventaLineaDolares:reventaLineaDolares,
                    ReventaLineaEuros:reventaLineaEuros,
                    /*CosteDolares: Math.round(+activeService?.ServicioProveedor?.CosteDolares),
                    ReservaEuros: +activeService.ImporteReventaEuros,
                    ReventaEuros: (monedaUsuario==='EUR') ? 
                        Math.round(+values.ReventaLineaEuros) :
                        //+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,+values.ReventaLineaDolares)
                        Math.round(100*(+values.ReventaLineaDolares * activeRate.CambioDolares / activeRate.CambioEuros))/100,
                
                    // cálculo de in situ en función del tipo de revendedor
                    InSituLineaDolares: Math.round(+activeService?.ServicioProveedor?.CosteDolares),
                    InSituLineaEuros: obtenerPrecioEuros(activeService?.ServicioProveedor?.CosteDolares, activeRate.CambioEuros, activeRate.CambioDolares),

                    ReservaLineaEuros: +activeService.ImporteReventaEuros,
                    ReservaLineaDolares: obtenerPrecioDolares(+activeService.ImporteReventaEuros, activeRate?.CambioEuros, activeRate?.CambioDolares),

                    ReventaLineaDolares:  (monedaUsuario==='EUR') ? 
                        +values.ReventaLineaEuros  * activeRate.CambioEuros / activeRate.CambioDolares :
                        //+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,+values.ReventaLineaDolares)
                        +values.ReventaLineaDolares,
                    ReventaLineaEuros: (monedaUsuario==='EUR') ? 
                        Math.round(+values.ReventaLineaEuros) :
                        //+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,+values.ReventaLineaDolares)
                        Math.round(100*(+values.ReventaLineaDolares * activeRate.CambioDolares / activeRate.CambioEuros))/100,
*/
                    Unidades: +values?.Unidades,
                    FkServiciosProveedores: activeService?.FkServiciosProveedores,
                    
                    NombreServicio: activeService?.ServicioProveedor?.Nombre,
                    NombreProveedor: activeService?.ServicioProveedor?.ProveedorServicio?.Nombre,
                    FechaRecogida: values.FechaRecogida ? values.FechaRecogida : null,
                    
                    TasaDolares: +values.TasaDolares,                    
                    TasaEuros: obtenerPrecioEuros(values.TasaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares),

                    SuplementoTransporteDolares: +values.SuplementoTransporteDolares,
                    SuplementoTransporteEuros: +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),
                    

                }
                dispatch(startAddOrderLineToState( payload, setSubmitting ));
            }
            else{
                const payload = {
                    "Concepto": values.Concepto,
                    
                    "CosteDolares": costeDolares,
                    "ReservaEuros":reservaEuros,
                    "ReventaEuros":reventaEuros,
                    "InSituLineaDolares":insituLineaDolares,
                    "InSituLineaEuros":insituLineaEuros,
                    "ReservaLineaEuros":reservaLineaEuros,
                    "ReservaLineaDolares":reservaLineaDolares,
                    "ReventaLineaDolares":reventaLineaDolares,
                    "ReventaLineaEuros":reventaLineaEuros,
                    
                    /*"CosteDolares":  Math.round(+activeService?.ServicioProveedor?.CosteDolares),
                    "InSituLineaDolares": Math.round(+activeService?.ServicioProveedor?.CosteDolares),
                    "ReservaEuros": +activeService.ImporteReventaEuros,
                    "ReservaLineaEuros": +activeService.ImporteReventaEuros,
                    "ReventaEuros":  (monedaUsuario==='EUR') ? 
                        Math.round(+values.ReventaLineaEuros) :
                        // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                        Math.round(100*(+values.ReventaLineaDolares * activeRate.CambioDolares / activeRate.CambioEuros))/100,
                    "ReventaLineaEuros":  (monedaUsuario==='EUR') ? 
                        Math.round(+values.ReventaLineaEuros) :
                        // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                        Math.round(100*(+values.ReventaLineaDolares * activeRate.CambioDolares / activeRate.CambioEuros))/100,*/
                    "Unidades": +values?.Unidades,
                    "Observaciones": values.Observaciones??'',
                    "ConfirmadaProveedor": false,
                    "FkVentas": activeEdit?.PkVentas,
                    "FkTiposLineaVenta": SERVICE,
                    "FkServiciosProveedores": values?.FkServiciosProveedores,
                    "FkTiposCambioProveedores": activeRate.PkTiposCambioProveedores,
                    "FkProveedores": activeService?.ServicioProveedor?.ProveedorServicio?.PkProveedores,
                    "FechaRecogida": values.FechaRecogida ? values.FechaRecogida : null,
                    "TasaDolares": +values.TasaDolares,
                    "SuplementoTransporteDolares": +values.SuplementoTransporteDolares,
                    /*"ReservaLineaDolares": obtenerPrecioDolares(+activeService.ImporteReventaEuros, activeRate?.CambioEuros, activeRate?.CambioDolares),
                    "ReventaLineaDolares":  (monedaUsuario==='EUR') ? 
                        +values.ReventaLineaEuros  * activeRate.CambioEuros / activeRate.CambioDolares :
                        //+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,+values.ReventaLineaDolares)
                        +values.ReventaLineaDolares,*/
                    "TasaEuros": obtenerPrecioEuros(values.TasaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares),
                    
                    "SuplementoTransporteEuros": +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),

                }
                //console.log( payload )
                dispatch(startNewOrderLine(payload))
            }
            
        }
    }

    if( idRol === ROLREVENDEDOR || isAdminEditingResellerOrder() ){
        services?.map( element => {
            servicesReseller.push({ label: element?.ServicioProveedor?.Alias ? element?.ServicioProveedor?.Alias : element?.ServicioProveedor?.Nombre
                , value: element?.FkServiciosProveedores });
            return null;
        })
    }
    if( idRol === ROLADMIN || idRol === ROLVENDEDOR ){
        servicesSupplier.map( service => {
            servicesAutoComplete.push({ label: service.Nombre, value: service.PkServiciosProveedores})
            return null;
        })
    }

    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };
    
    return (
        <>
            <Formik enableReinitialize 
                initialValues={ (idRol === ROLADMIN && !isAdminEditingResellerOrder()) || idRol === ROLVENDEDOR ? initialValuesSeller : initialValuesReSeller} 
                validationSchema={ (idRol === ROLADMIN && !isAdminEditingResellerOrder()) || idRol === ROLVENDEDOR ? validationSchemaOrderLineSeller : validationSchemaOrderLineReSeller }   
                onSubmit={handleSubmit}>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, values, setFieldValue }) => (
                <Form>
                    <Grid item style={{padding: '2rem'}}>
                        <Grid container direction='column'>
                            <Grid item classes={{root: classes.container }}>
                                <Typography variant='h3'>Agregar Línea de Venta</Typography>
                            </Grid>
                            {
                                (idRol !== ROLREVENDEDOR && !isAdminEditingResellerOrder())
                                ?
                                (
                                    <>
                                        <Autocomplete style={{fontSize: '0.8em'}}
                                            name='FkProveedores'
                                            autoHighlight
                                            fullWidth
                                            disablePortal
                                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                                            id="servicio-autocomplete-exchange-rate"
                                            noOptionsText={"No hay proveedores"}
                                            renderInput={(params) => ( 
                                                <TextField 
                                                    {...params}
                                                    name='FkProveedores' 
                                                    variant='outlined' 
                                                    label="Localizar proveedor" 
                                                    placeholder='Busca...'
                                                    disabled={ isSubmitting }
                                                    onBlur={ handleBlur }
                                                    onChange={handleChange}
                                                    error={touched.FkProveedores && Boolean(errors.FkProveedores)}
                                                    helperText={touched.FkProveedores && errors.FkProveedores}
                                                    

                                                />)}
                                            //onInputChange={ debounceSearchResult }
                                            sx={{ marginBottom: '2rem'}}
                                            options={ suppliersArray  }
                                            onChange={(_, value) => {
                                                setFieldValue(
                                                "FkProveedores",
                                                value?.value !== null && value?.value 
                                                );
                                                if(!value?.value && values.FkServiciosProveedores){
                                                    refService.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                                                    setValueService('')

                                                }
                                                setSupplierValue(value?.value);
                                                setFieldValue("FkServiciosProveedores",null);
                                                if( values.FkServiciosProveedores ){
                                                    refService.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                                                }
                                                
                                                setValueService("");

                                            }}
                                            
                                        />
                                        {
                                            supplierValue
                                            &&
                                            (
                                                <Autocomplete style={{fontSize: '0.8em'}}
                                                    name='FkServiciosProveedores'
                                                    autoHighlight
                                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                                    ref={refService}
                                                    fullWidth
                                                    disablePortal
                                                    id="servicio-autocomplete-exchange-rate"
                                                    noOptionsText={"No hay servicios"}
                                                    renderInput={(params) => ( 
                                                        <TextField 
                                                            {...params}
                                                            name='FkServiciosProveedores' 
                                                            variant='outlined' 
                                                            label="Servicio" 
                                                            placeholder='Busca...'
                                                            disabled={ isSubmitting }
                                                            onBlur={ handleBlur }
                                                            onChange={handleChange}
                                                            error={touched.FkServiciosProveedores && Boolean(errors.FkServiciosProveedores)}
                                                            helperText={touched.FkServiciosProveedores && errors.FkServiciosProveedores}
                                                            

                                                        />)}
                                                    sx={{ marginBottom: '2rem'}}
                                                    options={ servicesAutoComplete  }
                                                    onChange={(e, value) => {
                                                        setFieldValue(
                                                        "FkServiciosProveedores",
                                                        value?.value !== null && value?.value
                                                        );
                                                        
                                                        
                                                        setValueService(value?.value)
                                                        //setConceptoLine(value?.label) 

                                                        const servicios = servicesSupplier.filter(service=>service.PkServiciosProveedores==value?.value);
                                                        if (servicios.length>0) {
                                                            SetTasaLine(servicios[0].TasaDolares);
                                                            setConceptoLine(servicios[0].Alias ? servicios[0].Alias : value?.label);
                                                        }

                                                        if(!value?.value){
                                                            setValueService("");
                                                            setConceptoLine("");
                                                            SetTasaLine("");
                                                        }

                                                    }}
                                                />
                                            )
                                        }
                                        
                                    </>
                                )
                                :
                                <Autocomplete style={{fontSize: '0.8em'}}
                                    disablePortal
                                    fullWidth
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    noOptionsText={"No hay servicios"}
                                    id="combo-box-demo"
                                    options={servicesReseller}
                                    sx={{ marginBottom: '2rem'}}
                                    renderInput={(params) => <TextField {...params}  variant='outlined'  label="Servicio" />}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                        "FkServiciosProveedores",
                                        value?.value !== null ? value?.value : values.FkServiciosProveedores
                                        );
                                        setValueService(value?.value)
                                        if(!value?.value){
                                            setValueService("");
                                            setConceptoLine("");
                                            SetTasaLine("");
                                        }
                                        //setConceptoLine(value?.label)
                                        const servicios = services.filter(service=>service.FkServiciosProveedores==value?.value);
                                        if (servicios.length>0) {
                                            SetTasaLine(servicios[0].ServicioProveedor.TasaDolares);                                            
                                            setConceptoLine(servicios[0].ServicioProveedor.Alias ? servicios[0].ServicioProveedor.Alias : value?.label);
                                        }
                                    }}
                                />
                            }
                            
                            {
                                
                                (!values.FkProveedores || !values.FkServiciosProveedores)
                                ?
                                <div></div>
                                :
                                (
                                    (isEmpty(activeRate) && !loadingModal)
                                    ?
                                    <Alert sx={{ marginBottom: '2rem' }} variant="outlined" severity="error">
                                        Este proveedor no tiene configurado el tipo de cambio para el tipo de servicio seleccionado
                                    </Alert>
                                    :
                                    (
                                        (isEmpty(activeRate) && loadingModal)
                                        ?
                                        <Typography variant='h3'>Cargando...</Typography>
                                        :
                                        (((idRol === ROLADMIN && !isAdminEditingResellerOrder()) || idRol === ROLVENDEDOR)
                                        ?
                                        (
                                            <FormLinesSeller 
                                                handleChange={ handleChange } 
                                                errors={ errors } 
                                                handleBlur={ handleBlur } 
                                                isSubmitting={ isSubmitting } 
                                                touched={ touched } 
                                                isValid={ isValid } 
                                                values={ values }
                                                activeRate={ activeRate }
                                            />
                                        )
                                        :
                                        (
                                            <FormLinesReseller 
                                                handleChange={ handleChange } 
                                                errors={ errors } 
                                                handleBlur={ handleBlur } 
                                                isSubmitting={ isSubmitting } 
                                                touched={ touched } 
                                                isValid={ isValid } 
                                                values={ values }
                                                activeRate={ activeRate }
                                                vendedor= {activeEdit?(activeEdit?.Usuario):currentUser}
                                            />
                                        ))
                                    )
                                )
                                
                                
                            }
                            
                            
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >

                                <Button 
                                    style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                    onClick={ handleCancel }>
                                        <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                </Button>

                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    type='submit'
                                    loading={ isSubmitting }
                                    disabled={ !isValid || !activeRate?.PkTiposCambioProveedores }
                                    style={{
                                        backgroundColor: '#E5824F',
                    
                                    }}
                                >
                                        Añadir
                                </LoadingButton>

                            </Box>

                        </Grid>
                    </Grid>
            </Form>
            )}
            </Formik>
        </>
    )
}
