
    import { types } from "../types/types";

    const initialState = {
        suppliers: [],
        active: null,
        services: [],
    }
    
    export const supplierReducer = ( state = initialState, action ) => {
        switch (action.type) {
            case types.supplierActive:
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            case types.suppliersLoad:
                return {
                    ...state,
                    suppliers: [...action.payload]
                }
            
            
            case types.supplierDelete:
                return {
                    ...state,
                    active: null,
                    suppliers: state.suppliers.filter( supplier => supplier.PkProveedores !== action.payload )
                }
            case types.supplierDeleteCoordinator:
                return {
                    ...state,
                    active: null,
                    suppliers: state.suppliers.filter( supplier => supplier.FkProveedores !== action.payload )
                }
            case types.suppliersLogOutCleaning:
                return initialState;
            
            case types.supplierServicesLoad: 
                return {
                    ...state,
                    services: [...action.payload]
                }
            default:
                return state;
        }
    }
    