import React from 'react';
import { 
        AccountCircle, 
        AccountCircleRounded,  
        AddBox,  
        AlternateEmail, 
        ApartmentOutlined, 
        ApartmentRounded, 
        AttachMoney, 
        BeachAccess, 
        Business, 
        CardTravel, 
        Code, 
        CodeOutlined, 
        CodeRounded, 
        DateRange, 
        DateRangeOutlined, 
        DragIndicator, 
        Email, 
        EmailOutlined, 
        Euro, 
        EuroOutlined, 
        FormatListNumbered, 
        FormatListNumberedOutlined, 
        FormatListNumberedRounded, 
        Hotel, 
        InfoOutlined, 
        LibraryAdd, 
        LocalOffer, 
        LocalPhone, 
        MenuOutlined,  
        MonetizationOn,   
        PersonAdd, 
        Phone, 
        Policy, 
        RemoveRedEyeRounded, 
        Reorder, 
        ThumbsUpDown, 
        ThumbUpAlt, 
        VpnKey, 
        Web,
        
} from "@material-ui/icons"

export const loadGridUser = () => {
    const data = [
        {
            name: 'name',
            label: 'Nombre',
            startAdornment: <AccountCircleRounded />,
        },
        {
            name: 'surname',
            label: 'Apellidos',
            startAdornment: <AccountCircleRounded />,
        },
        {
            name: 'email',
            label: 'Email',
            startAdornment: <AlternateEmail />,
        },
        {
            name: 'password',
            label: 'Contraseña',
            startAdornment: <VpnKey />,
            endAdornment: true,
            filterdetail: true,
        },
        {
            name: 'telefono',
            label: 'Teléfono',
            startAdornment: <Phone />,
        },
        
        {
            name: 'rol',
            label: 'Rol',
            startAdornment: <ThumbsUpDown />,
            filter: 'notAdd',
            type: 'detail'
        },
        {
            name: 'aceptaPoliticas',
            label: 'Acepta Políticas',
            startAdornment: <Policy />,
            type: 'detail',
        },

        {
            name: 'aceptaPassword',
            label: 'Acepta Password',
            startAdornment: <ThumbsUpDown />,
            type: 'detail',
        },
        {
            name: 'created_at',
            label: 'Creación',
            startAdornment: <DateRange />,
            type: 'detail',
        },
        {
            name: 'updated_at',
            label: 'Modificación',
            startAdornment: <DateRange />,
            type: 'detail',
        },
        {
            name: 'fechaUltimoAcceso',
            label: 'Fecha Último Accceso',
            startAdornment: <DateRange />,
            type: 'detail',
        },
        {
            name: 'fechaAceptaPoliticas',
            label: 'Fecha Acepta Políticas',
            startAdornment: <DateRange />,
            type: 'detail',
        },

        {
            name: 'fechaBaja',
            label: 'Fecha de Baja',
            startAdornment: <DateRange />,
            type: 'detail',
        }, 

        {
            name: 'usuarioAlta',
            label: 'Usuario Alta',
            startAdornment: <AccountCircle />,
            type: 'detail',
        },
        
        {
            name: 'usuario_baja',
            label: 'Usuario Baja',
            startAdornment: <AccountCircle />,
            type: 'detail',
        },

        {
            name: 'user_mod',
            label: 'Usuario Modificación',
            startAdornment: <AccountCircle />,
            type: 'detail',
        },
    
        
        {
            name: 'observaciones',
            label: 'Observaciones',
            startAdornment: <RemoveRedEyeRounded />,
        },
    ]

    return {
        data
    }

    
    
}

export const loadGridSupplier = () => {
    const data = [
        {
            name: 'Nombre',
            label: 'Nombre',
            startAdornment: <AccountCircleRounded />,
        },
        {
            name: 'Email',
            label: 'Email',
            startAdornment: <AlternateEmail />,
        },
        {
            name: 'Telefono',
            label: 'Teléfono',
            startAdornment: <Phone />,
        },
        {
            name: 'PaginaWeb',
            label: 'Página Web',
            startAdornment: <Web />,
        },
        {
            name: 'Observaciones',
            label: 'Observaciones',
            startAdornment: <RemoveRedEyeRounded />,
        },
        
        /*{
            name: 'codigo',
            label: 'CP',
            startAdornment: <Home />,
        },
        {
            name: 'nif',
            label: 'NIF',
            startAdornment: <Code />,
        },
        {
            name: 'ciudad',
            label: 'Ciudad',
            startAdornment: <Apartment />,
        },
        {
            name: 'localizacion',
            label: 'Localización',
            startAdornment: <AddLocation />,
        },*/
        
        {
            name: 'UsuarioBaja',
            label: 'Usuario Baja',
            startAdornment: <AccountCircle />,
            type: 'detail'
        },
        {
            name: 'UsuarioAlta',
            label: 'Usuario Alta',
            startAdornment: <AccountCircle />,
            type: 'detail'
        },
        {
            name: 'UsuarioMod',
            label: 'Usuario Edición',
            startAdornment: <AccountCircle />,
            type: 'detail'
        },
        {
            name: 'FechaAlta',
            label: 'Fecha Alta',
            startAdornment: <DateRange />,
            type: 'detail'
        },
        {
            name: 'FechaBaja',
            label: 'Fecha Baja',
            startAdornment: <DateRange />,
            type: 'detail'
        },
        {
            name: 'FechaMod',
            label: 'Fecha Edición',
            startAdornment: <DateRange />,
            type: 'detail'
        },
    
    ]

    return {
        data
    }
}

export const loadGridExchangeRate = () => {
    const data = [
        {
            name: 'cambioEuros',
            label: 'Cambio Euros',
            startAdornment: <Euro />,
            formik: 'cambioEuros',
        },
        {
            name: 'cambioDolares',
            label: 'Cambio Dolares',
            startAdornment: <AttachMoney />,
            formik: 'cambioDolares',
        },


    ];

    return {
        data
    }
}

export const loadGridDetailExchangeRate = () => {
    const data = [
        {
            name: 'proveedor',
            label: 'Proveedor',
            startAdornment: <AccountCircleRounded />
        },
        {
            name: 'tipo_servicio',
            label: 'Tipo Servicio',
            startAdornment: <CardTravel />
        },
        {
            name: 'cambioEuros',
            label: 'Cambio Euros',
            startAdornment: <Euro />
        },
        {
            name: 'cambioDolares',
            label: 'Cambio Doláres',
            startAdornment: <AttachMoney />
        },
        {
            name: 'usuarioAltaTipoCambio',
            label: 'Usuario Alta',
            startAdornment: <AccountCircleRounded />
        },
        {
            name: 'usuarioBajaTipoCambio',
            label: 'Usuario Baja',
            startAdornment: <AccountCircleRounded />
        },
        {
            name: 'fechaBajaTipoCambio',
            label: 'Fecha de Baja',
            startAdornment: <DateRange />
        },
        {
            name: 'created_at',
            label: 'Creación',
            startAdornment: <DateRange />
        },
        {
            name: 'updated_at',
            label: 'Modificación',
            startAdornment: <DateRange />
        },


    ];

    return {
        data
    }
}





export const loadGridChangePassword = () => {
    const data = [
        {   
            name: 'password',
            label: 'Password',
        },
        {   
            name: 'passwordConfirm',
            label: 'Confirmar Password',
        }
    ];

    return {
        data
    }
}

export const loadGridSettings = () => {
    const data = [
        {
            name: 'NombreEmpresa',
            label: 'Nombre Empresa',
            startAdornment: <Business />,
        },
        {
            name: 'RazonSocial',
            label: 'Razón Social',
            startAdornment: <PersonAdd />,
        },
        /*{
            name: 'InformacionTransferencias',
            label: 'Información Transferencias',
            startAdornment: <MonetizationOnOutlined />,
            type: 'rich'
        },
        {
            name: 'TextoConfigurableInformePresupuesto',
            label: 'Texto Configurable Informe Presupuesto',
            startAdornment: <TocOutlined />,
            type: 'rich'
        },
        {
            name: 'TextoConfigurableInformeVoucher',
            label: 'Texto Configurable Informe Voucher',
            startAdornment: <LoyaltyOutlined />,
            type: 'rich'
        },
        {
            name: 'TextoConfigurableInformePresupuesto',
            label: 'Texto Configurable Informe Presupuesto',
            startAdornment: <TocOutlined />,
            type: 'rich'
        },
        {
            name: 'TextoConfigurableInformeFactura',
            label: 'Texto Configurable Informe Factura',
            startAdornment: <CreditCardOutlined />,
            type: 'rich'
        },*/
        {
            name: 'NombreSerieFacturacion',
            label: 'Nombre Serie Facturación',
            startAdornment: <MenuOutlined />,
        },
        {
            name: 'UltimoNumeroFactura',
            label: 'Último Número de Factura',
            startAdornment: <FormatListNumberedOutlined />,
        },
        


    ];

    return {
        data
    }
}

export const loadProfile = (esAgencia, idAgencia) => {
    console.log('esAgencia');
    console.log(esAgencia);
    let data =  [
        {   
            name: 'name',
            label: 'Nombre',
            startAdornment: <AccountCircle />
        },
        {   
            name: 'surname',
            label: 'Apellidos',
            startAdornment: <AccountCircle />
        },
        {   
            name: 'email',
            label: 'Email',
            startAdornment: <Email />
        },
        {   
            name: 'phone',
            label: 'Teléfono',
            startAdornment: <LocalPhone />
        },
    ];

    if (esAgencia && !idAgencia) {
        data = [
            {   
                name: 'name',
                label: 'Nombre',
                startAdornment: <AccountCircle />
            },
            {   
                name: 'surname',
                label: 'Apellidos',
                startAdornment: <AccountCircle />
            },
            {   
                name: 'email',
                label: 'Email',
                startAdornment: <Email />
            },
            {   
                name: 'phone',
                label: 'Teléfono',
                startAdornment: <LocalPhone />
            },
            
            
            {   
                name: 'Telefono2',
                label: 'Teléfono 2',
                startAdornment: <LocalPhone />
            },
            {   
                name: 'RazonSocial',
                label: 'Razon Social',
                startAdornment: <ApartmentOutlined />
            },
            {   
                name: 'DomicilioFiscal',
                label: 'Domicilio Fiscal (línea 1)',
                startAdornment: <ApartmentOutlined />
            },
            {   
                name: 'DomicilioFiscalLinea2',
                label: 'Domicilio Fiscal (línea 2)',
                startAdornment: <ApartmentOutlined />
            },
            
        ];
    }
    else if (esAgencia && idAgencia) {
        data = [
            {   
                name: 'name',
                label: 'Nombre',
                startAdornment: <AccountCircle />
            },
            {   
                name: 'surname',
                label: 'Apellidos',
                startAdornment: <AccountCircle />
            },
            {   
                name: 'email',
                label: 'Email',
                startAdornment: <Email />
            },
            {   
                name: 'phone',
                label: 'Teléfono',
                startAdornment: <LocalPhone />
            },
            
        ];
    }

    return {
        data
    }
}


export const loadGridServicesSupplier = () => {
    const data = [
        {
            name: 'Nombre',
            label: 'Nombre',
            startAdornment: <BeachAccess />,
            typeForm: ['add', 'edit'],
        },
        {
            name: 'Alias',
            label: 'Alias',
            startAdornment: <BeachAccess />,
            typeForm: ['add', 'edit'],
        },
        {
            name: 'CosteDolares',
            label: 'Coste Dolares/Balance',
            startAdornment: <MonetizationOn />,
            typeForm: ['add', 'edit'],
        },
        
        {
            name: 'ReservaEuros',
            label: 'Reserva Euros',
            startAdornment: <EuroOutlined />,
            typeForm: ['add', 'edit'],
        },
        {
            name: 'BalanceEuros',
            label: 'Venta',
            startAdornment: <EuroOutlined />,
            typeForm: ['add', 'edit'],
        },
        {
            name: 'PaginaWeb',
            label: 'Página Web',
            startAdornment: <LibraryAdd />,
            typeForm: ['add', 'edit'],
        },
        {
            name: 'Observaciones',
            label: 'Observaciones',
            startAdornment: <RemoveRedEyeRounded />,
            typeForm: ['add', 'edit'],
        },
        {
            name: 'UsuarioBaja',
            label: 'Usuario de Baja',
            typeForm: ['detail'],
        },
        {
            name: 'FechaBaja',
            label: 'Fecha de Baja',
            typeForm: ['detail'],
        },
        {
            name: 'UsuarioAlta',
            label: 'Usuario de Alta',
            typeForm: ['detail'],
        },
        {
            name: 'FechaAlta',
            label: 'Fecha de Alta',
            typeForm: ['detail'],
        },
        {
            name: 'UsuarioMod',
            label: 'Usuario Modificación',
            typeForm: ['detail'],
        },
        {
            name: 'FechaMod',
            label: 'Fecha de Modificación',
            typeForm: ['detail'],
        },
    ];

    return {
        data
    }
}


export const loadGridCustomer = (simple) => {

    let data = [
        {
            name: 'Nombre',
            label: 'Nombre',
            startAdornment: <AccountCircle />,
        },
        {
            name: 'Apellidos',
            label: 'Apellidos',
            startAdornment: <AccountCircle />,
        },
        {
            name: 'Email',
            label: 'Email',
            startAdornment: <EmailOutlined />,
        },
        {
            name: 'Telefono',
            label: 'Telefono',
            startAdornment: <Phone />,
        }]

    if (simple !== true) {
        data = data.concat([
        {
            name: 'NumeroIdentificacionFiscal',
            label: 'NIF',
            startAdornment: <Code />,
        },
        {
            name: 'Pais',
            label: 'Pais',
            startAdornment: <ApartmentRounded />,
        },
        {
            name: 'Provincia',
            label: 'Provincia',
            startAdornment: <ApartmentOutlined />,
        },
        {
            name: 'Localidad',
            label: 'Localidad',
            startAdornment: <ApartmentOutlined />,
        },
        {
            name: 'CodigoPostal',
            label: 'C.P',
            startAdornment: <CodeOutlined />,
        },
        {
            name: 'Direccion',
            label: 'Dirección',
            startAdornment: <ApartmentOutlined />,
        },
        {
            name: 'Observaciones',
            label: 'Observaciones',
            startAdornment: <RemoveRedEyeRounded />,
        },
        ]);
    }

    return {
        data
    }
}



export const loadGridComisionista = (simple) => {

    let data = [
        {
            name: 'Nombre',
            label: 'Nombre',
            startAdornment: <AccountCircle />,
        },
        {
            name: 'Email',
            label: 'Email',
            startAdornment: <EmailOutlined />,
        },
        {
            name: 'Telefono',
            label: 'Telefono',
            startAdornment: <Phone />,
        },
        {
            name: 'ComisionaPorImporte',
            label: 'Comisión por importe',
            startAdornment: <Phone />,
        },
        {
            name: 'ImporteComision',
            label: 'Importe Comisión',
            startAdornment: <Phone />,
        },
        {
            name: 'ImporteComisionDescuento',
            label: 'Comisión con descuento',
            startAdornment: <Phone />,
        },
        {
            name: 'PorcentajeComision',
            label: '% Comisión',
            startAdornment: <Phone />,
        }]

    if (simple !== true) {
        data = data.concat([
        {
            name: 'Observaciones',
            label: 'Observaciones',
            startAdornment: <RemoveRedEyeRounded />,
        },
        ]);
    }

    return {
        data
    }
}


export const loadGridLine = () => {
    const data = [
        {
            name: 'Concepto',
            label: 'Concepto',
            startAdornment: <Reorder />
        },
        {
            name: 'InSituLineaEuros',
            label: 'In Situ',
            startAdornment: <Euro />
        },
        {
            name: 'ReservaLineaEuros',
            label: 'Reserva',
            startAdornment: <Euro />
        },
        {
            name: 'ReventaLineaEuros',
            label: 'Reventa',
            startAdornment: <Euro />
        },
        {
            name: 'Unidades',
            label: 'Unidades',
            startAdornment: <DragIndicator />
        },
        {
            name: 'ConfirmadaProveedor',
            label: 'Confirma Proveedor',
            startAdornment: <ThumbUpAlt />
        },
        {
            name: 'CodigoReservaProveedor',
            label: 'Código Reserva Proveedor',
            startAdornment: <Code/>
        },
        {
            name: 'FechaRecogida',
            label: 'Fecha Recogida',
            startAdornment: <DateRange/>
        },
        {
            name: 'Observaciones',
            label: 'Observaciones',
            startAdornment: <RemoveRedEyeRounded />
        },
        {
            name: 'ObservacionesRecogida',
            label: 'Observaciones Recogida',
            startAdornment: <RemoveRedEyeRounded />
        },
    ];

    return {
        data
    }
}


export const loadGridOrder = () => {
    const data = [
        {
            name: 'HotelCliente',
            label: 'Hotel Cliente',
            startAdornment: <Hotel />
        },
        {
            name: 'PorcDescuento',
            label: 'Porcentaje de Descuento',
            startAdornment: <LocalOffer />
        },
        {
            name: 'Observaciones',
            label: 'Observaciones',
            startAdornment: <RemoveRedEyeRounded />
        },
        
        {
            name: 'ImporteFactura',
            label: 'Importe Reserva Xplora (€)',
            startAdornment: <AddBox />
        },
        
    ];


    return {
        data
    }
}


export const loadGridConfirmSupplier = () => {
    const data = [
        {
            name: 'CodigoReservaProveedor',
            label: 'Codigo Reserva',
            startAdornment: <Code />
        },
        {
            name: 'ObservacionesRecogida',
            label: 'Pick up',
            startAdornment: <RemoveRedEyeRounded />
        },
    ];

    return {
        data
    }
}

export const loadGridInvoice = () => {
    const data = [
        {
            name: 'NumeroFactura',
            label: 'Número Factura',
            startAdornment: <CodeRounded />
        },
        {
            name: 'ConceptoFactura',
            label: 'Concepto Factura',
            startAdornment: <InfoOutlined />
        },
        {
            name: 'ImporteFactura',
            label: 'Importe Factura',
            startAdornment: <FormatListNumberedRounded />
        },
    ];

    return {
        data
    }
}