
import { navigate } from "@reach/router";
import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadJWT } from "../../helpers/loadJWT";
import { loadSuppliers } from "../../helpers/loadSuppliers"
import { loadSuppliersCoordinator } from "../../helpers/loadSuppliersCoordinators";
import { types } from "../types/types";
import { startLogOutContentModal } from "./contentModal";
import { finishLoading, startLoading, uiManageModalCollectionTypes } from "./ui";

//Supplier


export const startNewSupplier = ( supplier, setSubmitting ) => {
    return async(dispatch) => {
        const { token } = loadJWT();
        await url.post('/proveedores', supplier, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch(startLoadingSuppliers());
            Swal.fire('Éxito', 'Proveedor añadido', 'success');
            return;
        })
        .catch( e => {
            console.log( e )
            Swal.fire('Error', e?.response?.data?.error?.message || 'El proveedor ya existe', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(uiManageModalCollectionTypes());
            dispatch(startLogOutContentModal())
        })

    }
}

export const startDeleteSupplier = ( id ) => {
    return async(dispatch) => {
        const { token } = loadJWT();
        await url.delete(`/proveedores/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            dispatch((deleteSupplier(id)));
            Swal.fire('Éxito', 'Proveedor eliminado', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido eliminar el proveedor', 'error')
            return;
        })
        .finally( () => {
            dispatch(startLogOutContentModal())
        })
    }
}

export const startUpdateSupplier = (supplier, setSubmitting) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.put(`/proveedores/${ supplier.PkProveedores }`, supplier ,{
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            Swal.fire('Éxito', 'Proveedor modificado con éxito', 'success')
            .then( () => {
                navigate(`/suppliers`);
            })
            return;
        })
        .catch( ( e ) => {
            Swal.fire('Error', e?.response?.data?.error?.message , 'error')
            return;
        })
        .finally( () => {
            setSubmitting( false )
        })
    }
}


export const startLoadingSuppliers = () => {
    return async(dispatch) => {
        dispatch(startLoading())
        const suppliers = await loadSuppliers();
        dispatch(setSuppliers(suppliers));
        dispatch(finishLoading())
    }
}

export const startLoadingAllSuppliers = () => {
    return async(dispatch) => {
        const { token } = loadJWT();
        dispatch(startLoading())
        await url.get('/proveedores/all', {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( ( resp ) => {
            dispatch(setSuppliers(resp.data));
        })
        .catch( (e) => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se han podido cargar los proveedores', 'error')
        })
        .finally( () => {
            dispatch(finishLoading())
        })
        
        
    }
}

export const startLoadingSuppliersFromCoordinator = ( id ) => {
    return async( dispatch ) => {
        dispatch(startLoading())
        const suppliers = await loadSuppliersCoordinator( id )
        dispatch( setSuppliers( suppliers ));
        dispatch(finishLoading())
    }
}

export const startAddSupplierToCoordinator = ( supplier, setSubmitting ) => {
    return async(dispatch) => {
        const { token } = loadJWT();
        await url.post('/proveedores-coordinadores', supplier, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch(startLoadingSuppliersFromCoordinator( supplier.FkUsuarios ));
            Swal.fire('Éxito', 'Proveedor añadido al coordinador', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'El proveedor ya existe', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(startLogOutContentModal())
            dispatch(uiManageModalCollectionTypes());
        })

    }
}

export const startDeleteSupplierCoordinator = ( idProveedor, idUsuario ) => {
    return async(dispatch, getState) => {
        const { token } = loadJWT();
        const nombre = getState().user.active.Nombre
        await url.delete(`/proveedores-coordinadores/${ idUsuario }/${ idProveedor }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            dispatch((deleteSupplierCoordinator(idProveedor)));
            Swal.fire('Éxito', `El proveedor ha dejado de estar asignado a ${ nombre }`, 'success')
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido eliminar el proveedor', 'error')
            return;
        })
        .finally( () => {
            dispatch(startLogOutContentModal())
           
        })
    }
}


export const startUpdateSupplierToCoordinator = (supplier, setSubmitting) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.put(`/proveedores-coordinadores`, supplier ,{
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            Swal.fire('Éxito', 'Coordinador modificado con éxito', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido editar el coordinador' , 'error');
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(startLogOutContentModal())
            dispatch(uiManageModalCollectionTypes());
        })
    }
}

export const startLogOutCleanSuppliers = () => {
    return async( dispatch ) => {
        dispatch(supplierLogout());
    }
}
export const setActiveSupplier = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.get(`proveedores/${id}?filter={"include": [{"relation":"ServiciosProveedor", "scope": {"where": {"FechaBaja":  {"eq": null}}}},{"relation":  "TiposCambioProveedor", "scope": {"include": [{"relation": "TipoServicio"}], "where": {"FechaBaja": {"eq": null}}}}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            if( resp.status === 204){
                navigate('/suppliers');
                dispatch(finishLoading());
                return;
            }
            dispatch(setActive( resp.data ))
            navigate(`/suppliers/${ id }`);
            dispatch(finishLoading());
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al eliminar el proveedor', 'error')
            .then( () => {
                navigate('/suppliers');
            })
            .finally( () => {
                dispatch(finishLoading());
            })
            return;
        })
    }
}

export const setActiveSupplierState = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.get(`proveedores/${id}?filter={"include": [{"relation":"ServiciosProveedor", "scope": {"where": {"FechaBaja":  {"eq": null}}}},{"relation":  "TiposCambioProveedor", "scope": {"include": [{"relation": "TipoServicio"}], "where": {"FechaBaja": {"eq": null}}}}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActive( resp.data ))
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
        })
    }
}



export const setActiveSupplierCoordinator = ( id ) => {
    return async( dispatch, getState ) => {
        const idUser = getState().user.active.PkUsuarios;
        const { token } = loadJWT();
        await url.get(`proveedores-coordinadores/${idUser}/${ id }?filter={"include": [{"relation": "Proveedor"}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActive( resp.data ))
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
        })
    }
}

export const startLoadingServicesSupplier = ( idSupplier ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal;
        url.get(`/proveedores/${ idSupplier }/servicios-proveedores?filter={"where": {"FechaBaja": {"eq": null}}}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setServicesSupplier(resp.data));
        })
        .catch( e => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes())
                dispatch(startLogOutContentModal())
            }
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
        })
    }
}

export const setServicesSupplier = ( services ) => ({
    type: types.supplierServicesLoad,
    payload: services
})

export const setActive = ( supplier ) => ({
    type: types.supplierActive,
    payload: supplier
})

export const setSuppliers = (suppliers) => ({
    type: types.suppliersLoad,
    payload: suppliers,
})

export const supplierLogout = () => ({
    type: types.suppliersLogOutCleaning
})

const deleteSupplier = ( id ) => ({
    type: types.supplierDelete,
    payload: id
})


const deleteSupplierCoordinator = ( id ) => ({
    type: types.supplierDeleteCoordinator,
    payload: id
})
