import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTableParams } from '../../../hooks/useTableParams'
import { actionAddResellerServices, actionDeleteServicesReseller, actionEditServicesReseller } from '../../../redux/actions/actions'
import { startLoadingServicesReseller } from '../../../redux/actions/supplierServices'
import { ROLADMIN, ROLREVENDEDOR } from '../../../utils/constants'
import { ButtonFloatNoOptions } from '../../ui/ButtonFloatNoOptions'
import { NotificationModal } from '../../ui/NotificationModal'
import { TransitionsModal } from '../../ui/TransitionModal'
import { getSimboloMoneda } from '../../../utils/utils'
import { actionAddAgencySeller, startLoadingSellersReseller, startDeleteAgencySeller, actionDeleteVendedorAgencia, actionEditVendedorAgencia } from '../../../redux/actions/agencyServices'

export const AgencyResellers = () => {
    const data = [];
    const dataReseller = [];
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const showColumnAction = false;
    const { active } = useSelector(state => state.user );
    const { vendedores } = useSelector(state => state.vendedoresAgencia);
    const { menuState } = useSelector(state => state.ui)
    const { id, nombre, idRol } = useSelector(state => state.auth)
    const userOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: `Servicios ${ active?.Nombre || nombre }`, rowsPerPage: 5};
    


    //const userColumnsDeskTop =  [['Nombre', true], ['Tipo de Servicio', true, !matchesXS], ['Coste($)', false, !matchesXS],
    //['Coste(€)', false, !matchesXS], ['Reserva(€)', false, !matchesXS], ['Precio Venta(€)', false, !matchesXS]];
    const userColumnsDeskTop =  [['Nombre', true], ['Email', true, !matchesXS], ['Comisión ($)', false, !matchesXS]];
    
    const userReseller =  [['Nombre', true], ['Email', true, !matchesXS], ['Comisión ($)', false, !matchesXS]];;

    const actions = [
        { isShown: true, type: 'delete', color: 'red', icon: <DeleteOutlineOutlined />, eject: ( dataIndex, e ) => handleDelete( dataIndex, e ) },
       // { isShown: true, color: 'blue', icon: <EditOutlined />, eject: ( dataIndex, e  ) => handleEditServiceReseller ( dataIndex, e  ) },
    ]
    const { options, columns } = useTableParams( userOptions, idRol === ROLADMIN ? userColumnsDeskTop : userReseller, idRol === ROLADMIN && (actions || []), idRol !== ROLADMIN ? showColumnAction : true, ( idRol === ROLADMIN && ((dataIndex, e) => handleEditServiceReseller(dataIndex, e))));

    useEffect(() => {
        if( idRol === ROLADMIN ){
            dispatch(startLoadingSellersReseller(active?.PkUsuarios))
        }
        else if( idRol === ROLREVENDEDOR ){
            dispatch(startLoadingSellersReseller( id ))
        }
       
    }, [ dispatch, idRol, id, active?.PkUsuarios ])

    console.log('vendedores');
    console.log(vendedores);

    vendedores.map( vendedor => {
        
        data.push([
            vendedor?.Nombre + ' ' + vendedor?.Apellidos, 
            vendedor?.Email, 
            vendedor?.ImporteComision 
        ])
        
       dataReseller.push([
        vendedor?.Nombre + ' ' + vendedor?.Apellidos, 
        vendedor?.Email, 
        vendedor?.ImporteComision 
        ])

       return null;
    })


    const handleAddVendedorAgencia = () => {
        dispatch(actionAddAgencySeller())
    }

    const handleDelete = ( dataIndex , e ) => {
        console.log('handleDelete vendedor agencia');
        console.log(e);
        if (e) e.stopPropagation();
        dispatch( actionDeleteVendedorAgencia( vendedores[ dataIndex ].PkUsuarios ))
    }

    const handleEditServiceReseller = ( dataIndex, e ) => {
        console.log('handleEditServiceReseller');
        console.log(e);
        if (e) e.stopPropagation();
        dispatch( actionEditVendedorAgencia( vendedores[ dataIndex ].PkUsuarios ))
    }
    return (
        <>
        <div style={{
            marginTop: '2rem',
            width: `${ matchesXS ? '88vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
        }}>

         
                <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>{`VENDEDORES`}</Typography>
         

        
            <MUIDataTable
                {...{ data: idRol === ROLADMIN ? data : dataReseller, columns, options}}
                
            />
            <TransitionsModal/>
            <NotificationModal />
            {
                idRol === ROLADMIN
                &&
                (
                    <ButtonFloatNoOptions 
                        action={ handleAddVendedorAgencia }
                    />
                )
            }
            
            

        </div>
        
    
    </>
    )
}
