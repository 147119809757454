import React, { useEffect } from 'react'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import {  DeleteOutlineOutlined } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import { useDispatch, useSelector } from 'react-redux'
import { useTableParams } from '../../../hooks/useTableParams'
import { NotificationModal } from '../../ui/NotificationModal'
import { TransitionsModal } from '../../ui/TransitionModal'
import { actionAddSupplierToCoordinator, actionDeleteCoordinatorSupplier, actionEditSupplierCoordinator } from '../../../redux/actions/actions'
import { startLoadingSuppliersFromCoordinator } from '../../../redux/actions/suppliers'
import { ROLADMIN, ROLCOLABORADOR } from '../../../utils/constants'
import { ButtonFloatNoOptions } from '../../ui/ButtonFloatNoOptions'


export const CoordinatorSuppliers = () => {
   
        const data = [];
        const showColumnAction = false;
        const theme = useTheme();
        const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
        const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
        const { idRol, id } = useSelector(state => state.auth)
        const { active } = useSelector(state => state.user );
        const { menuState } = useSelector(state => state.ui);
        const dispatch = useDispatch();
        const userOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: `Servicios ${ active?.Nombre }`, rowsPerPage: 5};
        const userColumnsDeskTop =  [['Nombre', true], ['Pagina Web', false, !matchesXS], ['Telefono', true, !matchesXS]];
        const { suppliers } = useSelector(state => state.supplier );
        const actions = [
            { isShown: true, type: 'delete', color: 'red', icon: <DeleteOutlineOutlined />, eject: ( dataIndex, e ) => handleDelete( dataIndex, e ) },
            // { isShown: true, color: 'blue', icon: <EditOutlined />, eject: ( dataIndex, e ) => handleEditSupplier( dataIndex, e ) },
        ]
        const { options, columns } = useTableParams( userOptions, userColumnsDeskTop ,  idRol === ROLADMIN && (actions || []), idRol !== ROLADMIN ? showColumnAction : true, (dataIndex, e) => handleEditSupplier(dataIndex, e));
    
        useEffect(() => {
            if( idRol === ROLADMIN){
                dispatch( startLoadingSuppliersFromCoordinator(active?.PkUsuarios ))
            }
            else if(idRol === ROLCOLABORADOR){
                dispatch(startLoadingSuppliersFromCoordinator( id ))
            }
            
        }, [ dispatch, active?.PkUsuarios, idRol, id ])
        
        suppliers.map( supplier => {
            data.push([supplier.Proveedor.Nombre, supplier.Proveedor.PaginaWeb, supplier.Proveedor.Telefono])
            return null;
        })
        const handleAddSupplier = () => {
            dispatch(actionAddSupplierToCoordinator());
        }

        const handleDelete = ( dataIndex, e ) => {
            if (e) e.stopPropagation();
            if( idRol !== ROLCOLABORADOR ) dispatch(actionDeleteCoordinatorSupplier(suppliers[ dataIndex ].FkProveedores))
        }

        const handleEditSupplier = ( dataIndex, e ) => {
            if (e) e.stopPropagation();
            if( idRol !== ROLCOLABORADOR ) dispatch(actionEditSupplierCoordinator(suppliers[ dataIndex ].FkProveedores))
        }
        return (
        <div style={{
            marginTop: '2rem',
            width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            
        }}>
    
             {
                !matchesXS
                &&
                (
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>{`PROVEEDORES ASOCIADOS`}</Typography>
                )
            }
    
            
                <MUIDataTable
                    title={matchesXS && `PROVEEDORES ASOCIADOS`}
                    {...{data, columns, options}}
                    
                />
            <TransitionsModal/>
            <NotificationModal />
            {
                idRol === ROLADMIN && (
                    <ButtonFloatNoOptions 
                        action={ handleAddSupplier }
                    />
                )
            }
            
    
        </div>
        )
    
}
