
import { getDateDayPlusTwo, getDateMonthMinusOne } from "../../utils/dates";
import { types } from "../types/types";

const { year, month, day } = getDateDayPlusTwo();
const { yearY, monthY, dayY } = getDateMonthMinusOne();


const initialState = {
    invoices: {
        data: [],
        count: 0,
        page: 0,
        sum: 0,
        currentFilter: [],
        dateRange:[new Date(yearY, monthY, dayY).toISOString(), new Date( year, month, day ).toISOString()],
    },
    active: {
        orderLines: [],
        activeLine: null,
        services: [],
        supplier: null,
        rate: null,
    },
    activeEdit: null
}

export const invoiceReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case types.changeFilter:
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    currentFilter: action.payload,
                }
            }
        case types.changeFilterDates:
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    dateRange: action.payload,
                }
            }
        case types.invoiceActive:
            return {
                ...state,
                active: {
                    ...action.payload
                },
            }
        case types.linesAddNew:
            return {
                ...state,
                active: {
                    ...state.active,
                    orderLines: [
                        ...state.active.orderLines,
                        action.payload
                    ]
                }
            }
        case types.lineDelete:
            return {
                ...state,
                active: {
                    ...state.active,
                    orderLines: state.active.orderLines.filter((orderLine, index) => index !== action.payload )
                }
            }
        case types.invoicesLoad:
            return {
                ...state,
                activeEdit: null,
                invoices: {
                    ...state.invoices,
                    data: [...action.payload],
                    page: action.page,
                }
            }
        
        case types.invoiceDelete:
            return {
                ...state,
                active: null,
                invoices: state.invoices.filter( invoice => invoice.PkVentas !== action.payload )
            }
        case types.invoiceActiveEdit:
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    data: [],
                },
                activeEdit: {
                    ...action.payload
                }
            }
        case types.invoiceShare:
            return {
                ...state,
                invoices: {
                    ...state.invoices
                },
                activeEdit: {
                    ...action.payload
                }
            }
        case types.lineDeleteFromInvoice:
            return {
                ...state,
                activeEdit: {
                    ...state.activeEdit,
                    LineasVentaCompuestas: state.activeEdit.LineasVentaCompuestas.filter(  line => line.PkLineasVenta !== action.payload )
                }
            }
        
        case types.getNumberOfSInvoices:
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    count: action.payload
                }
            }
        case types.getTotalInvoicesFiltered:
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    sum: action.payload.sum,
                    sumInSituEuros: action.payload.sumInSituEuros,
                    sumReservaEuros: action.payload.sumReservaEuros,
                    sumDescuentoEuros: action.payload.sumDescuentoEuros,
                    sumReventaEuros: action.payload.sumReventaEuros,
                    sumBalanceDolares: action.payload.sumBalanceDolares,
                }
            }
        case types.resetPage:
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    page: 0
                }
            }
        case types.lineEditFromInvoiceState:
            return {
                ...state,
                active: {
                    ...state.active,
                    activeLine: {
                        ...action.payload
                    },
                    services: [],
                    supplier: null,
                    
                }
            }
        case types.orderLineEditStateLoadingSuppliersAndServices:
            return {
                ...state,
                active: {
                    ...state.active,
                    services: [...action.payload[0]],
                    supplier: {
                        ...action.payload[1]
                    },
                }
            }
        case types.orderLineStateSetExchangerate:
            return {
                ...state,
                active: {
                    ...state.active,
                    rate: {
                        ...action.payload
                    }
                }
            }
        case types.resetActiveLineFromState:
            return {
                ...state,
                active: {
                    ...state.active,
                    activeLine: {
                        ...state.active.activeLine,
                        FkServiciosProveedores: action.payload.PkServiciosProveedores,
                        InSituLineaDolares: action.payload.CosteDolares,
                        Concepto: action.payload.Nombre,
                        ReservaLineaEuros: action.payload.ReservaEuros,
                        FkTiposCambioProveedores: action.rate,
                        CosteDolares: action.payload.CosteDolares,
                        ReservaEuros: action.payload.ReservaEuros,
                        NombreServicio: action.payload.Nombre,
                        FkTiposServicio: action.payload.FkTiposServicio,
                        TasaDolares: action.payload.TasaDolares,
                    }
                }
            }
        case types.lineUpdateState:
            return {
                ...state,
                active: {
                    ...state.active,
                    orderLines: state.active.orderLines.map(
                        (orderLine, i) =>  i  == action.payload.id
                        ?
                        action.payload.line
                        :
                        orderLine
                    ),
                },
            }
        case types.invoiceLineStateLogOut:
            return {
                ...state,
                active: {
                    ...state.active,
                    activeLine: null,
                    services: [],
                    supplier: null,
                    rate: null,
                }
            }
        
        case types.invoiceDeletebyId:
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    data: state.invoices.data.filter( invoice => invoice.PkVentas !== action.payload )
                }
            }
        
        case types.invoicesLogOutCleaning:
            return initialState;
        
        
        default:
            return state;
    }
}
