import { Grid, Input, Button, Box, Card, CardContent, Divider, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';

import { loadJWT } from '../../../helpers/loadJWT';
import { url } from '../../../api/api';
import { useFormStyles } from '../../../styles/formStyles';
import {  startUploadingLogo } from '../../../redux/actions/settings' 
import {useParams } from "@reach/router"
import Swal from 'sweetalert2';
import {  ROLCOLABORADOR, ROLREVENDEDOR } from '../../../utils/constants';



export const AccountProfile = () => {

    const hiddenFileInput = useRef();
    const dispatch = useDispatch();
    const params = useParams();

    const { token } = loadJWT();
    const [img, setImg] = useState();
    const uploadForm = useRef();
    const classes = useFormStyles();



    const { active } = useSelector(state => state.user)
    
    const theme = useTheme();
    const matchesXS = useMediaQuery( theme.breakpoints.down('xs'));
    

    const handleClick = event => {
        console.log('click');
        console.log (hiddenFileInput);
        hiddenFileInput.current.firstChild.click();
    };
    


    const init = ( e ) => {
        e.preventDefault();
        const value = uploadForm.current;
        dispatch( startUploadingLogo( value, active?.PkUsuarios ) );
        setTimeout( fetchImage, 3000);

    }
    
    const fetchImage = async () => {
        await url.get( '/usuarios/logo/'+active?.PkUsuarios, {
            headers: {
                Authorization: `Bearer ${ token }`
            },
            responseType: 'arraybuffer'
        })
        .then( ( res ) => {
            var file = new Blob([res.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            setImg(fileURL);
            
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se ha podido descargar el logo de la agencia', 'error')
        })
    };
  
    useEffect(() => {
        if (active?.PkUsuarios)      fetchImage();
    }, []);



    let name = active?.Nombre?.toUpperCase();
    return (
        <Card
        style={{
            backgroundColor: '#FFF6F1',
        }}
        >
            <CardContent>
                <Box
                    sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: !matchesXS ? '25rem' : '20rem',
                    justifyContent: 'center',
                    
                    }}
                    
                >
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                >
                { name }
                </Typography>
                <Typography
                    color="textSecondary"
                    variant="body1"
                >
                </Typography>
                

               { ( active?.Rol?.PkRoles === ROLREVENDEDOR && active?.EsAgencia)
                    &&
                    (
                        <Grid md={6} xs={12}>
                        <img src={ img } alt='logo' width={'200'}  style={{ height: 'auto'}} />

                        { ( !active?.FkUsuarios_Agencia)
                        &&
                        (   
                            <>
                                <Button style={{ backgroundColor: 'lightgray'}} onClick={handleClick}>
                                    Seleccionar imagen
                                </Button>
                                <form                  style= {{fontSize: '0.8em'}}                            classes={{ root: classes.inputText }}
                                            ref={ uploadForm } >
                                    <Input ref={hiddenFileInput} style={{display: 'none'}} accept="jpg/*,jpeg/*,png/*" id="files" name='files' multiple type="file" />
                                </form>
                                <Button
                                    variant="contained"
                                    type='submit'
                                    style={{
                                        backgroundColor: '#E5824F',
                                        color: 'white'
                    
                                    }}
                                    onClick={ init }
                                    
                                >
                                        Subir logo
                                </Button>
                            </>
                            )
                        }
                        </Grid>
                    )}

                </Box>
            </CardContent>
            <Divider />
      </Card>
    )
}
