
import { types } from '../types/types';
import Swal from 'sweetalert2'
import { url } from '../../api/api';
import { startLogOutUsers } from './users';
import { startLoading, finishLoading } from './ui';
import { navigate } from '@reach/router';
import { startLogOutDashBoard } from './dashboard';
import { startLogOutContentModal } from './contentModal';
import { startLogOutActiveRate } from './exchangeRate';
import { startLogOutClients } from './clients';
import { startLogOutSalesCleaning } from './sales';
import { startLogOutCleanSuppliers } from './suppliers';
import { startLogOutServicesActive } from './supplierServices';

import { setCurrentUser, setCurrentUserState} from "./users";
import moment from 'moment-timezone';

export const startLogin = (email, password, setSubmitting) => {
    return async(dispatch) => {
        let payload = { email, password };
        await url.post('/auth/login', payload)
        .then(resp =>{
            localStorage.setItem('user', JSON.stringify(resp.data));
            localStorage.setItem('token-date', new Date().getTime());
            moment.tz.setDefault("Europe/Madrid");

            
            dispatch( setCurrentUserState( resp.data.PkUsuarios ) );
            
            dispatch( login({
                id: resp.data.PkUsuarios,
                nombre: resp.data.Nombre,
                rol: resp.data.rol.Nombre,
                idRol: resp.data.rol.PkRoles,
                aceptaPoliticas: resp.data.AceptaPoliticas,
                cambiaPassword: resp.data.CambioPassword,
                esAgencia: resp.data.EsAgencia,
                aceptaPoliticas: resp.data.AceptaPoliticas,
                aceptaContratoTratamientoDatos: resp.data.AceptaContratoTratamientoDatos,
                moneda: resp.data.Moneda,
                margenConfiguradoEnServicios: resp.data.MargenConfiguradoEnServicios,
                idAgencia: resp.data.FkUsuarios_Agencia,
                isAuthenticated: true
            }) )
            
            if( !resp.data.AceptaPoliticas ){
                navigate('/politicas')
            }
            else if( !resp.data.CambioPassword ){
                navigate('/auth/change')
            }
            else if( resp.data.EsAgencia && !resp.data.AceptaContratoTratamientoDatos ){
                navigate('/politicas_agencia')
            }
            else{
                console.log(resp)
                navigate('/')
            }
        })
        .catch( ( e ) => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Usuario o contraseña no válidos', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
        })
    }
}

export const startVerifyingToken = ( token ) => {
    return async( dispatch ) => {
        await url.get(`/auth/verifyToken`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .catch( ( e ) => {
            navigate('/login');
        })
    }
}

export const loginUserWithToken = ( token, id ) => {
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/usuarios/${ id }?filter[include][]=Rol`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            }
        })
        .then( ( resp ) => {
            if( resp.status === 200){
                const cookieFromData = (userResp, token) => {
                    const toret = {
                        id: userResp.PkUsuarios,
                        nombre: userResp.Nombre,
                        rol: {
                            Nombre: userResp.Rol.Nombre,
                            idRol: userResp.Rol.PKRoles
                        },
                        idRol: userResp.Rol.PkRoles,
                        isAuthenticated: true,
                        aceptaPoliticas: userResp.AceptaPoliticas,
                        cambiaPassword: userResp.CambioPassword,
                        esAgencia: userResp.EsAgencia,
                        aceptaContratoTratamientoDatos: userResp.AceptaContratoTratamientoDatos,
                        moneda: userResp.Moneda,
                        margenConfiguradoEnServicios: userResp.MargenConfiguradoEnServicios,
                        idAgencia: userResp.FkUsuarios_Agencia ? userResp.FkUsuarios_Agencia : null,
                        token: token
                    }

                    return toret
                }

                const authObject = cookieFromData(resp.data, token)

                console.log('Cookie from data: ', authObject)

                localStorage.setItem('user', JSON.stringify(authObject));
                localStorage.setItem('token-date', new Date().getTime());
                moment.tz.setDefault("Europe/Madrid");

                dispatch( login(authObject) )

                dispatch(finishLoading())
                return;
            }
            dispatch( setCurrentUser( resp.data ));
            dispatch(finishLoading());
            
        })
        .catch( e => {
            console.log('Error en login: ', e)
            dispatch(finishLoading());
        })
    }
}
 

export const startResetPassword = ( user ) => {
    return async( dispatch ) => {
        await url.put(`/auth/cambiarPassword`, {password: user.password, passwordConfirm: user.passwordConfirm, id: user.id},
        {
            headers:{
                Authorization: `Bearer ${ user.token }`
            }
        })
        .then( resp => {
            Swal.fire('Cambio de contraseña', 'La contraseña se ha cambiado correctamente', 'success')
            .then( () => {
                navigate('/login')
            })            
        })
        .catch( ( e ) => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contcate con el administrador ', 'error');
            return;
        })
    }
}


export const startLogOut = () => {
    return async( dispatch ) => {
        localStorage.clear();
        dispatch(logOut());
        dispatch(startLogOutUsers());
        dispatch(startLogOutDashBoard());
        dispatch(startLogOutContentModal());
        dispatch(startLogOutActiveRate());
        dispatch(startLogOutClients());
        dispatch(startLogOutSalesCleaning());
        dispatch(startLogOutCleanSuppliers());
        dispatch(startLogOutServicesActive())
        navigate('/login')
    }
}


export const startForgotPassword = ( email, setSpinner ) => {
    return async() => {
        let payload = { email }
        setSpinner( true );
        await url.post(`/auth/sendEmailReset`, payload)
        .then(() => {
            Swal.fire('Éxito', `Email enviado con éxito a ${ email }`, 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No existe la cuenta', 'error');
            return;
        })
        .finally(() => {
            setSpinner( false )
        })
    }
}


const login = ( user ) => ({
    type: types.authLogin,
    payload: user
});

const logOut = () => ({
    type: types.authLogOut
})

