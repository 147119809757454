
import { types } from '../types/types';
import Swal from 'sweetalert2';
import { url } from '../../api/api';
import { finishLoading, startError, startLoading, uiManageModalCollectionTypes } from './ui';
import { loadJWT } from '../../helpers/loadJWT';
import { startLogOutContentModal } from './contentModal';
import { isBrowser } from '../../utils/constants';


export const startLoadingSettings = () => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        dispatch(startLoading())
        await url.get('/configuracion',  {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(( resp ) => {
            dispatch(loadSettings(resp.data))
        })
        .catch( (e) => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se han podido cargar los datos de configuración', 'error')
        })
        .finally( () => {
            dispatch(finishLoading())
        } )
    }
}

export const startUpdateSettings = ( settings, setSubmitting ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.put('/configuracion', settings ,{
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(( resp ) => {
            dispatch(startLoadingSettings(resp.data));
            Swal.fire('Éxito', 'Ajustes Editados', 'success');
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se han podido editar los datos de configuración', 'error')
        })
        .finally( () => {
            setSubmitting( false )
            dispatch(startLogOutContentModal());
            dispatch(uiManageModalCollectionTypes());
        })
    }
}

const loadSettings = ( data ) => ({
    type: types.settingsLoad,
    payload: data
})


export const startUploadingFile = ( file, setOpenDialog ) => {
    const { token } = loadJWT();
    return async( dispatch) => {
        await url.post('/configuracion/uploadPoliticas',  new FormData( file ), {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            setOpenDialog( false );
            Swal.fire('Exito', 'Has subido un nuevo archivo de políticas', 'success');
            return;
        })
        .catch( (e) => {
            dispatch(startError(e?.response?.data?.error?.message))
        })
    }
}



export const startUploadingContratoTratamientoDatos = ( file, setOpenDialog ) => {
    const { token } = loadJWT();
    return async( dispatch) => {
        await url.post('/configuracion/uploadContratoTratamientoDatos',  new FormData( file ), {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            setOpenDialog( false );
            Swal.fire('Exito', 'Has subido un nuevo archivo de contrato de tratamiento de datos', 'success');
            return;
        })
        .catch( (e) => {
            dispatch(startError(e?.response?.data?.error?.message))
        })
    }
}


export const startUploadingLogo = ( file, idUsuario ) => {
    const { token } = loadJWT();
    return async( dispatch) => {
        await url.post('/usuarios/uploadLogo/' + idUsuario,  new FormData( file ), {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            Swal.fire('Exito', 'Has subido el logo de la agencia', 'success');
            return;
        })
        .catch( (e) => {
            dispatch(startError(e?.response?.data?.error?.message))
        })
    }
}



export const startDownloadTerms = () => {
    const { token } = loadJWT();

    return async() => {
        await url.get('/Configuracion/downloadPoliticas', {
            headers: {
                Authorization: `Bearer ${ token }`
            },
            responseType: 'arraybuffer'
        })
        .then( ( res ) => {
            var file = new Blob([res.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            if( isBrowser ){
                window.open(fileURL);
            }
            
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se ha podido descargar el archivo de políticas', 'error')
        })
    }
}


export const startDownloadContratoTratamientoDatos = () => {
    const { token } = loadJWT();

    return async() => {
        await url.get('/Configuracion/downloadContratoTratamientoDatos', {
            headers: {
                Authorization: `Bearer ${ token }`
            },
            responseType: 'arraybuffer'
        })
        .then( ( res ) => {
            var file = new Blob([res.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            if( isBrowser ){
                window.open(fileURL);
            }
            
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se ha podido descargar el archivo de contrato de tratamiento de datos', 'error')
        })
    }
}