import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeleteLineFromInvoice } from '../../redux/actions/lines';
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content'

export const ConfirmDeleteLineFromInvoice = () => {
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { active: {activeLine} } = useSelector(state => state.lines) //we get the users from the state 

    
    const handleSuccess = () => {
        dispatch(uiManageModalNotification());
        dispatch(startDeleteLineFromInvoice(activeLine?.PkLineasVenta))
    }
    return (
        <Content
                title={ `¿Quieres borrar la línea de venta?` }
                text={ `La línea quedará eliminada definitivamente del sistema` }
                action={ handleSuccess }
            />
    )
}
