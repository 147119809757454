import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {  DeleteOutlineOutlined } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import React, { useEffect } from 'react'
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTableParams } from '../../../hooks/useTableParams';
import { actionDeleteOrderLineFromInvoice, actionEditOrderLineFromInvoice } from '../../../redux/actions/actions';
import { startContentSendEmailVoucher } from '../../../redux/actions/contentModal';
import { setActiveGettingLine, startLogOutLines, startSendingVoucherEmail } from '../../../redux/actions/lines';
import { startDownloadingVoucher } from '../../../redux/actions/sales';

import { ROLADMIN, ROLREVENDEDOR } from '../../../utils/constants';
import { NotificationModal } from '../../ui/NotificationModal';
import mailIcon from '../../../images/mail.png';
import pdfIcon from '../../../images/pdf.png';
import Swal from 'sweetalert2';
import { getSimboloMoneda } from '../../../utils/utils';
import moment from 'moment-timezone';



export const LinesTable = () => {
    const data = [];
    const theme = useTheme();
    const { menuState } = useSelector(state => state.ui);
    const {modal} = useSelector(state => state.modal)
    const dispatch = useDispatch()
    const { activeEdit } = useSelector(state => state.invoice)
    const showColumnAction = true;
    const { idRol, moneda: monedaUsuario } = useSelector(state => state.auth)
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const linesOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: 'Lineas de Venta', rowsPerPage: 5};
    const linesColumnsDeskTopAdmin =  [
        [ 'Servicio', true ],
        [ 'Reserva', false, !matchesXS ], 
        [ 'Unidades', false, !matchesXS ], 
        [ 'In situ (€)', false, !matchesXS ], 
        [ 'Proveedor', true, !matchesXS ],  
        [ 'Importe Venta', false, !matchesXS]
    ];
    const linesColumnsDeskTopReseller =  [
        [ 'Servicio', true ],
        [ 'Fecha', false, !matchesXS ],
        [ 'PAX', false, !matchesXS ],
        [ 'Neto/PAX', false, !matchesXS ],
        [ 'Reserva/PAX', false, !matchesXS ], 
        [ 'PVP/PAX', false, !matchesXS ], 
        [ 'PVP Total', false, !matchesXS]
    ];
    const actions = [
        { isShown: true, type: 'delete', color: '#000', icon: <DeleteOutlineOutlined />, eject: ( dataIndex,e ) => handleDelete( dataIndex, e )},
        { isShown: (dataIndex) => handleDownloadVisible(dataIndex) , color: '#000', icon: mailIcon, eject: ( dataIndex,e ) => handleSendEmailVoucher( dataIndex, e )},
        { isShown: (dataIndex) => handleDownloadVisible(dataIndex) , color: '#000', icon: pdfIcon, eject: ( dataIndex,e ) => handleDownloadVoucher( dataIndex, e )},
    ]
    const { options: optionsAdmin, columns: columnsAdmin } = useTableParams( linesOptions, linesColumnsDeskTopAdmin , actions,  showColumnAction, (dataIndex, e) => handleEdit(dataIndex, e) );
    const { options: optionsReseller, columns: columnsReseller } = useTableParams( linesOptions, linesColumnsDeskTopReseller , actions,  showColumnAction, (dataIndex, e) => handleEdit(dataIndex, e) );
    

    console.log(activeEdit?.LineasVentaCompuestas);

    activeEdit?.LineasVentaCompuestas?.forEach( line => {

        console.log('Linea de venta: ', line)
        if (idRol===ROLREVENDEDOR) {
            let reserva = Math.round(100*(+line?.ReservaLineaEuros ))/100;
            let importeVenta = line?.ImporteLinea;
            let inSitu = Math.round(line?.InSituConTransporteEuros*line?.Unidades);
            if (monedaUsuario==='USD') {
                reserva = +line?.ReservaLineaDolares;
                importeVenta = line?.ImporteLineaDolares;
                inSitu = Math.round(line?.InSituConTransporteDolares*line?.Unidades);
            }
            let precioUnitario = importeVenta / line?.Unidades;
            let neto = precioUnitario - line?.ReventaLineaEuros;
            if (monedaUsuario==='USD') {
                neto = precioUnitario - line?.ReventaLineaDolares;
            }
            data.push([
                line.Concepto,
                line?.FechaRecogida ? moment(line?.FechaRecogida).format('DD/MM/YYYY') : 'Sin seleccionar',
                line?.Unidades,
                neto+ ' ' + getSimboloMoneda(monedaUsuario),
                reserva + ' ' + getSimboloMoneda(monedaUsuario),
                precioUnitario+ ' ' + getSimboloMoneda(monedaUsuario),
                importeVenta + ' ' + getSimboloMoneda(monedaUsuario)
            ]);
        }
        else {
            data.push([
                line.Concepto,
                (Math.round(100*(+line?.ReservaLineaEuros ) * (+line?.Unidades))/100) + ' €', 
                line?.Unidades,
                Math.round(line?.InSituConTransporteEuros*line?.Unidades) + ' €', 
                line?.Proveedor?.Nombre,  
                line?.ImporteLinea + ' €'
            ]);
        }
    });

    const handleDownloadVisible = ( dataIndex ) => {
        return activeEdit?.LineasVentaCompuestas[dataIndex].ConfirmadaProveedor;
    }

    const handleDelete = ( dataIndex, e ) => {
        if (e) e.stopPropagation();
        dispatch( actionDeleteOrderLineFromInvoice(  activeEdit?.LineasVentaCompuestas[ dataIndex ] ) )
    }

    const func = useCallback( () => {
       // dispatch(startSendingVoucherEmail(activeEdit?.LineasVentaCompuestas[ dataIndex ].PkLineasVenta))
    }, [activeEdit?.PkVentas, dispatch ])

    const handleEdit = ( dataIndex, e ) => {
        dispatch(actionEditOrderLineFromInvoice( activeEdit?.LineasVentaCompuestas[ dataIndex ] ))
    }

    const handleDownloadVoucher = ( dataIndex, e) => {
        if (e) e.stopPropagation();
        Promise.resolve( dispatch(setActiveGettingLine(activeEdit?.LineasVentaCompuestas[ dataIndex ].PkLineasVenta)))
        .then( () => {
                dispatch(startDownloadingVoucher( activeEdit?.LineasVentaCompuestas[ dataIndex ].PkLineasVenta) )
            
        })        

    }

      

    const handleSendEmailVoucher = ( dataIndex, e ) => {
        if (e) e.stopPropagation();
        Promise.resolve( dispatch(setActiveGettingLine(activeEdit?.LineasVentaCompuestas[ dataIndex ].PkLineasVenta)))
        .then( () => {
            if( activeEdit?.LineasVentaCompuestas[ dataIndex ]?.ConfirmadaProveedor){
                //dispatch(startContentSendEmailVoucher( func,  ))
                dispatch(startSendingVoucherEmail(activeEdit?.LineasVentaCompuestas[ dataIndex ].PkLineasVenta));
            }
            else{
                Swal.fire('Error', 'La línea de venta no está confirmada', 'error')
                return;
            }   
           
        })

    }

    useEffect(() => {
        if( !modal ){
            dispatch(startLogOutLines())
        }
    }, [modal, dispatch])


    return (
        <>
         <div style={{
                marginTop: '2rem',
                width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}>
                {
                    !matchesXS
                    &&
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>LÍNEAS DE VENTA</Typography>
                }
                { idRol !== ROLREVENDEDOR ?
                    <MUIDataTable {...{data}} title={ matchesXS && 'Líneas de Venta' } columns={columnsAdmin} options={optionsAdmin} />
                    : <MUIDataTable {...{data}} title={ matchesXS && 'Líneas de Venta Prueba' } columns={columnsReseller} options={optionsReseller} />
                }
             

        </div>

        <NotificationModal />

        
        </>
    )
}
