    import { LocalizationProvider } from '@mui/x-date-pickers'
    import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Box } from '@mui/system'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadGridCustomer } from '../../helpers/loadGrid'
import { startUpdateClient } from '../../redux/actions/clients'
import { useFormStyles } from '../../styles/formStyles'
import { validationSchemaCustomer } from '../../utils/schemaValidationForm'
import { FormikDatePicker } from '../ui/FormikDatePicker'
import esLocale from 'date-fns/locale/es'
import { Spinner } from '../ui/Spinner'
import { navigate } from '@reach/router';

export const ClientInfo = () => {
    const classes = useFormStyles();
    const { active } = useSelector(state => state.client);
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui)
    
    const initialValues =  {
        Nombre: active?.Nombre,
        Apellidos: active?.Apellidos,
        Email: active?.Email,
        Telefono: active?.Telefono,
        NumeroIdentificacionFiscal: active?.NumeroIdentificacionFiscal,
        Pais: active?.Pais,
        Provincia: active?.Provincia,
        Localidad: active?.Localidad,
        CodigoPostal: active?.CodigoPostal,
        Direccion: active?.Direccion,
        date: active?.FechaBaja,
        Observaciones: active?.Observaciones
    }

    const { data } = loadGridCustomer();

    const handleSubmit = ( values, {setSubmitting} ) => {
        const client = {
            "PkClientes": active?.PkClientes,
            "VersionFila": active?.VersionFila,
            "Nombre": values.Nombre,
            "Apellidos": values.Apellidos,
            "Email": values.Email,
            "Telefono": values.Telefono,
            "NumeroIdentificacionFiscal": values.NumeroIdentificacionFiscal,
            "Pais": values.Pais,
            "Provincia": values.Provincia,
            "Localidad": values.Localidad,
            "CodigoPostal": values.CodigoPostal,
            "Direccion": values.Direccion??'',
            "FechaBaja": !values.date ? null : values.date,
            "Observaciones": values.Observaciones,

        }
       dispatch(startUpdateClient( client, setSubmitting ))
        
    }

    return (
        loading
        ?
        <Spinner />
        :
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <Formik
                    validationSchema={ validationSchemaCustomer }
                    enableReinitialize={ true } 
                    initialValues={ initialValues } 
                    onSubmit={handleSubmit}>
                {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid }) => (
                <Grid md={8} xs={11}>
                <Form>
                    <Box sx={{ p:2, mb: 4}}>
                        <Typography variant='h1'>Cliente</Typography>
                    </Box>
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            
                            data.map( (element) => {
                                return (
                                    <Grid
                                        key={ element.name }
                                        item
                                        md={6}
                                        xs={12}
                                        >
                                    <TextField
                                                fullWidth
                                                name={element.name}
                                                size='small'
                                                classes={{ root: classes.inputText }}
                                                id={`outlined-basic-${element.name}`} 
                                                label={ element.label }
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            { element.startAdornment }
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={values[element.name]}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched[element.name] && Boolean(errors[element.name])}
                                                helperText={touched[element.name] && errors[element.name]}
                                                disabled={ isSubmitting }
                                                multiline={ element.name === 'Observaciones' ? true : false}
                                                maxRows={ element.name === 'Observaciones' ? 5 : undefined }
                                                minRows={ element.name === 'Observaciones' ? 5 : undefined }
                                            />
                                    </Grid>
                                )
                            })

                        }

                                <Grid
                                item
                                md={6}
                                xs={12}
                                >
                                    <Field style={{fontSize: '0.8em'}} component={FormikDatePicker} name="date" label='Fecha Baja' textfield='date' isSubmitting={ isSubmitting } value={ values?.date } />
                                </Grid>
                        
                    </Grid>
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                    >
           
                    <Button 
                         style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                         onClick={ () => navigate('/clients') }>
                             <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                     </Button>

                            <LoadingButton
                                        variant="contained"
                                        type='submit'
                                        loading={ isSubmitting }
                                        disabled={ !isValid }
                                        style={{
                                            backgroundColor: '#E5824F',
                        
                                        }}
                                >
                                    Actualizar
                            </LoadingButton>
                    </Box>
                
                </Form>
                </Grid>
                )}
                </Formik>
                </LocalizationProvider>
        
        
    )
}
