import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react'
import { validationSchemaSupplpierToCoordinator } from '../../../utils/schemaValidationForm';
import Autocomplete from '@mui/material/Autocomplete';
import { LoadingButton } from '@mui/lab';
import { RemoveRedEyeRounded } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useFormStyles } from '../../../styles/formStyles';
import { useDebounce } from '../../../hooks/useDebounce';
import { startAddSupplierToCoordinator } from '../../../redux/actions/suppliers';
import { Box } from '@mui/system';

export const ContentModalAddSupplierToCoordinator = () => {

    const { active } = useSelector(state => state.user);
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch();
    const [ search, debounceSearchResult ] = useDebounce('/proveedores?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkProveedores"});
   
    const initialValues = {
        proveedor: '',
        observaciones: ''
      };
    
    const submit = (values, {setSubmitting}) => {
    const instance = {
        "FkUsuarios": active.PkUsuarios,
        "FkProveedores": values.proveedor,
        "Observaciones": values.observaciones
    }

    dispatch( startAddSupplierToCoordinator(instance, setSubmitting ));
    }
    return (
        <Formik validationSchema={ validationSchemaSupplpierToCoordinator } initialValues={initialValues} onSubmit={submit}>
            {({ handleChange, errors, values, setFieldValue, handleBlur, isSubmitting, touched, isValid }) => (
            <Form>
            <Grid item style={{ padding: '2rem'}}>
                <Grid container direction='column'>
                    
                    <Grid item classes={{root: classes.container }}>
                        <Typography variant='h1'>Agregar Proveedor a { active?.Nombre }</Typography>
                    </Grid>

                    <Autocomplete
                        name='proveedor'
                        autoHighlight
                        fullWidth
                        disablePortal
                        noOptionsText={"No hay proveedores"}
                        id="proveedor-autocomplete-exchange-rate"
                        renderInput={(params) => ( 
                            <TextField
                                {...params}
                                name='proveedor' 
                                variant='outlined' 
                                label="Proveedor" 
                                placeholder='Busca...'
                                disabled={ isSubmitting }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                error={touched.servicio && Boolean(errors.servicio)}
                                helperText={touched.servicio && errors.servicio}
                                

                            />)}
                        onInputChange={ debounceSearchResult }
                        sx={{ marginBottom: '2rem'}}
                        options={ search }
                        onChange={(e, value) => {
                            setFieldValue(
                            "proveedor",
                            value?.value !== null ? value?.value : initialValues.proveedor
                            );
                        }}
                        
                    />

                        <Grid item  classes={{root: classes.container }}>
                            <TextField
                                fullWidth
                                name='observaciones'
                                size='small'
                                classes={{ root: classes.inputText }}
                                id={`outlined-basic-observaciones`}  
                                label='Observaciones'
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <RemoveRedEyeRounded />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                value={ values.observaciones }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                disabled={ isSubmitting }
                                multiline
                                maxRows={ 10 }
                                minRows={ 5 }
                                
                            />
                        </Grid>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >

                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            type='submit'
                            loading={ isSubmitting }
                            disabled={ !isValid || isSubmitting }
                            style={{
                                backgroundColor: '#E5824F',
            
                            }}
                        >
                                Agregar 
                        </LoadingButton>

                        </Box>



                </Grid>
            </Grid>
            </Form>
            )}
            </Formik>
    )
}
