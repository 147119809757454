import { LINEACONFIRMADA, NUMROWINVOICESTATEORDERLINE, PENDIENTEPROVEEDOR } from "./constants";
import dateFormat from "dateformat";


const isEmpty = (obj) => {
    return Object.keys( obj || {} ).length === 0;
}

export const buildFilterLine = ( filterList, page, dateRange ) => {
  console.log( 'buildFilterLine' );
  console.log( filterList );
    const whereFilter = {
      "FechaRecogida": {"between":[ `${ dateFormat(dateRange[0], "isoUtcDateTime")}`, `${ dateFormat(dateRange[1], "isoUtcDateTime")}` ]},
    };
    
    if (filterList && filterList.length>0) {
      if( filterList[0] && filterList[0].length>0 &&
          filterList[0][0] ){
          whereFilter['FkVentas'] = filterList[0][0];
      }
      if( filterList[1] && filterList[1].length>0 &&
        filterList[1][0] ){
        whereFilter['FkClientes'] = filterList[1][0];
      }
      if( filterList[2] && filterList[2].length>0 &&
        filterList[2][0] ){
        whereFilter['EmailCliente'] =  {"regexp":`/${filterList[2][0]}/`};
      }
      if( filterList[5] && filterList[5].length>0 &&
        filterList[5][0] ){
        whereFilter['FkServiciosProveedores'] = filterList[5][0];
      }
      if( filterList[6] && filterList[6].length>0 &&
        filterList[6][0] ){
        whereFilter['FechaRecogida'] = filterList[6][0];
      }

      if( filterList[11] && filterList[11].length>0 &&
          filterList[11][0] && filterList[11][0] === PENDIENTEPROVEEDOR){
          //whereFilter['ConfirmadaProveedor'] = false;
          //whereFilter['ReservaPagada'] = true;
          whereFilter['PendienteProveedor'] = true;
          
      }
      if( filterList[11] && filterList[11].length>0 &&
        filterList[11][0] && filterList[11][0] === LINEACONFIRMADA){
          whereFilter['ConfirmadaProveedor'] = true;
      }
      if( filterList[13] && filterList[13].length>0 &&
        filterList[13][0]){
          whereFilter['FkUsuarios'] = filterList[13][0];
      }
      if( filterList[14] && filterList[14].length>0 &&
        filterList[14][0] ){
        whereFilter['FkProveedores'] = filterList[14][0];
      }
      
      if( filterList[16] && filterList[16].length>0 &&
        filterList[16][0] ){
        whereFilter['FkComisionistas'] = filterList[16][0];
      }
    }
    const filterquery = {
        limit: NUMROWINVOICESTATEORDERLINE,
        skip: page * NUMROWINVOICESTATEORDERLINE,
        include: [
          {
            relation: 'Venta',
            scope: {
              include: [
                {
                  relation: 'Cliente',
                },
                {
                  relation: 'Usuario',
                }
              ]
            }
          },
          {
            relation: 'ServicioProveedor'
          },
          {
            relation: 'Proveedor',
          }
        ]
    }
    
    const filterquerySum = {};
    if( !isEmpty(whereFilter) ){
        filterquery['where'] = whereFilter
        filterquerySum['where'] = whereFilter
    }

    const encodedFilter = JSON.stringify(filterquery);

    console.log('encodedFilter');
    console.log(encodedFilter);

    let query = `/lineas-ventas?filter=${ encodedFilter }`;
    let queryCount = `/lineas-ventas/count?filter=${ encodedFilter }`;
    let sumTotal = `/lineas-ventas/sum?filter=${ JSON.stringify(filterquerySum) }`;
    let csvQuery = `/lineas-ventas/csv?filter=${ JSON.stringify(filterquerySum) }`;

    console.log( query )
    return [query, queryCount, sumTotal, csvQuery];
}


export const buildFilterEmptyLines = ( dateRange )=> {
  const whereFilter = {
    "FechaRecogida": {"between":[ `${ dateFormat(dateRange[0], "isoUtcDateTime")}`, `${ dateFormat(dateRange[1], "isoUtcDateTime")}` ]},
  };
  
  const filterquery = {
      include: [
        {
          relation: 'Venta',
          scope: {
            include: [
              {
                relation: 'Cliente',
              },
              {
                relation: 'Usuario',
              }
            ]
          }
        },
        {
          relation: 'ServicioProveedor'
        },
        {
          relation: 'Proveedor',
        }
      ]
  }
  
  if( !isEmpty(whereFilter) ){
      filterquery['where'] = whereFilter
  }

  const encodedFilter = JSON.stringify(filterquery);
  let csvQuery = `/lineas-ventas/csv?filter=${ encodedFilter }`;


  return [csvQuery];
}