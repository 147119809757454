import {  Form, Formik } from 'formik'
import React from 'react'
import { FormikDatePicker } from '../../ui/FormikDatePicker'
import { Box, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { useFormStyles } from '../../../styles/formStyles'
import { loadGridInvoice } from '../../../helpers/loadGrid'
import { useDispatch, useSelector } from 'react-redux'

export const InvoiceForm = () => {
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const { data } = loadGridInvoice();
    const { activeEdit } = useSelector(state => state.invoice);
    const dispatch = useDispatch();

    const initialValues = {
        NumeroFactura: `${ activeEdit?.SerieFactura}${ activeEdit?.NumeroFactura}`,
        ConceptoFactura: activeEdit?.ConceptoFactura,
        ImporteFactura: activeEdit?.ImporteFactura,
    }

    
    return (
        <Formik enableReinitialize initialValues={ initialValues }>
            {({ values }) => (

        <Form style={{ marginTop: '2rem'}}>
            <Grid container direction='column' >
                <Grid item classes={{root: classes.container }}>
                    <Typography variant='h2'>Datos Factura</Typography>
                </Grid>
               
                {
                    data.map( element => {
                        return(
                            <Grid item xs={12} md={6} classes={{root: classes.container }}>
                                <TextField
                                    name={element.name}
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-${element.name}`}  
                                    label={ element.label }
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                { element.startAdornment }
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={ values[element.name] }
                                    disabled
                                   />
                            </Grid>
                        )
                    })
                }

                    
                
                

             
            </Grid>
        </Form>
        )}
        </Formik>
    )
}
