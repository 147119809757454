import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { AccountCircle, EuroOutlined, Info, MonetizationOn, People, RemoveRedEye } from '@material-ui/icons';
import React from 'react'
import { useSelector } from 'react-redux';
import { LocalizationProvider }  from'@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field } from 'formik';
import { FormikDatePicker } from '../../ui/FormikDatePicker';
import esLocale from 'date-fns/locale/es'
import { useFormStyles } from '../../../styles/formStyles';
import { ROLREVENDEDOR } from '../../../utils/constants';
import { getTotalLineFromDollarToEuros, calculateFromDollarsToEuro, calculateFromEuroToDollars, calcularInSituVentaRevendedorDolares, calcularInSituVentaRevendedorEuros, calcularReservaLineaVentaRevendedorDolares, calcularReservaLineaVentaRevendedorEuros, calcularPrecioBaseServicioRevendedorEuros, calcularPrecioBaseServicioRevendedorDolares } from '../../../utils/utils';
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../../utils/exchange';
import { calcularImporteVentaEuros, calcularImporteVentaDolares, calcularImporteReservaServicioRevendedorEuros, calcularImporteReservaServicioRevendedorDolares } from '../../../utils/utils';
import { ROLADMIN } from '../../../utils/constants';

export const FormLinesReseller = ( props ) => {
    
    
    const { current : currentUser } = useSelector(state => state.user);

    const { active: activeService } = useSelector(state => state.service);
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const { idRol, moneda: monedaUsuario } = useSelector(state => state.auth)
    const { vendedor,handleChange, errors, handleBlur, isSubmitting, touched, values, activeRate } = props;
    

    const isAdminEditingResellerOrder = () => {
        return idRol === ROLADMIN && vendedor?.FkRoles === ROLREVENDEDOR;
    }

    const usuarioVenta = isAdminEditingResellerOrder() ? vendedor : currentUser;
    const monedaVendedor = isAdminEditingResellerOrder() ? vendedor.Moneda : monedaUsuario;

    return (
        <Grid 
        container
        spacing={3}>
            {/* <Grid xs={12} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Proveedor'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ activeService?.ServicioProveedor?.ProveedorServicio?.Nombre}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                disabled
                />
            </Grid> */}
             
           
{
        !usuarioVenta.Comisiona && !usuarioVenta.MargenConfiguradoEnServicios &&
        (<> 

            { (monedaVendedor === 'EUR') &&
                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                    <TextField 
                        fullWidth   
                        label='Neto/PAX'
                        size='small'
                        variant='outlined'
                        classes={{ root: classes.inputText }}
                        value={ 
                            +calcularPrecioBaseServicioRevendedorEuros(
                                activeRate,
                                activeService.CosteDolares,
                                activeService.PrecioVenta,
                                activeService.ImporteReventa,
                                activeService.Moneda
                            )
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EuroOutlined/>
                                </InputAdornment>
                            ),

                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                    disabled
                    />
                </Grid>
            }
                          
            { (monedaVendedor === 'USD') &&  
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                    <TextField 
                        fullWidth   
                        label='Neto/PAX'
                        size='small'
                        variant='outlined'
                        classes={{ root: classes.inputText }}
                        value={
                            +calcularPrecioBaseServicioRevendedorDolares(
                                activeRate,
                                activeService.CosteDolares,
                                activeService.PrecioVenta,
                                activeService.ImporteReventa,
                                activeService.Moneda
                            )
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MonetizationOn/>
                                </InputAdornment>
                            ),

                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                    disabled
                    />
            </Grid>
            }
            
        </>)
}

{
        !usuarioVenta.Comisiona && !usuarioVenta.MargenConfiguradoEnServicios &&
        (<>

            { (monedaVendedor === 'EUR') &&  
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label={`Margen/PAX`}
                    name='ReventaLineaEuros'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    //value={ +calcularImporteReservaServicioRevendedorEuros(activeRate, activeService?.ServicioProveedor?.CosteDolares,activeService?.PrecioVenta, activeService?.Moneda)}
                    value={ values.ReventaLineaEuros }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EuroOutlined />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.ReventaLineaEuros && Boolean(errors.ReventaLineaEuros)}
                    helperText={touched.ReventaLineaEuros && errors.ReventaLineaEuros}
                    disabled={ isSubmitting }
                />
            </Grid>
            }

            { (monedaVendedor === 'USD') &&  
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label={ `Margen/PAX`}
                    name='ReventaLineaDolares'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    //value={ +calcularImporteReservaServicioRevendedorDolares(activeRate, activeService?.ServicioProveedor?.CosteDolares,activeService?.PrecioVenta, activeService?.Moneda) }
                    value={ values.ReventaLineaDolares }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.ReventaLineaEuros && Boolean(errors.ReventaLineaEuros)}
                    helperText={touched.ReventaLineaEuros && errors.ReventaLineaEuros}
                    disabled={ isSubmitting }
                />
            </Grid>
            }
        </>)

}

{ // LA TASA SE MUESTRA SIEMPRE EN DÓLARES 
             (1 ||monedaVendedor === 'USD') && 
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label={ `Tasa`}
                    size='small'
                    name='TasaDolares'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ values.TasaDolares }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                    helperText={touched.TasaDolares && errors.TasaDolares}
                    disabled
                />
            </Grid>
            }
           
            { (monedaVendedor === 'EUR') &&  
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label={ `Tasa`}
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            value={ +calculateFromDollarsToEuro( activeRate, values.TasaDolares) }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            disabled
                        />
                    </Grid>
            }

            { (monedaVendedor === 'EUR') &&
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='PVP/PAX'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ 
                        +calcularImporteReservaServicioRevendedorEuros(
                                activeRate, activeService?.ServicioProveedor?.CosteDolares,activeService?.PrecioVenta, 
                                activeService?.ImporteReventa, activeService?.Moneda) 
                        + +calculateFromDollarsToEuro( activeRate, activeService.ServicioProveedor.CosteDolares)
                        + +values.ReventaLineaEuros }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EuroOutlined/>
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                disabled
                />
            </Grid>
            }

            { (monedaVendedor === 'USD') &&
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='PVP/PAX'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ 
                        +calcularImporteReservaServicioRevendedorDolares(activeRate, activeService?.ServicioProveedor?.CosteDolares,activeService?.PrecioVenta,  activeService?.ImporteReventa, activeService?.Moneda) 
                        + activeService.ServicioProveedor.CosteDolares 
                        + +values.ReventaLineaDolares}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn/>
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                disabled
                />
            </Grid>
            }
            
            

            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='PAX'
                    size='small'
                    name='Unidades'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <People />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    value={ values.Unidades }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.Unidades && Boolean(errors.Unidades)}
                    helperText={touched.Unidades && errors.Unidades}
                    disabled={ isSubmitting }
                />
            </Grid>
            

            { (monedaVendedor === 'EUR') &&
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='PVP Total'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ 
                        (+calcularImporteReservaServicioRevendedorEuros(
                                activeRate, activeService?.ServicioProveedor?.CosteDolares,activeService?.PrecioVenta, 
                                activeService?.ImporteReventa, activeService?.Moneda) 
                        + +calculateFromDollarsToEuro( activeRate, activeService.ServicioProveedor.CosteDolares)
                        + +values.ReventaLineaEuros  ) 
                        * values.Unidades}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EuroOutlined/>
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                disabled
                />
            </Grid>
            }

            { (monedaVendedor === 'USD') &&
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='PVP Total'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ 
                        (+calcularImporteReservaServicioRevendedorDolares(activeRate, activeService?.ServicioProveedor?.CosteDolares,activeService?.PrecioVenta,  activeService?.ImporteReventa, activeService?.Moneda) 
                        + activeService.ServicioProveedor.CosteDolares 
                        + +values.ReventaLineaDolares ) 
                        * values.Unidades}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn/>
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                disabled
                />
            </Grid>
            }

            { // EL SUPLEMENTO DE TRANSPORTE SIEMPRE SE METE EN DÓLARES
            (1 ||monedaVendedor === 'USD') &&
            <Grid  md={3} xs={6} 
                    item  
                    classes={{root: classes.container }}
                >
                    <TextField
                        fullWidth
                        size='small'
                        name='SuplementoTransporteDolares'
                        label='Suplemento transporte'
                        variant='outlined'
                        value={ values.SuplementoTransporteDolares }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MonetizationOn />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        onBlur={ handleBlur }
                        onChange={ handleChange }
                        error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                        helperText={touched.TasaDolares && errors.TasaDolares}                   
                    />
                </Grid>
            }
            
            { 
            (monedaVendedor === 'EUR') && 
                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Suplemento transporte'
                    size='small'
                    variant='outlined'
                    value={ 
                        activeRate ?
                        (
                            obtenerPrecioEuros(values.SuplementoTransporteDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)
                             || 0)
                        : ''
                        }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EuroOutlined />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    disabled                
                    />
            </Grid>
            }



            { /* IN SITU */ (monedaVendedor === 'USD') && 
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label={ `Pago in situ`}
                    size='small'
                    name='InSituLineaDolares'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ +calcularInSituVentaRevendedorDolares(
                        activeRate,
                        usuarioVenta,
                        activeService.PrecioVenta,
                        values.SuplementoTransporteDolares,
                        activeService.Moneda,
                        activeService?.CosteDolares,
                        values.ReventaLineaEuros) * values.Unidades }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                    helperText={touched.TasaDolares && errors.TasaDolares}
                    disabled
                />
            </Grid>
            }
           
            { /* IN SITU */ (monedaVendedor === 'EUR') &&  
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label={ `Pago in situ`}
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            value={ 
                                +calcularInSituVentaRevendedorEuros(
                                    activeRate,
                                    usuarioVenta,
                                    activeService.PrecioVenta,
                                    obtenerPrecioEuros(values.SuplementoTransporteDolares, activeRate?.CambioEuros, activeRate?.CambioDolares),
                                    activeService.Moneda,
                                    activeService?.CosteDolares,
                                    values.ReventaLineaEuros
                                    )
                                * values.Unidades }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            disabled
                        />
                    </Grid>
            }
            


            { /* RESERVA */ (monedaVendedor === 'USD') && 
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label={ `Pago en reserva`}
                    size='small'
                    name='ReservaLineaDolares'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ +calcularReservaLineaVentaRevendedorDolares(
                        activeRate,
                        usuarioVenta,
                        activeService.PrecioVenta,
                        activeService.ServicioProveedor.CosteDolares,
                        activeService.Moneda, 
                        activeService?.ImporteReventa,
                        values.ReventaLineaDolares
                        )* values.Unidades }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                    helperText={touched.TasaDolares && errors.TasaDolares}
                    disabled
                />
            </Grid>
            }
            { /* RESERVA */ (monedaVendedor === 'EUR') &&  
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label={ `Pago en reserva`}
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            value={ +calcularReservaLineaVentaRevendedorEuros(
                                activeRate,
                                usuarioVenta,
                                activeService.PrecioVenta,
                                activeService.ServicioProveedor.CosteDolares,
                                activeService.Moneda, 
                                activeService?.ImporteReventa,
                                values.ReventaLineaEuros
                                )* values.Unidades }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            disabled
                        />
                    </Grid>
            }
            








            <Grid xs={12} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Concepto'
                    size='small'
                    variant='outlined'
                    name='Concepto'
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Info />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    value={ values.Concepto }
                    error={touched.Concepto && Boolean(errors.Concepto)}
                    helperText={touched.Concepto && errors.Concepto}
                    disabled={ isSubmitting }
                />
            </Grid>

            <Grid xs={12} item  classes={{root: classes.container }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}  locale={ esLocale }>
                    <Field component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida   }  />
                </LocalizationProvider>
            </Grid>

            <Grid xs={12} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Observaciones'
                    size='small'
                    name='Observaciones'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <RemoveRedEye />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.Observaciones && Boolean(errors.Observaciones)}
                    helperText={touched.Observaciones && errors.Observaciones}
                    disabled={ isSubmitting }
                    multiline
                    maxRows={ 10 }
                    minRows={ 5 }
                />
            </Grid>
        </Grid>
    )
}
