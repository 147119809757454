import React, { useEffect, useState } from 'react'
import {  InputAdornment, TextField, Button, Box, Container,  } from '@material-ui/core'
import { AccountCircle, VpnKey } from '@material-ui/icons'
import jwt_decode from "jwt-decode";

import * as yup from 'yup';
import { useFormik } from "formik";
import { Helmet } from 'react-helmet';
import LoadingButton from '@mui/lab/LoadingButton';
import showPassword from '../../../images/show-password.svg';
import hidePassword from '../../../images/hide-password.svg';
import { loadGridChangePassword } from '../../../helpers/loadGrid';
import { useLoginStyles } from '../../../styles/loginStyles';
import { navigate } from '@reach/router';
import backImage from '../../../images/back-login.png';
import { useDispatch } from 'react-redux';
import { startResetPassword, startVerifyingToken } from '../../../redux/actions/auth';


const ResetPassword = ( props ) => {

    const classes = useLoginStyles();
    const [visible, setVisible] = useState(false);
    const [visiblePC, setVisiblePC] = useState(false);
    const { data } = loadGridChangePassword();
    const dispatch = useDispatch();

    useEffect(() => {
        const token = props.location.search.slice(3);
        let decodedJWT = '';
        try{
            decodedJWT = jwt_decode( token );
        }
        catch(err){
            navigate('/login');
        }
        
        if( decodedJWT.exp * 1000 < Date.now() ){
            navigate('/login');
            return;
        }
        dispatch(startVerifyingToken( token ));
    }, [props.location.search, dispatch])
    

    const validationSchema = yup.object().shape({
        password: yup.string().required("La contraseña es requerida").min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres'),
        passwordConfirm: yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
            [yup.ref("password")],
            "Ambas contraseñas deben ser iguales"
            )
        }).required('La contraseña es requerida').min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres')
      });

      const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            const token = props.location.search.slice(3);
            const decodedJWT = jwt_decode( token );
            let payload = {
                id: parseInt(decodedJWT.id),
                password: values.password,
                passwordConfirm: values.passwordConfirm,
                token: token
            }

            dispatch( startResetPassword( payload ));
            
        }
    });

    
  

    const handleBack = e => {
        navigate('/login')
    }

  return (
     <>
        <div
            style={{
                backgroundImage: `url(${ backImage }) `,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                objectFit: 'cover'
                
            }}
            className='body'
        >
            <Helmet>
                <title>Reseteo Contraseña | Xplora</title>
            </Helmet>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10rem'
                }}
            >
            <Box
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    borderRadius: 7,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '40vh',
                    width: '40vw',
                    justifyContent: 'center',
                    minWidth: 330.4,
                    maxWidth: 700,
                    maxHeight: 400,
                    minHeight: 300,
                    }}

            >
                <Container maxWidth="sm">
                    <form onSubmit={formik.handleSubmit}>
                        {
                            data.map( element => {
                                return(
                                    <TextField
                                        fullWidth
                                        label={ element.label }
                                        margin="normal"
                                        name={ element.name }
                                        type={ element.name === 'password' ? (visible ? 'text' : 'password') : ( visiblePC ? 'text' : 'password') }
                                        variant="outlined"
                                        value={formik.values[element.name]}
                                        onBlur={ formik.handleBlur }
                                        onChange={formik.handleChange}
                                        error={formik.touched[element.name] && Boolean(formik.errors[element.name])}
                                        helperText={formik.touched[element.name] && formik.errors[element.name]}
                                        disabled={ formik.isSubmitting }
                                        InputProps={{
                                            startAdornment:(
                                                <InputAdornment position='start'>
                                                    <VpnKey />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                element.name === 'password'
                                                ?
                                                <InputAdornment position='end'  onClick={ () => setVisible(!visible)}>
                                                    <img 
                                                        src={ visible ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>
                                                :
                                                <InputAdornment position='end' onClick={ () => setVisiblePC(!visiblePC)}>
                                                    <img 
                                                        src={ visiblePC ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    />
                                )
                            })
                        }
                        <Box sx={{ py: 2 }}>
                        <LoadingButton
                            fullWidth
                            loadingPosition="start"
                            variant="contained"
                            type='submit'
                            loading={ formik.isSubmitting }
                            disabled={ !formik.isValid }
                            style={{
                                backgroundColor: '#E6824F',
                                height: 'auto'
                            }}
                        >
                            Enviar
                        </LoadingButton>
                        <Button fullWidth variant='contained' disabled={ formik.isSubmitting } startIcon={<AccountCircle />} onClick={ handleBack } style={{marginTop: '1vh',  backgroundColor: '#fff', height: 'auto'}}>
                            Login
                        </Button>
                        </Box>
                    </form>
                </Container>
            </Box>
            </div>
        </div>
      </>
  )
}


export default ResetPassword;