
// array is a matrix
// variableOptions is an object
import { IconButton } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from '../components/ui/Spinner';
import edit from '../images/edit.png';
import deleteIcon from '../images/trash.png';
import clsx from 'clsx';
import { useTableStyles } from '../styles/useTableUsersStyles'


export const useTableParams = ( variableOptions, array, actions, actionsColumn, editAction, actionsTitle = '') => {
    
    const classes = useTableStyles()
    const { loading } = useSelector(state => state.ui)
    const {
        filter,
        filterType,
        responsive,
        tableBodyMaxHeight,
        filename,
        rowsPerPage,
    } = variableOptions;
    const options = {
        filter: filter,
        filterType: filterType,
        responsive: responsive,
        tableBodyMaxHeight: `${ tableBodyMaxHeight }`,
        draggableColumns: {
          enabled: true
        },
        downloadOptions: {
            filename: `${ filename }.csv`,
            separator: ';',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              }
        },
        print: false,
        searchPlaceholder: 'Buscar...',
        rowsPerPage: rowsPerPage,
        selectToolbarPlacement: 'above',
        textLabels: {
            body: {
              noMatch: loading ? <Spinner /> : "Lo siento, no se han encontrado registros",
            },
            pagination: {
              next: "Siguiente",
              previous: "Anterior",
              rowsPerPage: "Filas por página",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              viewColumns: "Consultar Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Incluir dados de baja",
              title: "Fitros",
              reset: "Reset",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas Tablas",
            },
            selectedRows: {
              text: "fila(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar filas seleccionadas",
            },
          },
          selectableRowsHideCheckboxes: true,
          selectableRowsOnClick: false,
          
          onRowClick : (_, rowMeta) => { 
            if (editAction) editAction(rowMeta.dataIndex);
          },
          onDownload: (buildHead, buildBody, columns, data) => {
            return "\uFEFF" + buildHead(columns) + buildBody(data);
          }
        
          
    };

    const columns = [];

    array.map( element => {
      columns.push({
        name: element[0],
        options: {
          filter: element[1],
          display: element[2] === false ? element[2] : true
      }
      })
      return null;
    });
    actionsColumn && columns.push({
      name: actionsTitle ? actionsTitle : '',
      options:{
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
                {
                  actions?.map( (action, i) => {
                    if( action.type === 'delete'){
                      return (
                          <IconButton key={ i } style={{ color: `${ action?.color }`, width: '1rem', height: '2rem'}} onClick={ e => action.eject(dataIndex, e) }>
                            <img src={`${ deleteIcon }`}  alt='img'/>
                          </IconButton>
                      )
                    }
                    else if( action.type === 'edit' ){
                      return (
                        
                          <IconButton key={ i } style={{ color: `${ action.color }`, width: '1rem', height: '2rem'}} onClick={ e => action.eject( dataIndex, e) }>
                            <img src={`${ edit }`}  alt='img'/>
                          </IconButton>
                      )
                    }
                    else if( action.type === 'custom' ){
                      return (
                        
                          <IconButton key={ i } style={{ color: `${ action.color }`, width: '0.8rem', height: '2.4rem'}} onClick={ e => action.eject( dataIndex, e) }>
                            {
                              action.icon
                            }
                          </IconButton>
                      )
                    }
                    else {
                      if (action.isShown(dataIndex)) 
                          return (
                          <IconButton key={ i } size="large" style={{ color: `${ action?.color }`, width: '1rem', height: '2rem'}} onClick={ e => action.eject(dataIndex, e) }>
                            <img src={`${ action.icon }`}  alt='img'  />
                          </IconButton>
                      )
                    } 
                  })
                }
            </div>
              
          );
        },

        setCellHeaderProps: () => {
          return {
            className: clsx({
              [classes.NameCell]: true,
            }),
            style:{
              fontSize: 15,
              fontWeight: 500
            }
          }
        }

      }

    })

    return {
        options,
        columns
    }
}
