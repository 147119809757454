const colors = {
    A: '#E99887',
    B: '#BBE987',
    C: '#F74E34',
    D: '#E9E4BE',
    E: '#BEE3E9',
    F: '#C3BEE9',
    G: '#AC791A',
    H: '#37DB59',
    I: '#60A6AF',
    J: '#63260D',
    K: '#4D412A',
    L: '#9F4DC5',
    M: '#1612EA',
    N: '#4C170C',
    O: '#BB51A1',
    P: '#B0A8B8',
    Q: '#D27FBD',
    R: '#42F212',
    S: '#12ADF2',
    T: '#76E186',
    U: '#76E1E0',
    V: '#7A76E1',
    W: '#BB1E55',
    X: '#1FBB1E',
    Y: '#1EBABB',
    Z: '#B9BB1E',
}

export  {
    colors
}