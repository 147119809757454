import { Grid, InputAdornment, TextField } from '@material-ui/core';
import {  Euro, EuroOutlined, Info, Loyalty, MonetizationOn, People, RemoveRedEye } from '@material-ui/icons';
import { LocalizationProvider }  from'@mui/x-date-pickers'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field } from 'formik';
import React from 'react'
import { useFormStyles } from '../../../styles/formStyles';
import { FormikDatePicker } from '../../ui/FormikDatePicker';
import esLocale from 'date-fns/locale/es'
import { calculateFromDollarsToEuro, calculateFromEuroToDollars } from '../../../utils/utils';
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../../utils/exchange';


export const FormLinesSeller = (props) => {
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const { handleChange, errors, handleBlur, isSubmitting, 
        touched, values, activeRate } = props;
    
    return (
        <Grid 
        container
        spacing={3}>

            <Grid xs={12} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Concepto'
                    size='small'
                    variant='outlined'
                    name='Concepto'
                    value={ values.Concepto }
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Info />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.Concepto && Boolean(errors.Concepto)}
                    helperText={touched.Concepto && errors.Concepto}
                    disabled={ isSubmitting }
                />
            </Grid>
            

            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='In situ'
                    size='small'
                    name='InSituLineaDolares'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    value={ values.InSituLineaDolares}
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.InSituLineaDolares && Boolean(errors.InSituLineaDolares)}
                    helperText={touched.InSituLineaDolares && errors.InSituLineaDolares}
                    disabled={ isSubmitting }
                />
            </Grid>
           


            {
                values.InSituLineaDolares && !isNaN(values.InSituLineaDolares)
                ?
                (
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label=''
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={ obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares) }
                            disabled
                        />
                    </Grid>
                )
                :
                (
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                    </Grid>
                )
            }


            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Reserva'
                    name='ReservaLineaEuros'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EuroOutlined />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                     
                    
                    inputProps={{ maxLength: 5 }}
                    disabled
                />
            </Grid>
            {
                (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares))>0
                ?
                (
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label=''
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            value={ obtenerPrecioDolares(
                                        values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares), 
                                        activeRate.CambioEuros, activeRate.CambioDolares) }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MonetizationOn />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            disabled
                        />
                    </Grid>
                )
                :
                (
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                    </Grid>
                )
            }

            
            <Grid  md={3} xs={6} 
                                item  
                classes={{root: classes.container }}
            >
            
                <TextField
                    fullWidth
                    size='small'
                    name='PrecioUnitarioEuros'
                    label='Precio Servicio'
                    variant='outlined'
                    value={ values.PrecioUnitarioEuros }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EuroOutlined />
                            </InputAdornment>
                        ),
                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={ handleChange }
                    error={touched.PrecioUnitarioEuros && Boolean(errors.PrecioUnitarioEuros)}
                    helperText={touched.PrecioUnitarioEuros && errors.PrecioUnitarioEuros}                   
                />

            </Grid>
        
        

            {
                values.PrecioUnitarioEuros && !isNaN(values.PrecioUnitarioEuros)
                ?
                (
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label=''
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MonetizationOn />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={ obtenerPrecioDolares(values.PrecioUnitarioEuros, activeRate.CambioEuros, activeRate.CambioDolares) }
                            disabled
                        />
                    </Grid>
                )
                :
                (
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                    </Grid>
                )
            }





            
                <Grid  md={3} xs={6} 
                    item  
                    classes={{root: classes.container }}
                >
                    <TextField
                        fullWidth
                        size='small'
                        name='SuplementoTransporteDolares'
                        label='Suplemento transporte'
                        variant='outlined'
                        value={ values.SuplementoTransporteDolares }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MonetizationOn />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        onBlur={ handleBlur }
                        onChange={ handleChange }
                        error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                        helperText={touched.TasaDolares && errors.TasaDolares}                   
                    />
                </Grid>

                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Suplemento transporte'
                    size='small'
                    variant='outlined'
                    value={ 
                        activeRate ?
                        (obtenerPrecioEuros(values['SuplementoTransporteDolares'], activeRate?.CambioEuros, activeRate?.CambioDolares) || 0)
                        : ''
                        }
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EuroOutlined />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    disabled                />
            </Grid>


  

            <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Nº Personas'
                    size='small'
                    name='Unidades'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <People />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.Unidades && Boolean(errors.Unidades)}
                    helperText={touched.Unidades && errors.Unidades}
                    disabled={ isSubmitting }
                    value={values.Unidades}
                />
            </Grid>
            <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='%Dto reserva'
                    size='small'
                    name='PorcDescuento'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Loyalty />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    value={ values.PorcDescuento}
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.PorcDescuento && Boolean(errors.PorcDescuento)}
                    helperText={touched.PorcDescuento && errors.PorcDescuento}
                    disabled={ isSubmitting }
                />
            </Grid>
            
            {
                (values.PorcDescuento > 0 && (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares))>0 && values.PorcDescuento <= 100)
                &&
                (
                    <>
                        <Grid  md={2} xs={6} item  classes={{root: classes.container }}>
                            <TextField 
                                fullWidth   
                                label='Reserva con dto'
                                size='small'
                                variant='outlined'
                                classes={{ root: classes.inputText }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Euro />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                value={ Math.round(
                                    (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) 
                                    - ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) * (+values.PorcDescuento/100))) }
                                disabled
                            />
                        </Grid>

                        <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                            <TextField 
                                fullWidth   
                                label='Reserva con dto'
                                size='small'
                                variant='outlined'
                                classes={{ root: classes.inputText }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MonetizationOn />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                value={ calculateFromEuroToDollars(activeRate, 
                                    Math.round(
                                        (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) 
                                        - ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) * (+values.PorcDescuento/100)))
                                        ) }
                                disabled
                            />
                        </Grid>
                    </>
                )
            }

            


            <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Tasa'
                    name='TasaDolares'
                    inputProps={
                        { readonly: true }
                    }
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    value={ values.TasaDolares}
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                    helperText={touched.TasaDolares && errors.TasaDolares}
                    disabled={ true }
                />
            </Grid>

            {
                
                (
                    <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label='Tasa'
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            disabled
                            value={
                                +obtenerPrecioEuros(values.TasaDolares, activeRate.CambioEuros, activeRate.CambioDolares)
                                // +calculateFromDollarsToEuro( activeRate, values.TasaDolares) 
                                }
                        />
                    </Grid>
                )
            }

            
            {
                ( values.PrecioUnitarioEuros
                && (values.PrecioUnitarioEuros)>0
                )
                ?
                (
                    
                    <>
                        <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                            <TextField 
                                fullWidth   
                                label='Importe Final'
                                size='small'
                                variant='outlined'
                                classes={{ root: classes.inputText }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroOutlined />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                value={ 
                                    +values.Unidades *
                                    (
                                        +obtenerPrecioEuros(values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares) +
                                        Math.round(+values.PrecioUnitarioEuros - ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) * (+values.PorcDescuento/100)))
                                    )
                                }
                                disabled
                            />
                        </Grid>

                        <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                            <TextField 
                                fullWidth   
                                label='Importe Final'
                                size='small'
                                variant='outlined'
                                classes={{ root: classes.inputText }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MonetizationOn />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                value={ 
                                    +values.Unidades * 
                                    (
                                       +obtenerPrecioDolares(
                                        Math.round(+values.PrecioUnitarioEuros - 
                                            ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares)) * (+values.PorcDescuento/100))), 
                                        activeRate.CambioEuros, activeRate.CambioDolares)
                                       + +values.SuplementoTransporteDolares
                                    )
                                }
                                disabled
                            />
                        </Grid>
                    </>
                )
                :
                <></>
            }


            <Grid xs={12} item  classes={{root: classes.container }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}  locale={ esLocale }>
                    <Field component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida   }  />
                </LocalizationProvider>
            </Grid>



            <Grid  xs={12} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label='Observaciones'
                    size='small'
                    name='Observaciones'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <RemoveRedEye />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.Observaciones && Boolean(errors.Observaciones)}
                    helperText={touched.Observaciones && errors.Observaciones}
                    disabled={ isSubmitting }
                    multiline
                    maxRows={ 10 }
                    minRows={ 5 }
                />
            </Grid>
        </Grid>
    )
}
