import Swal from 'sweetalert2';
import { url } from '../api/api';
import { loadJWT } from './loadJWT';




export const loadSuppliersCoordinator = async( id ) => {
    let suppliers = [];
    const { token } = loadJWT();
    await url.get(`/usuarios/${ id }?filter={"include": [{"relation": "ProveedoresCoordinador","scope": {"include": [{"relation": "Proveedor"}]} }]}`, {
        headers:{
            Authorization: `Bearer ${ token }`
        }
    })
    .then( response => {
        if( response?.data?.ProveedoresCoordinador ){
            suppliers = response.data.ProveedoresCoordinador.map( resp => resp);
        }
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
    })
    return suppliers;

}