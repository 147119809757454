
//Third-party packages
import React from 'react'
import { ThemeProvider } from '@material-ui/core'
import { store } from '../../redux/store/store'
import { Provider } from 'react-redux'

//Custom files

import theme from './theme';
import { AppRouter } from '../../routes/AppRouter';

/**
 * Author: QBK
 * Date: 22-09-2021
 */

export const XploraApp =  () => {
    return (
        <ThemeProvider theme={ theme }>
            <Provider store={ store }>
                <AppRouter />
            </Provider>
        </ThemeProvider>
    )
}
