
//Third-party imports
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  Grid, InputAdornment, TextField, Typography } from '@material-ui/core'

import { startUpdateSupplier } from '../../redux/actions/suppliers';
import { useFormStyles } from '../../styles/formStyles';
import { Field, Form, Formik } from 'formik';
import { validationSchemaSupplier } from '../../utils/schemaValidationForm';
import { loadGridSupplier } from '../../helpers/loadGrid';
import { Send } from '@material-ui/icons';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

import { FormikDatePicker } from '../ui/FormikDatePicker';
/**
 * 
 * Author: QBK
 * Date: 30-09-2021
 */
export const ContentModalSupplierEdit = () => {
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions 
    const { active } = useSelector(state => state.supplier)
    const { data } = loadGridSupplier()
    const result = data.filter( item => item?.type !== 'detail')
    const initialValues = {
        Nombre: active?.Nombre,
        Email: active?.Email,
        Telefono: active?.Telefono,
        PaginaWeb: active?.PaginaWeb,
        Observaciones: active?.Observaciones,
        date: active?.FechaBaja,
    };

    

    //Submit form logic
   
    const handleSubmit = ( values, {setSubmitting} ) => {
        const newSupplier = {
            "PkProveedores": active.PkProveedores,
            "Nombre": values.Nombre,
            "Email": values.Email,
            "Telefono": values.Telefono,
            "PaginaWeb": values.PaginaWeb,
            "Observaciones": values.Observaciones,
            "FechaBaja": values.date ? values.date : null,
            "VersionFila": active.VersionFila + 1
        }
        dispatch( startUpdateSupplier( newSupplier, setSubmitting ) )
    }

    //HTML Grid Container
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik initialValues={initialValues} validationSchema={ validationSchemaSupplier }   onSubmit={handleSubmit}>
        {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid }) => (
        <Form>
        <Grid item style={{ padding: '2rem'}}>
            <Grid container direction='column'>
                
                <Grid item classes={{root: classes.container }}>
                    <Typography variant='h3'>Editar Proveedor { values.Nombre }</Typography>
                </Grid>

                {
                        result.map( element => {
                            return(
                                <Grid item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name={element.name}
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-${element.name}`}  
                                        label={ element.label }
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { element.startAdornment }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        value={values[element.name]}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched[element.name] && Boolean(errors[element.name])}
                                        helperText={touched[element.name] && errors[element.name]}
                                        disabled={ isSubmitting }
                                        multiline={ element.name === 'Observaciones' ? true : false}
                                        maxRows={ element.name === 'Observaciones' ? 10 : undefined }
                                        minRows={ element.name === 'Observaciones' ? 5 : undefined }
                                    />
                                </Grid>
                            )
                        })
                    }
                    <Grid item  classes={{root: classes.container }}>
                        <Field component={FormikDatePicker} name="date" isSubmitting={ isSubmitting } value={ values?.Fechabaja}/>
                    </Grid>

                    <LoadingButton
                        fullWidth
                        loadingPosition="start"
                        startIcon={<Send />}
                        variant="outlined"
                        type='submit'
                        disabled={ !isValid }
                        loading={ isSubmitting }
                    >
                            Editar 
                    </LoadingButton>



            </Grid>
        </Grid>
        </Form>
        )}
        </Formik>
        </LocalizationProvider>
    )
}
