import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeleteSupplierCoordinator } from '../../redux/actions/suppliers';
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';

export const ConfirmDeleteSupplierCoordinator = () => {
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { active: activeSupplier } = useSelector(state => state.supplier) //we get the users from the state 
    const { active: activeUser } = useSelector(state => state.user)
    
    const handleSuccess = () => {
        dispatch(uiManageModalNotification());
        dispatch(startDeleteSupplierCoordinator(activeSupplier.FkProveedores, activeUser.PkUsuarios)); //We dispatch the action passing as a parameter the user id
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ `¿Quieres borrar el proveedor?` }
                text={ `El proveedor asociado a este coordinador no seguirá asignado` }
                action={ handleSuccess }
            />
    )
}
