import { Grid, Typography, Box, FormControl, Select, InputLabel, MenuItem, InputAdornment, TextField } from '@material-ui/core';
import { EuroOutlined, Info, MonetizationOn, People, RemoveRedEye } from '@material-ui/icons';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { startLogOutContentModal } from '../../../../redux/actions/contentModal';
import { startSettingExchangeRateOrderLineState, startUpdatingLineState } from '../../../../redux/actions/sales';
import { uiManageModalCollectionTypes } from '../../../../redux/actions/ui';
import { useFormStyles } from '../../../../styles/formStyles';
import { CUSTOMSERVICE } from '../../../../utils/constants';
import { validationSchemaEditFreeLine } from '../../../../utils/schemaValidationForm';
import esLocale from 'date-fns/locale/es'
import { FormikDatePicker } from '../../../ui/FormikDatePicker';
import { calculateFromDollarsToEuro } from '../../../../utils/utils';
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../../../utils/exchange';


const isEmpty = (obj) => {
    return Object.keys( obj || {} ).length === 0;
}
export const ContentModalEditStateFreeLine = () => {
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const { activeLine, rate: activeRate }  = useSelector(state => state.invoice.active);
    const dispatch = useDispatch();
    const [typeService, setTypeService] = useState(activeLine?.FkTiposServicio);
    const refServiceType = useRef();

    useEffect(() => {
        if( typeService ) {
            dispatch( startSettingExchangeRateOrderLineState( activeLine.FkProveedores, typeService ) )

        }
        else{
            Promise.resolve( dispatch(startLogOutContentModal()))
            .then(() => {
                dispatch(uiManageModalCollectionTypes())
            })
            .then( () => {
                Swal.fire('Error', 'Ha habido un error en el sistema', 'error')
            })
        }
    }, [ typeService, dispatch ])

    const initialValues = {
        FkProveedores: activeLine?.FkProveedores,
        FkTipoServicio: activeLine?.FkTiposServicio,
        ReservaLineaEuros: activeLine?.ReservaLineaEuros,
        ReservaEuros: activeLine?.ReservaEuros,
        InSituLineaDolares: activeLine?.InSituLineaDolares,
        Unidades: activeLine?.Unidades,
        Concepto: activeLine?.Concepto,
        Observaciones: activeLine?.Observaciones,
        FechaRecogida: activeLine?.FechaRecogida,
        TasaDolares: activeLine?.TasaDolares,
        SuplementoTransporteDolares: activeLine?.SuplementoTransporteDolares,
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const id = localStorage.getItem('indexOrderLine')
        const payload = {
            ...values,
            FkTiposLineaVenta: CUSTOMSERVICE,
            FkTiposCambioProveedores: activeRate.PkTiposCambioProveedores,
            CosteDolares: +values.InSituLineaDolares,
            ReservaEuros: +values.ReservaEuros,
            NombreServicio: values.Concepto,
            NombreProveedor: activeLine?.NombreProveedor,
            FkTiposServicio: values.FkTipoServicio,
            ReservaLineaEuros: +values.ReservaLineaEuros,
            InSituLineaDolares: +values.InSituLineaDolares,
            InSituLineaEuros: obtenerPrecioEuros(values.InSituLineaDolares, activeRate.CambioEuros, activeRate.CambioDolares),
            ReventaLineaEuros: 0,
            ReventaEuros: 0,
            Unidades: +values.Unidades,
            FechaRecogida: values.FechaRecogida ? values.FechaRecogida : null,
            TasaDolares: +values.TasaDolares,
            TasaEuros: obtenerPrecioEuros(values.TasaDolares, activeRate.CambioEuros, activeRate.CambioDolares ),
            SuplementoTransporteDolares: +values.SuplementoTransporteDolares,
            SuplementoTransporteEuros: +obtenerPrecioEuros(+values.SuplementoTransporteDolares, activeRate.CambioEuros, activeRate.CambioDolares),

        }
        dispatch( startUpdatingLineState( id, payload, setSubmitting ) )  
    }
    return (
        <>
            <Formik initialValues={ initialValues } validationSchema={ validationSchemaEditFreeLine} enableReinitialize  onSubmit={ handleSubmit }>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, values, setFieldValue }) => (
                <Form>
                    <Grid item style={{padding: '2rem'}}>
                        <Grid container direction='column'>
                            <Grid item classes={{root: classes.container }}>
                                <Typography variant='h3'>Editar Línea de Venta</Typography>
                            </Grid>

                            {
                                values.FkProveedores
                                &&
                                <FormControl
                                    style={{
                                        marginBottom: '2rem'
                                    }}
                                    disabled={ isSubmitting }
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.FkTipoServicio && Boolean(errors.FkTipoServicio)}
                                    helperText={touched.FkTipoServicio && errors.FkTipoServicio}
                                                
                                >
                                    <InputLabel id="select-label-type-services">Tipo Servicio</InputLabel>
                                    <Select
                                        ref={ refServiceType }
                                        name='FkTipoServicio'
                                        displayEmpty
                                        defaultValue={values.FkTipoServicio}
                                        onChange={e => {
                                            setFieldValue("FkTipoServicio", e.target.value);
                                            setTypeService(e.target.value);
                                        }}
                                        variant='outlined'
                                    >
                                        <MenuItem value={2}>Parque</MenuItem>
                                        <MenuItem value={1}>Tour</MenuItem>

                                    </Select>
                                </FormControl>

                                
                            }

{
                                values.FkProveedores && values.FkTipoServicio
                                &&
                                (
                                    <>
                                   
                                    
                                        <Grid md={12} xs={12} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Tasa'
                                                name='TasaDolares'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.TasaDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                                helperText={touched.TasaDolares && errors.TasaDolares}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        {
                                        values.TasaDolares > 0
                                        &&
                                        (
                                            <Grid md={12} xs={12} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Tasa'
                                                    size='small'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EuroOutlined />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    value={ +calculateFromDollarsToEuro( activeRate, values.TasaDolares) }
                                                    disabled
                                                />
                                            </Grid>
                                        )
                                    }
                                    <Grid xs={12} item  classes={{root: classes.container }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
                                    <Field component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida   }  />
                                    </LocalizationProvider>
                                    </Grid>
                                    <Grid item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Coste Línea'
                                                size='small'
                                                name='InSituLineaDolares'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                value={ values.InSituLineaDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.InSituLineaDolares && Boolean(errors.InSituLineaDolares)}
                                                helperText={touched.InSituLineaDolares && errors.InSituLineaDolares}
                                                disabled={ isSubmitting }
                                            />
                                    </Grid>

                                    {
                                        values.InSituLineaDolares
                                        && !isEmpty( activeRate )
                                        &&
                                        (
                                            <>
                                                <Grid item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Coste Línea'
                                                        size='small'
                                                        name='CosteEuros'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EuroOutlined />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        value={ obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares) || 0 }
                                                        disabled={ true }
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                        
                                    <Grid item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Reserva Línea'
                                                name='ReservaLineaEuros'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ values.ReservaLineaEuros}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EuroOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.ReservaLineaEuros && Boolean(errors.ReservaLineaEuros)}
                                                helperText={touched.ReservaLineaEuros && errors.ReservaLineaEuros}
                                                disabled={ isSubmitting }
                                            />
                                     </Grid>

                                     {
                                         !isEmpty( activeRate ) &&
                                         values.InSituLineaDolares &&
                                         values.ReservaLineaEuros &&
                                         (
                                            <>
                                            <Grid item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Reserva Línea'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ obtenerPrecioDolares(values.ReservaLineaEuros, activeRate?.CambioEuros, activeRate?.CambioDolares) || 0}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                            <Grid item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Venta'
                                                size='small'
                                                name='venta'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EuroOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                value={ 
                                                    ( parseFloat( obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares) )
                                                    + parseFloat( values.ReservaLineaEuros ) ) 
                                                    || 0}
                                                disabled={ true }
                                                />
                                            </Grid>

                                            <Grid item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Venta'
                                                    size='small'
                                                    name='venta'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <MonetizationOn />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    value={ ( parseFloat( values.InSituLineaDolares )  +  
                                                        parseFloat( obtenerPrecioDolares( values.ReservaLineaEuros, activeRate.CambioEuros, activeRate.CambioDolares ) ) ) || 0}
                                                    disabled={ true }
                                                />
                                            </Grid>
                                            </>
                                         )
                                     }

                                     <Grid item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Concepto'
                                                size='small'
                                                variant='outlined'
                                                name='Concepto'
                                                value={ values.Concepto }
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Info />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Concepto && Boolean(errors.Concepto)}
                                                helperText={touched.Concepto && errors.Concepto}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>

                                        <Grid item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Personas'
                                                size='small'
                                                value={ values.Unidades }
                                                name='Unidades'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <People />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Unidades && Boolean(errors.Unidades)}
                                                helperText={touched.Unidades && errors.Unidades}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>

                                        <Grid item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Observaciones'
                                                size='small'
                                                value={ values.Observaciones }
                                                name='Observaciones'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <RemoveRedEye />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Observaciones && Boolean(errors.Observaciones)}
                                                helperText={touched.Observaciones && errors.Observaciones}
                                                disabled={ isSubmitting }
                                                multiline
                                                maxRows={ 10 }
                                                minRows={ 5 }
                                            />
                                        </Grid>
                                    </>
                                    
                                )
                            }
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >

                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    type='submit'
                                    loading={ isSubmitting }
                                    disabled={ !isValid }
                                    style={{
                                        backgroundColor: '#E5824F',
                    
                                    }}
                                >
                                        Editar
                                </LoadingButton>

                            </Box>

                        </Grid>
                    </Grid>
            </Form>
            )}
            </Formik>
        </>
    )
}
