import { Select } from '@material-ui/core';
import React from 'react';


export const CustomizedSelect = ({ children, form, field }) => {
    const { name, value, variant } = field;
    const { setFieldValue } = form;


    return (
      <Select  style={{fontSize: '0.7em'}}
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
        variant={ variant }
      >
        {children}
      </Select>
    );
};