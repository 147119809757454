import { Checkbox, Grid, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, Slide, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Layout } from '../../components/ui/Layout'
import MUIDataTable from 'mui-datatables';
import { TransitionsModal } from '../../components/ui/TransitionModal';
import { NotificationModal } from '../../components/ui/NotificationModal';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilter, changeFilterDates, setActiveInvoice, setActiveInvoiceToExport, startDownloadCSVInvoices, startDownloadCSVInvoicesWithOutFilter, startHandlingFilter, startLoadSales, startResetPage } from '../../redux/actions/sales';
import { Spinner } from '../../components/ui/Spinner';
import Autocomplete from '@mui/material/Autocomplete';
import { useDebounce } from '../../hooks/useDebounce';
import { useDebounceComplex } from '../../hooks/useDebounceComplex';

import { FACTURADO, NUMROWSTATEINVOICE, PENDIENTEFACTURAR, PENDIENTEPRESUPUESTO, PENDIENTERESERVA, PENDIENTEVOUCHER, ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR } from '../../utils/constants';
import { RangePickerMUI } from '../../components/ui/RangePickerMUI';
import clsx from 'clsx';
import { useTableStyles } from '../../styles/useTableUsersStyles'
import share from '../../images/share.png'
import pdf from '../../images/pdf.png';
import { startLogOutLines } from '../../redux/actions/lines';
import { SnackBar } from '../../components/ui/SnackBar';
import {  getDateDayPlusTwo, getDateMonthMinusOne } from '../../utils/dates';
import Swal from 'sweetalert2';
import { isMatrixEmpty } from '../../utils/utils';
import { navigate } from '@reach/router';
import { startContentDeleteInvoice, startContentPDFDownload } from '../../redux/actions/contentModal';
import { uiManageModalNotification } from '../../redux/actions/ui';
import deleteIcon from '../../images/trash.png';
import { ButtonFloatNoOptions } from '../../components/ui/ButtonFloatNoOptions';

import Alert from '@mui/material/Alert';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
 
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

import esLocale from 'date-fns/locale/es';
import { Form, Formik } from 'formik';

import moment from 'moment-timezone';

moment.tz.setDefault("Europe/Madrid");

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}



const SalesScreen = () => {
    
  const { idRol, moneda: monedaUsuario } = useSelector(state => state.auth)

    const result = [];
    const theme = useTheme();
    const { year, month, day } = getDateDayPlusTwo();
    const { yearY, monthY, dayY } = getDateMonthMinusOne();
    const classes = useTableStyles()
    const dispatch = useDispatch()
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const { menuState } = useSelector(state => state.ui);
    const { invoices: { dateRange, currentFilter: filterOptions, page, data, count, sum, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares }} = useSelector(state => state.invoice);
    const [ searchClientes, debounceSearchResultClientes ] = useDebounceComplex(
                                                                '/clientes/search/', 
                                                                {start: 'Nombre', second:'Apellidos', end: 'Email', id: "PkClientes"},
                                                                );

    const [ searchUsuarios, debounceSearchResultUsuarios ] = useDebounce('/usuarios?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkUsuarios"});

    const [ searchComisionistas, debounceSearchComisionistas ] = useDebounce('/comisionistas?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkComisionistas"});

    const {loading} = useSelector(state => state.ui);
    const { route } = useSelector(state => state.route);
    // const [dateRange, setDateRange] = useState([new Date(yearY, monthY, dayY).toISOString(), new Date( year, month, day ).toISOString()])
    // const [filterOptions, setFilterOptions] = useState([]);
    const [openSnack, setopenSnack] = useState(false);
    const [transition, setTransition] = useState(undefined);
    const [statePosition, setStatePosition] = useState({
      vertical: 'top',
      horizontal: 'center',
    })
   
    useEffect(() => {
      dispatch(startLogOutLines())
    }, [ dispatch ])

    
    

    useEffect(() => {
      //if( isMatrixEmpty(filterOptions) ){
      //  dispatch(startLoadSales( page, dateRange ))
      //}
      //else{
        //console.log('dispatch');
        //console.log(filterOptions);
        //console.log(page);
        dispatch(startHandlingFilter( filterOptions, page, dateRange ))
      //}
    }, []); // [page, dispatch, dateRange, filterOptions]);

    
 
    useEffect(() => {
      if( route ){
        navigate( route )

      }
    }, [route])


    const handleDownload = () => {
      if( !isMatrixEmpty(filterOptions) ){
        dispatch(startDownloadCSVInvoices(filterOptions, page, dateRange))
      }

      else{
          dispatch(startDownloadCSVInvoicesWithOutFilter(dateRange))
      }
        
    }



    const handleEdit = ( dataIndex, e ) => {
      if (e) e.stopPropagation();
      dispatch(setActiveInvoice( data[ dataIndex ].PkVentas))  
    }
    const handleDelete = ( dataIndex, e ) => {
      if (e) e.stopPropagation();
      Promise.resolve(dispatch(setActiveInvoiceToExport( data[ dataIndex ].PkVentas)))
      .then(() => {
        dispatch(startContentDeleteInvoice())
      } )
      .then(() => {  dispatch(uiManageModalNotification()) })


    }

    
    const handleOpenSnackbar = ( dataIndex, Transition, e ) => {
      if (e) e.stopPropagation();
      Promise.resolve( dispatch(setActiveInvoiceToExport( data[ dataIndex ].PkVentas)) )
      .then( () => {
        setTransition(() => Transition);
        setopenSnack( true )
      })
      .catch( () => {
        Swal.fire('Error', 'Está opción no está disponible. Contacte con el administrador', 'error')
      })
    }


    const handleDownloadPDFBudget = ( dataIndex, e ) => {
      if (e) e.stopPropagation();
      Promise.resolve( dispatch(setActiveInvoiceToExport( data[ dataIndex ].PkVentas)) )
      .then( () => {
        dispatch( startContentPDFDownload() )
      })
      .then( () => {
        dispatch(uiManageModalNotification())
      })
      
    }

    

    const columns = [
       { 
         label:"Número",
         name: 'Numero',
         options: {
           filter: true,
           filterList: filterOptions ? filterOptions[0] : [],
           display: !matchesXS,
           sort: false,
           filterType: 'textField',
           /*filterOptions:{
             display: (filterList, onChange, index, column) => {
               return (
                 <FormControl>
                  
                  <TextField
                      fullWidth   
                      label='Número'
                      size='small'
                      variant='outlined'
                      style={{ marginTop: '2rem' }}
                      classes={{ root: classes.inputText }} 
                      onChange={(e, newInputValue) => {
                        console.log('new input');
                        console.log(e);
                        filterList[1][0] = e.target.value;
                        onChange(filterList[1], index, column)
                      }}
                      InputProps={{
                          classes: {
                              input: classes.resize
                          }
                      }}
                      InputLabelProps={
                          {style: {fontSize: '0.8em'}}
                      }
                                               
                  />
                  
                 </FormControl>
               );
             }
           } */
         }
        }, 
        {
          label: 'Cliente',
          name: 'Cliente',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[1] : [],
            display: true,
            sort: false,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      id="combo-box-client"
                      options={ searchClientes }
                      sx={{ width: 300 }}
                      noOptionsText={`No hay clientes`}
                      onInputChange={ debounceSearchResultClientes }
                      onChange={(_, newInputValue) => {
                        filterList[1][0] = newInputValue?.value
                        onChange(filterList[1], index, column)
                      }}
                      renderInput={(params) => <TextField {...params} label="Cliente" />}
                    />
                  </FormControl>
                );
              }
            }
          }

        },
        {
          label: 'Fecha',
          name: 'FechaVenta',
          options: {
            filter: false,
            display: !matchesXS,
            sort: false,
          }
        },
        {
          label: 'Hotel',
          name: 'Hotel',
          options: {
            filter: false,
            display: !matchesXS,
            sort: false,
          }
        }, 
        {
          label: 'Estado Venta',
          name: 'Estado Venta',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[4] : [],
            display: true,
            sort: false,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                const optionValues = [PENDIENTEPRESUPUESTO, PENDIENTERESERVA, PENDIENTEVOUCHER, PENDIENTEFACTURAR, FACTURADO];

                return (
                  <FormControl>
                    <InputLabel htmlFor='select-multiple-chip'>
                    Estado
                  </InputLabel>
                  <Select
                    value={filterList[index]}
                    renderValue={selected => selected.join(', ')}
                    onChange={event => {
                      filterList[3][0] = event.target.value;
                      onChange(filterList[3], index, column);
                      //console.log( filterList )
                    }}
                  >
                    {optionValues.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[3].indexOf(item) > -1}
                        />
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                );
              }
            }
          }

        },
        {
          label: 'Vendedor',
          name: 'Vendedor',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[5] : [],
            display: !matchesXS,
            sort: false,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      id="combo-box-client"
                      options={ searchUsuarios }
                      sx={{ width: 300 }}
                      noOptionsText={`No hay usuarios`}
                      onInputChange={ debounceSearchResultUsuarios }
                      onChange={(event, newInputValue) => {
                        filterList[4][0] = newInputValue?.value
                        onChange(filterList[4], index, column)
                      }}
                      renderInput={(params) => <TextField {...params} label="Vendedor" />}
                    />
                  </FormControl>
                );
              }
            }
          }

        },
        {
          label: 'Importe',
          name: 'Importe',
          options: {
            filter: false,
            display: !matchesXS,
            sort: false,
          }
        },
        {
          label: 'Reserva',
          name: 'Importe',
          options: {
            filter: false,
            display: !matchesXS,
            sort: false,
          }
        },
        {
          label: 'In situ',
          name: 'InSituConTransporteEuros',
          options: {
            filter: false,
            display: !matchesXS,
            sort: false,
          }
        },
        {
        label: 'Margen Revendedor (€)',
        name: 'ReventaEuros',
        options: {
            filter: false,
            display: false, //(!matchesXS && idRol !== ROLCOLABORADOR)
            sort: false,
          }
        }, 
        {
        label: 'Margen Revendedor ($)',
        name: 'ReventaDolares',
        options: {
            filter: false,
            display: false, //(!matchesXS && idRol !== ROLCOLABORADOR)
            sort: false,
          }
        }, 
        {
        label: 'Pago Agencia Realizado',
        name: 'ReservaPagadaAgencia',
        options: {
            filter: false,
            display: false, //(!matchesXS && idRol !== ROLCOLABORADOR)
            sort: false,
          }
        }, 
        {
          label: 'Balance Proveedor',
          name: 'BalanceDolares',
          options: {
            filter: false,
            display: !matchesXS && (idRol === ROLADMIN || idRol === ROLVENDEDOR),
            viewColumns : (idRol === ROLADMIN || idRol === ROLVENDEDOR),
            sort: false,
          }
        },
        {
          label: 'Comisión Vendedor',
          name: 'ImporteComisionVendedor',
          options: {
            filter: false,
            display: false, //!matchesXS && (idRol === ROLADMIN || idRol === ROLREVENDEDOR),
            viewColumns : (idRol === ROLADMIN || idRol === ROLREVENDEDOR),
            sort: false,
          }
        },
        {
          label: 'Comisionista',
          name: 'Comisionista',
          options: {
            filter: (idRol === ROLADMIN || idRol === ROLVENDEDOR),
            viewColumns : (idRol === ROLADMIN || idRol === ROLVENDEDOR),
            filterList: filterOptions ? filterOptions[11] : [],
            display: false,
            sort: false,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      id="combo-box-client"
                      options={ searchComisionistas }
                      sx={{ width: 300 }}
                      noOptionsText={`No hay comisionistas`}
                      onInputChange={ debounceSearchComisionistas }
                      onChange={(_, newInputValue) => {
                        filterList[11][0] = newInputValue?.value
                        onChange(filterList[11], index, column)
                      }}
                      renderInput={(params) => <TextField {...params} label="Comisionista" />}
                    />
                  </FormControl>
                );
              }
            }
          }

        },
        {
          name: "Acciones",
          options: {
            filter: false,
            display: true,
            sort: false,
            customBodyRenderLite: ( dataIndex ) => {
              return (
                <div>
                  <IconButton style={{ color: `#000`, width: '1rem', height: '1rem'}} onClick={ e => handleDelete( dataIndex, e ) }>
                    <img src={`${ deleteIcon }`}  alt='img'/>
                  </IconButton>
                  <IconButton style={{ color: `#000`, width: '1rem', height: '1rem'}} onClick={ e => handleOpenSnackbar(dataIndex, TransitionDown, e) }>
                  <img src={`${ share }`}  alt='img'/>
                  </IconButton>
                  
                  <IconButton style={{ color: `#000`, width: '1rem', height: '1rem'}} onClick={ e => handleDownloadPDFBudget(dataIndex, e) }>
                  <img src={`${ pdf }`}  alt='img'/>
                  </IconButton>
                </div>
              )
            },
            setCellHeaderProps: () => {
              return {
                className: clsx({
                  [classes.NameCell]: true,
                }),
                style:{
                  fontSize: 15,
                  fontWeight: 500
                }
              }
            }
          }
        }
      ];
    const options = {
      filterType: 'custom', //'textField',
      responsive: 'standard',
      filename: 'Ventas',
      rowsPerPage: NUMROWSTATEINVOICE,
      serverSide: true,
      count: count,
      page,
      rowsPerPageOptions: [],
      onTableChange: (action, tableState) => {
        //console.log('tableState');
        //console.log(tableState);

        if (action === "changePage") {
          dispatch(startHandlingFilter(tableState.filterList, tableState.page, dateRange));          
        }
      },
      print: false,
      download: (idRol === ROLADMIN || idRol === ROLVENDEDOR) ? true : false,
      selectableRowsHideCheckboxes: true,
      selectableRowsOnClick: false,
      onFilterChange: (_, filterList) => {
        //setFilterOptions(filterList);
        //dispatch(startResetPage())
        dispatch(changeFilter(filterList, page, dateRange));
      },
      draggableColumns: {
        enabled: true
      },
      textLabels: {
        body: {
          noMatch: loading ? <Spinner /> : "Lo siento, no se han encontrado registros",
        },
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Filas por página",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          viewColumns: "Consultar Columnas",
          filterTable: "Filtrar Tabla",
        },
        filter: {
          all: "Incluir dados de baja",
          title: "Fitros",
          reset: "Reset",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas Tablas",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar filas seleccionadas",
        },
      },
      searchOpen: false,
      search: false,
  
      onRowClick : (rowData, rowMeta) => { 
        handleEdit(rowMeta.dataIndex);
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        if(idRol === ROLADMIN || idRol === ROLVENDEDOR){
          handleDownload();
        }
        return false;
      }
    };
    
    data.map( element => {
      let estado = '';
      if( element.FechaFactura ){
        estado = 'Facturada';
      }
      else{
        estado = !element.PresupuestoEnviado ? 'Pendiente de Enviar' : (!element.ReservaPagada ? 'Pendiente de Pago de Reserva': ( !element.VoucherEnviado ? 'Pendiente de Envío de Voucher' : 'Voucher Enviado'));
      }

      if (idRol!==ROLREVENDEDOR) {
        result.push([element.PkVentas, element.Cliente.Nombre, 
          moment(element.FechaVenta).format('DD/MM/YYYY'), element.HotelCliente, 
          estado, element.Usuario.Nombre, '' + 
          element.Importe + ' €',
          (element.ReservaEuros) + ' €',
          element.InSituConTransporteEuros + ' €',
          element.ReventaEuros + ' €',
          element.ReventaDolares + ' $',
          (element?.Usuario?.EsAgencia) ? (element?.ReservaPagadaAgencia ? 'Sí' : 'Pendiente') : '',
          (idRol === ROLADMIN || idRol === ROLVENDEDOR) ? (element.BalanceDolares + ' $') :'',
          
          (idRol === ROLADMIN || idRol === ROLREVENDEDOR) ? (element.ImporteComisionVendedor + ' $') :'',
          
          (idRol === ROLADMIN || idRol === ROLVENDEDOR) ? element.Comisionista?.Nombre : ''

        ]);
      }
      else {
        if (monedaUsuario === 'USD') {

          result.push([element.PkVentas, element.Cliente.Nombre, 
            moment(element.FechaVenta).format('DD/MM/YYYY'), element.HotelCliente, 
            estado, element.Usuario.Nombre, '' + 
            element.ImporteDolares + ' $',
            (element.ReservaDolares) + ' $',
            (element.InSituDolares+element.SuplementoTransporteDolares) + ' $',
            element.ReventaEuros + ' €',
            element.ReventaDolares + ' $',
            (element?.Usuario?.EsAgencia) ? (element?.ReservaPagadaAgencia ? 'Sí' : 'Pendiente') : '',
            (idRol === ROLADMIN || idRol === ROLVENDEDOR) ? (element.BalanceDolares + ' $') :'',
            
            (idRol === ROLADMIN || idRol === ROLREVENDEDOR) ? (element.ImporteComisionVendedor + ' $') :'',
            
            (idRol === ROLADMIN || idRol === ROLVENDEDOR) ? element.Comisionista?.Nombre : ''

          ]);        }
        else {
          result.push([element.PkVentas, element.Cliente.Nombre, 
            moment(element.FechaVenta).format('DD/MM/YYYY'), element.HotelCliente, 
            estado, element.Usuario.Nombre, '' + 
            element.Importe + ' €',
            (element.ReservaEuros) + ' €',
            element.InSituConTransporteEuros + ' €',
            element.ReventaEuros + ' €',
            element.ReventaDolares + ' $',
            (element?.Usuario?.EsAgencia) ? (element?.ReservaPagadaAgencia ? 'Sí' : 'Pendiente') : '',
            (idRol === ROLADMIN || idRol === ROLVENDEDOR) ? (element.BalanceDolares + ' $') :'',

            (idRol === ROLADMIN || idRol === ROLREVENDEDOR) ? (element.ImporteComisionVendedor + ' €') :'',
            
            (idRol === ROLADMIN || idRol === ROLVENDEDOR) ? element.Comisionista?.Nombre : ''

          ]);
        }
      }
      
      return null;
    })


    

      
    return (
        <Layout>

            <Helmet>
                <title>Ventas | Xplora</title>
            </Helmet>

            <div style={{
                    marginTop: '2rem',
                    display: 'flex',
                    flexDirection: 'column',
                    width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}>
                {
                    !matchesXS
                    &&
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>VENTAS</Typography>
                }

            
            {
              (idRol === ROLADMIN || idRol === ROLVENDEDOR)
              &&
              (
                <>
                  <Alert severity="info">
                    <strong>Total ventas:</strong> { Math.round(sum) }€ 
                    { matchesXS && <br/> }
                    <strong style={{ marginLeft: '0.8rem'}}>Insitu:</strong> { Math.round(sumInSituEuros) }€ 
                    { matchesXS && <br/> }
                    <strong style={{ marginLeft: '0.8rem'}}>Reserva:</strong> { Math.round(100*sumReservaEuros)/100 }€ 
                    { matchesXS && <br/> }
                    <strong style={{ marginLeft: '0.8rem'}}>Descuento:</strong> { Math.round(100*sumDescuentoEuros)/100 }€ 
                      {(idRol ===ROLADMIN) 
                        &&
                        (
                          <>
                            { matchesXS && <br/> }
                            <strong style={{ marginLeft: '0.8rem'}}>Margen Revendedor:</strong> { Math.round(100*sumReventaEuros)/100 }€ 
                            { matchesXS && <br/> }
                            <strong style={{ marginLeft: '0.8rem'}}>Balance con proveedores:</strong> { Math.round(sumBalanceDolares) }$ 
                          </>
                        )
                        }
                  </Alert>
                </>
              )
            }
            


            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
                    <Formik
                      initialValues={{
                        FechaDesde: dateRange[0],
                        FechaHasta:  dateRange[1],
                      }
                    } 
                    >
                       {({ values, setFieldValue }) => (
                        
                      <Form>
                        
            <Grid style={{ marginTop: '0.8rem'}}
            container
            spacing={3}
            
            >
              <Grid item  
                            md={4}
                            xs={12} classes={{root: classes.container }}
                >

                      <DesktopDatePicker
                        name='FechaDesde'
                        keyboard
                        label={`Fecha Desde`}
                        format="dd/MM/yyyy"
                        placeholder="10/10/2018"
                        mask={value =>
                            value
                            ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
                            : []
                        }
                        disableOpenOnEnter
                        onChange={(value) => {
                          
                        let dateselected = moment(value).add(2, 'hours').utcOffset(0);
                        dateselected.set({hour:0,minute:0,second:0,millisecond:0});
                        dateselected.toISOString();
                        dateselected.format(); 

                            setFieldValue(`FechaDesde`, value);
                            //setDateRange([dateselected, dateRange[1]]);
                            dispatch(changeFilterDates(filterOptions, page, [dateselected, dateRange[1]]));

 
                        }}
                        value={ values.FechaDesde }
                        renderInput={(params) => <TextField  {...params} />}
                    />
                    
                </Grid>

                <Grid item  
                            md={4}
                            xs={12} classes={{root: classes.container }}
                >

                      <DesktopDatePicker
                        name='FechaHasta'
                        keyboard
                        label={`Fecha Hasta`}
                        format="dd/MM/yyyy"
                        placeholder="10/10/2018"
                        mask={value =>
                            value
                            ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
                            : []
                        }
                        disableOpenOnEnter
                        onChange={(value) => {
                            
                        let dateselected = moment(value).add(2, 'hours').utcOffset(0);
                        dateselected.set({hour:23,minute:59,second:59,millisecond:0});
                        dateselected.toISOString();
                        dateselected.format(); 

                          setFieldValue(`FechaHasta`, value);
                          //setDateRange([dateRange[0], dateselected]);
                          dispatch(changeFilterDates(filterOptions, page, [dateRange[0], dateselected]));
                        }}
                        value={ values.FechaHasta }
                        renderInput={(params) => <TextField  {...params} />}
                    />
                    
                    </Grid>
                    </Grid>
                     </Form>
                    )}
                    </Formik>
                  </LocalizationProvider>

            
           {// <RangePickerMUI value={ dateRange } setValue={ setDateRange }/>
           }
           <MUIDataTable
              data={result}
              columns={columns}
              options={options}
            />

            <TransitionsModal/>
            <NotificationModal />

              
            <ButtonFloatNoOptions 
                action={ () => navigate('/sales/invoice') }
                />

            <SnackBar
             open={ openSnack } setOpen={setopenSnack } transition={ transition }
            statePosition={ statePosition }
            setStatePosition={ setStatePosition }
             />  

            </div>
            
        </Layout>
    )
}
 
export  default SalesScreen;