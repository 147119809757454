import Swal from 'sweetalert2';
import { url } from '../api/api';
import { loadJWT } from './loadJWT';



export const loadRols = async() => {
    let rols = [];
    const { token } = loadJWT();
    await url.get('/roles', {headers:{
        'Authorization': `Bearer ${ token }`,
    }})
    .then( response => {
        rols = response.data.map( resp => resp);
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
    })
    return rols;

}