import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import { IconButton } from '@material-ui/core';
import { Stack } from '@mui/material';
import { Email, WhatsApp } from '@material-ui/icons';
import { isBrowser, WHATSLINK } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { startContentSendEmail } from '../../redux/actions/contentModal';
import { useCallback } from 'react';
import { handleSendBudget } from '../../redux/actions/sales';


export const SnackBar = ({ open, setOpen, transition, statePosition, setStatePosition }) => {
    const dispatch = useDispatch()
    const { activeEdit: active } = useSelector(state => state.invoice);
    const handleClose = () => {
        setOpen(false);
    };
    const { vertical, horizontal } = statePosition;

    const handleSendWhatsApp = () => {
        setOpen( false )
        if( isBrowser ){
            window.open(WHATSLINK.concat(`${active?.Cliente?.Telefono}`));
        }
        

    }

    const func = useCallback( () => {
        dispatch(handleSendBudget( active?.PkVentas))
    }, [ active?.PkVentas, dispatch ])

    const handleSendEmail = () => {
        setOpen( false )
        dispatch(startContentSendEmail( func ))
    }

    return (
        <>
        <Stack spacing={2} sx={{ maxWidth: 300 }}>
            <Snackbar
                open={open}
                onClose={handleClose}
                TransitionComponent={transition}
                message="Compartir"
                anchorOrigin={{ vertical, horizontal }}
                action={
                    <>
                    {
                        active?.Cliente?.Email &&
                        (
                            <IconButton onClick={ handleSendEmail } color="secondary" aria-label="add to shopping cart">
                                <Email />
                            </IconButton>
                        )
                    }
                    {
                        active?.Cliente?.Telefono &&
                        (
                            <IconButton onClick={ handleSendWhatsApp } color="secondary" aria-label="add to shopping cart">
                                <WhatsApp />
                            </IconButton>
                        )
                    }
                        
                        
                    </>
                  }
                key={transition ? transition.name : ''}
            />
        </Stack>
        </>
    )
}
