import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Slide, TextField } from '@material-ui/core'
import { RemoveCircleOutlined, Save, VpnKey } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import showPassword from '../../../images/show-password.svg';
import hidePassword from '../../../images/hide-password.svg';
import { loadGridChangePassword } from '../../../helpers/loadGrid';
import { useLoginStyles } from '../../../styles/loginStyles';
import { startChangePasswordProfile } from '../../../redux/actions/users';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export const DialogChangePassword = ( { open, setOpenDialog } ) => {
    const classes = useLoginStyles();
    const [visible, setVisible] = useState(false);
    const [visiblePC, setVisiblePC] = useState(false);
    const { nombre } = useSelector(state => state.auth);
    const { data } = loadGridChangePassword();
    const { id: idLoggedIn } = useSelector(state => state.auth);
    const name = nombre.charAt(0).toUpperCase() + nombre.slice(1);
    const dispatch = useDispatch()
    const handleClose = () => {
        setOpenDialog( false )
    }

    
    const validationSchema = yup.object().shape({
        password: yup.string().required("La contraseña es requerida").min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres'),
        passwordConfirm: yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
            [yup.ref("password")],
            "Ambas contraseñas deben ser iguales"
            )
        }).required('La contraseña es requerida').min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres'),
      });

      const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            const user = {
                id: idLoggedIn,
                password: values.password,
                passwordConfirm: values.passwordConfirm,
            }

           dispatch( startChangePasswordProfile( user, setOpenDialog ) );
           
        }
    });
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{`Cambio de Contraseña ${ name }`}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                <Container maxWidth="sm">
                    <form>
                        {
                            data.map( element => {
                                return(
                                    <TextField
                                        fullWidth
                                        label={ element.label }
                                        margin="normal"
                                        name={ element.name }
                                        type={ element.name === 'password' ? (visible ? 'text' : 'password') : ( visiblePC ? 'text' : 'password') }
                                        variant="outlined"
                                        value={formik.values[element.name]}
                                        onBlur={ formik.handleBlur }
                                        onChange={formik.handleChange}
                                        error={formik.touched[element.name] && Boolean(formik.errors[element.name])}
                                        helperText={formik.touched[element.name] && formik.errors[element.name]}
                                        disabled={ formik.isSubmitting }
                                        InputProps={{
                                            startAdornment:(
                                                <InputAdornment position='start'>
                                                    <VpnKey />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                element.name === 'password'
                                                ?
                                                <InputAdornment position='end' onClick={ () => setVisible(!visible)}>
                                                    <img 
                                                        src={ visible ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>
                                                :
                                                <InputAdornment position='end' onClick={ () => setVisiblePC(!visiblePC)}>
                                                    <img 
                                                        src={ visiblePC ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    />
                                )
                            })
                        }
                    </form>
                    </Container>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button 
                    variant='outlined' 
                    startIcon={<RemoveCircleOutlined />}
                    onClick={handleClose}
                    color='secondary'
                        style={{
                            borderRadius: 7
                        }}
                >Cancelar</Button>
                <Button 
                    variant='outlined' 
                    startIcon={<Save />}
                    style={{
                        borderRadius: 7
                    }} 
                    onClick={formik.handleSubmit}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
