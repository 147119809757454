import { navigate } from "@reach/router";
import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadComisionistas } from "../../helpers/loadComisionistas"
import { loadJWT } from "../../helpers/loadJWT";
import { types } from "../types/types";
import { startLogOutContentModal } from "./contentModal";
import { finishLoading, startLoading, uiManageModalCollectionTypes } from "./ui";

export const startNewComisionista = ( comisionista, setSubmitting ) => {
    
    return async(dispatch) => {
        const { token } = loadJWT();
        await url.post('/comisionistas', comisionista, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        } )
        .then( (res) => {
            dispatch(startLoadingComisionistas());
            Swal.fire('Éxito', 'Comisionista añadido', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'El comisionista ya existe', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(uiManageModalCollectionTypes());
            dispatch(startLogOutContentModal())
        })

    }
}


export const startUpdateComisionista = (comisionista, setSubmitting) => {
    return async(dispatch) => {
        const { token } = loadJWT();
            await url.put(`/comisionistas/${ comisionista.PkComisionistas }`, comisionista ,{
                headers:{
                    Authorization: `Bearer ${ token }`
                }
            })
            .then( () => {
                Swal.fire('Éxito', 'Comisionista modificado con éxito', 'success')
                .then(() => {
                    navigate(`/comisionistas`);
                })
                return;
            })
            .catch( e => {
                Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido editar el comisionista', 'error');
                return;
            })
            .finally( () => {
                setSubmitting( false );
            })
        }
        
    
}

export const startDeleteComisionista = ( id ) => {
    return async(dispatch) => {
        const { token } = loadJWT();
            await url.delete(`/comisionistas/${ id }`, {
                headers:{
                    Authorization: `Bearer ${ token }`
                }
            })
            .then(() => {
                dispatch((deleteComisionista(id)));
                Swal.fire('Éxito', 'Comisionista eliminado', 'success');
                return;
            })
            .catch( e => {
                Swal.fire('Error', e?.response?.data?.error?.message || 'Error al eliminar el comisionista', 'error')
                return;
            })
    }
}


export const setActiveComisionista = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/comisionistas/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            if( resp.status === 204){
                navigate('/comisionistas');
                dispatch(finishLoading());
                return;
            }
            dispatch((setActive(resp.data)));
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al cargar el comisionista', 'error')
            .then( () => {
                navigate('/comisionistas');
            })
            .then( () => {
                dispatch(finishLoading());
            })
            return;
        })
    }
    
}

export const setActiveComisionistaState = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/comisionistas/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch((setActive(resp.data)));
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al cargar el comisionista', 'error')
            return;
        })
    }
    
}

export const startLoadingComisionistas = () => {
    return async(dispatch) => {
        dispatch(startLoading());
        const clients = await loadComisionistas();
        dispatch(setComisionistas(clients));
        dispatch(finishLoading())
    }
}

export const startLoadingAllComisionistas = () => {
    return async(dispatch) => {
        const { token } = loadJWT();
        dispatch(startLoading())
        await url.get('/comisionistas', {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( ( resp ) => {
            dispatch(setComisionistas(resp.data));
           
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al cargar los comisionistas', 'error');
        })
        .finally( () => {
            
            dispatch(finishLoading());
        })
        
        
    }
}

export const setActive = ( comisionista ) => ({
    type: types.comisionistaActive,
    payload: comisionista,
})
export const setComisionistas = (comisionistas) => ({
    type: types.comisionistasLoad,
    payload: comisionistas,
})


export const deleteComisionista = (id) =>({
    type: types.comisionistaDelete,
    payload: id
})

const logOut = () => ({
    type: types.comisionistasLogOutCleaning
})

export const startLogOutComisionistas = () => {
    return async( dispatch ) => {
        dispatch( logOut() )
    }
}