import React, { useEffect, useState } from 'react'
import {  
        TextField,
        Box,
        Container,  
        InputAdornment,
        Typography,
        CircularProgress,
        useTheme,
        useMediaQuery
} from '@material-ui/core';
import * as yup from 'yup';
import { AccountCircle, LockRounded } from '@material-ui/icons'
import { Link as LinkMui} from '@material-ui/core'

//Own imports
import { useLoginStyles } from '../../styles/loginStyles';
import showPassword from '../../images/show-password.svg';
import hidePassword from '../../images/hide-password.svg';
import { useDispatch, useSelector } from 'react-redux';
import { startForgotPassword, startLogin } from '../../redux/actions/auth';
import logo from '../../images/logo.png'
import { Helmet } from 'react-helmet';
import { useFormik } from "formik";
import LoadingButton from '@mui/lab/LoadingButton';
import backImage from '../../images/back-login.png';
import { navigate } from '@reach/router';
import moment from 'moment-timezone';

const Login = () => {
    
    const [visible, setVisible] = useState(false);
    const [spinner, setSpinner ] = useState( false );
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useLoginStyles();
    const dispatch = useDispatch(); 
    const { id, cambiaPassword, aceptaPoliticas } = useSelector(state => state.auth)
    useEffect(() => {
        if( id && cambiaPassword && aceptaPoliticas ){
            navigate('/');
        }
        else if( id && !cambiaPassword && aceptaPoliticas ){
            navigate('/auth/change/');
        }
        else if( id && !aceptaPoliticas ){
            navigate('/politicas/');
        }
    }, [ id, aceptaPoliticas, cambiaPassword ]);
    const validationSchema = yup.object({
        email: yup
          .string("El email es requerido")
          .email("El email no es válido")
          .required("El email es requerido"),
        password: yup
          .string("La password es requerida")
          .min(8, "La password debe tener como mínimo una longitud de 8 caracteres")
          .required("La password es requerida")
      });

    
    const handleForgotPassword = () => {
        dispatch(startForgotPassword( formik.values.email, setSpinner ));
    }
    

    const formik = useFormik({
        initialValues: {
            email: '', //'adminxplora@xplorariviera.com',
            password: '', //'testpass123'
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            dispatch(startLogin(values.email, values.password, setSubmitting));
        }
    });
    
    moment.tz.setDefault("Europe/Madrid");

   

    return (
        
        <div
            style={{
                backgroundImage: `url(${ backImage }) `,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                objectFit: 'cover'
                
            }}
            className='body'
        >
            <Helmet>
                <title>Login | Xplora</title>
            </Helmet>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10rem',
                }}
            >
                <Box style={{ 
                        zIndex: 999,
                        }}>
                    <img src={ logo } alt='logo' className={ classes.logo } style={{ marginLeft: '2rem'}}/>
                </Box>
                <Box
                    sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    borderRadius: 7,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '35vh',
                    width: '55vw',
                    justifyContent: 'center',
                    minWidth: 330.4,
                    maxWidth: 700,
                    maxHeight: 400,
                    minHeight: 300,
                    }}

                >
                    <Container maxWidth="sm">
                        <form onSubmit={formik.handleSubmit}>
                            
                            <TextField
                                fullWidth
                                label="Email"
                                margin="normal"
                                name="email"
                                type="email"
                                variant="outlined"
                                value={formik.values.email}
                                onBlur={ formik.handleBlur }
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                disabled={ formik.isSubmitting || spinner }
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                    ),
                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Contraseña"
                                margin="normal"
                                name="password"
                                disabled={ formik.isSubmitting || spinner }
                                type={ visible ? 'text' : 'password' }
                                variant="outlined"
                                value={formik.values.password}
                                onBlur={ formik.handleBlur }
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <LockRounded />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position='end'  onClick={ () => setVisible(!visible)}>
                                            <img 
                                                src={ visible ? showPassword : hidePassword}
                                                alt='eye-password'
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Box sx={{ py: 2 }} style={{ display: 'flex', justifyContent: 'space-between'}}>
                            
                            <Typography
                                color="textSecondary"
                                variant="body1"
                            >
                                {
                                    spinner
                                    ?
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    :
                                        !formik.isSubmitting && 
                                        <LinkMui onClick={ handleForgotPassword } style={{ cursor: 'pointer', textDecoration: 'none', fontWeight: 700, fontSize: !matchesXS ? '15px' : '12px' }}>
                                            Te has olvidado la contraseña?
                                        </LinkMui>
                                }
                            
                            </Typography>
                            
                            <LoadingButton
                                loadingPosition="start"
                                variant="contained"
                                type='submit'
                                disabled={ spinner || formik.isSubmitting }
                                style={{
                                    backgroundColor: '#E6824F',
                                    height: 'auto'
                                }}
                            >
                                Acceder
                            </LoadingButton>
                            
                            </Box>
                        </form>
                    </Container>
                </Box>
            </div>
        </div>
    )
}

export default Login;
