import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import React from 'react'
import { useSelector } from 'react-redux';

export const TotalOrders = (props) => {
    const { cards } = useSelector(state => state.dashboard)
    return (
        <Card {...props}>
            <CardContent sx={{ minHeight: 140 }}>
            <Grid
                container
                spacing={3}
                sx={{ justifyContent: 'space-between' }}
            >
                <Grid item>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="overline"
                    >
                        VENTAS PENDIENTES DE ENVIAR VOUCHER
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="h4"
                    >
                        { cards?.NumeroVentasPendientesEnviarVoucher || 0 }
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar
                        sx={{
                        backgroundColor: 'success.main',
                        height: 56,
                        width: 56
                        }}
                    >
                        <PeopleIcon />
                    </Avatar>
                </Grid>
            </Grid>
           
            </CardContent>
        </Card>
    )
}
