import Swal from 'sweetalert2';
import { url } from '../api/api';
import { loadJWT } from './loadJWT';




export const loadSuppliers = async() => {
    let suppliers = [];
    const { token } = loadJWT();
    await url.get('/proveedores/all?filter={"where":{"FechaBaja": null}}', {headers:{
        'Authorization': `Bearer ${ token }`,
    }})
    .then( response => {
        suppliers = response.data.map( resp => resp);
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
    })
    return suppliers;

}