import React from 'react';

import {DesktopDatePicker} from '@mui/x-date-pickers';
import { TextField, useTheme } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';
import { MobileDatePicker } from '@mui/lab';

import esLocale from 'date-fns/locale/es'

import moment from 'moment-timezone';



export const FormikDatePicker = ({
    name,
    form: { setFieldValue },
    field: { value },
    isSubmitting,
    label,
    textfield
}) => {
    const theme = useTheme()
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    
    //moment.tz.setDefault("Europe/Madrid");
    //moment.locale('es');

    //console.log('value');
    //console.log(value ? moment.utc(value).tz("Europe/Madrid") : value);
    
    //console.log('value');
    //console.log(moment.tz.guess());
    //console.log(moment(value).utcOffset(12));
    //console.log(moment.tz.guess() !== 'Europe/Madrid' ? moment(value).utcOffset(12) : moment(value).tz("Europe/Madrid"));

    //console.log(moment(value).format('YYYY-MM-DD'));
    //console.log(moment(moment(value).format('YYYY-MM-DD')));
    //console.log(moment(moment(value).utcOffset(0).format('YYYY-MM-DD')));

    return (
            !matchesXS

            ?

            (
                <DesktopDatePicker
                    locale = { esLocale }
                    name={name}
                    keyboard
                    variant='outlined'
                    label={`${ label }`}
                    format="dd/MM/yyyy"
                    placeholder="10/10/2018"
                    mask={value =>
                        value
                        ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
                        : []
                    }
                    disableOpenOnEnter
                    onChange={(value) => {
                        //console.log('value');
                        // console.log(value);
                        let dateselected = moment(value).add(2, 'hours').utcOffset(0);
                        dateselected.set({hour:0,minute:0,second:0,millisecond:0});
                        dateselected.toISOString();
                        dateselected.format(); 
                        //dateselected = dateselected.add(2, 'hours');
                        setFieldValue(`${ textfield }`, dateselected);
                    }}
                    disabled={ isSubmitting }
                    value={ value ? moment(moment(value).format('YYYY-MM-DD 16:00:00')) : value } 
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            )
            :
            (
                <MobileDatePicker
                    name={name}
                    keyboard
                    clearable
                    autoOk
                    variant='outlined'
                    label="Fecha Baja"
                    format="dd/MM/yyyy"
                    placeholder="10/10/2018"
                    disabled={ isSubmitting }
                    mask={value =>
                        value
                        ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
                        : []
                    }
                    disableOpenOnEnter
                    onChange={value => {
                        let dateselected = moment(value).add(2, 'hours').utcOffset(0);
                        dateselected.set({hour:0,minute:0,second:0,millisecond:0});
                        dateselected.toISOString();
                        dateselected.format(); 
                        setFieldValue("date", dateselected);
                    }}
                    value={ value ? moment(moment(value).format('YYYY-MM-DD 16:00:00')) : value } 
                    animateYearScrolling={false}
                    renderInput={(params) => <TextField fullWidth {...params} />}

                />
            )
    )
}
