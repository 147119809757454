import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Input, Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  startUploadingFile } from '../../redux/actions/settings'
import { finishError } from '../../redux/actions/ui';

const UploadTerms = ({ openDialog, setOpenDialog }) => {
    const dispatch = useDispatch();
    const uploadForm = useRef()
    const init = ( e ) => {
        e.preventDefault();
        const value = uploadForm.current;
        dispatch( startUploadingFile( value, setOpenDialog ) );
    }
    const { error } = useSelector(state => state.ui)

    const handleClose = () => {
        setOpenDialog( false );
    }

    useEffect(() => {
        dispatch(finishError())
    }, [openDialog, dispatch])

    
    return (

        <>
            <Dialog open={openDialog} onClose={handleClose}>
                <Typography style={{ padding: '1rem'}} variant='h2'>Términos y Condiciones de Xplora</Typography>
                <DialogContent>
                <DialogContentText>
                    <Typography variant='body1'>Formulario para subir el archivo de términos y condiciones de Xplora Riviera</Typography>
                    { error && <Typography style={{ color: '#E5824F'}}>{ error }</Typography>}
                </DialogContentText>
                <form ref={ uploadForm }>
                    <Input accept="pdf/*" id="files" name='files' multiple type="file" />
                </form>
                </DialogContent>
                <DialogActions>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >

                        <Button
                            variant="contained"
                            type='submit'
                            style={{
                                backgroundColor: '#E5824F',
                                color: 'white'
            
                            }}
                            onClick={ init }
                            
                        >
                                Subir
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default UploadTerms;