import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeleteServiceReseller } from '../../redux/actions/supplierServices';
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';

export const ConfirmDeleteServicesReseller = () => {
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { active: activeService } = useSelector(state => state.service) //we get the users from the state 
    const { active: activeuser } = useSelector(state => state.user)
    
    const handleSuccess = () => {
        dispatch(uiManageModalNotification());
        //console.log( activeuser.PkUsuarios, activeService.PkServiciosProveedores)
        dispatch(startDeleteServiceReseller(activeuser.PkUsuarios, activeService.FkServiciosProveedores)); //We dispatch the action passing as a parameter the user id
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ `¿Quieres borrar el servicio?` }
                text={ `El servicio del revendedor quedará eliminado definitivamente del sistema` }
                action={ handleSuccess }
            />
    )
}
