import { Box, Button, Card, Grid, InputAdornment, Typography, TextField, useMediaQuery, useTheme } from '@material-ui/core'
import { LoadingButton } from '@mui/lab';
import { Form, Formik, validateYupSchema } from 'formik';
import React, {  useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loadProfile } from '../../../helpers/loadGrid'
import { startUpdateProfile } from '../../../redux/actions/users';
import { useFormStyles } from '../../../styles/formStyles';
import { validationSchemaProfile } from '../../../utils/schemaValidationForm';
import { DialogChangePassword } from './DialogChangePassword';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {  ROLCOLABORADOR, ROLREVENDEDOR } from '../../../utils/constants';
import { AgencyResellers } from '../../users/resellers/agencySellers';
import { ButtonFloatAgencia } from '../ButtonFloatAgencia';



export const AccountProfileDetail = () => {
    
    const dispatch = useDispatch();
    const classes = useFormStyles();
    const theme = useTheme();
    const matchesXS = useMediaQuery( theme.breakpoints.down('xs'));

    
    const { active } = useSelector(state => state.user)
    const [open, setOpenDialog] = useState(false)

    const { data } = loadProfile(active?.EsAgencia, active?.FkUsuarios_Agencia);

    const initialValues =  {
        name: active?.Nombre,
        surname: active?.Apellidos,
        email: active?.Email,
        phone: active?.Telefono,
        
        Telefono2: active?.Telefono2,
        RazonSocial: active?.RazonSocial,
        DomicilioFiscal: active?.DomicilioFiscal,
        DomicilioFiscalLinea2: active?.DomicilioFiscalLinea2,
        TextoPiePresupuesto: active?.TextoPiePresupuesto,
        TextoTransferencias: active?.TextoTransferencias,

    }


    const handleSubmit = ( values, {setSubmitting} ) => {
        const user = {
            PkUsuarios: active.PkUsuarios,
            Email: values.email,
            Nombre: values.name,
            Apellidos: values.surname,
            Telefono: values.telefono,
            VersionFila: active.VersionFila,

            Telefono2: values.Telefono2,
            RazonSocial: values.RazonSocial,
            DomicilioFiscal: values.DomicilioFiscal,
            DomicilioFiscalLinea2: values.DomicilioFiscalLinea2,
            TextoPiePresupuesto: values.TextoPiePresupuesto,
            TextoTransferencias: values.TextoTransferencias
        }
        dispatch(startUpdateProfile(user, setSubmitting));
        
       

    }

    const handleOpenDialog = () => {
        setOpenDialog( true );
    }


    return (
        <>
        <Formik enableReinitialize={ true } initialValues={initialValues} validationSchema={ validationSchemaProfile }   onSubmit={handleSubmit}>
        {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid, setFieldValue }) => (
        <Form
            autoComplete='off'
        >
        <Card
            style={{
                width: !matchesXS ? '45rem' : '90vw',
                height: (active?.EsAgencia) ? '100rem' : '27rem',
                backgroundColor: '#FAF9F9',
                padding: '2rem'
            }}
        >
                {
                    data.map( element => {
                        return(
                        <Grid
                        item
                        style={{
                            marginBottom: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                        >
                            <TextField
                                label={ element.label }
                                name={ element.name }
                                value={ values[element.name] }
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            { element.startAdornment }
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                error={touched[element.name] && Boolean(errors[element.name])}
                                helperText={touched[element.name] && errors[element.name]}
                                disabled={ isSubmitting }
                                
                            />
                        </Grid>
               
            
                        )
                    })
                }


{ ( active?.Rol?.PkRoles === ROLREVENDEDOR && active?.EsAgencia && ( !active?.FkUsuarios_Agencia))
                    &&
                    (
                    <>
                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                            marginBottom: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                                  
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h3"
                        >
                            Texto pie presupuesto
                        </Typography>
                            
                        <CKEditor                
                            config = {{
                                toolbar: {
                                    items: [ 'bold', 'italic', '|', 'undo', 'redo', '-', 'numberedList', 'bulletedList' ],
                            
                                    shouldNotGroupWhenFull: true
                                }
                            }
                            }
                            editor={ ClassicEditor }
                            data={ values.TextoPiePresupuesto }
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                
                            } }
                            onChange={ ( _, editor ) => {
                                const data = editor.getData();
                                setFieldValue('TextoPiePresupuesto', data);
                            } }
                            
                        />

 
            
                    </Grid>



                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                            marginBottom: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                                        
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h3"
                        >
                            Texto transferencias presupuesto
                        </Typography>  
                        <CKEditor                   
                            config = {{
                                toolbar: {
                                    items: [ 'bold', 'italic', '|', 'undo', 'redo', '-', 'numberedList', 'bulletedList' ],
                            
                                    shouldNotGroupWhenFull: true
                                }
                            }
                            }
                            editor={ ClassicEditor }
                            data={ values.TextoTransferencias }
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                
                            } }
                            onChange={ ( _, editor ) => {
                                const data = editor.getData();
                                setFieldValue('TextoTransferencias', data);
                            } }
                            
                        />

 
            
                    </Grid>







                    </>
)}





            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    position: 'relative',

                }}
            >
            <Button
                color="info"
                variant="contained"
                style={{
                    marginRight: '0.5rem',
                    backgroundColor: '#404040',
                    color: '#fff',
                }}
                onClick={ handleOpenDialog }
            >
                Cambiar Contraseña
            </Button>

            <LoadingButton
                loadingPosition="start"
                variant="contained"
                type='submit'
                loading={ isSubmitting }
                disabled={ !isValid }
                style={{
                    backgroundColor: '#E5824F',

                }}
            >
                    Actualizar
            </LoadingButton>
            </Box>
        </Card>
        </Form>
        )}
        </Formik>

        <DialogChangePassword open={ open } setOpenDialog={ setOpenDialog }/>
        </>
    )
}
