import Swal from "sweetalert2";
import { types } from "../types/types"
import { uiManageModalNotification } from "./ui";

export const startContentModalAddUser = () => {
    return async(dispatch) => {
        dispatch(addUser());
    }
}


export const startContentModalEditUser = () => {
    return async(dispatch) => {
        dispatch(editUser());
    }
}

export const startContentModalDetailUser = () => {
    return async(dispatch) => {
        dispatch(detailUser());
    }
}


export const startDeleteUser = () => {
    return async(dispatch) => {
        dispatch(deleteUser());
    }
}



//Users
const addUser = () =>({
    type: types.userAddContentModal
})

const editUser = () =>({
    type: types.userEditContentModal
})

const detailUser = () => ({
    type: types.userDetailContentModal
})

const deleteUser = () => ({
    type: types.uiNotificationDeleteModal
})

//Suppliers

export const startDeleteSupplier = () => {
    return async(dispatch) => {
        dispatch(deleteSupplier());
    }
}


export const startContentModalAddProvider = () => {
    return async(dispatch) => {
        dispatch(addSupplier());
    }
}

export const startContentModalEditProvider = () => {
    return async(dispatch) => {
        dispatch(editSupplier());
    }
}

export const startContentModalDetailProvider = () => {
    return async(dispatch) => {
        dispatch(detailSupplier());
    }
}


const addSupplier = () => ({
    type: types.supplierAddContentModal
})

const deleteSupplier = () => ({
    type: types.uiNotificationDeleteModalSupplier
})

const editSupplier = () =>({
    type: types.supplierEditContentModal
})

const detailSupplier = () => ({
    type: types.supplierDetailContentModal
})

// Comisionistas

export const startContentModalAddComisionista = (simple) => {
    return async(dispatch) => {
        dispatch(addComisionista(simple));
    }
}





export const startDeleteComisionista = () => {
    return async(dispatch) => {
        dispatch(deleteComisionista());
    }
}


const addComisionista = (simple) => ({
    type: types.comisionistaAddContentModal,
    payload: simple
})






const deleteComisionista = () => ({
    type: types.uiNotificationDeleteModalComisionista
})




//Clients

export const startContentModalAddClient = (simple) => {
    return async(dispatch) => {
        dispatch(addClient(simple));
    }
}





export const startDeleteClient = () => {
    return async(dispatch) => {
        dispatch(deleteClient());
    }
}


const addClient = (simple) => ({
    type: types.clientAddContentModal,
    payload: simple
})






const deleteClient = () => ({
    type: types.uiNotificationDeleteModalClient
})


//Exhange Rate


export const startContentModalAddExchangeRate = () => {
    return async( dispatch ) => {
        dispatch( addExchangeRate() );
    }
}

export const startDeleteExchangeRate = () => {
    return async( dispatch ) => {
        dispatch( deleteExchangeRate() );
    }
}



const addExchangeRate = () => ({
    type: types.exchangeRateAddContentModal
})

const deleteExchangeRate = () => ({
    type: types.uiNotificationDeleteExchangeRate,
})





//Settings

export const startContentModalSettings = () => {
    return async( dispatch ) => {
        dispatch( loadSettings() );
    }
}

const loadSettings = () => ({
    type: types.settingsEditContentModal
})

export const startContentModalDetailVoucher = ( props ) => {
    return async(dispatch) => {
        dispatch(detailVoucher( props ));
    }
}

const detailVoucher = ( props ) => ({
    type: types.settingsEditVoucher,
    payload: props
})


//Supplier Services 

export const startContentModalAddSupplierService = () => {
    return async( dispatch ) => {
        dispatch( addSupplierService() );
    }
}



export const startDeleteSupplierService = () => {
    return async( dispatch ) => {
        dispatch( deleteSupplierService() )
    }
}

export const startDeleteResellerService = () => {
    return async( dispatch ) => {
        dispatch( deleteResellerService() )
    }
}

export const startDeleteVendedorAgencia = () => {
    return async( dispatch ) => {
        dispatch( deleteVendedorAgencia() )
    }
}


export const startEditService = () => {
    return async( dispatch ) => {
        dispatch( editSupplierService() )
    }
}


const addSupplierService = () => ({
    type: types.serviceSupplierAddContentModal
})

const editSupplierService = () => ({
    type: types.serviceSupplierEditContentModal
})



const deleteSupplierService = () => ({
    type: types.serviceSupplierDeleteContentModal,
})


const deleteResellerService = () => ({
    type: types.serviceResellerDeleteContentModal
})

const deleteVendedorAgencia = () => ({
    type: types.vendedorAgenciaDeleteContentModal
})


export const startContentModalEditVendedorAgencia = () => {
    return async( dispatch ) => {
        dispatch( editVendedorAgencia() );
    }
}

const editVendedorAgencia = () => ({
    type: types.vendedorAgenciaEditContentModal
})


//Reseller Services


export const startContentModalAddResellerService = () => {
    return async( dispatch ) => {
        dispatch( addResellerService() );
    }
}

export const startContentModalEditResellerService = () => {
    return async( dispatch ) => {
        dispatch( editResellerService() );
    }
}


const addResellerService = () => ({
    type: types.serviceResellerAddContentModal
})

const editResellerService = () => ({
    type: types.serviceResellerEditContentModal
})



// VENDEDORES DE AGENCIAS
export const startContentModalAddAgencySeller = () => {
    return async( dispatch ) => {
        dispatch( addAgencySeller() );
    }
}

const addAgencySeller = () => ({
    type: types.agencySellerAddContentModal
})




//Coordinator Suppliers

export const startContentModalAddSupplierToCoordinator = () => {
    return async( dispatch ) => {
        dispatch( addSupplierToCoordinator() )
    }
}

const addSupplierToCoordinator = () => ({
    type: types.supplierCoordinatorAddContentModal
})

export const startDeleteSupplierToCoordinator = () => {
    return async( dispatch ) => {
        dispatch(deleteSupplierCoordinator())
    }
}

const deleteSupplierCoordinator = () => ({
    type: types.supplierCoordinatorDeleteContentModal
})

export const startEditSupplierToCoordinator = () => {
    return async( dispatch ) => {
        dispatch(editSupplierToCoordinator());
    }
}

const editSupplierToCoordinator = () => ({
    type: types.supplierCoordinatorEditContentModal
})


//Order Lines
export const startAddInvoice = ( payload, setSubmitting ) => {
    return async(dispatch) => {
        dispatch(addInvoice( payload, setSubmitting ));
    }
}

export const startContentModalAddLineToOrder = () => {
    return async( dispatch ) => {
        dispatch(addLineToOrder());
    }
}

export const startContentModalAddFreeLineToOrder = () => {
    return async( dispatch ) => {
        dispatch(freeAddLineToOrder());
    }
}

export const startContentDeleteOrderLineFromInvoice = () => {
    return async( dispatch ) => {
        dispatch(deleteOrderLine())
    }
}

export const startContentModalEditOrderLine = () => {
    return async( dispatch ) => {
        dispatch(editOrderLine())
    }
}

export const startContentEditOrderLineState = () => {
    return async( dispatch ) => {
        dispatch( editLineState() )
    }
}

export const startContentEditFreeLineFromState = () => {
    return async( dispatch ) => { 
        dispatch( editFreeLineState() )
    }
}

const editFreeLineState = () => ({
    type: types.orderFreeLineStateContentModal
})
export const startContentSendEmail = ( action ) => {
    return async( dispatch ) => {
        Promise.resolve( dispatch(sendEmailModal( action )) )
        .then( () => {
            dispatch(uiManageModalNotification())
        })
        .catch( () => {
            Promise.resolve(uiManageModalNotification())
            .then( () => {
                dispatch(startLogOutContentModal())
            })
            .then( () => {
                Swal.fire('Error', 'El recurso solicitado no está disponible', 'error')
            })
        })
        
    }
}

export const startContentSendEmailVoucher = ( action ) => {
    return async( dispatch ) => {
        Promise.resolve( dispatch(sendEmailModalVoucher( action )) )
        .then( () => {
            dispatch(uiManageModalNotification())
        })
        .catch( () => {
            Promise.resolve(uiManageModalNotification())
            .then( () => {
                dispatch(startLogOutContentModal())
            })
            .then( () => {
                Swal.fire('Error', 'El recurso solicitado no está disponible', 'error')
            })
        })
        
    }
}

export const startContentPDFDownload =() => {	
    return async( dispatch ) => {
        dispatch( downloadPDF() )
    }
}

export const startContentModalVoucher = () => {
    return async( dispatch ) => {
        dispatch( downloadVoucher() )
    }
}

export const startContentDeleteInvoice = () => {
    return async( dispatch ) => {
        dispatch( deleteInvoice() )
    }
}


const deleteInvoice = () => ({
    type: types.invoiceDeleteContentModal
})

const downloadPDF = () => ({
    type: types.pdfContentInvoice
})

const downloadVoucher = () => ({
    type: types.lineContentGetVoucher
})
const editOrderLine = () => ({
    type: types.lineDetailContentModal
})

const deleteOrderLine = () => ({
    type: types.uiNotificationDeleteLineFromInvoice
})


const addLineToOrder = () => ({
    type: types.orderLineAddContentModal
})

const freeAddLineToOrder = () => ({
    type: types.freeOrderLineAddContentModal
})

const addInvoice = ( payload, setSubmitting ) => ({
    type: types.invoiceAddContentModalConfirm,
    payload: payload,
    setSubmitting: setSubmitting
})

const sendEmailModal = ( payload ) => ({
    type: types.uiNotificationSendEmailModal,
    payload: payload 
})

const sendEmailModalVoucher = ( payload ) => ({
    type: types.uiNotificationSendEmailModalVoucher,
    payload: payload 
})

const editLineState = () => ({
    type: types.orderLineEditStateContentModal
})
export const startLogOutContentModal = () => ({
    type: types.logOutContentModal
})



export const startContentModalEditLineReseller = () => {
    return async( dispatch ) => {
        dispatch( editLineReseller() )
    }
}

const editLineReseller = () => ({
    type: types.orderLineEditResellerContentModal
})


export const startContentFacturar = () => {
    return async( dispatch ) => {
        dispatch( facturar() )
    }
}

const facturar = () => ({
    type: types.uiFacturarContentModal
})