
import { navigate } from "@reach/router";
import axios from "axios";
import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadJWT } from "../../helpers/loadJWT";
import { loadLines } from "../../helpers/loadLines"
import { buildFilterEmptyLines, buildFilterLine } from "../../utils/buildFilterLine";
import { SERVICE } from "../../utils/constants";
import { types } from "../types/types";
import { startLogOutContentModal } from "./contentModal";
import { startChangeRoute, startLogOutRedirect } from "./routeManagement";
import { setActiveInvoice, setActiveInvoiceToExport } from "./sales";
import { finishLoading, startLoading, uiManageModalCollectionTypes } from "./ui";

export const startNewOrderLine = ( payload ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        
        delete payload.SuplementoTransporteEuros;
        delete payload.TasaEuros;
        delete payload.ReventaLineaDolares;
        delete payload.ReservaLineaDolares;
        delete payload.InSituLineaEuros;

        await url.post('/lineas-ventas', payload, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {  
            dispatch(setActiveInvoice(payload.FkVentas)) 
            Swal.fire('Success', 'Línea de Venta Añadida', 'success') 
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se ha podido añadir las líneas. Contacte con el administrador', 'error')
        })
        .finally( () => {
            dispatch(uiManageModalCollectionTypes())
            dispatch(startLogOutContentModal())
        })
    }
}


export const startLoadingLines = ( page, dateRange ) => {
    return async( dispatch, getState ) => {
        const { idRol: rol, id: loggedInUser} = getState().auth;
        dispatch(startLoading())
        const [lines, total, sumTotalAux, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares] = await loadLines( page, rol, loggedInUser, dateRange );
        Promise.resolve('OK')
        .then( () => {
            dispatch(setLines(lines, page))
            dispatch(setNumberOfItems( total ))
            dispatch(sumLines(sumTotalAux, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares))
        })
        .then( () => {
            dispatch(finishLoading())
        })
    }
}

export const changeFilter = (filterList, page, dateRange) => {
    return async(dispatch) => {
        console.log('CHANGE FILTER LINES');
        //localStorage.setItem('currenFilter', JSON.stringify(filter ?? []));
        await dispatch(changeFilterAction(filterList));
        dispatch(startHandlingLinesFilter (filterList, 0, dateRange));
    }
}

const changeFilterAction = ( filter ) => ({
    type: types.changeLinesFilter,
    payload: filter
});



const changeFilterDateAction = ( dateRange ) => ({
    type: types.changeLinesFilterDates,
    payload: dateRange
});

export const changeFilterDates = (filterList, page, dateRange) => {
    return async(dispatch) => {
        //localStorage.setItem('currenFilter', JSON.stringify(filter ?? []));
        await dispatch(changeFilterDateAction(dateRange));
        dispatch(startHandlingLinesFilter (filterList, 0, dateRange));
    }
}

export const startHandlingLinesFilter = ( filterList, page, dateRange ) => {
    return async( dispatch) => {
        const { token } = loadJWT();
        dispatch(startLoading());

        //await dispatch(changeFilterAction(filterList));

        console.log('filterlist startHandlingLinesFilter');
        console.log(filterList);
        const [query, queryCount, sumTotal] = buildFilterLine( filterList, page, dateRange )
        const linesQuery = url.get(`${ query }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        const countLinesQuery = url.get(`${ queryCount }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })

        const sumLinesQuery = url.get(`${ sumTotal }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        await axios.all([linesQuery, countLinesQuery, sumLinesQuery]).then(([res1, res2, res3]) => {
            dispatch(setLines(res1.data, page))
            dispatch(setNumberOfItems(res2.data.count)) 
            dispatch(sumLines(res3.data.sum, res3.data.sumInSituEuros, res3.data.sumReservaEuros, res3.data.sumDescuentoEuros, res3.data.sumReventaEuros, res3.data.sumBalanceDolares)) 
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se han podido cargar las líneas. Contacte con el administrador', 'error')
        })
        .finally( () => {
            dispatch(finishLoading());
        })
    }
}


export const startExportLines = ( filterList, page, dateRange ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        dispatch(startLoading());
        const query = buildFilterLine( filterList, page, dateRange )
        await url.get(`${ query[3] }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            },
            responseType: 'blob'
        })
        .then( res => {
            let reader = new FileReader()
            reader.readAsDataURL(res.data)
            reader.onload = (e) => {
            let a = document.createElement('a')
            a.download = `lineas_ventas.csv`
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            }
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se han podido cargar las líneas de venta', 'error')
                
        })

    }
}


export const startDownloadCSVLinesWithOutFilter = ( dateRange ) => {
    return async( dispatch ) => {
        const [ csvQuery ] = buildFilterEmptyLines(dateRange);
        const { token } = loadJWT();
        await url.get(`${ csvQuery }`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            },
            responseType: 'blob'
        })
        .then( res => {
                let reader = new FileReader()
                reader.readAsDataURL(res.data)
                reader.onload = (e) => {
                let a = document.createElement('a')
                a.download = `lineas_ventas.csv`
                a.href = e.target.result
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                }
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se han podido cargar las ventas', 'error')
                
        })
    }
}


export const setActiveLineFromInvoice = ( line ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        const info = { headers:{ Authorization: `Bearer ${ token }` }};
        const req1 = url.get(`/lineas-ventas/${ line.PkLineasVenta }?filter={"include": [{"relation": "TipoLineaVenta"}, {"relation": "Cliente"}, {"relation": "ServicioProveedor"},{"relation": "TipoCambioProveedor"},{"relation": "Proveedor"}]}`, info);
        const req2 = url.get(`/proveedores/${line.FkProveedores}/servicios-proveedores?filter={"where": {"FechaBaja": {"eq": null}}}`, info)
        const req3 = url.get(`/proveedores?filter={"where": {"FechaBaja": {"eq": null}}}`, info);
        line?.FkTiposServicio === SERVICE
        ?
        await axios.all( [ req1, req2, req3 ] ).then(([res1, res2, res3]) => {
            dispatch(setActive([res1.data, res2.data, res3.data]))
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        })

        :

        await axios.all( [ req1, req3 ] ).then(([res1, res3]) => {
            dispatch(setActive([res1.data, [], res3.data]))
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        })

    }
}

export const startGettingLine = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        dispatch(startLoading());
        await url.get(`/lineas-ventas/${ id }?filter={"include": [{"relation": "TipoLineaVenta"}, {"relation": "Cliente"}, {"relation": "ServicioProveedor"}, {"relation": "TipoCambioProveedor"}, {"relation": "Proveedor"}, {"relation": "Venta"}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            if( resp.status === 204){
                dispatch(finishLoading())
                dispatch(startChangeRoute('lines'))
                return;
            }
            dispatch(setActiveFromList( resp.data ));
            dispatch(startChangeRoute(`lines/${ resp.data.PkLineasVenta }`))
            navigate(`/lines/${ resp.data.PkLineasVenta }`)
            dispatch(finishLoading());
        })
        .catch( () => {
            navigate('/lines')
            Swal.fire('Error', 'No se ha podido cargar la línea. Contacte con el administrador', 'error')
        })
        .finally( () => {
            //dispatch(startResetLinesPage())
            dispatch(startLogOutRedirect())
        })
    }
}

export const setActiveGettingLine = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.get(`/lineas-ventas/${ id }?filter={"include": [{"relation": "TipoLineaVenta"}, {"relation": "Cliente"}, {"relation": "ServicioProveedor"},{"relation": "TipoCambioProveedor"},{"relation": "Proveedor"}]}`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActiveFromList( resp.data ));
        })
        .catch( () => {
            Swal.fire('Error', 'No se ha podido cargar la línea. Contacte con el administrador', 'error')
        })
        .finally( () => {
            dispatch(startLogOutRedirect())
            dispatch(startLogOutContentModal())
        })
    }
}

export const setActiveFreeLineFromInvoice = ( line ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        const info = { headers:{ Authorization: `Bearer ${ token }` }};
        const req1 = url.get(`/lineas-ventas/${ line.PkLineasVenta }?filter={"include": [{"relation": "TipoLineaVenta"},{"relation": "ServicioProveedor"},{"relation": "TipoCambioProveedor"},{"relation": "Proveedor"}]}`, info);
        const req3 = url.get(`/proveedores?filter={"where": {"FechaBaja": {"eq": null}}}`, info);
        
        await axios.all( [ req1, req3 ] ).then(([res1, res3]) => {
            dispatch(setActive([res1.data, [], res3.data]))
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        })
    }
}

export const updateServicesOnSupplier = ( supplier ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        const info = { headers:{ Authorization: `Bearer ${ token }` }};
        await url.get(`/proveedores/${supplier}/servicios-proveedores?filter={"where": {"FechaBaja": {"eq": null}}}`, info)
        .then( resp => {
            dispatch(chargeCurrentLine(resp.data))
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        })

    }
}

export const startDeleteLineFromInvoice = ( id ) => {
    return async( dispatch ) => {
        const { token } = loadJWT();
        await url.delete(`/lineas-ventas/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            dispatch(deleteLine( id ))
            Swal.fire('Exito', 'Línea eliminada', 'success');
            return;
        })
        .catch( e => {
            console.log(e)
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        })
        .finally( () => {
            dispatch(startLogOutContentModal())
        })
    }
}


export const startCheckDetailSupplierFromLine = ( id ) => {
    return async( dispatch ) => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes()) )
        .then( () => {
            dispatch(startChangeRoute(`suppliers/${id}`))
        })
       
    }
}

export const startConfirmLine = ( payload, setSubmitting ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal;
        await url.put(`/lineas-ventas/${ payload.PkLineasVenta }`, payload , {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            console.log('payload');
            console.log(payload);
                Swal.fire('Éxito', 'Línea Confirmada', 'success')
                .then( () => {
                    if( !modal ){
                        if (!payload.VoucherEnviado) 
                            dispatch(startSendingVoucherEmail(payload.PkLineasVenta));
                        navigate('/lines');
                    }
                    else{
                        if (!payload.VoucherEnviado) 
                            dispatch(startSendingVoucherEmail(payload.PkLineasVenta));
                        const venta = getState().invoice.activeEdit.PkVentas
                        dispatch(setActiveInvoiceToExport(venta));
                    }
                })
                
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        })
        .finally( () => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes());
            }
            dispatch(startLogOutContentModal());
            setSubmitting( false )
        })
    }
}

export const startUpdateConfirmLine = ( payload ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal;
        await url.put(`/lineas-ventas/${ payload.PkLineasVenta }`, payload , {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
                Swal.fire('Éxito', 'Estado de Voucher enviado modificado', 'success')
                .then( () => {
                    if( !modal ){
                        navigate('/lines');
                    }
                    else{
                        const venta = getState().invoice.activeEdit.PkVentas
                        dispatch(setActiveInvoiceToExport(venta));
                    }
                })
                
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        })
        .finally( () => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes());
            }
            dispatch(startLogOutContentModal());
        })
    }
}

export const startLogOutLines = () => {
    return async( dispatch ) => {
        dispatch(logOutLines());
    }
}


export const startChangingDataRelatedWithSettledLine = ( service ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal;
        await url.get(`/servicios-proveedores/${ service }?filter={"include": [{"relation": "ProveedorServicio", "scope": {"include": [{"relation": "TiposCambioProveedor"}]}}]}` , {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            const tipoServicio = resp.data.FkTiposServicio;
            let element = {};
            let supplier = {
                ...resp.data.ProveedorServicio,
                TiposCambioProveedor: null
            }
            let servicio = {
                ...resp.data,
                ProveedorServicio: null
            }
            let resExchanges = resp.data.ProveedorServicio.TiposCambioProveedor.filter(e => e.FechaBaja===null)

            resExchanges.map( e => {
                if( e.FkTiposServicio === tipoServicio ){
                    element = {
                        ...e
                    }
                }
                return null;
            })
            dispatch(changeDataPricesSettledLine([resp.data.CosteDolares, resp.data.ReservaEuros, element, supplier, servicio, resp.data.Nombre, 0, resp.data.TasaDolares]))
        })
        .catch( e => {
            if( modal ){
                Promise.resolve(dispatch(uiManageModalCollectionTypes()))
                .then( () => {
                    dispatch(startLogOutContentModal())
                })
                .then( () => {
                    Swal.fire('Error', 'No se ha podido cargar el servicio solicitado', 'error');
                    return;
                })
            }
            
        })
    }
}

export const startChangingDataRelatedWithSettledLineReseller = ( service, user ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal;
        const data1 = url.get(`/servicios-proveedores/${ service }?filter={"include": [{"relation": "ProveedorServicio", "scope": {"include": [{"relation": "TiposCambioProveedor"}]}}]}` , {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })

        const data2 = url.get(`/servicios-revendedores?filter={"where": {"FkServiciosProveedores": ${ service }, "FkUsuarios": ${ user } }}`,{
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        await axios.all([data1, data2]).then(([res1, res2]) => {
            const tipoServicio = res1.data.FkTiposServicio;
            let element = {};
            let supplier = {
                ...res1.data.ProveedorServicio,
                TiposCambioProveedor: null
            }
            let servicio = {
                ...res1.data,
                ProveedorServicio: null
            }
            let resExchanges = res1.data.ProveedorServicio.TiposCambioProveedor.filter(e => e.FechaBaja===null)

            resExchanges.map( e => {
                if( e.FkTiposServicio === tipoServicio ){
                    element = {
                        ...e
                    }
                }
                return null;
            })
            dispatch(changeDataPricesSettledLine([res1.data.CosteDolares, res1.data.ReservaEuros, element, supplier, servicio, res1.data.Nombre, res2.data[0].ImporteReventaEuros, res1.data.TasaDolares]))
        })
        .catch( () => {
            if( modal ){
                Promise.resolve(dispatch(uiManageModalCollectionTypes()))
                .then( () => {
                    dispatch(startLogOutContentModal())
                })
                .then( () => {
                    Swal.fire('Error', 'No se ha podido cargar los precios. Inténtelo de nuevo más tarde.', 'error');
                    return;
                })
            }
            
        })
    }
}


export const startUpdateLineActive = ( line ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        const { modal } = getState().modal;

        delete line.SuplementoTransporteEuros;
        delete line.TasaEuros;
        delete line.ReventaLineaDolares;
        delete line.ReservaLineaDolares;
        delete line.SuplementoTransporteEuros;

        await url.put(`/lineas-ventas/${ line?.PkLineasVenta }` , line, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            dispatch(setActiveInvoice(line.FkVentas))
        })
        .then( () => {
            Swal.fire('Éxito', 'Línea de venta actualizada correctamente', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'No se ha podido actualizar la línea', 'error');
            return;
        })
        .finally( () => {
            if( modal ){
                dispatch(uiManageModalCollectionTypes())
                dispatch(startLogOutContentModal())
            }
        })
    }
}


export const startSendingVoucherEmail = (idLinea ) => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        await url.post(`/sendEmailVoucher/${ idLinea }`, {} ,{ headers:{ Authorization: `Bearer ${ token }` }})
        .then( ( resp ) => {
            Swal.fire('Exito', 'El email con el voucher ha sido enviado', 'success').then( () => {
                //Actualizar el campo "EnvioVoucher" para que pueda
            })
        })
        .catch( e => {
            console.log('startSendingVoucherEmail');
            console.log(e);
            
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error en el envío del email. Contacte con el administrador', 'error')
            .then( () => {
                //dispatch(setActiveInvoice( idVenta ))
            })
        })
    }
}

export const startSendingVoucherEmailFromLine = () => {
    return async( dispatch, getState ) => {
        const { token } = loadJWT();
        dispatch(startLoading())
        const idLinea = getState().lines.active.activeLine.PkLineasVenta;
        await url.post(`/sendEmailVoucher/${ idLinea }`, {} ,{ headers:{ Authorization: `Bearer ${ token }` }})
        .then( ( resp ) => {
            Swal.fire('Exito', 'El email con el voucher ha sido enviado', 'success')
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error en el envío del email. Contacte con el administrador', 'error')
        })
        .finally(() => {
            dispatch(finishLoading())
        })
    }
}


export const startLogOutActiveLineEdit = () => {
    return async( dispatch ) => {
        dispatch(logOutLineActive())
    }
}


const logOutLines = () => ({
    type: types.linesLogOutCleaning
})

const logOutLineActive = () => ({
    type: types.lineActiveEditLogOut
})

const deleteLine = ( id ) => ({
    type: types.lineDeleteFromInvoice,
    payload: id
})
const setLines = ( lines, page ) => ({
    type: types.linesLoad,
    payload: lines,
    page: page
})


const setActive = ( data ) => ({
    type: types.lineActive,
    payload: data
})

const setActiveFromList = ( data ) => ({
    type: types.lineCurrentActive,
    payload: data
})

const chargeCurrentLine = ( payload ) => ({
    type: types.lineActiveUpdatePrices,
    payload: payload
})


const changeDataPricesSettledLine = ( payload ) => ({
    type: types.lineChangeDataPricesAndLine,
    payload: payload
})

export const setNumberOfItems = ( count ) => ({
    type: types.getNumberOfLines,
    payload: count
})

export const startResetLinesPage = () => {
    return async( dispatch ) => {
        dispatch(resetLines())
    }
}

const resetLines = () => ({
    type: types.resetPageLine
})


const sumLines = ( sum, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares ) => ({
    type: types.lineSumTotal,
    payload: {
        sum: sum,
        sumInSituEuros: sumInSituEuros,
        sumReservaEuros: sumReservaEuros,
        sumDescuentoEuros: sumDescuentoEuros,
        sumReventaEuros: sumReventaEuros,
        sumBalanceDolares: sumBalanceDolares
      }
})