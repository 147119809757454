import { isBrowser } from "../../utils/constants";
import { types } from "../types/types";

const initialState = {
    //menuState: isBrowser ? ((window.screen.width>768) ? true : false) : true,
    menuState: isBrowser ? false : true,
    loading: false,
    loadingModal: false,
    error: null,
    success: null
}

export const uiReducer = (state=initialState, action) => {

    switch (action.type) {
        case types.uiManageModal:
            
            return {
                ...state,
                menuState: !state.menuState
            }
        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }
        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }
        case types.uiStartError:
            return {
                ...state,
                success: null,
                error: action.payload
            }
        case types.uiFinishError:
            return {
                ...state,
                error: null
            }
        case types.uiLogout:
            return {
                ...state,
                loading: false,
                error: null,
                success: null
            }
        case types.uiStartSuccess:
            return {
                ...state,
                error: null,
                success: action.payload
            }
        case types.uiFinishSuccess:
            return {
                ...state,
                success: null
            }
        case types.uiModalContentStartLoading:
            return {
                ...state,
                loadingModal: true
            }
        case types.uiModalContentFinishLoading:
            return {
                ...state,
                loadingModal: false
            }
        default:
            return state;
    }
}