import React, { useEffect } from 'react'
import { Layout } from '../components/ui/Layout'
import { Helmet } from 'react-helmet';
import { Box, Grid } from '@material-ui/core';
import { AccountProfile } from '../components/ui/account/AccountProfile';
import { AccountProfileDetail } from '../components/ui/account/AccountProfileDetail';
import { Spinner } from '../components/ui/Spinner'
import { useDispatch, useSelector } from 'react-redux';
import { setActiveUserState } from '../redux/actions/users';

const Me = () => {
    const { loading } = useSelector(state => state.ui)
    const dispatch = useDispatch()
    const { id } = useSelector(state => state.auth)
    useEffect(() => {
        dispatch(setActiveUserState( id ))
    }, [ dispatch, id ]);

    return (
        <Layout>
            <Helmet>
                <title>Perfil</title>
            </Helmet>
            {
                !loading
                ?
                <Box
                    sx={{
                        backgroundColor: 'background.default',
                        minHeight: '100%',
                        py: 3
                    }}
                >
                    <Grid
                    container
                    spacing={3}
                    md={8}
                    >
                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <AccountProfile />
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        md={6}
                        xs={12}
                    >
                        <AccountProfileDetail />
                    </Grid>
                    </Grid>
                </Box>
            :
            <Spinner />

            }
            
        </Layout>
    )
}


export default Me;