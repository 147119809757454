//User Roles

export const ROLADMIN = 1;
export const ROLVENDEDOR = 2;
export const ROLCOLABORADOR = 4;
export const ROLREVENDEDOR = 3;


//Invoices


export const SERVICE = 1;
export const CUSTOMSERVICE = 2;
export const NUMROWSTATEINVOICE = 10;
export const NUMROWINVOICESTATEORDERLINE = 10;
//Invoices State
//['Pendiente Enviar Presupuesto', 'Pendiente de Reserva', 'Pendiente de Voucher', 'Pendiente Facturar', 'Facturado'];

export const PENDIENTEPRESUPUESTO = 'Pendiente Enviar Presupuesto';
export const PENDIENTERESERVA = 'Pendiente de Reserva';
export const PENDIENTEVOUCHER = 'Pendiente de Voucher';
export const PENDIENTEFACTURAR = 'Pendiente Facturar';
export const FACTURADO = 'Facturado';
export const PENDIENTEPROVEEDOR = 'Pendiente de Proveedor';
export const LINEACONFIRMADA = 'Líneas Confirmadas';


//WhatsApp

export const WHATSLINK = 'https://wa.me/';
export const TEXTMESSAGE_WHATSAPP=`Hola, Aquí tienes el voucher con los datos de tu reserva. Desde Xplora te deseamos un fantástico viaje. Esperamos que disfrutes de la experiencia`


//Gatsby
export const isBrowser =  typeof window !== "undefined"
export const baseURLIcon = 'https://flagcdn.com'