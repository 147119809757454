import { FormControl, InputLabel, Select, MenuItem, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Euro, MonetizationOn, RemoveRedEyeRounded } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import {  Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import {  useSelector, useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks/useDebounce';
import { useFormStyles } from '../../../styles/formStyles';
import { startAddServiceReseller } from '../../../redux/actions/supplierServices';
import { Box } from '@mui/system';
import { startLoadingServicesSupplier, supplierLogout } from '../../../redux/actions/suppliers';
import * as yup from 'yup'
import { useParams } from '@reach/router';
import { setActiverateResellerAndLoadExchangeRates } from '../../../redux/actions/actions';
import { obtenerPrecioEuros } from '../../../utils/exchange';
import { loadCurrencies } from '../../../helpers/loadCurrencies';
import { calcularImporteReservaServicioRevendedor } from '../../../utils/utils';
import { calculateFromDollarsToEuro, calculateFromEuroToDollars } from '../../../utils/utils';


export const ContentAddService = () => {
    const { active } = useSelector(state => state.user);
     

    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch();
    const [ search, debounceSearchResult ] = useDebounce('/proveedores?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkProveedores"});
    const [proveedorValue, setProveedorValue] = useState(0);
    const { services } = useSelector(state => state.supplier);
    const { active: activeService } = useSelector(state => state.service)
    const servicesAutoComplete = [];
    const { active: activeRate } = useSelector(state => state.exchangeRate)
    const { modal } = useSelector(state => state.modal);
    const params = useParams();
    const refService = useRef();
    const currencies = loadCurrencies();
    

    useEffect(() => {
        if( proveedorValue && modal){
            dispatch(startLoadingServicesSupplier( proveedorValue ));
        }
    }, [proveedorValue, dispatch, modal])

    useEffect(() => {
        if( !proveedorValue && params.PkUsuarios){
            dispatch( supplierLogout() );
        }
    }, [modal, dispatch, proveedorValue, params.PkUsuarios])

    

    const initialValues = {
        servicio: '',
        //importeReventa: 0,
        observaciones: '',
        proveedor: '',
        moneda: active.Moneda,
        precioVenta: 0,
        importeReventa: 0,
    };
    
    const validationSchemaResellerService = () => {
        return yup.object({
            servicio: yup.number("El servicio es requerido").required('El servicio es requerido'),
            //importeReventa: yup.number().required('Importe reventa es requerido').positive('No se aceptan números negativos'),
            proveedor: yup.number().required('Proveedor es requerido'),
            precioVenta: yup.number().required('Precio venta es obligatorio'),
            moneda: yup.string().required('Moneda es obligatorio'),
        })
    }

    const submit = (values, {setSubmitting}) => {
        const instance = {
            "FkUsuarios": active.PkUsuarios,
            "FkServiciosProveedores": values.servicio,
            //"ImporteReventaEuros": +values.importeReventa,
            "Observaciones": values.observaciones,
            "PrecioVenta": +values.precioVenta,
            "ImporteReventa": +values.importeReventa,
            "Moneda": values.moneda
        }

        dispatch(startAddServiceReseller( instance, setSubmitting));
    }

    
    services.map( service => {
        servicesAutoComplete.push({ label: service.Nombre, value: service.PkServiciosProveedores});
        return null;
    })

    return (
        <Formik validationSchema={ validationSchemaResellerService } initialValues={initialValues} onSubmit={submit}>
            {({ handleChange, errors, values, setFieldValue, handleBlur, isSubmitting, touched, isValid }) => (
            <Form>
            <Grid item style={{ padding: '2rem'}}>
                <Grid container direction='column'>
                    
                    <Grid item classes={{root: classes.container }}>
                        <Typography variant='h1'>Agregar Servicio a { active?.Nombre }</Typography>
                    </Grid>

                    <Autocomplete
                        name='proveedor'
                        autoHighlight
                        fullWidth
                        disablePortal
                        id="proveedor-autocomplete-exchange-rate"
                        noOptionsText={"No hay proveedores"}
                        renderInput={(params) => ( 
                            <TextField 
                                {...params}
                                name='proveedor' 
                                variant='outlined' 
                                label="Proveedor" 
                                placeholder='Busca...'
                                disabled={ isSubmitting }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                error={touched.proveedor && Boolean(errors.proveedor)}
                                helperText={touched.proveedor && errors.proveedor}
                                

                            />)}
                        onInputChange={ debounceSearchResult }
                        sx={{ marginBottom: '2rem'}}
                        options={ search }
                        onChange={(_, value) => {
                            setFieldValue(
                            "proveedor",
                            value?.value !== null ? value?.value : values.proveedor
                            );
                            setProveedorValue(value?.value)
                            if(!value?.value ){
                                refService.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                            }
                        }}
                        
                    />

                    <Autocomplete
                        name='servicio'
                        autoHighlight
                        fullWidth
                        ref={refService}
                        disablePortal
                        id="servicio-autocomplete-exchange-rate"
                        noOptionsText={"No hay servicios"}
                        renderInput={(params) => ( 
                            <TextField 
                                {...params}
                                name='servicio' 
                                variant='outlined' 
                                label="Servicio" 
                                placeholder='Busca...'
                                disabled={ isSubmitting }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                error={touched.servicio && Boolean(errors.servicio)}
                                helperText={touched.servicio && errors.servicio}
                                

                            />)}
                        sx={{ marginBottom: '2rem'}}
                        options={ servicesAutoComplete }
                        onChange={(e, value) => {
                            setFieldValue(
                            "servicio",
                            value?.value !== null ? value?.value : values.servicio
                            );

                            /*
                            let service = services.filter( service => service.PkServiciosProveedores===value?.value);
                            setFieldValue('precioVenta',0);
                             if (service.length>0) { 
                                if (values.moneda==='EUR') {
                                    setFieldValue('precioVenta', 
                                        service[0].PrecioVentaEuros
                                    );
                                }
                                else {
                                    setFieldValue('precioVenta', 
                                    +calculateFromEuroToDollars(activeRate, service[0].PrecioVentaEuros)
                                    );
                                }
                            }
                            */
                            
                            if( value?.value ){
                                dispatch(setActiverateResellerAndLoadExchangeRates(value?.value, values.proveedor));
                            }
                        }}
                        
                    />

                    
                        {
                            values.servicio && values.proveedor && activeService && activeRate 
                            &&
                            (
                                <Grid item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-costedolares`}  
                                        label='Coste Dólares'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MonetizationOn />
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        value={ activeService?.CosteDolares }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.importeReventa && Boolean(errors.importeReventa)}
                                        helperText={touched.importeReventa && errors.importeReventa}
                                        disabled
                                        
                                    />
                                </Grid>
                            )
                        }

                        
                        {
                            values.servicio && values.proveedor && activeService && activeRate
                            &&
                            (
                                <Grid item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-importeReventa`}  
                                        label='Reserva Xplora'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { (values.moneda==='EUR') ? <Euro /> : <MonetizationOn /> }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        value={ activeService ? +calcularImporteReservaServicioRevendedor(
                                            activeRate,
                                            activeService?.CosteDolares,
                                            values.precioVenta,
                                            values.importeReventa??0,
                                            values.moneda) : 0 }
                                        onChange={ handleChange }
                                        onBlur={ handleBlur }
                                        error={touched.importeReventa && Boolean(errors.importeReventa)}
                                        name='importeReventa'
                                        disabled
                                    />
                                </Grid>
                            )
                        }

          
                                <Grid md={4} xs={12} item classes={{root: classes.container }}>
                                    <FormControl fullWidth>
                                        <InputLabel style={{fontSize: '0.7em'}}  id="demo-simple-select-label">Moneda</InputLabel>
                                        <Select style={{fontSize: '0.8em'}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            //defaultValue={c[0]?.code}
                                            label="Moneda"
                                            name="moneda"
                                            onChange={ ( e ) => {
                                                setFieldValue('moneda', e.target.value);
                                                //handleChange(e);
                                            }}
                                            variant="outlined"
                                            value={ values.moneda }
                                        >
                                            {
                                                currencies.map( aux => {
                                                    return (
                                                        <MenuItem selected={true} style={{fontSize: '0.7em'}}  value={aux.code}>{ aux.label }</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {
                                    (!active.Comisiona || active.MargenConfiguradoEnServicios) &&
                                    (
                                <Grid item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name='importeReventa'                                        
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-importeReventa`}  
                                        label='Margen'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { (values.moneda==='EUR') ? <Euro /> : <MonetizationOn /> }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={ values.importeReventa }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.importeReventa && Boolean(errors.importeReventa)}
                                        helperText={touched.importeReventa && errors.importeReventa}
                                        disabled={ isSubmitting }
                                        
                                    />
                                </Grid>

                                    )
                                }

                                <Grid item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name='precioVenta'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-precioVenta`}  
                                        label='Precio Venta'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { (values.moneda==='EUR') ? <Euro /> : <MonetizationOn /> }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={ values.precioVenta }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.precioVenta && Boolean(errors.precioVenta)}
                                        helperText={touched.precioVenta && errors.precioVenta}
                                        disabled={ isSubmitting }
                                        
                                    />
                                </Grid>



                        <Grid item  classes={{root: classes.container }}>
                            <TextField
                                fullWidth
                                name='observaciones'
                                size='small'
                                classes={{ root: classes.inputText }}
                                id={`outlined-basic-observaciones`}  
                                label='Observaciones'
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <RemoveRedEyeRounded />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                value={ values.observaciones }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                disabled={ isSubmitting }
                                multiline
                                maxRows={ 10 }
                                minRows={ 5 }
                                
                            />
                        </Grid>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >

                        <LoadingButton
                           loadingPosition="start"
                           variant="contained"
                           type='submit'
                           loading={ isSubmitting }
                           disabled={ !isValid || isSubmitting }
                           style={{
                               backgroundColor: '#E5824F',
           
                           }}
                        >
                                Agregar
                        </LoadingButton>

                        </Box>

                </Grid>
            </Grid>
            </Form>
            )}
            </Formik>
    )
}
