
import React from 'react'
import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { loadGridExchangeRate } from '../../helpers/loadGrid';
import { useFormStyles } from '../../styles/formStyles';
import { validationSchemaExchangeRate } from '../../utils/schemaValidationForm';
import Autocomplete from '@mui/material/Autocomplete';
import { useDebounce } from '../../hooks/useDebounce';
import { CustomizedSelect } from '../ui/CustomizedSelect';
import { startNewExchangeRate } from '../../redux/actions/exchangeRate';
import { Box } from '@mui/system';
import { startLogOutContentModal } from "../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../redux/actions/ui";


export const ContentModalExchangeRateAdd = () => {
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions 
    const { data } = loadGridExchangeRate();
    const [ search, debounceSearchResult ] = useDebounce('/proveedores?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkProveedores"});
    const { active } = useSelector(state => state.supplier);

    
    const initialValues = {
        tipo_servicio: '',
        proveedor: active ? active.PkProveedores : '',
        cambioEuros: '',
        cambioDolares: '',
    };


    const submit = (values, {setSubmitting}) => {
        const instance = {
            "FkProveedores": values.proveedor,
            "FkTiposServicio": values.tipo_servicio,
            "CambioEuros": +values.cambioEuros,
            "CambioDolares": +values.cambioDolares
        }
        dispatch( startNewExchangeRate(instance, setSubmitting) );
    }


    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };
    
    
    
    return (
        <Formik validationSchema={ validationSchemaExchangeRate } initialValues={initialValues} onSubmit={submit}>
            {({ handleChange, errors, values, setFieldValue, handleBlur, isSubmitting, touched, isValid }) => (
            <Form>
            <Grid item style={{ padding: '2rem'}}>
                <Grid container direction='column'>
                    
                    <Grid item classes={{root: classes.container }}>
                        <Typography variant='h2'>Agregar Tipo de Cambio</Typography>
                    </Grid>

                    <FormControl
                        style={{
                            marginBottom: '2rem'
                        }}
                        disabled={ isSubmitting }
                        onBlur={ handleBlur }
                        onChange={handleChange}
                        error={touched.tipo_servicio && Boolean(errors.tipo_servicio)}
                        helperText={touched.tipo_servicio && errors.tipo_servicio}
                                            
                    >
                        <InputLabel style={{fontSize: '0.8em'}} id="select-label-exchange-rates">Tipo Servicio</InputLabel>
                        <Field 
                            name="tipo_servicio" 
                            component={CustomizedSelect}
                            >
                            <MenuItem style={{fontSize: '0.7em'}} value={2}>Parque</MenuItem>
                            <MenuItem style={{fontSize: '0.7em'}} value={1}>Tour</MenuItem>
                        </Field>
                    </FormControl>

                    {
                        !active 
                        &&
                        <Autocomplete style={{fontSize: '0.8em'}}
                            name='proveedor'
                            autoHighlight
                            fullWidth
                            disablePortal
                            id="proveedor-autocomplete-exchange-rate"
                            renderInput={(params) => ( 
                                <TextField 
                                    {...params}
                                    name='proveedor' 
                                    variant='outlined' 
                                    label="Proveedor" 
                                    placeholder='Busca...'
                                    disabled={ isSubmitting }
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.proveedor && Boolean(errors.proveedor)}
                                    helperText={touched.proveedor && errors.proveedor}
                                    

                                />)}
                            onInputChange={ debounceSearchResult }
                            sx={{ marginBottom: '2rem'}}
                            options={ search }
                            onChange={(e, value) => {
                                setFieldValue(
                                "proveedor",
                                value?.value !== null ? value?.value : initialValues.proveedor
                                );
                            }}
                            
                        />
                    }
                    

                    {
                            data.map( (element, i) => {
                                return(
                                    <Grid item  classes={{root: classes.container }}>
                                        <TextField
                                            key={ i }
                                            fullWidth
                                            name={element.name}
                                            size='small'
                                            classes={{ root: classes.inputText }}
                                            id={`outlined-basic-${element.name}`}  
                                            label={ element.label }
                                            variant='outlined'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        { element.startAdornment }
                                                    </InputAdornment>
                                                ),

                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                            InputLabelProps={
                                                {style: {fontSize: '0.8em'}}
                                            }
                                            value={values[element.formik]}
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched[element.formik] && Boolean(errors[element.formik])}
                                            helperText={touched[element.formik] && errors[element.formik]}
                                            disabled={ isSubmitting }
                                            multiline={ element.name === 'observaciones' ? true : false}
                                            maxRows={ element.name === 'observaciones' ? 10 : undefined }
                                            minRows={ element.name === 'observaciones' ? 5 : undefined }
                                        />
                                    </Grid>
                                )
                            })
                        }
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >

                        <Button 
                            style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                            onClick={ handleCancel }>
                            <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                        </Button>

                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            type='submit'
                            loading={ isSubmitting }
                            disabled={ !isValid }
                            style={{
                                backgroundColor: '#E5824F',
            
                            }}
                        >
                                Agregar
                        </LoadingButton>

                        </Box>



                </Grid>
            </Grid>
            </Form>
            )}
            </Formik>
    )
}
