import { Grid, InputAdornment, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'
import Autocomplete from '@mui/material/Autocomplete';

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormStyles } from '../../../styles/formStyles';
import { validationSchemaOrder } from '../../../utils/schemaValidationForm'
import { FormikDatePicker } from '../../ui/FormikDatePicker';
import { startUpdateInvoiceFromInvoice } from '../../../redux/actions/sales';
import esLocale from 'date-fns/locale/es'
import { RemoveRedEye } from '@material-ui/icons';
import { Box } from '@mui/material';
import { loadLanguages } from '../../../helpers/loadLangauges';
import { navigate } from '@reach/router';
import { loadCurrencies } from '../../../helpers/loadCurrencies';
import { ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR, SERVICE } from '../../../utils/constants';



export const Info = () => {
    const classes = useFormStyles();
    const { activeEdit } = useSelector(state => state.invoice);
    const { idRol, id: loggedInUser } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const languages = loadLanguages();
    const currencies = loadCurrencies();
    const l = languages.filter( e => e.code === activeEdit?.Idioma)
    const c = currencies.filter( e => e.code === activeEdit?.Moneda)
    const { comisionistas } = useSelector(state => state.comisionistas);

    const comisionistasArray = comisionistas.map ((client) => { return { label: `${client.Nombre} `, value: client.PkComisionistas }});
    
    //console.log( l )
    const initialValues = {
        "FechaVenta": activeEdit?.FechaVenta,
        "HotelCliente": activeEdit?.HotelCliente,
        "PorcDescuento": activeEdit?.PorcDescuento,
        "Observaciones":  activeEdit?.Observaciones,
        "FechaLimitePresupuesto": activeEdit?.FechaLimitePresupuesto,
        "FechaPagoReserva": activeEdit?.FechaPagoReserva,
        "FechaFactura": activeEdit?.FechaFactura,
        "PresupuestoEnviado": activeEdit?.PresupuestoEnviado,
        "ReservaPagada": activeEdit?.ReservaPagada,
        "ImporteFactura": +activeEdit?.ImporteFactura,
        "VersionFila": activeEdit?.VersionFila,
        "PkVentas": activeEdit?.PkVentas,
        "Idioma": activeEdit?.Idioma,
        "Moneda": activeEdit?.Moneda,
        "FkComisionistas": activeEdit?.FkComisionistas,
    }


    const handleSubmit = (values, {setSubmitting}) => {
        const payload = {
            ...values,
            HotelCliente: !values.HotelCliente ? "" : values.HotelCliente,
            FechaLimitePresupuesto: !values.FechaLimitePresupuesto ? null : values.FechaLimitePresupuesto,
            Observaciones: !values.Observaciones ? "" : values.Observaciones,
            FkComisionistas: !values.FkComisionistas?null:values.FkComisionistas
        }
        console.log(' payload ');
        console.log( payload );
        dispatch( startUpdateInvoiceFromInvoice(activeEdit.PkVentas, payload, setSubmitting) )
    }


    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={ validationSchemaOrder }
            onSubmit={ handleSubmit }
        >
        {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid, setFieldValue }) => (
        <Form> 
            <Grid container spacing={3} >

                <Grid  md={4} xs={12} item  >
                    <TextField 
                        fullWidth   
                        label='Hotel cliente'
                        name='HotelCliente'
                        size='small'
                        variant='outlined'
                        value={ values?.HotelCliente   }
                        classes={{ root: classes.inputText }}
                        InputProps={{
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        onBlur={ handleBlur }
                        onChange={handleChange}
                        error={touched.HotelCliente && Boolean(errors.HotelCliente)}
                        helperText={touched.HotelCliente && errors.HotelCliente}
                        disabled = { isSubmitting || activeEdit?.FechaFactura}
                        inputProps={{ maxLength: 45 }}
                    />
                </Grid>


                <Grid md={5} xs={12} item >
                    <TextField 
                        fullWidth   
                        label='Vendedor'
                        name='Usuario.Nombre'
                        size='small'
                        variant='outlined'
                        classes={{ root: classes.inputText }}
                        value={ `${activeEdit?.Usuario?.Nombre} ${activeEdit?.Usuario?.Apellidos}` }
                        InputProps={{
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        onBlur={ handleBlur }
                        onChange={handleChange}
                        disabled={ true }
                    />
                </Grid>
                


                <Grid md={3} xs={12} item    >
                            <Field 
                                component={FormikDatePicker} 
                                textfield='FechaLimitePresupuesto' 
                                name='FechaLimitePresupuesto' 
                                label="Fecha validez presupuesto" 
                                isSubmitting={ isSubmitting } 
                                value={ values?.FechaLimitePresupuesto   } 
                            />
                    </Grid>

                <Grid md={4} xs={12} item>
                    <FormControl fullWidth>
                        <InputLabel style={{fontSize: '0.7em'}} id="demo-simple-select-label">Idioma</InputLabel>
                        <Select style={{fontSize: '0.8em'}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={l[0]?.code}
                            label="Idioma"
                            onChange={ (e) => {
                                setFieldValue('Idioma', e.target.value)
                            }
                            }
                            variant="outlined"
                            value={values.Idioma}
                        >
                            {
                                languages.map( l => {
                                    return (
                                        <MenuItem value={l.code}>{ l.label }</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>

                
                <Grid md={4} xs={12} item>
                <FormControl fullWidth>
                        <InputLabel style={{fontSize: '0.7em'}}  id="demo-simple-select-label">Moneda</InputLabel>
                        <Select style={{fontSize: '0.8em'}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={c[0]?.code}
                            label="Moneda"
                            onChange={ ( e ) => {
                                setFieldValue('Moneda', e.target.value)
                            }}
                            variant="outlined"
                            value={ values?.Moneda }
                        >
                            {
                                currencies.map( aux => {
                                    return (
                                        <MenuItem value={aux.code}>{ aux.label }</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>

                {(idRol === ROLADMIN || idRol === ROLVENDEDOR) &&

                <Grid md={4} xs={12} item >
                            <Autocomplete style={{fontSize: '0.8em'}}
                                    name='FkComisionistas'
                                    defaultValue={activeEdit?.Comisionista ? {label: activeEdit?.Comisionista?.Nombre, value: activeEdit?.FkComisionistas } : null }
                                    classes={{ root: classes.inputText }}
                                    size='small'
                                    autoHighlight
                                    fullWidth
                                    disablePortal
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    noOptionsText={ `No hay comisionistas` }
                                    id="client-autocomplete"
                                    renderInput={(params) => ( 
                                        <TextField 
                                            {...params}
                                            name='FkComisionistas' 
                                            size='small'
                                            variant='outlined' 
                                            label="Seleccionar comisionista" 
                                            placeholder='Busca...'
                                            disabled={ isSubmitting }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched.Comisionista && Boolean(errors.Comisionista)}
                                            helperText={touched.Comisionista && errors.Comisionista}

                                        />)}
                                    //onInputChange={ idRol === ROLADMIN ? debounceSearchResultClientes : debounceSearchResultClientesNotAdmin}
                                    sx={{ marginBottom: '0.2rem'}}
                                    options= { comisionistasArray } //{ idRol === ROLADMIN ? searchClientes : searchClientesNotAdmin }                                
                                    onChange={(_, value) => {                                    
                                        setFieldValue(
                                        "FkComisionistas",
                                        value?.value !== null ? value?.value : initialValues.FkComisionistas
                                        );

                                    }}                    
                            />
                            
                        </Grid>
                        
            }


                <Grid  xs={12} item  classes={{root: classes.container }}>
                    <TextField 
                        fullWidth   
                        label='Observaciones'
                        size='small'
                        name='Observaciones'
                        value={ values?.Observaciones   }
                        variant='outlined'
                        classes={{ root: classes.inputText }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <RemoveRedEye />
                                </InputAdornment>
                            ),

                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        onBlur={ handleBlur }
                        onChange={handleChange}
                        error={touched.Observaciones && Boolean(errors.Observaciones)}
                        helperText={touched.Observaciones && errors.Observaciones}
                        disabled={ isSubmitting || activeEdit?.FechaFactura}
                        multiline
                        maxRows={ 10 }
                        minRows={ 5 }
                    />
                </Grid>
                
                
             </Grid>
            


            

            <Grid container spacing={3} >

            <Grid item md={9} xs={1} >
                </Grid>

            </Grid>


           
                    <>

                <Box
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >

                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                            marginTop: '1rem',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                         <Button
                            style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                            onClick={ () => navigate('/sales') }>
                            <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                        </Button>
                        {
                        !activeEdit?.FechaFactura
                        &&
                        (
                        <LoadingButton
                            variant="contained"
                            type='submit'
                            style={{
                                backgroundColor: '#E5824F',
                            }}
                            disabled={ isSubmitting || !isValid }

                        >
                            Guardar Cambios
                        </LoadingButton>
                         )
                        }
                    </Grid>
                </Box>

                </>
               
            
            
            </Form>
        )}
        </Formik>
        </LocalizationProvider>

        
        </>        
    )
}
