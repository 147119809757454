import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { Form, Formik } from 'formik';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormStyles } from '../../../styles/formStyles';
import { useRef } from 'react';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { CallMergeOutlined, EuroOutlined, Info, MonetizationOn, People, RemoveRedEye } from '@material-ui/icons';
import { InputAdornment } from '@mui/material';
import { startLoadingServicesResellerFromEdit } from '../../../redux/actions/sales';
import { startChangingDataRelatedWithSettledLineReseller, startUpdateLineActive } from '../../../redux/actions/lines';
import { calcularImporteVentaDolares, calcularImporteVentaEuros, calcularInSituVentaRevendedorDolares, calcularInSituVentaRevendedorEuros, calculateFromDollarsToEuro, calculateFromEuroToDollars } from '../../../utils/utils';
import { startLogOutContentModal } from "../../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../../redux/actions/ui";
import { ROLADMIN } from '../../../utils/constants';
import { LocalizationProvider }  from'@mui/x-date-pickers'
import { FormikDatePicker } from '../../ui/FormikDatePicker';
import esLocale from 'date-fns/locale/es'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field } from 'formik';
import { actionSetCurrentUser } from '../../../redux/actions/actions';



export const ContentModalEditOrderLineReseller = () => {
    const { active : currentUser } = useSelector(state => state.user);
    const classes = useFormStyles();
    const refService = useRef();
    const { idAgencia, idRol, id: loggedInUser, moneda: monedaUsuario, margenConfiguradoEnServicios } = useSelector(state => state.auth)
    const [conceptoLine, setConceptoLine] = useState('');
    const [valueService, setValueService] = useState();
    const [tasaLine, SetTasaLine] = useState(0);    

    const { active, active: { services: servicesState } } = useSelector(state => state.lines);
    const { activeEdit } = useSelector(state => state.invoice)
    const dispatch = useDispatch();
    const servicesAutoComplete = [];

    useEffect(() => {  
        SetTasaLine(active.activeLine.TasaDolares);
    }, [ ]);

    useEffect(() => {        
        dispatch(actionSetCurrentUser(loggedInUser))
    }, [ dispatch, loggedInUser ]);

    useEffect(() => {
        dispatch(startLoadingServicesResellerFromEdit((idAgencia>0)?idAgencia:active.activeLine.UsuarioAlta));
    }, [dispatch, active.activeLine.UsuarioAlta])

    useEffect( () => {
        if( valueService ){
            dispatch(startChangingDataRelatedWithSettledLineReseller( valueService, (idAgencia>0)?idAgencia:activeEdit.FkUsuarios ))
        }
    }, [ activeEdit.FkUsuarios, dispatch ])

    const initialValuesReSeller = {
        FkProveedores: active.activeLine.Proveedor.PkProveedores,
        FkServiciosProveedores: active.activeLine.ServicioProveedor.PkServiciosProveedores,
        Concepto: conceptoLine || active.activeLine.Concepto,
        InSituLineaDolares: active.activeLine.InSituLineaDolares,
        ReservaLineaEuros: active.activeLine.ReservaLineaEuros,
        ReventaLineaEuros: +active.activeLine.ReventaLineaEuros,
        Unidades: active.activeLine.Unidades,
        Observaciones: active.activeLine.Observaciones,
        TasaDolares: tasaLine??0,
        FechaRecogida: active.activeLine.FechaRecogida,
//      TasaDolares: active.activeLine.TasaDolares,
        SuplementoTransporteDolares: active.activeLine.SuplementoTransporteDolares,

        
        ReventaLineaDolares: +calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, +active.activeLine.ReventaLineaEuros),
    }


    const validationSchemaOrderLineReSeller = () => {
        return yup.object().shape({
            FkProveedores: yup.number().required(),
            FkServiciosProveedores: yup.number().required('Servicio es requerido'),
            Concepto: yup.string().required('Concepto requerido'),
            ReventaLineaEuros: yup
                                .number()
                                .required('El importe de reventa es requerido')
                                .min(0,'El margen no puede ser negativo'),
            Unidades: yup.number().required('Unidades es requerido').integer('Debe ser un entero'),
            Observaciones: yup.string().nullable(),
            FechaRecogida: yup.date().nullable(),

        })
    }

    const handleSubmit = ( values, {setSubmitting } ) => {


            // TODO: CÁLCULO EN FUNCIÓN DE LA MONEDA CON LA QUE SE CREA LA VENTA, ES DECIR, SI LA MONEDA ES DÓLARES,
            // NO TENEMOS VALOR EN VALUES.IMPORTEREVENTAEUROS, ...
            let _reventaLineaEuros = (monedaUsuario==='EUR') ? 
                Math.round(+values.ReventaLineaEuros) :
                // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                Math.round(100*(+values.ReventaLineaDolares * +active.activeLine.TipoCambioProveedor.CambioDolares / +active.activeLine.TipoCambioProveedor.CambioEuros))/100;
        

            // PRECIO DE COSTE DEL SERVICIO 
            let costeDolares = Math.round(+active.activeLine?.CosteDolares);
            // RESERVA XPLORA DEL SERVICIO --> PRECIO VENTA - COSTE - MARGEN (EN EL SERVICIO DEL REVENDEDOR)
            let reservaEuros = +active.activeLine.ReservaEuros;
            // REVENTA EUROS: SE ESTÁ COGIENDO REVENTALINEAEUROS -- DEBE COGER EL MARGEN DEFINIDO PARA EL REVENDEDOR EN SU SERVICIO
            let reventaEuros = +_reventaLineaEuros; //active.activeLine.ReventaEuros;
            // INSITULINEA: IMPORTE QUE VA INSITU EN LA VENTA (sin tener en cuenta el precio de transporte)
            let insituLineaEuros = 
            (currentUser.MargenConfiguradoEnServicios ? 
                (+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor, active.activeLine?.CosteDolares ) +active.activeLine?.ReservaEuros ) :
                (currentUser.Comisiona ? 
                    (+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor, +active.activeLine.CosteDolares))
                    : 
                    // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                    (+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor, +active.activeLine.CosteDolares))
                    ));
            
            let insituLineaDolares = 
                (
                    currentUser.MargenConfiguradoEnServicios ? 
                    ( +active.activeLine?.CosteDolares + 
                            calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, +active.activeLine?.ReservaEuros) ) :
                    (currentUser.Comisiona ? 
                        (+active.activeLine.CosteDolares)
                        : 
                        // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                        (+active.activeLine.CosteDolares)
                        )                                
                );
            // RESERVALINEA: IMPORTE QUE VA DE RESERVA EN LA LINEA
            let reservaLineaEuros = 
                (
                    currentUser.MargenConfiguradoEnServicios ? 
                    +((monedaUsuario==='EUR') ? 
                        Math.round(+values.ReventaLineaEuros) :
                        // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                        Math.round(100*(+values.ReventaLineaDolares * +active.activeLine.TipoCambioProveedor.CambioDolares / +active.activeLine.TipoCambioProveedor.CambioEuros))/100) :
                    (currentUser.Comisiona ? 
                        (+active.activeLine.ReservaEuros) :
                        (+active.activeLine.ReservaEuros + +((monedaUsuario==='EUR') ? 
                        Math.round(+values.ReventaLineaEuros) :
                        // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                        Math.round(100*(+values.ReventaLineaDolares * +active.activeLine.TipoCambioProveedor.CambioDolares / +active.activeLine.TipoCambioProveedor.CambioEuros))/100)
                        )
                    )
                );
            let reservaLineaDolares = 
                (currentUser.MargenConfiguradoEnServicios ? 
                    +values.ReventaLineaDolares :
                    (currentUser.Comisiona ? 
                        (+calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, +active.activeLine?.ReservaEuros) ) :
                        (
                            +calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, +active.activeLine?.ReservaEuros) + 
                            +values.ReventaLineaDolares
                            )
                    ));
            // REVENTALINEA: EL MARGEN MARCADO EN LA LINEA
            let reventaLineaEuros = 
            currentUser.MargenConfiguradoEnServicios ? 
                +_reventaLineaEuros :
                    (currentUser.Comisiona ? 
                        (0) :
                        (+_reventaLineaEuros)
                    );
            let reventaLineaDolares = 
                currentUser.MargenConfiguradoEnServicios ? 
                    +values.ReventaLineaDolares :
                        (currentUser.Comisiona ? 
                            (0) :
                            (+values.ReventaLineaDolares)
                        );
            // TASA: SE COGE DEL SERVICIO
            // SUPLEMENTOTRANSPORTE: EL QUE SE DEFINA POR EL VENDEDOR



        const payload = {
            ...values,
            PkLineasVenta: active.activeLine.PkLineasVenta,

            /*CosteDolares: +active.activeLine.CosteDolares,
            ReservaEuros: +active.activeLine.ReservaEuros,
            InSituLineaDolares: +active.activeLine.InSituLineaDolares,
            ReservaLineaEuros: +active.activeLine.ReservaLineaEuros,            
            ReventaLineaEuros: (monedaUsuario==='EUR') ? 
                                    Math.round(+values.ReventaLineaEuros) :
                                    // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                                    Math.round(100*(+values.ReventaLineaDolares * active.activeLine.TipoCambioProveedor.CambioDolares / active.activeLine.TipoCambioProveedor.CambioEuros))/100,
            ReventaEuros: +active.activeLine.ReventaEuros,*/

            CosteDolares: costeDolares,
            ReservaEuros:reservaEuros,
            ReventaEuros:reventaEuros,
            InSituLineaDolares:insituLineaDolares,
            //InSituLineaEuros:insituLineaEuros,
            ReservaLineaEuros:reservaLineaEuros,
            //ReservaLineaDolares:reservaLineaDolares,
            //ReventaLineaDolares:reventaLineaDolares,
            ReventaLineaEuros:reventaLineaEuros,

            FkTiposCambioProveedores: active.activeLine.TipoCambioProveedor.PkTiposCambioProveedores,
            FkVentas: activeEdit.PkVentas,
            Unidades: +values.Unidades,
            VersionFila: active.activeLine.VersionFila,
            Observaciones: values.Observaciones ? values.Observaciones : "",
            TasaDolares: +values.TasaDolares,
            SuplementoTransporteDolares: +values.SuplementoTransporteDolares,
            FechaRecogida: values.FechaRecogida ? values.FechaRecogida : null,

        }
        delete payload.ReventaLineaDolares;
        dispatch(startUpdateLineActive(payload))
        setSubmitting(false)
    }
    
    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };

    servicesState?.map( element => {
        servicesAutoComplete.push({ 
            label: element?.ServicioProveedor?.Alias ? element?.ServicioProveedor?.Alias : element?.ServicioProveedor?.Nombre,
            value: element?.FkServiciosProveedores
        });
        return null;
    })
    return (
        <>
         <Formik enableReinitialize initialValues={ initialValuesReSeller } validationSchema={ validationSchemaOrderLineReSeller} onSubmit={handleSubmit}>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, values, setFieldValue }) => (
            <Form>
            <Grid item style={{padding: '2rem'}}>
            <Grid 
                container
                spacing={3}>
            <Grid item classes={{root: classes.container }}>
                <Typography variant='h3'>Editar Línea de Venta</Typography>
            </Grid>
            <Grid xs={12} item  classes={{root: classes.container }}>
                <Autocomplete
                    name='FkServiciosProveedores'
                    options={servicesAutoComplete}
                    getOptionLabel={option => option.label}
                    autoHighlight
                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                    ref={refService}
                    fullWidth
                    disablePortal
                    id="servicio-autocomplete-exchange-rate"
                    noOptionsText={"No hay servicios"}
                    renderInput={(params) => ( 
                        <TextField 
                            {...params}
                            name='FkServiciosProveedores' 
                            variant='outlined' 
                            label="Servicio" 
                            placeholder='Busca...'
                            disabled={ isSubmitting }
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched.FkServiciosProveedores && Boolean(errors.FkServiciosProveedores)}
                            helperText={touched.FkServiciosProveedores && errors.FkServiciosProveedores}
                            

                        />)}
                    sx={{ marginBottom: '0.5rem'}}
                    defaultValue={{
                        label: active.activeLine.ServicioProveedor.Alias ? active.activeLine.ServicioProveedor.Alias : active.activeLine.ServicioProveedor.Nombre,
                        value: active.activeLine.ServicioProveedor.PkServiciosProveedores
                    }}
                    onChange={(e, value) => {
                        setFieldValue(
                        "FkServiciosProveedores",
                        value?.value !== null && value?.value
                        );
                        setValueService(value?.value)
                        //setConceptoLine(value?.label)
                        console.log('filtramos servicio');
                        console.log(servicesAutoComplete);
                        const servicios = servicesAutoComplete.filter(service=>service.PkServiciosProveedores==value?.value);
                        console.log(servicios);
                        if (servicios.length>0) {
                            SetTasaLine(servicios[0].TasaDolares);
                            setConceptoLine(servicios[0].Alias ? servicios[0].Alias : value?.label);
                        }

                        if(!value?.value){
                            setValueService("");
                            setConceptoLine("");
                            SetTasaLine(0);
                        }

                    }}
                />
            </Grid>
                {
                    values.FkServiciosProveedores
                    &&
                    (
                        <>

                        
                            <Grid xs={12} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Concepto'
                                    size='small'
                                    variant='outlined'
                                    name='Concepto'
                                    classes={{ root: classes.inputText }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Info />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    value={ values.Concepto }
                                    error={touched.Concepto && Boolean(errors.Concepto)}
                                    helperText={touched.Concepto && errors.Concepto}
                                    disabled={ isSubmitting }
                                />
                            </Grid>

                            
                            {
                                idRol === ROLADMIN
                                &&
                                (
                                <>
                                    <Grid item  classes={{root: classes.container }}>
                                        <TextField 
                                            fullWidth   
                                            label='Coste'
                                            size='small'
                                            variant='outlined'
                                            classes={{ root: classes.inputText }}
                                            value={ values.InSituLineaDolares}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MonetizationOn/>
                                                    </InputAdornment>
                                                ),

                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                        disabled
                                        />
                                    </Grid>
                                    <Grid item  classes={{root: classes.container }}>
                                        <TextField 
                                            fullWidth   
                                            label='Coste'
                                            size='small'
                                            variant='outlined'
                                            classes={{ root: classes.inputText }}
                                            value={calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,values.InSituLineaDolares)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EuroOutlined/>
                                                    </InputAdornment>
                                                ),

                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                        disabled
                                        />
                                    </Grid>

                                    <Grid item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Venta Xplora'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,values.InSituLineaDolares) + Math.round(+active.activeLine.ReservaLineaEuros)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EuroOutlined/>
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                            disabled
                                            />
                                    </Grid>
                            </>
                                )
                            }


{
        ((!currentUser.Comisiona && !currentUser.MargenConfiguradoEnServicios) || (idRol === ROLADMIN)) &&
        (
        <>
                            { (monedaUsuario === 'EUR') &&
                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                    <TextField 
                                        fullWidth   
                                        label='Neto/PAX'
                                        size='small'
                                        variant='outlined'
                                        classes={{ root: classes.inputText }}
                                        value={
                                            calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,
                                                active.activeLine.CosteDolares) + +active.activeLine.ReservaEuros                                             
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EuroOutlined/>
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    disabled
                                    />
                            </Grid>
                            }

                            { (monedaUsuario === 'USD') &&
                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                    <TextField 
                                        fullWidth   
                                        label='Neto/PAX'
                                        size='small'
                                        variant='outlined'
                                        classes={{ root: classes.inputText }}
                                        value={
                                            +active.activeLine.CosteDolares +
                                            +calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor,
                                                +active.activeLine.ReservaEuros) 
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MonetizationOn/>
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    disabled
                                    />
                            </Grid>
                            }

</>
                                )
                            }

                            {
        !currentUser.Comisiona && !currentUser.MargenConfiguradoEnServicios &&
        (
        <>
                            { (idRol === ROLADMIN || monedaUsuario === 'EUR') &&
                            
                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Margen/PAX'
                                    name='ReventaLineaEuros'
                                    size='small'
                                    variant='outlined'
                                    classes={{ root: classes.inputText }}
                                    value={ values.ReventaLineaEuros }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EuroOutlined />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.ReventaLineaEuros && Boolean(errors.ReventaLineaEuros)}
                                    helperText={touched.ReventaLineaEuros && errors.ReventaLineaEuros}
                                    disabled={ isSubmitting }
                                />
                            </Grid>
                            }

                            { (idRol === ROLADMIN || monedaUsuario === 'USD') &&
                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Margen/PAX'
                                    name='ReventaLineaDolares'
                                    size='small'
                                    variant='outlined'
                                    value={ values.ReventaLineaDolares }
                                    //value={ calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, values.ReventaLineaEuros) }
                                    classes={{ root: classes.inputText }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MonetizationOn />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    disabled={ isSubmitting }

                                />
                            </Grid>
                            }

                            </>)

                            }

                            { (idRol === ROLADMIN || (1 || monedaUsuario === 'USD')) && // mostramos siempre la tassa en dólares
                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Tasa Dólares'
                                    name='TasaDolares'
                                    size='small'
                                    variant='outlined'
                                    classes={{ root: classes.inputText }}
                                    value={ values.TasaDolares }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MonetizationOn />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                    helperText={touched.TasaDolares && errors.TasaDolares}
                                    disabled
                                />
                            </Grid>
                            }
                            { (idRol === ROLADMIN || monedaUsuario === 'EUR') &&

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Tasa Euros'
                                    size='small'
                                    variant='outlined'
                                    classes={{ root: classes.inputText }}
                                    value={ +calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor, values.TasaDolares) }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EuroOutlined />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    disabled
                                />
                            </Grid>
                            }
                            
                            { (idRol === ROLADMIN || monedaUsuario === 'EUR') &&

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                    <TextField 
                                        fullWidth   
                                        label='PVP/PAX'
                                        size='small'
                                        variant='outlined'
                                        classes={{ root: classes.inputText }}
                                        value={
                                            (
                                                +calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor, 
                                                    active.activeLine.CosteDolares) + active.activeLine.ReservaEuros  
                                                    + active.activeLine.ReventaLineaEuros
                                            ) 
                                            }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EuroOutlined/>
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    disabled
                                    />
                            </Grid>
                            
                            }
                            { (idRol === ROLADMIN || monedaUsuario === 'USD') &&

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                    <TextField 
                                        fullWidth   
                                        label='PVP/PAX'
                                        size='small'
                                        variant='outlined'
                                        classes={{ root: classes.inputText }}
                                        value={
                                            (
                                               +values.ReventaLineaDolares + +active.activeLine.CosteDolares 
                                               + calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, 
                                                    active.activeLine.ReservaEuros) 
                                                )
                                            }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MonetizationOn/>
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    disabled
                                    />
                            </Grid>
                            } 


                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='PAX'
                                    size='small'
                                    name='Unidades'
                                    variant='outlined'
                                    value={ values.Unidades }
                                    classes={{ root: classes.inputText }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <People />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.Unidades && Boolean(errors.Unidades)}
                                    helperText={touched.Unidades && errors.Unidades}
                                    disabled={ isSubmitting }
                                />
                            </Grid>


                            { (idRol === ROLADMIN || monedaUsuario === 'EUR') &&

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                    <TextField 
                                        fullWidth   
                                        label='PVP Total'
                                        size='small'
                                        variant='outlined'
                                        classes={{ root: classes.inputText }}
                                        value={
                                            (
                                                +calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor, 
                                                    active.activeLine.CosteDolares) + active.activeLine.ReservaEuros  
                                                    + active.activeLine.ReventaLineaEuros
                                            ) 
                                                * values.Unidades
                                            }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EuroOutlined/>
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    disabled
                                    />
                            </Grid>
                            
                            }
                            { (idRol === ROLADMIN || monedaUsuario === 'USD') &&

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                    <TextField 
                                        fullWidth   
                                        label='PVP Total'
                                        size='small'
                                        variant='outlined'
                                        classes={{ root: classes.inputText }}
                                        value={
                                            (
                                               +values.ReventaLineaDolares + +active.activeLine.CosteDolares 
                                               + calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, 
                                                    active.activeLine.ReservaEuros) 
                                                ) * values.Unidades
                                            }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MonetizationOn/>
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    disabled
                                    />
                            </Grid>
                            }                            



                            { (idRol === ROLADMIN || (monedaUsuario === 'USD' || 1)) && // suplemento transporte siempre en dólares??

                           <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Supl. Transporte'
                                                name='SuplementoTransporteDolares'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.SuplementoTransporteDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.SuplementoTransporteDolares && Boolean(errors.SuplementoTransporteDolares)}
                                                helperText={touched.SuplementoTransporteDolares && errors.SuplementoTransporteDolares}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>

                                        }
                                        { (idRol === ROLADMIN || monedaUsuario === 'EUR') &&

                                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Supl. Transporte'
                                                    size='small'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EuroOutlined />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    value={ +calculateFromDollarsToEuro( active.activeLine.TipoCambioProveedor, values.SuplementoTransporteDolares) }
                                                    disabled
                                                />
                                            </Grid>
                                        
                                        }





            { /* IN SITU */ (monedaUsuario === 'USD') && 
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label={ `Pago in situ`}
                    size='small'
                    name='InSituLineaDolares'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ 
                        (
                            currentUser.MargenConfiguradoEnServicios ? 
                            (+values.SuplementoTransporteDolares + +active.activeLine?.CosteDolares + 
                                    calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, +active.activeLine?.ReservaEuros) ) :
                            (currentUser.Comisiona ? 
                                (+active.activeLine.CosteDolares + +values.SuplementoTransporteDolares)
                                : 
                                // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                                (+active.activeLine.CosteDolares + +values.SuplementoTransporteDolares)
                                )                                
                        )
                         * values.Unidades }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                    helperText={touched.TasaDolares && errors.TasaDolares}
                    disabled
                />
            </Grid>
            }
            { /* IN SITU */ (monedaUsuario === 'EUR') &&  
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label={ `Pago in situ`}
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            value={ 
                                (currentUser.MargenConfiguradoEnServicios ? 
                                    (+calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor, active.activeLine?.CosteDolares + +values.SuplementoTransporteDolares) +active.activeLine?.ReservaEuros  ) :
                                    (currentUser.Comisiona ? 
                                        +calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,(+active.activeLine.CosteDolares + +values.SuplementoTransporteDolares))
                                        : 
                                        // SI NO COMISIONA VA EL MARGEN DE LA LÍNEA
                                        +calculateFromDollarsToEuro(active.activeLine.TipoCambioProveedor,(+active.activeLine.CosteDolares + +values.SuplementoTransporteDolares))
                                        ))
                                    * values.Unidades }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            disabled
                        />
                    </Grid>
            }

            { /* RESERVA */ (monedaUsuario === 'USD') && 
            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                <TextField 
                    fullWidth   
                    label={ `Pago en reserva`}
                    size='small'
                    name='ReservaLineaDolares'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ 
                        (currentUser.MargenConfiguradoEnServicios ? 
                            +values.ReventaLineaDolares :
                            (currentUser.Comisiona ? 
                                (+calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, +active.activeLine?.ReservaEuros) ) :
                                (
                                    +calculateFromEuroToDollars( active.activeLine.TipoCambioProveedor, +active.activeLine?.ReservaEuros) + 
                                    +values.ReventaLineaDolares
                                    )
                            ))
                             * values.Unidades 
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                    helperText={touched.TasaDolares && errors.TasaDolares}
                    disabled
                />
            </Grid>
            }
            { /* RESERVA */ (monedaUsuario === 'EUR') &&  
                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                        <TextField 
                            fullWidth   
                            label={ `Pago en reserva`}
                            size='small'
                            variant='outlined'
                            classes={{ root: classes.inputText }}
                            value={ 
                                (
                                    currentUser.MargenConfiguradoEnServicios ? 
                                    +((monedaUsuario==='EUR') ? 
                                    Math.round(+values.ReventaLineaEuros) :
                                    // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                                    Math.round(100*(+values.ReventaLineaDolares * +active.activeLine.TipoCambioProveedor.CambioDolares / +active.activeLine.TipoCambioProveedor.CambioEuros))/100) :
                                    (currentUser.Comisiona ? 
                                        (+active.activeLine.ReservaEuros) :
                                        (+active.activeLine.ReservaEuros + +((monedaUsuario==='EUR') ? 
                                        Math.round(+values.ReventaLineaEuros) :
                                        // NO REDONDEAMOS AL GUARDAR EL IMPORTE EN EUROS PARA QUE NOS DE EL MISMO IMPORTE SIEMPRE EN DÓLARES
                                        Math.round(100*(+values.ReventaLineaDolares * +active.activeLine.TipoCambioProveedor.CambioDolares / +active.activeLine.TipoCambioProveedor.CambioEuros))/100)
                                        )
                                    )
                                ) * values.Unidades 
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),

                                classes: {
                                    input: classes.resize
                                }
                            }}
                            disabled
                        />
                    </Grid>
            }
            






                            <Grid xs={12} item  classes={{root: classes.container }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}  locale={ esLocale }>
                                    <Field component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida   }  />
                                </LocalizationProvider>
                            </Grid>

                            <Grid xs={12} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Observaciones'
                                    size='small'
                                    name='Observaciones'
                                    variant='outlined'
                                    classes={{ root: classes.inputText }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <RemoveRedEye />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.Observaciones && Boolean(errors.Observaciones)}
                                    helperText={touched.Observaciones && errors.Observaciones}
                                    disabled={ isSubmitting }
                                    multiline
                                    maxRows={ 10 }
                                    minRows={ 5 }
                                />
                            </Grid>
                        </>
                    )
                }
                                                    
            

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >

                        <Button 
                            style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                            onClick={ handleCancel }>
                                <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                        </Button>

                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            type='submit'
                            loading={ isSubmitting }
                            disabled={ !isValid }
                            style={{
                                backgroundColor: '#E5824F',
            
                            }}
                        >
                                Editar
                        </LoadingButton>

                    </Box>

                        </Grid>
                    </Grid>
            </Form>
            )}
            </Formik>
        </>
    )
}
