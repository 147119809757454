//React
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//Dependencias externas
import { Router } from "@reach/router"
import jwt_decode from "jwt-decode";

//Dependencias propias
import { PrivateRoute } from './PrivateRoute'
import { startLogOut } from '../redux/actions/auth'

//Utils
import { ROLADMIN, ROLCOLABORADOR, ROLREVENDEDOR, ROLVENDEDOR } from '../utils/constants'

//Pages
import  Dashboard  from '../pages'
import  ComisionistasScreen  from '../pages/comisionistas'
import  ClientsScreen  from '../pages/clients'
import  Login  from '../pages/login'
import SuppliersScreen from '../pages/suppliers'
import UserScreen from '../pages/users'
import  Settings  from '../pages/settings'
import  ExchangesRateScreen  from '../pages/exchanges'
import ServicesScreen from '../pages/services'
import  SalesScreen  from '../pages/sales'
import  LineasScreen  from '../pages/lines'
import  Politicas  from '../pages/politicas'
import  PoliticasAgencia  from '../pages/politicas_agencia'
import  Me  from '../pages/me'
import ChangePassword from '../pages/auth/change'
import  UserDetailScreen  from '../pages/users/[PkUsuarios]'
import SupplierDetailScreen from '../pages/suppliers/[PkProveedores]'
import ServicesDetailScreen from '../pages/services/[PkServiciosProveedores]'
import ClientDetailScreen from '../pages/clients/[PkClientes]'
import ComisionistaDetailScreen from '../pages/comisionistas/[PkComisionistas]'
import Invoice  from '../pages/sales/invoice'
import SaleDetailScreen  from '../pages/sales/invoice/[PkVentas]'
import LineDetailScreen  from '../pages/lines/[PkLineas]'
import NotFoundPage from '../pages/404'
import ResetPassword from '../pages/auth/resetPassword'
import LoginDirecto from '../pages/loginDirecto'


export const AppRouter = () => {

    const { id, idRol, cambiaPassword, aceptaPoliticas, esAgencia, aceptaContratoTratamientoDatos } = useSelector(state => state.auth);
    const dispatch = useDispatch()

    useEffect(() => {
        const user = localStorage.getItem('user') || '';
        if( user ){
            const { token } = JSON.parse(user);
            const decodedJWT = jwt_decode( token );
            if( decodedJWT.exp * 1000 < Date.now() ){
                dispatch( startLogOut() );
                return;
            }

        }
    }, [ dispatch ])
    
    return (
      <Router basepath='/'>
            { !id && <Login path='/login' />}
            { !id && <ResetPassword path='/auth/resetPassword' />}
            { <LoginDirecto path='/loginDirecto' /> }
            
            { id && aceptaPoliticas && cambiaPassword && (!esAgencia || (esAgencia && aceptaContratoTratamientoDatos)) && <Me path='/me' />}
             <Dashboard path='/' />
            { id  && !aceptaPoliticas && <Politicas path='/politicas' />}

            { id && aceptaPoliticas && !cambiaPassword && <ChangePassword path='/auth/change' />}
            
            { id  && aceptaPoliticas  && (esAgencia && !aceptaContratoTratamientoDatos) && <PoliticasAgencia path='/politicas_agencia' />}

            <PrivateRoute 
                exact path='/lines/:PkLineas'
                component={ LineDetailScreen }
            />
            {
                                idRol === ROLADMIN && aceptaPoliticas && cambiaPassword && id &&
                                (
                                    <>
                                        <PrivateRoute 
                                            path='/services/:PkServiciosProveedores'
                                            component={ ServicesDetailScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            path='/users/:PkUsuarios'
                                            component={ UserDetailScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            path='/suppliers/:PkProveedores'
                                            component={ SupplierDetailScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/users'
                                            component={ UserScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/comisionistas'
                                            component={ ComisionistasScreen }
                                            
                                        />
                                        
                                        <PrivateRoute 
                                            path='/comisionistas/:PkComisionistas'
                                            component={ ComisionistaDetailScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/clients'
                                            component={ ClientsScreen }
                                            
                                        />
                                        

                                        <PrivateRoute 
                                            path='/clients/:PkClientes'
                                            component={ ClientDetailScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/suppliers'
                                            component={ SuppliersScreen }
                                            
                                        />
                                        <PrivateRoute 
                                            exact path='/services'
                                            component={ ServicesScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/sales'
                                            component={ SalesScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/sales/invoice/:PkVentas'
                                            component={ SaleDetailScreen }
                                            
                                        />


                                        <PrivateRoute 
                                            exact path='/sales/invoice'
                                            component={ Invoice }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/lines'
                                            component={ LineasScreen }
                                            
                                        />

                                        

                                        <PrivateRoute 
                                            path='/exchanges'
                                            component={ ExchangesRateScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            path='/settings'
                                            component={ Settings }
                                            
                                        />
                                    </>
                                )
            }

{
                                idRol === ROLVENDEDOR && aceptaPoliticas && cambiaPassword && id &&
                                (
                                    <>
                                        <PrivateRoute 
                                            path='/services'
                                            component={ ServicesScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/sales'
                                            component={ SalesScreen }
                                            
                                        />
                                        <PrivateRoute 
                                            exact path='/sales/invoice'
                                            component={ Invoice }
                                            
                                        />
                                         <PrivateRoute 
                                            exact path='/sales/invoice/:PkVentas'
                                            component={ SaleDetailScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            path='/lines'
                                            component={ LineasScreen }
                                            
                                        />
                                    </>
                                )
                            }

                            {
                                idRol === ROLCOLABORADOR && aceptaPoliticas && cambiaPassword && id &&
                                (
                                    <>

                                        <PrivateRoute 
                                            exact path='/suppliers'
                                            component={ SuppliersScreen }
                                            
                                        />
                                        <PrivateRoute 
                                            path='/lines'
                                            component={ LineasScreen }
                                            
                                        />
                                    </>
                                )
                            }

                            {
                                idRol === ROLREVENDEDOR && aceptaPoliticas && cambiaPassword && id &&
                                (
                                    <>

                                        <PrivateRoute 
                                            path='/services'
                                            component={ ServicesScreen }
                                            
                                        />

                                        <PrivateRoute 
                                            exact path='/sales'
                                            component={ SalesScreen }
                                            
                                        />
                                        <PrivateRoute 
                                            exact path='/sales/invoice'
                                            component={ Invoice }
                                            
                                        />

                                        <PrivateRoute 
                                            path='/lines'
                                            component={ LineasScreen }
                                            
                                        />
                                         <PrivateRoute 
                                            exact path='/sales/invoice/:PkVentas'
                                            component={ SaleDetailScreen }
                                            
                                        />

                                    </>
                                )
                            }
                
            <NotFoundPage default />
      </Router>
    )
}


/**
 * {
                                routerItems.map(({ route, role: roleIter, component: Component}, i) => {
                                    let result = roleIter.filter( role => role === rol);
                                    if( result.includes(rol)){
                                        console.log( Component )
                                        return (
                                            <PrivateRoute 
                                                path={route}
                                                component={ Component }
                                                isLoggedIn={!!id}
                                            />
                                        )
                                    }  
                                })
                            }
 */