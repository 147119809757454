import { types } from "../types/types"

export const uiManageModal = () => ({
    type: types.uiManageModal
})


export const uiManageModalCollectionTypes = () => ({
    type: types.uiManageModalCollectionTypes,
})


export const uiManageModalNotification = () => ({
    type: types.uiManageModalNotifications
})


export const startLoading = () => ({
    type: types.uiStartLoading,
})

export const finishLoading = () => ({
    type: types.uiFinishLoading,
})


export const startLoadingModal = () => ({
    type: types.uiModalContentStartLoading,
})

export const finishLoadingModal = () => ({
    type: types.uiModalContentFinishLoading,
})


export const startError = ( err ) => ({
    type: types.uiStartError,
    payload: err
})

export const finishError = () => ({
    type: types.uiFinishError,
})

export const startLogoutUI = () => ({
    type: types.uiLogout
})


export const startSuccess = ( check ) => ({
    type: types.uiStartSuccess,
    payload: check
})

export const finishSuccess = () => ({
    type: types.uiFinishSuccess,
})