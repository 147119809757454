import React, { useState } from 'react'
import {  
        Grid, 
        InputAdornment, 
        TextField, 
        Typography
} from '@material-ui/core'
import { useDispatch, useSelector} from 'react-redux';
//Own imports
import { useFormStyles } from '../../styles/formStyles';
import showPassword from '../../images/show-password.svg';
import hidePassword from '../../images/hide-password.svg';
import { startUpdateUser } from '../../redux/actions/users';
import { Field, Form, Formik } from 'formik';
import {Edit } from '@material-ui/icons';

import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

import { loadGridUser } from '../../helpers/loadGrid';

import { FormikDatePicker } from '../ui/FormikDatePicker';
import { validationSchemaUserEdit } from '../../utils/schemaValidationForm';



export const ContentModalUserEdit = () => {


    const classes = useFormStyles(); //the classes variable to handle the form styles
    const { active } = useSelector(state => state.user)
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false); //password type ('password' o 'text') state management
    const { id: idLoggedIn } = useSelector( state => state.auth)
    const { data } = loadGridUser();
    const result = data.filter( e => e?.type !== 'detail' && e?.filter === undefined && e?.filterdetail === undefined)

    const initialValues = {
        name: active?.Nombre,
        surname: active?.Apellidos,
        observaciones: active?.Observaciones,
        telefono: active?.Telefono,
        email: active?.Email,
        date: active?.FechaBaja
    }



    const handleSubmit = ( values, {setSubmitting} ) => {
       
        const user = {
            PkUsuarios: active.PkUsuarios,
            Email: values.email,
            Nombre: values.name,
            Apellidos: values.surname,
            Telefono: values.telefono,
            Observaciones: values.observaciones ? values.observaciones : "",
            VersionFila: active.VersionFila  + 1,
            UsuarioMod: idLoggedIn,
            FechaBaja: !values.date ? null : values.date,
        }

        dispatch(startUpdateUser(user, setSubmitting));
        
    }

    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik validationSchema={ validationSchemaUserEdit } initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid }) => (
        <Form>
        <Grid item style={{padding: '2rem'}}>
            <Grid container direction='column'>
                <Grid item classes={{root: classes.container }}>
                    <Typography variant='h3'>Editar Usuario { active?.Nombre } </Typography>
                </Grid>
                    
                {
                        result.map( element => {
                            return(
                                <Grid item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name={element.name}
                                        size='small'
                                        type={element.name==='password' ? (visible ? 'text' : 'password') : 'text'}
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-${element.name}`} 
                                        label={ element.label }
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { element.startAdornment }
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                element?.endAdornment &&

                                                (<InputAdornment position='end' onClick={ () => setVisible(!visible)}>
                                                    <img 
                                                        src={ visible ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>)
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        value={values[element.name]}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched[element.name] && Boolean(errors[element.name])}
                                        helperText={touched[element.name] && errors[element.name]}
                                        disabled={ isSubmitting || (element.name==='rol' ? true : false) }
                                        multiline={ element.name === 'observaciones' ? true : false}
                                        maxRows={ element.name === 'observaciones' ? 10 : undefined }
                                        minRows={ element.name === 'observaciones' ? 5 : undefined }
                                    />
                                </Grid>
                            )
                        })
                    }


                    <Grid item  classes={{root: classes.container }}>
                        <Field component={FormikDatePicker} name="date" isSubmitting={ isSubmitting } value={ values?.FechaBaja } />
                    </Grid>
                    
                
                <LoadingButton
                    fullWidth
                    loadingPosition="start"
                    startIcon={<Edit />}
                    variant="outlined"
                    type='submit'
                    loading={ isSubmitting }
                    disabled={ !isValid }
                >
                        Editar
                </LoadingButton>

             
            </Grid>
        </Grid>
        </Form>
        )}
        </Formik>
        </LocalizationProvider>
    )
}
