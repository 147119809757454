import { Input, Typography, Button, Grid } from '@material-ui/core';

import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from '../../components/ui/Layout';
import { CoordinatorSuppliers } from '../../components/users/coordinator/coordinatorSuppliers';
import { ServicesResellers } from '../../components/users/resellers/servicesResellers';
import { UserInfo } from '../../components/users/userInfo';
import {  ROLCOLABORADOR, ROLREVENDEDOR } from '../../utils/constants';
import {useParams } from "@reach/router"
import { Spinner } from '../../components/ui/Spinner';
import { actionEdit } from '../../redux/actions/actions';
import axios from 'axios';
import { loadJWT } from '../../helpers/loadJWT';
import Swal from 'sweetalert2';
import { url } from '../../api/api';
import { useFormStyles } from '../../styles/formStyles';
import {  startUploadingLogo } from '../../redux/actions/settings' 
import { AgencyResellers } from '../../components/users/resellers/agencySellers';
import { ButtonFloatAgencia } from '../../components/ui/ButtonFloatAgencia';



const UserDetailScreen = () => {
    

    const { active } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const params = useParams();
    const { loading } = useSelector(state => state.ui)
    const { modal } = useSelector(state => state.modal);

    const { token } = loadJWT();
    const [img, setImg] = useState();
    const uploadForm = useRef();
    const classes = useFormStyles();
    const hiddenFileInput = useRef();

    
    const init = ( e ) => {
        e.preventDefault();
        const value = uploadForm.current;
        dispatch( startUploadingLogo( value, params.PkUsuarios ) );
        setTimeout( fetchImage, 3000);

    }
    
    const handleClick = event => {
        console.log('click');
        console.log (hiddenFileInput);
        hiddenFileInput.current.firstChild.click();
    };

    const fetchImage = async () => {
        await url.get( '/usuarios/logo/'+params.PkUsuarios, {
            headers: {
                Authorization: `Bearer ${ token }`
            },
            responseType: 'arraybuffer'
        })
        .then( ( res ) => {
            var file = new Blob([res.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            setImg(fileURL);
            
        })
        .catch( e => {
            Swal.fire('Error',  e?.response?.data?.error?.message || 'No se ha podido descargar el logo de la agencia', 'error')
        })
    };
  
    useEffect(() => {
      fetchImage();
    }, []);

    
      useEffect(() => {

        dispatch(actionEdit(params.PkUsuarios))
    }, [ dispatch, params.PkUsuarios ]);
    

    return (
       <Layout>
           <div style={{ marginTop: '2rem'}}/>
           {
               
                     (loading && !modal)

                     ?

                     <Spinner />

                    :

                    <UserInfo />
                   
            
           }

            <>

            {
                    
                    ( active?.Rol?.PkRoles === ROLREVENDEDOR && img)
                    &&
                    (
                        <Grid md={6} xs={12}>

                            <Typography style={{fontWeight: 'bold'}} >LOGO </Typography>
                            
                            <Button style={{ backgroundColor: 'lightgray'}} onClick={handleClick}>
                                Seleccionar imagen
                            </Button>
                            <img src={ img } alt='logo' width={'200'}  style={{ height: 'auto'}} />
                            <form                  style= {{fontSize: '0.8em'}}                            classes={{ root: classes.inputText }}
 ref={ uploadForm }>
                                <Input  ref={hiddenFileInput} style={{display: 'none'}}  accept="jpg/*,jpeg/*,png/*" id="files" name='files' multiple type="file" />
                            </form>
                            
                        <Button
                            variant="contained"
                            type='submit'
                            style={{
                                backgroundColor: '#E5824F',
                                color: 'white'
            
                            }}
                            onClick={ init }
                            
                        >
                                Subir logo
                        </Button>
                        </Grid>
                    )
            }

                
                {
                    
                    ( active?.Rol?.PkRoles === ROLREVENDEDOR )
                    &&
                        
                        <ServicesResellers />
                    
    
    
                }
                {
            
                    ( active?.EsAgencia )
                    &&
                        <>
                            <AgencyResellers />
                    
                            <ButtonFloatAgencia />
                        </>
                }
    
                {
                    (active?.Rol?.PkRoles === ROLCOLABORADOR) 
                    &&
                    <CoordinatorSuppliers />
                }
            </> 
                   
           
           
       </Layout>
    )
}


export default UserDetailScreen;