import React from 'react';
import { baseURLIcon } from '../utils/constants';


export const loadLanguages = () => {

    const data = [
        {
            code: 'AL',
            label: 'Alemán',
            icon: () => (
            <img
                loading="lazy"
                width="20"
                src={`${baseURLIcon}/w20/de.png`}
                alt='img'
                srcSet={`${baseURLIcon}/w40/de.png 2x`
                }
            />)
        },
        {
            code: 'ES',
            label: 'Español',
            icon: () => (
                <img
                    loading="lazy"
                    width="20"
                    src={`${baseURLIcon}/w20/es.png`}
                    alt='img'
                    srcSet={`${baseURLIcon}/w40/es.png 2x`
                    }
                />)
        },
        {
            code: 'EN',
            label: 'Inglés',
            icon: () => (
                <img
                    loading="lazy"
                    width="20"
                    src={`${baseURLIcon}/w20/us.png`}
                    alt='img'
                    srcSet={`${baseURLIcon}/w40/us.png 2x`
                    }
                />)
        },
        {
            code: 'FR',
            label: 'Francés',
            icon: () => (
                <img
                    loading="lazy"
                    width="20"
                    src={`${baseURLIcon}/w20/fr.png`}
                    alt='img'
                    srcSet={`${baseURLIcon}/w40/fr.png 2x`
                    }
                />)
        },
        {
            code: 'IT',
            label: 'Italiano',
            icon: () => (
            <img
                loading="lazy"
                width="20"
                src={`${baseURLIcon}/w20/it.png`}
                srcSet={`${baseURLIcon}/w40/it.png 2x`
                }
                alt='img'
            />)
        },

    ];

    return data;
}