
import { Grid} from '@material-ui/core'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ExchangesRate } from '../../components/suppliers/ExchangesRate'
import { SupplierInfo } from '../../components/suppliers/supplierInfo'
import { ButtonFloatSupplier } from '../../components/ui/ButtonFloatSupplier'
import { Layout } from '../../components/ui/Layout'
import { ServicesProvided } from '../../components/users/suppliers/servicesprovided'
import { actionEditSupplier } from '../../redux/actions/actions'
import { useParams } from "@reach/router"
import { Spinner } from '../../components/ui/Spinner'

const SupplierDetailScreen = ( props ) => {
    
    const dispatch = useDispatch();
    const params = useParams();
    const { loading } = useSelector(state => state.ui)

    useEffect(() => {
        dispatch(actionEditSupplier(params.PkProveedores));
    }, [ dispatch, params.PkProveedores ]);

    return (
        <Layout>
                {   loading 
                    ?
                    <Spinner />
                    :
                <Box sx={{ flexGrow: 1, mt: 4, width:'90vw'}}>
                    <Grid container spacing={3}>
                        <SupplierInfo props={ props }/>
                        <ExchangesRate />
                    </Grid>
                </Box>
                }
                
                <ServicesProvided />

                <ButtonFloatSupplier />
        </Layout>
    )
}

export default SupplierDetailScreen;