import { FormControlLabel, Checkbox, Box, Button, Divider, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadGridConfirmSupplier } from '../../helpers/loadGrid'
import { startConfirmLine,  startSendingVoucherEmailFromLine,  startUpdateConfirmLine } from '../../redux/actions/lines'
import { useFormStyles } from '../../styles/formStyles'
import { validationSchemaConfirmLine } from '../../utils/schemaValidationForm'
import { FormikDatePicker } from '../ui/FormikDatePicker'
import esLocale from 'date-fns/locale/es'
import { loadLanguages } from '../../helpers/loadLangauges'

import { uiManageModalCollectionTypes } from '../../redux/actions/ui'
import { startLogOutContentModal } from '../../redux/actions/contentModal'
import { navigate } from '@reach/router'
import { loadCurrencies } from '../../helpers/loadCurrencies'
import { Euro, MonetizationOn, RemoveRedEye } from '@material-ui/icons'
import moment from 'moment-timezone';


const copy = require('clipboard-copy');




export const ConfirmLineScreen = () => {

    const { data } = loadGridConfirmSupplier();
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const { active } = useSelector(state => state.lines);
    const { activeEdit } = useSelector(state => state.invoice);
    const dispatch = useDispatch();
    const languages = loadLanguages();
    const currencies = loadCurrencies();
    const l = languages.filter( e => e.code === (active?.activeLine?.Venta?.Idioma || activeEdit?.Idioma));
    const c = currencies.filter( e => e.code === (active?.activeLine?.Venta?.Moneda || activeEdit?.Moneda));
    const { modal } = useSelector( state => state.modal)

    
    moment.tz.setDefault("Europe/Madrid");

    const initialValues = {
        FechaRecogida: (active?.activeLine?.FechaRecogida) ? moment(active?.activeLine?.FechaRecogida) : active?.activeLine?.FechaRecogida,
        CodigoReservaProveedor: active?.activeLine?.CodigoReservaProveedor,
        ObservacionesRecogida: active?.activeLine?.ObservacionesRecogida,
        VoucherEnviado: active?.activeLine?.VoucherEnviado,
        ConfirmadaProveedor: active?.activeLine?.ConfirmadaProveedor,
        Observaciones: active?.activeLine?.Observaciones,
    }

    const handleSubmit = ( values, { setSubmitting} ) => {
        const payload = {
            ...values,
            ConfirmadaProveedor: true, // values.FechaRecogida ? true : false,
            PkLineasVenta: active.activeLine.PkLineasVenta,
            VersionFila: active.activeLine.VersionFila,
            FechaRecogida: values.FechaRecogida ? moment(values.FechaRecogida).format('YYYY-MM-DDT00:00:00') : null,
            ObservacionesRecogida: values.ObservacionesRecogida ? values.ObservacionesRecogida : "",
            Observaciones: values.Observaciones ? values.Observaciones : "",
            CodigoReservaProveedor: values.CodigoReservaProveedor ? values.CodigoReservaProveedor : "",
            VoucherEnviado: values.VoucherEnviado,
        }
        //console.log( payload )
        dispatch(startConfirmLine( payload, setSubmitting ));
    }

    const handleCopyButton = () => {

        const inSituLinea = ((active?.activeLine?.Venta?.Moneda || activeEdit?.Moneda) === 'EUR') ? 
            (+active?.activeLine?.InSituConTransporteEuros * +active?.activeLine?.Unidades) :
            (+active?.activeLine?.InSituConTransporteDolares * +active?.activeLine?.Unidades);
        
        const tasasLinea = ((active?.activeLine?.Venta?.Moneda || activeEdit?.Moneda) === 'EUR') ? 
            (+active?.activeLine?.TasaEuros * +active?.activeLine?.Unidades) :
            (+active?.activeLine?.TasaDolares * +active?.activeLine?.Unidades);
        
        const inSituLineaTexto = ((active?.activeLine?.Venta?.Moneda || activeEdit?.Moneda) === 'EUR') ? ((inSituLinea) + '€') : ((inSituLinea) + '$');
        const tasasLineaTexto = ((active?.activeLine?.Venta?.Moneda || activeEdit?.Moneda) === 'EUR') ? ((tasasLinea) + '€') : ((tasasLinea) + '$');

        copy(`Nombre: ${ active?.activeLine?.Cliente?.Nombre}, ${ active?.activeLine?.Cliente?.Apellidos}
Telfn: ${ active?.activeLine?.Cliente?.Telefono}
Hotel: ${ active?.activeLine?.HotelCliente }
Tour: ${ active?.activeLine?.Concepto}
Fecha: ${ moment(active?.activeLine?.FechaRecogida).format('DD/MM/YYYY')}
Unidades: ${ active?.activeLine?.Unidades}
Balance (in situ): ${ inSituLineaTexto }
Tasas: ${ tasasLineaTexto }
Idioma:${l[0]?.label || 'No seleccionado'}`);
    }

    const handlehandleToggleVoucherEnviado = () => {        
        const payload = {
            ConfirmadaProveedor: active.activeLine.ConfirmadaProveedor, // values.FechaRecogida ? true : false,
            PkLineasVenta: active.activeLine.PkLineasVenta,
            VersionFila: active.activeLine.VersionFila,
            VoucherEnviado: !active.activeLine.VoucherEnviado,
        }
        dispatch(startUpdateConfirmLine( payload ));
    }

    const handlehandleToggleConfirmadaProveedor = () => {        
        const payload = {
            ConfirmadaProveedor: false, // values.FechaRecogida ? true : false,
            PkLineasVenta: active.activeLine.PkLineasVenta,
            VersionFila: active.activeLine.VersionFila,
            VoucherEnviado: false,
        }
        dispatch(startUpdateConfirmLine( payload ));
    }


    const handleSendVoucher = () => {      
        dispatch(startSendingVoucherEmailFromLine());
    }



    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
        <Formik enableReinitialize validationSchema={ validationSchemaConfirmLine } initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid }) => (

        <Form style={{ marginTop: '2rem'}}>
            <Grid container spacing={4} >
                <Grid xs={12} item classes={{root: classes.container }}>
                    <Typography variant='h2'>Confirmación de la Línea</Typography>
                </Grid>


                <Grid item  
                            md={6}
                            xs={12} classes={{root: classes.container }}
                >
                    <TextField 
                    fullWidth
                    disabled
                    id="outlined-required"
                    label="Servicio"
                    variant='outlined'
                    defaultValue={`${ active?.activeLine?.Concepto}`}
                    InputProps={{
                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    />
                </Grid>
                <Grid item  
                            md={6}
                            xs={12} classes={{root: classes.container }}
                >
                    <TextField
                    fullWidth
                    disabled
                    id="outlined-required"
                    label="Cliente"
                    variant='outlined'
                    defaultValue={`${ active?.activeLine?.Cliente?.Nombre}, ${ active?.activeLine?.Cliente?.Apellidos}`}
                    InputProps={{
                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    />
                </Grid>
                <Grid item  
                            md={6}
                            xs={12} classes={{root: classes.container }}
                >
                    <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Proveedor"
                    variant='outlined'
                    defaultValue={`${ active?.activeLine?.Proveedor?.Nombre}`}
                    InputProps={{
                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    />
                </Grid>



                <Grid item  
                            md={6}
                            xs={12} classes={{root: classes.container }}
                >
                    <TextField 
                    fullWidth
                    disabled
                    id="outlined-required"
                    label="Teléfono"
                    variant='outlined'
                    defaultValue={`${ active?.activeLine?.Cliente?.Telefono}`}
                    InputProps={{
                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    />
                </Grid>
                <Grid item  
                            md={4}
                            xs={12} classes={{root: classes.container }}
                >
                    <TextField
                    disabled
                    id="outlined-required"
                    label="Unidades"
                    variant='outlined'
                    defaultValue={`${ active?.activeLine?.Unidades}`}
                    InputProps={{
                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    />
                </Grid>
                <Grid item  
                            md={4}
                            xs={12} classes={{root: classes.container }}
                >
                    {
                ((active?.activeLine?.Venta?.Moneda || activeEdit?.Moneda) === 'EUR')
                ?
                (
                    <TextField
                    disabled
                    id="outlined-disabled"
                    label="In situ"
                    variant='outlined'
                    defaultValue={`${ +active?.activeLine?.InSituConTransporteEuros * +active?.activeLine?.Unidades }`}
                    
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Euro />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }

                    />
                )
                :
                (
                    <TextField
                    disabled
                    id="outlined-disabled"
                    label="In situ"
                    variant='outlined'
                    defaultValue={`${ +active?.activeLine?.InSituConTransporteDolares * +active?.activeLine?.Unidades }`}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    />
                )}
                </Grid>

                <Grid item  
                            md={4}
                            xs={12} classes={{root: classes.container }}
                >
                    {
                ((active?.activeLine?.Venta?.Moneda || activeEdit?.Moneda) === 'EUR')
                ?
                (
                    <TextField
                    disabled
                    id="outlined-disabled-tasa"
                    label="Tasas"
                    variant='outlined'
                    defaultValue={`${ +active?.activeLine?.TasaEuros * +active?.activeLine?.Unidades }`}
                    
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Euro />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }

                    />
                )
                :
                (
                    <TextField
                    disabled
                    id="outlined-disabled-tasas"
                    label="Tasas"
                    variant='outlined'
                    defaultValue={`${ +active?.activeLine?.TasaDolares * +active?.activeLine?.Unidades }`}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MonetizationOn />
                            </InputAdornment>
                        ),

                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    />
                )}
                </Grid>

                
                <Grid item  md={12} xs={12} classes={{root: classes.container }}
                >
                    <TextField
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    label="Hotel"
                    variant='outlined'
                    defaultValue={`${ active?.activeLine?.HotelCliente }`}
                    />
                </Grid>

                <Divider style={{ marginTop: '1rem', marginBottom: '1rem'}} />
                {
                    data.map( element => {
                        return(
                            <Grid item  
                            md={12}
                            xs={12} classes={{root: classes.container }}>
                                <TextField
                                    fullWidth
                                    name={element.name}
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-${element.name}`}  
                                    label={ element.label }
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                { element.startAdornment }
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    value={ values[element.name] }
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched[element.name] && Boolean(errors[element.name])}
                                    helperText={touched[element.name] && errors[element.name]}
                                    disabled={ isSubmitting }
                                    multiline={ element.name === 'ObservacionesRecogida' ? true : false}
                                    maxRows={ element.name === 'ObservacionesRecogida' ? 10 : undefined }
                                    minRows={ element.name === 'ObservacionesRecogida' ? 5 : undefined }
                                />
                        </Grid>
                        )
                    })


                }
                


                <Grid 
                    item
                    md={12}
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Field component={FormikDatePicker} name="FechaRecogida" textfield='FechaRecogida' label='Fecha Actividad' isSubmitting={ isSubmitting } 
                        value={ (values?.FechaRecogida ? moment(values?.FechaRecogida) : values?.FechaRecogida) } 
                        
                        error={touched.FechaRecogida && Boolean(errors.FechaRecogida)}
                        helperText={touched.FechaRecogida && errors.FechaRecogida}
                    />

                    
                </Grid>

                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ marginTop: '1.5rem' }}
                >
                    <TextField 
                        fullWidth   
                        label='Idioma'
                        size='small'
                        variant='outlined'
                        classes={{ root: classes.inputText }}
                        value={ `${l[0]?.label || 'No seleccionado'}` }
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        InputProps={{

                            classes: {
                                input: classes.resize
                            }
                        }}
                        
                        disabled
                    />  
                </Grid>

                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ marginTop: '1.5rem' }}
                >
                    <TextField 
                        fullWidth   
                        label='Moneda'
                        size='small'
                        variant='outlined'
                        classes={{ root: classes.inputText }}
                        value={ `${c[0]?.label || 'No seleccionado'}` }
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        InputProps={{

                            classes: {
                                input: classes.resize
                            }
                        }}
                        
                        disabled
                    />  
                </Grid>
                
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ marginTop: '1.5rem' }}
                >
                    <TextField 
                        fullWidth   
                        label='Observaciones'
                        size='small'
                        name='Observaciones'
                        variant='outlined'
                        value={values.Observaciones}
                        classes={{ root: classes.inputText }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <RemoveRedEye />
                                </InputAdornment>
                            ),

                            classes: {
                                input: classes.resize
                            }
                        }}
                        onBlur={ handleBlur }
                        error={touched.Observaciones && Boolean(errors.Observaciones)}
                        helperText={touched.Observaciones && errors.Observaciones}
                        multiline
                        maxRows={ 10 }
                        minRows={ 5 }

                        disabled
                    />

                    
                    <FormControlLabel control={
                        <Checkbox 
                        name='VoucherEnviado'
                        checked={values.VoucherEnviado}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onClick={ handlehandleToggleVoucherEnviado }                      />
                        } label="Voucher enviado" 
                    />
                    
                    <FormControlLabel disabled={!values.ConfirmadaProveedor} control={
                        <Checkbox 
                        checked={values.ConfirmadaProveedor}
                        onClick={!values.ConfirmadaProveedor?(()=>{}):handlehandleToggleConfirmadaProveedor}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                        } label="Confirmada" 
                    />

                </Grid>
                    
                
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ marginTop: '0.2rem' }}
                >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 0
                    }}
                >
                    
                    <Button size='small'
                            style={{ marginRight:'10px', backgroundColor: '#cccccc', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                            onClick={ handleCopyButton }>
                            <Typography style={{fontWeight: 'bold'}} >Copiar info</Typography>
                        </Button>

                     <Button
                        style={{ marginRight:'10px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                        onClick={ () => {
                            if( modal ){
                                dispatch(uiManageModalCollectionTypes());
                                dispatch(startLogOutContentModal());
                            }
                            else{
                                navigate('/lines')
                            }
                        } }>
                        <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                    </Button>
                    


                    <Button
                            style={{ marginRight:'10px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                            onClick={ handleSendVoucher }>
                            <Typography style={{fontWeight: 'bold'}} >Enviar Voucher</Typography>
                        </Button>

                        

                { (values.ConfirmadaProveedor === false) &&
                    (
                    <>

                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            type='submit'
                            loading={ isSubmitting }
                            disabled={ !isValid }
                            style={{
                                backgroundColor: '#E5824F',
            
                            }}
                        >
                                Confirmar
                        </LoadingButton>
                    </>
                )}

                </Box>
                </Grid>
             
            </Grid>
        </Form>
        )}
        </Formik>
        </LocalizationProvider>
    )
}
