import { Checkbox, Grid, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CheckOutlined, ClearOutlined } from '@material-ui/icons';
import Autocomplete from '@mui/material/Autocomplete';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from '../../components/ui/Layout'
import { Spinner } from '../../components/ui/Spinner';
import { useDebounce } from '../../hooks/useDebounce';
import { useDebounceComplex } from '../../hooks/useDebounceComplex';

import ojo from '../../images/ojo.svg';
import { changeFilter, changeFilterDates, setActiveGettingLine, startDownloadCSVLinesWithOutFilter, startExportLines, startGettingLine, startHandlingLinesFilter, startLoadingLines, startResetLinesPage } from '../../redux/actions/lines';
import { LINEACONFIRMADA, NUMROWINVOICESTATEORDERLINE, PENDIENTEPROVEEDOR, ROLADMIN, ROLCOLABORADOR, ROLREVENDEDOR, ROLVENDEDOR, WHATSLINK } from '../../utils/constants';
import clsx from 'clsx';
import { useTableStyles } from '../../styles/useTableUsersStyles'
import { RangePickerMUI } from '../../components/ui/RangePickerMUI';
import {  getDateDayPlusTwo2, getDateMonthMinusOne2 } from '../../utils/dates';
import { isMatrixEmpty } from '../../utils/utils';
import { navigate } from '@reach/router';
import Alert from '@mui/material/Alert';
import pdf from '../../images/pdf.png';
import { startContentModalVoucher } from '../../redux/actions/contentModal';
import { uiManageModalNotification } from '../../redux/actions/ui';
import { NotificationModal } from '../../components/ui/NotificationModal';
import { startLoadingSuppliers } from '../../redux/actions/suppliers';
import {  startLoadingServices } from '../../redux/actions/supplierServices';
import { Form, Formik } from 'formik';
 
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
 
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

import esLocale from 'date-fns/locale/es'
import moment from 'moment-timezone';
import whats from '../../images/whatsapp.svg';
import _ from 'lodash';


const LineasScreen = () => {
    const result = [];
    const { year, month, day } = getDateDayPlusTwo2();
    const { yearY, monthY, dayY } = getDateMonthMinusOne2();

    const theme = useTheme();
    const dispatch = useDispatch()
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const { menuState } = useSelector(state => state.ui);
    const { route } = useSelector(state => state.route);
    const { idRol, moneda: monedaUsuario } = useSelector(state => state.auth)
    const { services } = useSelector(state => state.service);
    const { suppliers } = useSelector( state => state.supplier)
    const [ searchClientes, debounceSearchResultClientes ] = useDebounceComplex('/clientes/search/', {start: 'Nombre', second:'Apellidos', end: 'Email', id: "PkClientes"});
    const [ searchUsuarios, debounceSearchResultUsuarios ] = useDebounce('/usuarios?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkUsuarios"});

    const [ searchComisionistas, debounceSearchComisionistas ] = useDebounce('/comisionistas?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkComisionistas"});

    const { lines:{ dateRange, currentFilter: filterOptions, page, data, count, sum, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares } } = useSelector(state => state.lines);
    const {loading} = useSelector(state => state.ui);
    //const [dateRange, setDateRange] = useState([new Date(yearY, monthY, dayY).toISOString(), new Date( year, month, day ).toISOString()])
    /*const initialFilterValue = [[],[],[],[],[],[],[],[],[],[],[],[],[],[]]
    if (idRol === ROLCOLABORADOR) {
      filterOptions[10][0] = 'Pendiente de Proveedor';
      dispatch(changeFilter (filterOptions, page, dateRange));
    }*/
    //const [filterOptions, setFilterOptions] = useState(initialFilterValue);
    const classes = useTableStyles();

    useEffect( () => {
      if( idRol !== ROLCOLABORADOR ){
        dispatch( startLoadingSuppliers() )
      }
      dispatch( startLoadingServices() )
    }, [ dispatch, idRol ])

    useEffect(() => {
        /*if(isMatrixEmpty(filterOptions)){
          console.log('useEffect isMatrixEmpty startLoadingLines');
            dispatch(startLoadingLines( page, dateRange ))
        }
        else{
          console.log('useEffect startHandlingLinesFilter');
          console.log(filterOptions);
          console.log(page);
          console.log(dateRange);*/
          //if (idRol === ROLCOLABORADOR) {
            // filterOptions[10][0] = 'Pendiente de Proveedor';
          //  dispatch(startHandlingLinesFilter (filterOptions, page, dateRange));
          //}
          //else {
            dispatch(startHandlingLinesFilter( filterOptions, page, dateRange ))
          //}
        //}

        
    }, []); //, page, dateRange, filterOptions ]);

    


    useEffect(() => {
      if( route ){
        navigate( route )

      }
    }, [route])

    const handleEdit = ( dataIndex, e ) => {
      if (e) e.stopPropagation();
      dispatch(startGettingLine( data[dataIndex].PkLineasVenta))
    }

    const handleDownloadVoucher = ( dataIndex, e) => {
      if (e) e.stopPropagation();
      Promise.resolve( dispatch(setActiveGettingLine( data[ dataIndex ].PkLineasVenta)) )
      .then( () => {
        dispatch( startContentModalVoucher() )
      })
      .then( () => {
        dispatch(uiManageModalNotification())
      })

    }

    const handleSendWhatsApp = ( dataIndex, e ) => {
      if (e) e.stopPropagation();
      window.open(WHATSLINK.concat(`${data[ dataIndex ]?.Venta?.Cliente?.Telefono}`))
     }

     const handleDownload = () => {
      if( !isMatrixEmpty(filterOptions) ){
        dispatch(startExportLines(filterOptions, page, dateRange))
      }

      else{
          dispatch(startDownloadCSVLinesWithOutFilter(dateRange))
      }
        
    }

    // Nombre, Teléfono, Hotel, Tour, Fecha actividad, Unidades, Reserva, In situ, Confirmada, Balance, Vendedor, Proveedor, Acciones
    const columns = [
        {
          label: 'Venta',
          name: 'Venta',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[0] : [],
            display: !matchesXS,
            sort: false,
          }, 
        },
        {
          label: 'Cliente',
          name: 'Cliente',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[1] : [],
            display: true,
            sort: false,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      id="combo-box-client"
                      options={ searchClientes }
                      sx={{ width: 300 }}
                      noOptionsText={`No hay clientes`}
                      onInputChange={ debounceSearchResultClientes }
                      onChange={(event, newInputValue) => {
                        filterList[1][0] = newInputValue?.value
                        onChange(filterList[1], index, column)
                      }}
                      renderInput={(params) => <TextField {...params} label="Cliente" />}
                    />
                  </FormControl>
                );
              }
            }
          }

        },
        {
          label: 'Email Cliente',
          name: 'EmailCliente',
          options: {
              filter: true,
              sort: false,
              display: false,
              viewColumns: idRol === ROLADMIN
          }
        }, 
        {
          label: 'Teléfono',
          name: 'TelefonoCliente',
          options: {
            filter: false,
            display: !matchesXS,
            sort: false,
          }, 
        },
        {
          label: 'Hotel',
          name: 'HotelCliente',
          options: {
            filter: false,
            display: !matchesXS,
            sort: false,
          }, 
        },        
        {
          label: 'Servicio',
          name: 'Servicio',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[5] : [],
            display: !matchesXS,
            sort: false,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      id="combo-box-client"
                      ListboxProps={{ style: { maxHeight: '10rem' } }}
                      options={ services.map ((service) => { return { label: `${service.NombreServicio}`, value: service.PkServiciosProveedores }}) }
                      sx={{ width: 300 }}
                      noOptionsText={`No hay servicios`}
                      onChange={(_, newInputValue) => {
                        filterList[5][0] = newInputValue?.value
                        onChange(filterList[5], index, column)
                      }}
                      renderInput={(params) => <TextField {...params} label="Servicio" />}
                    />
                  </FormControl>
                );
              }
            }
          }
        },
        {
          label: 'Fecha Actividad',
          name: 'Fecha Actividad',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[6] : [],
            sort: false,
            display: !matchesXS,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
                    <Formik
                      initialValues={{
                        FechaRecogida: null,
                      }
                    } 
                    >
                       {({ values, setFieldValue }) => (
                      <Form>
                      <DesktopDatePicker
                        name='FechaRecogida'
                        keyboard
                        label={`Fecha Recogida`}
                        format="dd/MM/yyyy"
                        placeholder="10/10/2018"
                        mask={value =>
                            value
                            ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
                            : []
                        }
                        disableOpenOnEnter
                        onChange={(value) => {
                            setFieldValue(`FechaRecogida`, value);
                            filterList[6][0] = moment(value).format('YYYY-MM-DD')
                            onChange(filterList[6], index, column)

                        }}
                        value={ values.FechaRecogida }
                        renderInput={(params) => <TextField  {...params} />}
                    />
                     </Form>
                    )}
                    </Formik>
                  </LocalizationProvider>
                );
              }
            }
          }

        },
        {
            label: 'Unidades',
            name: 'Unidades',
            options: {
                filter: false,
                display: (!matchesXS && idRol !== ROLCOLABORADOR),
                sort: false,
            }
        },
        {
            label: 'Reserva',
            name: 'Reserva',
            options: {
              filter: false,
              sort: false,
              display: (!matchesXS && idRol !== ROLCOLABORADOR)
            }
        },
        {
        label: 'In Situ',
        name: 'InSitu',
        options: {
            filter: false,
            sort: false,
            display: (!matchesXS && idRol !== ROLCOLABORADOR)
          }
        }, 
        {
        label: 'Margen Revendedor',
        name: 'ReventaEuros',
        options: {
            filter: false,
            sort: false,
            display: false, //(!matchesXS && idRol !== ROLCOLABORADOR)
          }
        }, 
        {
          label: '¿Confirmada?',
          name: 'Estado Línea',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[11] : [],
            sort: false,
            display: true,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                const optionValues = [PENDIENTEPROVEEDOR, LINEACONFIRMADA];

                return (
                  <FormControl>
                    <InputLabel htmlFor='select-multiple-chip'>
                    Estado Línea
                  </InputLabel>
                  <Select
                    value={filterList[index]}
                    renderValue={selected => selected.join(', ')}
                    onChange={event => {
                      filterList[11][0] = event.target.value;
                      onChange(filterList[11], index, column);
                    }}
                  >
                    {optionValues.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color='primary'
                          checked={filterList[11].indexOf(item) > -1}
                        />
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                );
              }
            }
          }

        },
        {
          label: 'Balance',
          name: 'BalanceDolares',
          options: {
              filter: false,
              sort: false,
              viewColumns : (idRol === ROLADMIN || idRol === ROLVENDEDOR),
              display: (!matchesXS && idRol !== ROLCOLABORADOR && idRol !== ROLREVENDEDOR)
          }
        }, 
        {
          label: 'Vendedor',
          name: 'Vendedor',
          options: {
            filter: true,
            filterList: filterOptions ? filterOptions[13] : [],
            sort: false,
            display: !matchesXS,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      id="combo-box-client"
                      options={ searchUsuarios }
                      sx={{ width: 300 }}
                      noOptionsText={`No hay usuarios`}
                      onInputChange={ debounceSearchResultUsuarios }
                      onChange={(_, newInputValue) => {
                        filterList[13][0] = newInputValue?.value
                        onChange(filterList[13], index, column)
                      }}
                      renderInput={(params) => <TextField {...params} label="Vendedor" />}
                    />
                  </FormControl>
                );
              }
            }
          }

        },
        

        {
          label: 'Proveedor',
          name: 'Proveedor',
          options: {
            filter: idRol !== ROLCOLABORADOR,
            filterList: filterOptions ? filterOptions[14] : [],
            display: !matchesXS,
            sort: false,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      id="combo-box-client"
                      ListboxProps={{ style: { maxHeight: '10rem' } }}
                      options={ suppliers.map ((supplier) => { return { label: `${supplier.Nombre}`, value: supplier.PkProveedores }}) }
                      sx={{ width: 300 }}
                      noOptionsText={`No hay proveedores`}
                      onChange={(event, newInputValue) => {
                        filterList[14][0] = newInputValue?.value;
                        onChange(filterList[14], index, column)
                      }}
                      renderInput={(params) => <TextField {...params} label="Proveedor" />}
                    />
                  </FormControl>
                );
              }
            }
          }

        },
        
        {
          label: 'Reserva Pagada',
          name: 'ReservaPagada',
          options: {
              filter: false,
              sort: false,
              display: false
          }
        }, 

        

        
        
        {
          label: 'Comisionista',
          name: 'Comisionista',
          options: {
            filter: (idRol === ROLADMIN || idRol === ROLVENDEDOR),
            filterList: filterOptions ? filterOptions[16] : [],
            display: false,
            viewColumns : (idRol === ROLADMIN || idRol === ROLVENDEDOR),
            sort: false,
            filterType: 'custom',
            filterOptions:{
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      id="combo-box-client"
                      options={ searchComisionistas }
                      sx={{ width: 300 }}
                      noOptionsText={`No hay comisionistas`}
                      onInputChange={ debounceSearchComisionistas }
                      onChange={(_, newInputValue) => {
                        filterList[16][0] = newInputValue?.value
                        onChange(filterList[16], index, column)
                      }}
                      renderInput={(params) => <TextField {...params} label="Comisionista" />}
                    />
                  </FormControl>
                );
              }
            }
          }

        },

        {
          name: "Acciones",
          options: {
            filter: false,
            sort: false,
            display: true,
            customBodyRenderLite: ( dataIndex ) => {
              if( data[ dataIndex ]?.ConfirmadaProveedor ){
                return(
                  <>
                    {
                      (idRol !== ROLVENDEDOR && idRol !== ROLREVENDEDOR)
                      &&
                      <>
                        <IconButton style={{ color: `#000`, width: '1rem', height: '2rem'}} onClick={ e => handleEdit( dataIndex, e ) }>
                          <img src={`${ ojo }`}  alt='img'/>
                        </IconButton>
                      </>
                    }
                    
                    
                    <IconButton style={{ color: `#000`, width: '1rem', height: '2rem'}} onClick={ e => handleDownloadVoucher(dataIndex, e) }>
                      <img src={`${ pdf }`}  alt='img'/>
                    </IconButton>
                    <IconButton style={{ color: `#000`, width: '1rem', height: '2rem'}} onClick={ e => handleSendWhatsApp( dataIndex, e ) }>
                      <img src={`${ whats }`}  alt='img'/>
                    </IconButton>
                  </>
                )
              }
              else{
                return (
                  <>
                    {
                      (idRol !== ROLVENDEDOR && idRol !== ROLREVENDEDOR)
                      &&
                      <>
                        <IconButton style={{ color: `#000`, width: '1rem', height: '2rem'}} onClick={ e => handleEdit( dataIndex, e ) }>
                          <img src={`${ ojo }`}  alt='img'/>
                        </IconButton>
                      </>
                    }
                     
                    <IconButton style={{ color: `#000`, width: '1rem', height: '2rem'}} onClick={ e => handleSendWhatsApp( dataIndex, e ) }>
                      <img src={`${ whats }`}  alt='img'/>
                    </IconButton>
                  </>
                )
              }
            },
            setCellHeaderProps: () => {
              return {
                className: clsx({
                  [classes.NameCell]: true,
                }),
                style:{
                  fontSize: 15,
                  fontWeight: 500
                }
              }
            }
          }
        },
        
      ];
      const options = {
        filterType: 'textField',
        responsive: 'standard',
        filename: 'Lineas de Venta',
        rowsPerPage: NUMROWINVOICESTATEORDERLINE,
        serverSide: true,
        count: count,
        page,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            if (action === "changePage") {
                    dispatch(startHandlingLinesFilter( tableState.filterList, tableState.page, dateRange ))                
            }
        },
        onFilterChange: (_, filterList) => {
          console.log('onFilterChange');
          console.log(filterList);
            //setFilterOptions(filterList);
            //dispatch(startResetLinesPage())
            dispatch(changeFilter (filterList, page, dateRange));
          },
          draggableColumns: {
            enabled: true
          },
          downloadOptions: {
              filename: `lineas_venta.csv`,
              separator: ';',
              filterOptions: {
                  useDisplayedColumnsOnly: true,
                  useDisplayedRowsOnly: true,
                }
          },
        print: false,
        download: (idRol === ROLADMIN || idRol === ROLVENDEDOR) ? true : false,
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: false,
        textLabels: {
          body: {
            noMatch: loading ? <Spinner /> : "Lo siento, no se han encontrado registros",
          },
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas por página",
            displayRows: "de",
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            viewColumns: "Consultar Columnas",
            filterTable: "Filtrar Tabla",
          },
          filter: {
            all: "Incluir dados de baja",
            title: "Fitros",
            reset: "Reset",
          },
          viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas Tablas",
          },
          selectedRows: {
            text: "fila(s) seleccionadas",
            delete: "Borrar",
            deleteAria: "Borrar filas seleccionadas",
          },
        },
        searchOpen: false,
        search: false,
  
        onRowClick : (rowData, rowMeta) => { 
          if( idRol === ROLADMIN || idRol === ROLCOLABORADOR ) {
            handleEdit(rowMeta.dataIndex);
          } 
        },
        onDownload: (buildHead, buildBody, columns, data) => {
          if( idRol === ROLADMIN || idRol === ROLVENDEDOR ) {
            handleDownload();
          }
          
          return false;
        }
      };

      
      
      moment.tz.setDefault("Europe/Madrid");

    // Nombre, Teléfono, Hotel, Tour, Fecha actividad, Unidades, Reserva, In situ, Confirmada, Balance, Vendedor, Proveedor, Acciones
    data.map( line => {

      if (idRol!==ROLREVENDEDOR) {
        result.push([line?.FkVentas, line?.Venta?.Cliente?.Nombre, line?.Venta?.Cliente?.Email,
            line?.Venta?.Cliente?.Telefono,
            line?.Venta?.HotelCliente,
            line?.ServicioProveedor?.Nombre || line?.Concepto, 
          (line?.FechaRecogida ? 
            moment(line?.FechaRecogida).format('DD/MM/YYYY')
            : '') ,           
            line?.Unidades, 
            (Math.round(100*(line?.ReservaLineaEuros)*line?.Unidades)/100) + ' €', 
            (line?.InSituConTransporteEuros*line?.Unidades) + ' €', 
            (Math.round(100*line?.ReventaLineaEuros*line?.Unidades)/100) + ' €', 
            line?.ConfirmadaProveedor ? <CheckOutlined /> : <ClearOutlined />, 
            line?.BalanceDolares + ' $',
            line?.Venta?.Usuario?.Nombre, line?.Proveedor?.Nombre,
            line?.ReservaPagada ? <CheckOutlined /> : <ClearOutlined />,
            line?.Venta?.Comisionista?.Nombre,
        ])
      }
      else {
        if (monedaUsuario === 'USD') {
          result.push([line?.FkVentas, line?.Venta?.Cliente?.Nombre,  line?.Venta?.Cliente?.Email,
              line?.Venta?.Cliente?.Telefono,
              line?.Venta?.HotelCliente,
              line?.ServicioProveedor?.Nombre || line?.Concepto, 
            (line?.FechaRecogida ? 
              moment(line?.FechaRecogida).format('DD/MM/YYYY')
              : '') ,           
              line?.Unidades, 
              ((line?.ReservaLineaDolares)*line?.Unidades) + ' $', 
              (line?.InSituConTransporteDolares*line?.Unidades) + ' $', 
              (line?.ReventaLineaDolares*line?.Unidades) + ' $', 
              line?.ConfirmadaProveedor ? <CheckOutlined /> : <ClearOutlined />, 
              line?.BalanceDolares + ' $',
              line?.Venta?.Usuario?.Nombre, line?.Proveedor?.Nombre,
              line?.ReservaPagada ? <CheckOutlined /> : <ClearOutlined />,
              line?.Venta?.Comisionista?.Nombre,
          ])

        }
        else {
          result.push([line?.FkVentas, line?.Venta?.Cliente?.Nombre,  line?.Venta?.Cliente?.Email,
              line?.Venta?.Cliente?.Telefono,
              line?.Venta?.HotelCliente,
              line?.ServicioProveedor?.Nombre || line?.Concepto, 
            (line?.FechaRecogida ? 
              moment(line?.FechaRecogida).format('DD/MM/YYYY')
              : '') ,           
              line?.Unidades, 
              (Math.round(100*(line?.ReservaLineaEuros)*line?.Unidades)/100) + ' €', 
              (line?.InSituConTransporteEuros*line?.Unidades) + ' $', 
              (Math.round(100*line?.ReventaLineaEuros*line?.Unidades)/100) + ' €', 
              line?.ConfirmadaProveedor ? <CheckOutlined /> : <ClearOutlined />, 
              line?.BalanceDolares + ' $',
              line?.Venta?.Usuario?.Nombre, line?.Proveedor?.Nombre,
              line?.ReservaPagada ? <CheckOutlined /> : <ClearOutlined />,
              line?.Venta?.Comisionista?.Nombre,
          ])
        }
      }
        return null;
      })


    return (
        <Layout>
            
            <Helmet>
                <title>Líneas de Venta | Xplora</title>
            </Helmet>

            <div style={{
                    marginTop: '2rem',
                    display: 'flex',
                    flexDirection: 'column',
                    width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}>
                {
                    !matchesXS
                    &&
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>LÍNEAS DE VENTA</Typography>
                }
            {
              (idRol ===ROLADMIN || idRol === ROLVENDEDOR) 
              &&
              (
                <>
                   <Alert severity="info">
                      <strong>Total ventas:</strong> { Math.round(sum) }€ 
                      { matchesXS && <br/> }
                      <strong style={{ marginLeft: '0.8rem'}}>Insitu:</strong> { Math.round(sumInSituEuros) }€ 
                      { matchesXS && <br/> }
                      <strong style={{ marginLeft: '0.8rem'}}>Reserva:</strong> { Math.round(100*sumReservaEuros)/100 }€ 
                      { matchesXS && <br/> }
                      <strong style={{ marginLeft: '0.8rem'}}>Descuento:</strong> { Math.round(100*sumDescuentoEuros)/100 }€ 
                      {(idRol ===ROLADMIN) 
                        &&
                        (
                          <>
                            { matchesXS && <br/> }
                            <strong style={{ marginLeft: '0.8rem'}}>Margen Revendedor:</strong> { Math.round(100*sumReventaEuros)/100 }€ 
                            { matchesXS && <br/> }
                            <strong style={{ marginLeft: '0.8rem'}}>Balance con proveedores:</strong> { Math.round(sumBalanceDolares) }$ 
                          </>
                        )
                        }
                  </Alert>
                </>
              )
            }


                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
                    <Formik
                      initialValues={{
                        FechaDesde: dateRange[0],
                        FechaHasta:  dateRange[1],
                      }
                    } 
                    >
                       {({ values, setFieldValue }) => (
                        
                      <Form>
                        
            <Grid style={{ marginTop: '0.8rem'}}
            container
            spacing={3}
            
            >
              <Grid item  
                            md={4}
                            xs={12} classes={{root: classes.container }}
                >

                      <DesktopDatePicker
                        name='FechaDesde'
                        keyboard
                        label={`Fecha Desde`}
                        format="dd/MM/yyyy"
                        placeholder="10/10/2018"
                        mask={value =>
                            value
                            ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
                            : []
                        }
                        disableOpenOnEnter
                        onChange={(value) => {
                          
                          let dateselected = moment(value).add(2, 'hours').utcOffset(0);
                          dateselected.set({hour:0,minute:0,second:0,millisecond:0});
                          dateselected.toISOString();
                          dateselected.format(); 
  
                              setFieldValue(`FechaDesde`, value);
                              //setDateRange([dateselected, dateRange[1]]);
                              dispatch(changeFilterDates (filterOptions, page, [dateselected, dateRange[1]]));
                        }}
                        value={ values.FechaDesde }
                        renderInput={(params) => <TextField  {...params} />}
                    />
                    
                </Grid>

                <Grid item  
                            md={4}
                            xs={12} classes={{root: classes.container }}
                >

                      <DesktopDatePicker
                        name='FechaHasta'
                        keyboard
                        label={`Fecha Hasta`}
                        format="dd/MM/yyyy"
                        placeholder="10/10/2018"
                        mask={value =>
                            value
                            ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
                            : []
                        }
                        disableOpenOnEnter
                        onChange={(value) => {
                            
                          let dateselected = moment(value).add(2, 'hours').utcOffset(0);
                          dateselected.set({hour:23,minute:59,second:59,millisecond:0});
                          dateselected.toISOString();
                          dateselected.format(); 
  
                            setFieldValue(`FechaHasta`, value);
                            //setDateRange([dateRange[0], dateselected]);
                            dispatch(changeFilterDates(filterOptions, page, [dateRange[0], dateselected]));


                        }}
                        value={ values.FechaHasta }
                        renderInput={(params) => <TextField  {...params} />}
                    />
                    
                    </Grid>
                    </Grid>
                     </Form>
                    )}
                    </Formik>
                  </LocalizationProvider>

            
           {// <RangePickerMUI value={ dateRange } setValue={ setDateRange }/>
           }
            <MUIDataTable
              data={result}
              columns={columns}
              options={options}
            />
            </div>

            <NotificationModal />
        </Layout>
    )

}

export default LineasScreen;
