
import { useSelector } from "react-redux";


export const useDataCharts = () => {
    const { graphic } = useSelector( state => state.dashboard)
    const dataVentas = Object?.values(graphic || {}).map( e => Math.round(e.ImporteVentas));
    const meses = Object?.values(graphic || {}).map( e => e.Mes)
    const dataBar = {
        labels: meses,
        datasets: [
          {
            label: 'Ventas Mes',
            data: dataVentas,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
    const optionsBar = {
      indexAxis: 'y',
      // Elements options apply to all of the options unless overridden in a dataset
      // In this case, we are setting the border of each horizontal bar to be 2px wide
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
        },
        title: {
          display: true,
          text: 'Ventas pagadas por mes',
        },
      },
    };

    return {
        dataBar,
        optionsBar
    };
};

export const useCollaboratorDataCharts = () => {
  const { graphic } = useSelector( state => state.dashboard)
  const num = Object?.values(graphic || {}).map( e => e.NumeroLineasConfirmadas);
  const meses = Object?.values(graphic || {}).map( e => e.Mes)
  const dataBar = {
      labels: meses,
      datasets: [
        {
          label: 'Lineas Mes',
          data: num,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  const optionsBar = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Líneas Confirmadas por mes',
      },
    },
  };

  return {
      dataBar,
      optionsBar
  };
};