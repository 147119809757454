import {  useParams } from '@reach/router';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { ClientInfo } from '../../components/clients/clientInfo';
import { Layout } from '../../components/ui/Layout'
import { actionEditCustomer } from '../../redux/actions/actions';

const ClientDetailScreen = () => {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        dispatch(actionEditCustomer(params.PkClientes))
    }, [ dispatch, params.PkClientes ]);

    return (
        <Layout>
            <div style={{ marginTop: '2rem'}}/>
                <ClientInfo />
            
        </Layout>
    )
}


export default ClientDetailScreen;
