import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { Delete, RemoveCircleOutlined } from '@material-ui/icons'
import React from 'react'
import { useDispatch } from 'react-redux'
import { startLogOutContentModal } from '../../redux/actions/contentModal'
import { uiManageModalNotification } from '../../redux/actions/ui'

export const Content = ({ title, text, action }) => {
    const dispatch = useDispatch();
    const handleBack = () => {
        dispatch( uiManageModalNotification()); //we close the confirmation modal when
        dispatch( startLogOutContentModal() )
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    { text }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                    <Button 
                        variant='outlined' 
                        startIcon={<RemoveCircleOutlined />} 
                        onClick={ handleBack }
                        color='secondary'
                        style={{
                            borderRadius: 7
                        }}
                    >
                        CANCELAR
                    </Button>
                    <Button 
                        variant='outlined' 
                        startIcon={<Delete />} 
                        onClick={ action }
                        style={{
                            borderRadius: 7
                        }}
                    >
                        ACEPTAR
                    </Button>
            </DialogActions>
        </div>
    )
}
