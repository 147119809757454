import { makeStyles } from "@material-ui/core";

export const useFormStyles = makeStyles(theme => ({
    inputText:{
        position: 'relative',
        width: '100%',
    },

    resize: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 18
        },
        
        fontSize: '0.7em'
        
    },

    resize_xs: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        },
        
        fontSize: '0.5em'
        
    },

    resizeEmail :{
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        }
    },
    button:{
        color: '#fff'
    },
    container:{
        marginBottom: '1.6rem'
    },
    switch:{
        color: '#000',
    },
    buttonContainer:{
        border: '1px solid',
        backgroundColor: '#000',
        
    },
    outerDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        
    },
    rangeDate: {
        border: 'none'
    }
}))