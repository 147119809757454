import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { BeachAccess, EuroOutlined, MonetizationOnOutlined, RemoveRedEye, Web } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useFormStyles } from '../../styles/formStyles';
import { validationSchemaSupplierServiceEdit } from '../../utils/schemaValidationForm';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { startExchangeRateToSupplier, startUpdateServiceSupplierFromSupplier } from '../../redux/actions/supplierServices';
import { Box } from '@mui/system';
import { startLogOutContentModal } from "../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../redux/actions/ui";
import Alert from '@mui/material/Alert';
import { calculateFromDollarsToEuro } from '../../utils/utils';
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../utils/exchange';
const isEmpty = (obj) => {
    return Object.keys( obj || {} ).length === 0;
}

export const ContentModalSupplerServiceEdit = () => {
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const dispatch = useDispatch()
    const [suppliers, setSuppliers] = useState([]);
    const [valueSupplier, setValueSupplier] = useState('');
    const [serviceType, setServiceType] = useState('')
    const { rate } = useSelector(state => state.service);
    const { active: activeService } = useSelector(state => state.service);
    const { error } = useSelector(state => state.ui);

    const initialValues =  {
        FkProveedores: activeService.ProveedorServicio.PkProveedores,
        FkTiposServicio: activeService.TipoServicio.PkTiposServicio,
        Nombre: activeService.Nombre,
        Alias: activeService.Alias,
        CosteDolares: activeService.CosteDolares,
        TasaDolares: activeService.TasaDolares,
        PrecioVentaEuros: activeService.PrecioVentaEuros,
        PaginaWeb: activeService.PaginaWeb,
        Observaciones: activeService.Observaciones,
    }


    useEffect(() => {
        if( serviceType ){
            dispatch(startExchangeRateToSupplier(activeService.ProveedorServicio.PkProveedores, serviceType))
        }
    }, [ serviceType, dispatch ])


    
   

    const handleSubmit = ( values, {setSubmitting} ) => {
        
        const service = {
            "PkServiciosProveedores": activeService.PkServiciosProveedores,
            "FkTiposServicio": values.FkTiposServicio,
            "FkProveedores": values.FkProveedores,
            "Nombre": values.Nombre,
            "Alias": values.Alias,
            "CosteDolares": Math.round( +values.CosteDolares),
            "TasaDolares": Math.round( +values.TasaDolares),
            //"ReservaEuros":(
            //    values['PrecioVentaEuros'] - obtenerPrecioEuros(values['CosteDolares'], rate?.CambioEuros, rate?.CambioDolares)),
            "PrecioVentaEuros": Math.round( +values.PrecioVentaEuros),
            "PaginaWeb": values.PaginaWeb,
            "Observaciones": values.Observaciones??'',
            "VersionFila": activeService.VersionFila,
        }
        //console.log( service )
        dispatch( startUpdateServiceSupplierFromSupplier( service, setSubmitting ));
    }

    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };

    return (
        <Formik enableReinitialize initialValues={initialValues} validationSchema={ validationSchemaSupplierServiceEdit }   onSubmit={handleSubmit}>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, setFieldValue, values }) => (
            <Form>
                <Grid item style={{padding: '2rem'}}>
                    <Grid container direction='column'>
                        <Grid item classes={{root: classes.container }}>
                            <Typography variant='h1'>Editar Servicio</Typography>
                        </Grid>
                        { error && <Box sx={{ mb: 3 }}><Alert style={{ backgroundColor: '#F3D1C7'}} severity="error">{ error }</Alert></Box> }
                        <FormControl
                                style={{
                                    marginBottom: '2rem'
                                }}
                                disabled={ isSubmitting }
                                onBlur={ handleBlur }
                                onChange={ handleChange }
                                error={touched.FkTiposServicio && Boolean(errors.FkTiposServicio)}
                                helperText={touched.FkTiposServicio && errors.FkTiposServicio}                   
                            >
                                <InputLabel style={{fontSize: '0.8em'}} id="select-label-exchange-rates">Tipo de Servicio</InputLabel>
                                
                                    <Select style={{fontSize: '0.8em'}}
                                        name='FkTiposServicio'
                                        value={values.FkTiposServicio}
                                        onChange={e => {
                                            setFieldValue("FkTiposServicio", e.target.value);
                                            setServiceType(e.target.value);
                                        }}
                                        variant='outlined'
                                    >
                                        <MenuItem style={{fontSize: '0.7em'}} value={2}>Parque</MenuItem>
                                        <MenuItem style={{fontSize: '0.7em'}} value={1}>Tour</MenuItem>

                                    </Select>
                                   
                            </FormControl>

                            <Autocomplete
                                    options={suppliers}
                                    noOptionsText={"No hay proveedores"}
                                    getOptionLabel={option => option.label}
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    renderInput={(params) => ( 
                                        <TextField 
                                            {...params}
                                            name='FkProveedores' 
                                            variant='outlined' 
                                            size='small'
                                            label="Proveedor" 
                                            placeholder='Busca...'
                                            disabled={ isSubmitting }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched.FkProveedores && Boolean(errors.FkProveedores)}
                                            helperText={touched.FkProveedores && errors.FkProveedores}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <BeachAccess />
                                                    </InputAdornment>
                                                ),
            
                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                            

                                        />
                                    )}
                                    sx={{ marginBottom: '2rem'}}
                                    name="FkProveedores"
                                    defaultValue={{label: activeService.ProveedorServicio.Nombre, value: activeService.ProveedorServicio.PkProveedores }}
                                    onChange={(_, value) => {
                                        setFieldValue(
                                        "FkProveedores",
                                        value?.value !== null ? value?.value : values.FkProveedores
                                        );
                                        setValueSupplier(value?.value)

                                    }}
                                    disabled
                                />

                            <Grid  xs={12} 
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    name='Nombre'
                                    size='small'
                                    label='Nombre'
                                    variant='outlined'
                                    value={ values.Nombre }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BeachAccess />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.Nombre && Boolean(errors.Nombre)}
                                    helperText={touched.Nombre && errors.Nombre}                   
                                />
                            </Grid>

                            <Grid  xs={12} 
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    name='Alias'
                                    size='small'
                                    label='Alias'
                                    variant='outlined'
                                    value={ values.Alias }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BeachAccess />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.Alias && Boolean(errors.Alias)}
                                    helperText={touched.Alias && errors.Alias}                   
                                />
                            </Grid>


                    </Grid>
                    <Grid container spacing={3}> 

                            <Grid md={3} xs={6}
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    name='CosteDolares'
                                    label='Coste'
                                    variant='outlined'
                                    value={ values.CosteDolares }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MonetizationOnOutlined />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.CosteDolares && Boolean(errors.CosteDolares)}
                                    helperText={touched.CosteDolares && errors.CosteDolares}                   
                                />
                            </Grid>


                            <Grid  md={3} xs={6} 
                            item  
                            classes={{root: classes.container }}
                            style={{
                                display: (errors.CosteDolares && touched.CosteDolares && 'none') 
                            }}
                        >
                            <TextField
                                fullWidth
                                size='small'
                                label='Coste'
                                variant='outlined'
                                classes={{ root: classes.inputText }}
                                value={ 
                                    rate ?
                                    (obtenerPrecioEuros(values.CosteDolares, rate?.CambioEuros, rate?.CambioDolares) || 0) 
                                    : ''
                                }
                                inputProps={
                                    { readonly: true }
                                }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroOutlined />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                            />


                        </Grid>
                        


                            <Grid  md={3} xs={6} 
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    name='PrecioVentaEuros'
                                    label='Precio Venta'
                                    variant='outlined'
                                    value={ values.PrecioVentaEuros }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EuroOutlined />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.PrecioVentaEuros && Boolean(errors.PrecioVentaEuros)}
                                    helperText={touched.PrecioVentaEuros && errors.PrecioVentaEuros}                   
                                />
                            </Grid>

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                            <TextField 
                                fullWidth   
                                label='Precio Venta'
                                size='small'
                                variant='outlined'
                                name='BalanceEuros'
                                value={ 
                                    rate ?
                                    (obtenerPrecioDolares(values['PrecioVentaEuros'], rate?.CambioEuros, rate?.CambioEuros) || 0)
                                    : ''
                                 }
                                classes={{ root: classes.inputText }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MonetizationOnOutlined />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                disabled={ isSubmitting }
                            />
                        </Grid>

                        {
                            (values.PrecioVentaEuros && values.PrecioVentaEuros >= calculateFromDollarsToEuro(rate, values.CosteDolares))

                            &&
                            
                            (
                                <>
                                     <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                        <TextField 
                                            fullWidth   
                                            label='Reserva'
                                            size='small'
                                            variant='outlined'                                  
                                            value={ 
                                                rate ?
                                                (( values['PrecioVentaEuros'] - 
                                                    obtenerPrecioEuros(values['CosteDolares'], rate?.CambioEuros, rate?.CambioDolares)) || 0 )
                                                : ''
                                            }
                                            classes={{ root: classes.inputText }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EuroOutlined />
                                                    </InputAdornment>
                                                ),

                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                            InputLabelProps={
                                                {style: {fontSize: '0.8em'}}
                                            }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            disabled={ isSubmitting }
                                        />
                                    </Grid>



                                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                        <TextField 
                                            fullWidth   
                                            label='Reserva'
                                            size='small'
                                            variant='outlined'                                  
                                            value={ 
                                                rate ?
                                                ((
                                                    obtenerPrecioDolares(values['PrecioVentaEuros'], rate?.CambioEuros, rate?.CambioDolares) - 
                                                    values['CosteDolares']) || 0)
                                                : ''
                                            }
                                            classes={{ root: classes.inputText }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MonetizationOnOutlined />
                                                    </InputAdornment>
                                                ),

                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                            InputLabelProps={
                                                {style: {fontSize: '0.8em'}}
                                            }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            disabled={ isSubmitting }
                                        />
                                    </Grid>
                                </>
                            )
                        }
                       


                           
                       <Grid  md={3} xs={6} 
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    name='TasaDolares'
                                    label='Tasa Dólares'
                                    variant='outlined'
                                    value={ values.TasaDolares }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MonetizationOnOutlined />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                    helperText={touched.TasaDolares && errors.TasaDolares}                   
                                />
                            </Grid>

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                            <TextField 
                                fullWidth   
                                label='Tasa Euros'
                                size='small'
                                variant='outlined'
                                value={ 
                                    rate ?
                                    (obtenerPrecioEuros(values['TasaDolares'], rate?.CambioEuros, rate?.CambioDolares) || 0)
                                    : ''
                                 }
                                classes={{ root: classes.inputText }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroOutlined />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                disabled={ isSubmitting }
                            />
                        </Grid>
                        


                           </Grid>
                    <Grid container spacing={3}> 
                    
                            <Grid xs={12}
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    name='PaginaWeb'
                                    label='Pagina Web'
                                    variant='outlined'
                                    size='small'
                                    value={ values.PaginaWeb }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Web />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.PaginaWeb && Boolean(errors.PaginaWeb)}
                                    helperText={touched.PaginaWeb && errors.PaginaWeb}                   
                                />
                            </Grid>

                            <Grid  xs={12}
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    name='Observaciones'
                                    label='Observaciones'
                                    variant='outlined'
                                    size='small'
                                    value={ values.Observaciones }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <RemoveRedEye />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    multiline
                                    maxRows={5}
                                    minRows={5}
                                    onChange={ handleChange }
                                    error={touched.Observaciones && Boolean(errors.Observaciones)}
                                    helperText={touched.Observaciones && errors.Observaciones}                   
                                />
                            </Grid>
                        
                        <Grid  xs={12}
                            fullWidth
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >

                            <Button 
                                style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                onClick={ handleCancel }>
                                <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                            </Button>

                            <LoadingButton
                                 loadingPosition="start"
                                 variant="contained"
                                 type='submit'
                                 loading={ isSubmitting }
                                 disabled={ !isValid || isEmpty( rate )}
                                 style={{
                                     backgroundColor: '#E5824F',
                 
                                 }}
                            >
                                    Editar
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
        </Form>
        )}
        </Formik>
    )
}
