import { combineReducers } from "redux";
import { modalReducer } from "./modalReducer";
import { authReducer } from "./authReducer";
import { uiReducer } from "./uiReducer";
import { userReducer } from "./userReducer";
import { contentModalReducer } from "./contentModalReducer";
import { supplierReducer } from "./supplierReducer";
import { notificationReducer } from "./notificationReducer";
import { clientReducer } from "./clientReducer";
import { settingsReducer } from "./settingsReducer";
import { exchangeRateReducer } from "./exchangeRateReducer";
import { rolesReducer } from './rolesReducer';
import { servicesSuppliersReducer } from "./servicesSuppliersReducer";
import { routeReducer } from "./routeReducer";
import { invoiceReducer } from "./invoiceReducer";
import { linesReducer } from "./linesReducer";
import { dashboardReducer } from "./dashboardReducer";
import { filterReducer } from "./filterReducer";
import { comisionistasReducer } from "./comisionistasReducer";
import { vendedoresReducer } from "./sellersAgencyReducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    user: userReducer,
    roles: rolesReducer,
    supplier: supplierReducer,
    modal: modalReducer,
    contentModal: contentModalReducer,
    notificationModal: notificationReducer,
    client: clientReducer,
    settings: settingsReducer,
    exchangeRate: exchangeRateReducer,
    service: servicesSuppliersReducer,
    route: routeReducer,
    invoice: invoiceReducer,
    lines: linesReducer,
    dashboard: dashboardReducer,
    salesFilter: filterReducer,
    comisionistas: comisionistasReducer,
    vendedoresAgencia: vendedoresReducer,
})