import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormStyles } from '../../../styles/formStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { validationSchemaEditLine } from '../../../utils/schemaValidationForm';
import { Euro, Info, Loyalty, MonetizationOn, EuroOutlined, MonetizationOnOutlined, People, RemoveRedEye } from '@material-ui/icons';
import { startChangingDataRelatedWithSettledLine, startUpdateLineActive, updateServicesOnSupplier } from '../../../redux/actions/lines';
import { SERVICE } from '../../../utils/constants';
import { setActiveExchangeRateToSupplier, setCurrentLineRate } from '../../../redux/actions/exchangeRate';
import { calculateFromDollarsToEuro, calculateFromEuroToDollars, getTotalLineFromDollarToEuros, getTotalLineFromEurosToDollars } from '../../../utils/utils';
import { uiManageModalCollectionTypes } from '../../../redux/actions/ui';
import { startLogOutContentModal } from '../../../redux/actions/contentModal';
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../../utils/exchange';
import esLocale from 'date-fns/locale/es';
 import { Field } from 'formik';
import { FormikDatePicker } from '../../ui/FormikDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

export const ContentModalEditOrderLine = () => {
    const { active: activeOrderLine }  = useSelector(state => state.lines);
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const dispatch = useDispatch();
    const [suppliers, setSuppliers] = useState([]);
    const [services, setServices] = useState([]);
    const [valueSupplier, setValueSupplier] = useState('');
    const [valueService, setValueService] = useState('');
    const refAutoService = useRef();
    const { active: activeRate } = useSelector(state => state.exchangeRate);
    const { active: { activeLine } } = useSelector(state => state.lines)

    useEffect(() => {
        activeOrderLine.services.map( service => {
            setServices( prev => prev.concat({label: service?.Nombre, value: service?.PkServiciosProveedores }))
            return null;
        })
        activeOrderLine.suppliers.map( supplier => {
            setSuppliers( prev => prev.concat({label: supplier?.Nombre, value: supplier?.PkProveedores }))
            return null;
        })
      
    }, []);

    useEffect(() => {
        
        setServices([])
        if( valueSupplier ){
            dispatch(updateServicesOnSupplier( valueSupplier ));
        }
        
    }, [ valueSupplier, dispatch ])

    useEffect(() => {
        setServices([])
        activeOrderLine.services.map( service => {
            setServices( prev => prev.concat({label: service?.Nombre, value: service?.PkServiciosProveedores }))
            return null;
        })
    }, [activeOrderLine.services])

    useEffect(() => {
        if(  valueService ){
            dispatch(startChangingDataRelatedWithSettledLine(valueService))
        }
        
       
    }, [ valueService, dispatch ])

    useEffect(() => {
        if( valueService || valueSupplier ){
            dispatch(setActiveExchangeRateToSupplier(activeOrderLine.activeLine.ServicioProveedor.FkProveedores, activeOrderLine.activeLine.ServicioProveedor.FkTiposServicio))
        }
        else{
            dispatch(setCurrentLineRate( activeLine.FkTiposCambioProveedores))
        }
    }, [ valueService, valueSupplier, activeOrderLine, dispatch ])

    const initialValues = {
        FkProveedores: activeOrderLine.activeLine.Proveedor.PkProveedores,
        FkServiciosProveedores: activeOrderLine.activeLine.ServicioProveedor.PkServiciosProveedores,
        
        ReservaLineaEuros: Math.round(activeOrderLine.activeLine.ReservaLineaEuros),
        
        
        ReventaLineaEuros: activeOrderLine.activeLine.ReventaLineaEuros,
        
        
        InSituLineaDolares: Math.round(activeOrderLine.activeLine.InSituLineaDolares),
        Unidades: activeOrderLine.activeLine.Unidades,
        Concepto: activeOrderLine.activeLine.Concepto,
        Observaciones: activeOrderLine.activeLine.Observaciones,
        PorcDescuento: activeOrderLine.activeLine.PorcDescuento,
        TasaDolares: activeOrderLine.activeLine.TasaDolares,
        FechaRecogida: activeOrderLine.activeLine.FechaRecogida,
        SuplementoTransporteDolares: activeOrderLine.activeLine.SuplementoTransporteDolares??0,
        PrecioUnitarioEuros: Math.round(activeOrderLine.activeLine.ReservaLineaEuros) + 
            +calculateFromDollarsToEuro( activeRate, activeOrderLine.activeLine.InSituLineaDolares),
        
    }


    const handleSubmit = ( values ) => {
        const payload = {
            "PkLineasVenta": activeOrderLine.activeLine.PkLineasVenta,
            "Concepto": values.Concepto,
            "CosteDolares": Math.round(+activeOrderLine.activeLine.CosteDolares),
            "ReservaEuros": Math.round(+activeOrderLine.activeLine.ReservaEuros),
            "ReventaLineaEuros": +activeOrderLine.activeLine.ReventaLineaEuros,
            "InSituLineaDolares": Math.round(+values.InSituLineaDolares),
            "ReservaLineaEuros": (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)),
            "Unidades": +values.Unidades,
            "Observaciones": values.Observaciones??'',
            "VersionFila": activeOrderLine.activeLine.VersionFila,
            "FkVentas": activeOrderLine.activeLine.FkVentas,
            "FkTiposLineaVenta": SERVICE,
            "FkServiciosProveedores": activeOrderLine.activeLine.ServicioProveedor.PkServiciosProveedores,
            "FkTiposCambioProveedores": activeOrderLine.activeLine.TipoCambioProveedor.PkTiposCambioProveedores,
            "FkProveedores": activeOrderLine.activeLine.Proveedor.PkProveedores,
            "PorcDescuento": +values.PorcDescuento,
            "TasaDolares": +values.TasaDolares,
            "SuplementoTransporteDolares": +values.SuplementoTransporteDolares,
            "FechaRecogida": values.FechaRecogida ? values.FechaRecogida : null,                    
        }
        dispatch(startUpdateLineActive(payload))
    }

    return (
        <Fragment>
             <Formik enableReinitialize initialValues={ initialValues } validationSchema={ validationSchemaEditLine } onSubmit={ handleSubmit }>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, values, setFieldValue }) => (
                <Form>
                    <Grid item style={{padding: '2rem'}}>
                        <Grid container spacing={3}>
                            <Grid item classes={{root: classes.container }}>
                                <Typography variant='h3'>Editar Línea de Venta</Typography>
                            </Grid>

                            <Grid xs={12} item  classes={{root: classes.container }}>
                                <Autocomplete style={{fontSize: '0.8em', }}
                                    options={suppliers}
                                    noOptionsText={"No hay proveedores"}
                                    getOptionLabel={option => option.label}
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    renderInput={(params) => ( 
                                        <TextField 
                                            {...params}
                                            name='FkProveedores' 
                                            variant='outlined' 
                                            label="Proveedor" 
                                            placeholder='Busca...'
                                            disabled={ isSubmitting }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched.FkProveedores && Boolean(errors.FkProveedores)}
                                            helperText={touched.FkProveedores && errors.FkProveedores}
                                            

                                        />
                                    )}
                                    sx={{ marginBottom: '0.5rem'}}
                                    name="FkProveedores"
                                    defaultValue={{label: activeOrderLine.activeLine.Proveedor.Nombre, value: activeOrderLine.activeLine.Proveedor.PkProveedores }}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                        "FkProveedores",
                                        value?.value !== null ? value?.value : values.FkProveedores
                                        );
                                        if(!value?.value && values.FkServiciosProveedores){
                                            setFieldValue('FkServiciosProveedores', "");
                                            setValueService("")
                                            refAutoService.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();


                                        }

                                        if( values.FkServiciosProveedores ){
                                            setFieldValue('FkServiciosProveedores', "");
                                            setValueService("")
                                            refAutoService.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                                        }

                                        setValueSupplier(value?.value)

                                    }}
                                />
                            </Grid>
                            <Grid xs={12} item  classes={{root: classes.container }}>
                                <Autocomplete style={{fontSize: '0.8em'}}
                                    options={services}
                                    ref={ refAutoService }
                                    noOptionsText={"No hay servicios"}
                                    getOptionLabel={option => option.label}
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    renderInput={(params) => ( 
                                        <TextField 
                                            {...params}
                                            name='FkServiciosProveedores' 
                                            variant='outlined' 
                                            label="Servicio" 
                                            placeholder='Busca...'
                                            disabled={ isSubmitting }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched.FkServiciosProveedores && Boolean(errors.FkServiciosProveedores)}
                                            helperText={touched.FkServiciosProveedores && errors.FkServiciosProveedores}
                                            

                                        />
                                    )}
                                    sx={{ marginBottom: '0.5rem'}}
                                    name="FkServiciosProveedores"
                                    defaultValue={{label: activeOrderLine.activeLine.ServicioProveedor.Nombre, value: activeOrderLine.activeLine.ServicioProveedor.PkServiciosProveedores }}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                        "FkServiciosProveedores",
                                        value?.value 
                                        );
                                        if( !values.FkProveedores ){
                                            setFieldValue("FkServiciosProveedores", "");
                                        }
                                        setValueService(value?.value)


                                    }}
                                />
                            </Grid>


                            <Grid xs={12} item  classes={{root: classes.container }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
                            <Field component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida   }  />
                            </LocalizationProvider>
                            </Grid>

                                {
                                    values.FkProveedores && values.FkServiciosProveedores
                                    &&
                                    (
                                        <>
                                        
                                        <Grid xs={12} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Concepto'
                                                size='small'
                                                value={values.Concepto}
                                                variant='outlined'
                                                name='Concepto'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Info />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Concepto && Boolean(errors.Concepto)}
                                                helperText={touched.Concepto && errors.Concepto}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>






                                        <Grid md={3} xs={6}  item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth
                                                name='InSituLineaDolares' 
                                                label='In Situ'
                                                size='small'
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.InSituLineaDolares && Boolean(errors.InSituLineaDolares)}
                                                helperText={touched.InSituLineaDolares && errors.InSituLineaDolares}
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ values.InSituLineaDolares }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        {
                                            activeRate && values.InSituLineaDolares != null && values.InSituLineaDolares != undefined
                                            &&
                                            (
                                                <Grid md={3} xs={6}  item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth
                                                        label='In situ'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ calculateFromDollarsToEuro( activeRate, values.InSituLineaDolares) }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Euro />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                            )
                                        }




                                        <Grid  md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth
                                                name='ReservaLineaEuros' 
                                                label='Reserva'
                                                size='small'
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.ReservaLineaEuros && Boolean(errors.ReservaLineaEuros)}
                                                helperText={touched.ReservaLineaEuros && errors.ReservaLineaEuros}
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={  values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares) }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Euro />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled
                                            />
                                        </Grid>
                                        {
                                            (activeRate && (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares))>0)
                                            ?
                                            (
                                                <Grid  md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth
                                                        label='Reserva'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ obtenerPrecioDolares(
                                                            values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares), 
                                                            activeRate?.CambioEuros, activeRate?.CambioDolares) }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOnOutlined />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                                

                                            )
                                            :
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                </Grid>
                                            )
                                        }
                                       



                                        
                                        <Grid  md={3} xs={6} 
                                                            item  
                                            classes={{root: classes.container }}
                                        >
                                        
                                            <TextField
                                                fullWidth
                                                size='small'
                                                name='PrecioUnitarioEuros'
                                                label='Precio Servicio'
                                                variant='outlined'
                                                value={ values.PrecioUnitarioEuros }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EuroOutlined />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={ handleChange }
                                                error={touched.PrecioUnitarioEuros && Boolean(errors.PrecioUnitarioEuros)}
                                                helperText={touched.PrecioUnitarioEuros && errors.PrecioUnitarioEuros}                   
                                            />

                                        </Grid>
                                    
                                    

                                        {
                                            values.PrecioUnitarioEuros && !isNaN(values.PrecioUnitarioEuros)
                                            ?
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label=''
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOn />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        value={ obtenerPrecioDolares(values.PrecioUnitarioEuros, activeRate?.CambioEuros, activeRate?.CambioDolares) }
                                                        disabled
                                                    />
                                                </Grid>
                                            )
                                            :
                                            (
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                </Grid>
                                            )
                                        }



{
    (+values.ReventaLineaEuros>0) &&
    <>
    
                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Margen'
                                    name='ReventaLineaEuros'
                                    size='small'
                                    variant='outlined'
                                    classes={{ root: classes.inputText }}
                                    value={ values.ReventaLineaEuros }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EuroOutlined />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.ReventaLineaEuros && Boolean(errors.ReventaLineaEuros)}
                                    helperText={touched.ReventaLineaEuros && errors.ReventaLineaEuros}
                                    disabled
                                />
                            </Grid>
                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Margen'
                                    name='ReventaLineaDolares'
                                    size='small'
                                    variant='outlined'
                                    value={ calculateFromEuroToDollars( activeRate, values.ReventaLineaEuros) }
                                    classes={{ root: classes.inputText }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MonetizationOn />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    disabled

                                />
                            </Grid>
    </>
}




                                        <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Supl. Transporte'
                                                name='SuplementoTransporteDolares'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.SuplementoTransporteDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.SuplementoTransporteDolares && Boolean(errors.SuplementoTransporteDolares)}
                                                helperText={touched.SuplementoTransporteDolares && errors.SuplementoTransporteDolares}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        
                                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Supl. Transporte'
                                                    size='small'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EuroOutlined />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    value={ +calculateFromDollarsToEuro( activeRate, values.SuplementoTransporteDolares) }
                                                />
                                            </Grid>
                                        









                                        <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Nº Personas'
                                                size='small'
                                                name='Unidades'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <People />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={values.Unidades}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Unidades && Boolean(errors.Unidades)}
                                                helperText={touched.Unidades && errors.Unidades}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>


                                        <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='%Dto reserva'
                                                size='small'
                                                name='PorcDescuento'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Loyalty />
                                                        </InputAdornment>
                                                    ),
                            
                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.PorcDescuento}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.PorcDescuento && Boolean(errors.PorcDescuento)}
                                                helperText={touched.PorcDescuento && errors.PorcDescuento}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        {
                                            (values.PorcDescuento > 0 && values.PrecioUnitarioEuros && values.PorcDescuento <= 100)
                                            &&
                                            (
                                                <>
                                                    <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                                                        <TextField 
                                                            fullWidth   
                                                            label='Reserva con dto'
                                                            size='small'
                                                            variant='outlined'
                                                            classes={{ root: classes.inputText }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <Euro />
                                                                    </InputAdornment>
                                                                ),

                                                                classes: {
                                                                    input: classes.resize
                                                                }
                                                            }}
                                                            InputLabelProps={
                                                                {style: {fontSize: '0.8em'}}
                                                            }
                                                            value={ Math.round(
                                                                (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)) 
                                                                - ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)) * (+values.PorcDescuento/100))) }
                                                            disabled
                                                        />
                                                    </Grid>

                                                    <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                                                        <TextField 
                                                            fullWidth   
                                                            label='Reserva con dto'
                                                            size='small'
                                                            variant='outlined'
                                                            classes={{ root: classes.inputText }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <MonetizationOn />
                                                                    </InputAdornment>
                                                                ),

                                                                classes: {
                                                                    input: classes.resize
                                                                }
                                                            }}
                                                            InputLabelProps={
                                                                {style: {fontSize: '0.8em'}}
                                                            }
                                                            value={  calculateFromEuroToDollars(activeRate,
                                                                        Math.round(
                                                                            (values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)) 
                                                                            - ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)) * (+values.PorcDescuento/100)))
                                                                    )
                                                             }
                                                            disabled
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        }



                                          <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth
                                                name='TasaDolares' 
                                                label='Tasa Dólares'
                                                size='small'
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                                helperText={touched.TasaDolares && errors.TasaDolares}
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ values.TasaDolares }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled={ true }
                                            />
                                        </Grid>
                                        <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth
                                                label='Tasa Euros'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ +calculateFromDollarsToEuro( activeRate, values.TasaDolares) }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Euro />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled
                                            />
                                        </Grid>


                                       
                                        {
                                            activeRate && values.InSituLineaDolares != null && values.InSituLineaDolares != undefined && (values.PrecioUnitarioEuros)>0
                                            &&
                                            (
                                                <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth
                                                        label='Venta'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={
                                                            Math.round(
                                                            100*(     
                                                            +values.Unidades *
                                                            (
                                                                +values.ReventaLineaEuros +
                                                                +obtenerPrecioEuros(values.SuplementoTransporteDolares, activeRate?.CambioEuros, activeRate?.CambioDolares) +
                                                                Math.round(+values.PrecioUnitarioEuros - ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)) * (+values.PorcDescuento/100)))
                                                            )
                                                            ))/100
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Euro />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                            )
                                        }

                                        {
                                            activeRate && values.InSituLineaDolares != null && values.InSituLineaDolares != undefined && (values.PrecioUnitarioEuros)>0 
                                            &&
                                            (
                                                <Grid md={2} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth
                                                        label='Venta'
                                                        size='small'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        value={ 
                                                            +values.Unidades * 
                                                            (
                                                                calculateFromEuroToDollars( activeRate, values.ReventaLineaEuros)+
                                                               +obtenerPrecioDolares(
                                                                Math.round(+values.PrecioUnitarioEuros - 
                                                                    ((values.PrecioUnitarioEuros - obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares)) * (+values.PorcDescuento/100))), 
                                                                activeRate?.CambioEuros, activeRate?.CambioDolares)
                                                               + +values.SuplementoTransporteDolares
                                                            )
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MonetizationOnOutlined />
                                                                </InputAdornment>
                                                            ),
        
                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        disabled
                                                    />
                                                </Grid>
                                            )
                                        }

                                        
                                        <Grid xs={12} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Observaciones'
                                                size='small'
                                                name='Observaciones'
                                                variant='outlined'
                                                value={values.Observaciones}
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <RemoveRedEye />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Observaciones && Boolean(errors.Observaciones)}
                                                helperText={touched.Observaciones && errors.Observaciones}
                                                disabled={ isSubmitting }
                                                multiline
                                                maxRows={ 10 }
                                                minRows={ 5 }
                                            />
                                        </Grid>
                                        </>
                                    )
                                }
                                
                                
                        
                            
                            
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                 <Button
                                    style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                    onClick={ () => {
                                        dispatch(uiManageModalCollectionTypes());
                                        dispatch(startLogOutContentModal());
                                    } }>
                                    <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                </Button>

                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    type='submit'
                                    loading={ isSubmitting }
                                    disabled={ !isValid }
                                    style={{
                                        backgroundColor: '#E5824F',
                    
                                    }}
                                >
                                        Editar
                                </LoadingButton>

                            </Box>

                        </Grid>
                    </Grid>
            </Form>
            )}
            </Formik>
        </Fragment>
    )
}
