import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeleteServiceReseller } from '../../redux/actions/supplierServices';
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';
import { startDeleteAgencySeller } from '../../redux/actions/agencyServices';

export const ConfirmDeleteSellerAgency = () => {
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { active: activeSeller } = useSelector(state => state.vendedoresAgencia) //we get the users from the state 
    
    const handleSuccess = () => {
        dispatch(uiManageModalNotification());
        //console.log( activeuser.PkUsuarios, activeService.PkServiciosProveedores)
        dispatch(startDeleteAgencySeller(activeSeller.PkUsuarios)); //We dispatch the action passing as a parameter the user id
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ `¿Quieres borrar el vendedor?` }
                text={ `El vendedor quedará eliminado definitivamente del sistema` }
                action={ handleSuccess }
            />
    )
}
