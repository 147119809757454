import {  useParams } from '@reach/router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ServiceInfo } from '../../components/services/ServiceInfo'
import { Layout } from '../../components/ui/Layout'
import { Spinner } from '../../components/ui/Spinner'
import { actionEditService } from '../../redux/actions/actions'

const ServicesDetailScreen = () => {

    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui);
    const params = useParams();
    useEffect(() => {
        dispatch(actionEditService(params.PkServiciosProveedores))
    }, [dispatch, params.PkServiciosProveedores]);
    return (
        <Layout>
            <div style={{ marginTop: '2rem'}}/>
            {
                loading
                ?
                <Spinner />
                :
                <ServiceInfo />
            }
            
        </Layout>
    )
}

export default ServicesDetailScreen;