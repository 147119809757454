import React from 'react'
//Third-party packages
import { styled } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer as MuiDrawer, List, Toolbar, Divider, useMediaQuery, useTheme, ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core';

//import Demo from './SwipableDrawer'

//Custom imports
import { menuItems } from '../../helpers/loadMenuRol';
import { navigate } from '@reach/router';
import {Drawer as DrawerMobile} from '@mui/material';
import { uiManageModal } from '../../redux/actions/ui';
import { AccountBox, ExitToAppOutlined } from '@material-ui/icons';
import { startLogOut } from '../../redux/actions/auth';
import { startResetPage } from '../../redux/actions/sales';
import { startResetLinesPage } from '../../redux/actions/lines';


/**
 * Author: QBK
 * Date: 22-09-2021
 */

  const drawerWidth = 260; 

  //Drawer component
  /**
   * We styles the paper using basically transitions. We get this using the transitions.create props
   * from the theme material-ui prop
   * Also, we manage the spacing based on the ui size
   */
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: 0, //theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: 0, // theme.spacing(9),
          },
        }),
        height: '100%'
      },
    }),
  );

  
 
export const DrawerNav = () => {
    const { menuState } = useSelector(state => state.ui); //we get the state (true or false) from the redux state
    const theme = useTheme(); // to get the theme
    const matcheXS = useMediaQuery(theme.breakpoints.down('md')); //if the screen side is less than XSS, matchesXS is true
    const dispatch = useDispatch()
    const { idRol } = useSelector(state => state.auth);
    const handleLogOut = () => {
      dispatch( startLogOut() );
    }
    //Rendered HTML. Based on the screen size, we render or the <Demo /> component or the <Drawer component />
    return (
        true //matcheXS
        ?
          (
            <React.Fragment>
            <DrawerMobile
              anchor='left'
              open={ menuState }
            >
              <Box
              sx={ 250 }
              role="presentation"
              onClick={ () => dispatch(uiManageModal())}
              onKeyDown={ () => dispatch(uiManageModal())}
              style={{ marginTop: '3.5rem' }}
            >
              <List>
                {
                      menuItems.map( (element, index) => {
                          let result = element.role.filter( role => role === idRol);
                          if( result.includes(idRol)){
                              return (
                                  <ListItem key={ index } button  onClick={ () =>{ 
                                      navigate(`${ element.route }`)
                                      dispatch(startResetPage())
                                      dispatch(startResetLinesPage())  
                                    } }>
                                      <ListItemIcon>
                                          { element.icon }
                                      </ListItemIcon>
                                      <ListItemText variant='h3' primary={ element.name } color='#000'/>
                                  </ListItem>
                              )
                          }
                          return null;  
                      })
                  }
                  <ListItem  button  onClick={ () => navigate(`/me`) }>
                          <ListItemIcon>
                              <AccountBox />
                          </ListItemIcon>
                          <ListItemText variant='h3' primary='Perfil' color='#000'/>
                  </ListItem>

                  <ListItem  button  onClick={ () => handleLogOut() }>
                          <ListItemIcon>
                              <ExitToAppOutlined />
                          </ListItemIcon>
                          <ListItemText variant='h3' primary='Salir' color='#000'/>
                  </ListItem>
            </List>
        
          </Box>
            </DrawerMobile>
          </React.Fragment>
          )
        :
        (<Drawer variant="permanent" anchor='left' open={menuState}>
        <Toolbar
            sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
            }}
        >
        </Toolbar>
        <Divider />
        <List>
        <div>
            {
                menuItems.map( (element, index) => {
                    let result = element.role.filter( role => role === idRol);
                    if( result.includes(idRol)){
                        return (
                            <ListItem key={ index } button  onClick={ () => {
                              navigate(`${ element.route }`)
                              dispatch(startResetPage())
                              dispatch(startResetLinesPage())    
                            } }>
                                <ListItemIcon>
                                    { element.icon }
                                </ListItemIcon>
                                <ListItemText variant='h3' primary={ element.name } color='#000'/>
                            </ListItem>
                        )
                    }
                    return null;  
                })
            }
        </div>
        </List>
      </Drawer>
        )
      
       
    )
}
