import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowForwardIosSharp } from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import React, { useState } from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {  useSelector } from 'react-redux';
import { ContentModalEditOrderLine } from '../invoices/lines/contentModalEditOrderLine';
import { ContentModalEditOrderLineReseller } from '../invoices/lines/contentModalEditOrderLineReseller';
import { ConfirmLineScreen } from './ConfirmLineScreen';
import { ContentModalEditFreeOrderLine } from '../invoices/lines/freeOrderLines/contentModalEditFreeOrderLine';
import { ROLADMIN, SERVICE, ROLVENDEDOR, ROLREVENDEDOR } from '../../utils/constants';
import { ContentModalEditOrderLineResellerAdminAccount } from '../invoices/lines/contentModalEditOrderLineResellerAdminAccount';

export const AccordionInforLine = () => {
const [expanded, setExpanded] = useState('panel3');
const handleChange = (panel) => (_, newExpanded) => {
  setExpanded(newExpanded ? panel : false);
};
const theme = useTheme();
const matchesXS = useMediaQuery(theme.breakpoints.down('md'));
const { active } = useSelector(state => state.lines);
const { idRol } = useSelector(state => state.auth)
const { activeEdit  } = useSelector(state => state.invoice)

  return (
    <div style={{ marginTop: matchesXS && '3rem'}}>
     
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='h2'>Editar Línea</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            (idRol === ROLADMIN || idRol === ROLVENDEDOR)            
            ?
            (active?.activeLine?.FkTiposLineaVenta === SERVICE
            ?
              (
                  (activeEdit.Usuario.FkRoles === ROLREVENDEDOR) 
                  ? <ContentModalEditOrderLineResellerAdminAccount />
                  : <ContentModalEditOrderLine />
              )
                :
              <ContentModalEditFreeOrderLine />
            )
            :
            <ContentModalEditOrderLineReseller />
          }
          
        </AccordionDetails>
      </Accordion>

      {
            (idRol === ROLADMIN || idRol === ROLVENDEDOR)            
            &&
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='h2'>Estado de la Línea</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            idRol === ROLADMIN
            ?
              <>
                <ConfirmLineScreen />
              </> //Form con fecha recogida, código proveedor y las observaciones de la recogida
            :
              <>
                {
                  active?.activeLine?.ConfirmadaProveedor
                  ?
                  <Typography variant='h3'>La fecha de recogida es el { active?.activeLine?.FechaRecogida } y su código de reserva es { active?.activeLine?.CodigoReservaProveedor }</Typography>
                  :
                  <Typography variant='h3'>La línea todavía no está confirmada</Typography>
                }
              </>

          }
          
        </AccordionDetails>
      </Accordion>
      }

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='h2'>Tipo de Cambio</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='h3'>
            Esta línea de venta tiene un tipo de cambio en el cual { active?.activeLine?.TipoCambioProveedor?.CambioDolares }€ son { active?.activeLine?.TipoCambioProveedor?.CambioEuros }$
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));