import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { AccountBoxOutlined, RemoveRedEyeRounded } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startUpdateSupplierToCoordinator } from '../../../redux/actions/suppliers';
import { useFormStyles } from '../../../styles/formStyles';
import { startLogOutContentModal } from "../../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../../redux/actions/ui";

export const ContentModalEditSupplierToCoordinator = () => {
    const { active } = useSelector(state => state.user);
    const { active: activeSupplier } = useSelector(state => state.supplier)
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch();
    
    const initialValues = {
        observaciones: activeSupplier?.Observaciones
    }

    const submit = ( values, { setSubmitting }) => {
        const instance = {
            "FkUsuarios": activeSupplier?.FkUsuarios,
            "Observaciones": values.observaciones,
            "FkProveedores": activeSupplier?.FkProveedores,
            "VersionFila": activeSupplier?.VersionFila
        }

        dispatch( startUpdateSupplierToCoordinator( instance, setSubmitting ));
    }

    
    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };

    return (
        <Formik initialValues={initialValues} onSubmit={submit}>
            {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid }) => (
            <Form>
            <Grid item style={{ padding: '2rem'}}>
                <Grid container direction='column'>
                    
                    <Grid item classes={{root: classes.container }}>
                        <Typography variant='h1'>Proveedor asociado a { active?.Nombre }</Typography>
                    </Grid>

                    <Grid item  classes={{root: classes.container }}>
                            <TextField
                                fullWidth
                                size='small'
                                classes={{ root: classes.inputText }}
                                id={`outlined-basic-observaciones`}  
                                label='Proveedor'
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountBoxOutlined />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                disabled
                                value={ activeSupplier?.Proveedor?.Nombre }
                                
                            />
                        </Grid>

                        <Grid item  classes={{root: classes.container }}>
                            <TextField
                                fullWidth
                                name='observaciones'
                                size='small'
                                classes={{ root: classes.inputText }}
                                id={`outlined-basic-observaciones`}  
                                label='Observaciones'
                                variant='outlined'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <RemoveRedEyeRounded />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                value={ values.observaciones }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                error={touched.observaciones && Boolean(errors.observaciones)}
                                helperText={touched.observaciones && errors.observaciones}
                                disabled={ isSubmitting }
                                multiline
                                maxRows={ 10 }
                                minRows={ 5 }
                                
                            />
                        </Grid>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >

                        <Button 
                            style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                            onClick={ handleCancel }>
                            <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                        </Button>

                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            type='submit'
                            loading={ isSubmitting }
                            disabled={ !isValid || isSubmitting }
                            style={{
                                backgroundColor: '#E5824F',
            
                            }}
                        >
                                Actualizar
                        </LoadingButton>

                        </Box>



                </Grid>
            </Grid>
            </Form>
            )}
            </Formik>
    )
}
