import {  NUMROWSTATEINVOICE, PENDIENTEPRESUPUESTO, PENDIENTERESERVA, PENDIENTEVOUCHER, ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR } from "./constants";
import dateFormat from "dateformat";


const isEmpty = (obj) => {
    return Object.keys( obj || {} ).length === 0;
}

export const buildFilterInvoice = ( filterList, page, dateRange, rol, loggedUser ) => {
    
    const whereFilter = {
        "FechaVenta": {"between":[ `${ dateFormat(dateRange[0], "isoUtcDateTime")}`, `${ dateFormat(dateRange[1], "isoUtcDateTime")}` ]},
      };

    if (filterList && filterList.length>0) {
        if( filterList[5] && filterList[5].length>0 &&
            filterList[5][0] && (rol === ROLREVENDEDOR || rol === ROLVENDEDOR) ){
            whereFilter['FkUsuarios'] = loggedUser
        }
        if( filterList[0] && filterList[0].length>0 &&
            filterList[0][0] ){
            whereFilter['PkVentas'] = filterList[0][0];
        }
        if( filterList[1] && filterList[1].length>0 &&
            filterList[1][0]){
            whereFilter['FkClientes'] = filterList[1][0];
        }
        if( filterList[4] && filterList[4].length>0 &&
            filterList[4][0] && filterList[4][0] === PENDIENTEPRESUPUESTO){
            whereFilter['PresupuestoEnviado'] = false;
            whereFilter['VoucherEnviado'] = false;
            whereFilter['ReservaPagada'] = false;

        }
        if( filterList[4] && filterList[4].length>0 &&
            filterList[4][0] && filterList[4][0] === PENDIENTEVOUCHER){
            whereFilter['VoucherEnviado'] = false;
            whereFilter['ReservaPagada'] = true;
            whereFilter['PresupuestoEnviado'] = true;
        }

        if( filterList[4] && filterList[4].length>0 &&
            filterList[4][0] && filterList[4][0] === PENDIENTERESERVA){
            whereFilter['VoucherEnviado'] = false;
            whereFilter['ReservaPagada'] = false;
            whereFilter['PresupuestoEnviado'] = true;
        }

        if( filterList[5] && filterList[5].length>0 &&
            filterList[5][0] && rol === ROLADMIN){
            whereFilter['FkUsuarios'] = filterList[5][0];
        }
        
        if( filterList[11] && filterList[11].length>0 &&
            filterList[11][0]){
            whereFilter['FkComisionistas'] = filterList[11][0];
        }
    }
    const filterquery = {
        limit: NUMROWSTATEINVOICE,
        skip: page * NUMROWSTATEINVOICE,
        include: [
          {
            relation: 'Usuario',
          },
          {
            relation: 'Cliente'
          },
          {
            relation: 'Comisionista'
          }
        ]
    }
    const filterquerySum = {};
    const filterQueryCSV = {};
    if( !isEmpty(whereFilter) ){
        filterquery['where'] = whereFilter
        filterquerySum['where'] = whereFilter
        filterQueryCSV['where'] = whereFilter
    }
    const encodedFilter = JSON.stringify(filterquery);
    let query = `/ventas?filter=${ encodedFilter }`;
    let queryCount = `/ventas/count?filter=${ encodedFilter }`;
    const sumCount= `/ventas/sum?filter=${ JSON.stringify(filterquerySum) }`;
    const csvQuery= `/ventas/csv?filter=${ JSON.stringify(filterQueryCSV) }`

    return [query, queryCount, sumCount, csvQuery];
}


export const buildFilterCVS = ( dateRange ) => {
    const whereFilter = {
        "FechaVenta": {"between":[ `${ dateFormat(dateRange[0], "isoUtcDateTime")}`, `${ dateFormat(dateRange[1], "isoUtcDateTime")}` ]},
      };
    const filterQueryCSV = {
        include: [
            {
              relation: 'Usuario',
            },
            {
              relation: 'Cliente'
            },
            {
              relation: 'Comisionista'
            }
        ]
    };
    if( !isEmpty(whereFilter) ){
        filterQueryCSV['where'] = whereFilter
    }
    const encodedFilter = JSON.stringify(filterQueryCSV);
    const csvQuery= `/ventas/csv?filter=${ encodedFilter }`;

    return [csvQuery];
    }
    
