import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles( theme => ({
    containerFeautures: {
        display: 'flex',
        flexDirection: 'column',
    },
    colorTitle:{
        color: '#000'
    },
    "@global": {
        'span.MuiTypography-root.MuiCardHeader-title.MuiTypography-h5': {
            color: '#000'
        }
    },
    wrapRowSettings:{
        display: 'flex',
        justifyContent: 'space-between',
    }
}))