import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react'
import { Helmet } from 'react-helmet';
import backImage from '../images/back2.png';
import logo from '../images/logo.png'
import { useLoginStyles } from '../styles/loginStyles';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux'
import { isBrowser } from '../utils/constants';

const NotFoundPage = () => {
    const classes = useLoginStyles();
    const { id, cambiaPassword, aceptaPoliticas} = useSelector(state => state.auth); 
    const theme = useTheme();
    const matchesMD = useMediaQuery( theme.breakpoints.down('md'));
    return (
        isBrowser &&
        <div
            style={{
                backgroundImage: `url(${ backImage }) `,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                objectFit: 'cover'
                
            }}
            className='body'
        >
            <Helmet>
                <title>No se ha encontrado | Xplora</title>
            </Helmet>
            <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem'}}>
                <img src={ logo } alt='logo' className={ classes.logo }/>
            </Box>
            
            <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem'}}>
                <Grid item >
                    <Typography style={{ color: '#F07E46', fontWeight: 700, fontSize: matchesMD ? 45 : 95}}>ERROR 404</Typography>
                </Grid>

                <Grid item >
                    <Typography style={{ color: '#fff', fontWeight: 700, fontSize: matchesMD ? 15 : 32}}>NO SE HAN ENCONTRADO RESULTADOS</Typography>
                </Grid>

                <Grid item >
                    <Typography style={{ color: '#fff', fontWeight: 500, fontSize: matchesMD ? 15 : 32, width: '45rem', marginTop: '2rem', textAlign: 'center'}}>La página solicitada no pudo encontrarse.</Typography>
                </Grid>


                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 2
                    }}
                >

                    <Button
                        style={{
                            backgroundColor: '#E5824F',
                            color: '#fff',
                            fontWeight: 700,
                            fontSize: 28,
                            marginTop: '3rem'
        
                        }}
                        variant='contained'
                        onClick={ () => {
                            if( !id ){
                                navigate('/login')
                            }
                            else if( id && !aceptaPoliticas ){
                                navigate('/politicas')
                            }
                            else if( id && !cambiaPassword && aceptaPoliticas ){
                                navigate('/auth/change')
                            }
                            else{
                                navigate('/')
                            }
                        }}
                    >
                            VOLVER
                    </Button>

                </Box>

            </Grid>

        </div>)
    
    
}


export default NotFoundPage;
