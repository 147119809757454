import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { BeachAccess, EuroOutlined, MonetizationOnOutlined, RemoveRedEye, Web } from '@material-ui/icons';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Box } from '@mui/system';
import { Field, Form, Formik } from 'formik';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startUpdateServiceSupplier } from '../../redux/actions/supplierServices';
import { useFormStyles } from '../../styles/formStyles';
import { validationSchemaSupplierService } from '../../utils/schemaValidationForm';
import { FormikDatePicker } from '../ui/FormikDatePicker';
import esLocale from 'date-fns/locale/es'
import { navigate } from '@reach/router';
import { calculateFromDollarsToEuro } from '../../utils/utils';
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../utils/exchange';


export const ServiceInfo = () => {
    const classes = useFormStyles();
    const { active, rate } = useSelector(state => state.service)
    const dispatch = useDispatch()
    const initialValues = {
        tipo_servicio: active?.TipoServicio?.PkTiposServicio,
        proveedor: active?.ProveedorServicio?.PkProveedores,
        Nombre: active?.Nombre,
        Alias: active?.Alias,
        CosteDolares: active?.CosteDolares,
        TasaDolares: active?.TasaDolares,
        PrecioVentaEuros: active?.PrecioVentaEuros,
        PaginaWeb: active?.PaginaWeb,
        Observaciones: active?.Observaciones,
        date: active?.FechaBaja,
    };

    const handleSubmit = ( values, {setSubmitting} ) => {
        const newSupplier = {
            "PkServiciosProveedores": active?.PkServiciosProveedores,
            "FkProveedores": values.proveedor,
            "FkTiposServicio": values.tipo_servicio,
            "Nombre": values.Nombre,
            "Alias": values.Alias,
            "CosteDolares": Math.round(+values.CosteDolares),
            "TasaDolares": Math.round(+values.TasaDolares),
            "PrecioVentaEuros": Math.round(+values.PrecioVentaEuros),
            //"ReservaEuros": values['PrecioVentaEuros'] - obtenerPrecioEuros(values['CosteDolares'], rate?.CambioEuros, rate?.CambioDolares),
            "PaginaWeb": values.PaginaWeb,
            "Observaciones": values.Observaciones ? values.Observaciones : "",
            "FechaBaja": values.date ? values.date : null,
            "VersionFila": active?.VersionFila

        }
        dispatch( startUpdateServiceSupplier( newSupplier, setSubmitting ) )
    }


    return (
    <>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
        <Formik enableReinitialize={ true } initialValues={initialValues} validationSchema={ validationSchemaSupplierService }   onSubmit={handleSubmit}>
        {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid }) => (
        <Grid md={8} xs={11}>
        <Form>
            <Box sx={{ p:2, mb: 4}}>
                <Typography variant='h1'>Servicio</Typography>
            </Box>
            <Grid
                container
                spacing={3}
            >

                    
                            <Grid  xs={12} 
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    name='Nombre'
                                    size='small'
                                    label='Nombre'
                                    variant='outlined'
                                    value={ values.Nombre }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BeachAccess />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.Nombre && Boolean(errors.Nombre)}
                                    helperText={touched.Nombre && errors.Nombre}                   
                                />
                            </Grid>

                            <Grid  xs={12} 
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    name='Alias'
                                    size='small'
                                    label='Alias'
                                    variant='outlined'
                                    value={ values.Alias }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BeachAccess />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.Alias && Boolean(errors.Alias)}
                                    helperText={touched.Alias && errors.Alias}                   
                                />
                            </Grid>


                    </Grid>
                    <Grid container spacing={3}> 

                            <Grid md={3} xs={6}
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    name='CosteDolares'
                                    label='Coste'
                                    variant='outlined'
                                    value={ values.CosteDolares }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MonetizationOnOutlined />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.CosteDolares && Boolean(errors.CosteDolares)}
                                    helperText={touched.CosteDolares && errors.CosteDolares}                   
                                />
                            </Grid>


                            <Grid  md={3} xs={6} 
                            item  
                            classes={{root: classes.container }}
                            style={{
                                display: (errors.CosteDolares && touched.CosteDolares && 'none') 
                            }}
                        >
                            <TextField
                                fullWidth
                                size='small'
                                label='Coste en Euros'
                                variant='outlined'
                                classes={{ root: classes.inputText }}
                                value={ 
                                    rate ?
                                    (obtenerPrecioEuros(values.CosteDolares, rate?.CambioEuros, rate?.CambioDolares) || 0) 
                                    : ''
                                }
                                disabled
                                inputProps={
                                    { readonly: true }
                                }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroOutlined />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                            />


                        </Grid>
                        


                            <Grid  md={3} xs={6} 
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    name='PrecioVentaEuros'
                                    label='Precio Venta Euros'
                                    variant='outlined'
                                    value={ values.PrecioVentaEuros }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EuroOutlined />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.PrecioVentaEuros && Boolean(errors.PrecioVentaEuros)}
                                    helperText={touched.PrecioVentaEuros && errors.PrecioVentaEuros}                   
                                />
                            </Grid>

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                            <TextField 
                                fullWidth   
                                label='Precio Venta Dolares'
                                size='small'
                                variant='outlined'
                                value={ 
                                    rate ?
                                    (obtenerPrecioDolares(+values.PrecioVentaEuros, rate?.CambioEuros, rate?.CambioDolares) || 0)
                                    : ''
                                 }
                                classes={{ root: classes.inputText }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MonetizationOnOutlined />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                disabled
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                            />
                        </Grid>
                        
                        {
                            (values.PrecioVentaEuros && values.PrecioVentaEuros >= calculateFromDollarsToEuro(rate, values.CosteDolares))
                            &&
                            (
                                <>
                                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                        <TextField 
                                            fullWidth   
                                            label='Reserva Euros'
                                            size='small'
                                            variant='outlined'                                  
                                            value={ 
                                                rate ?
                                                ( (values['PrecioVentaEuros'] - obtenerPrecioEuros(values['CosteDolares'], rate?.CambioEuros, rate?.CambioDolares)) || 0)
                                                : ''
                                            }
                                            classes={{ root: classes.inputText }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EuroOutlined />
                                                    </InputAdornment>
                                                ),

                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                            InputLabelProps={
                                                {style: {fontSize: '0.8em'}}
                                            }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </Grid>



                                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                        <TextField 
                                            fullWidth   
                                            label='Reserva Dólares'
                                            size='small'
                                            variant='outlined'                                  
                                            value={ 
                                                rate ?
                                                (
                                                    (obtenerPrecioDolares(values['PrecioVentaEuros'], rate?.CambioEuros, rate?.CambioDolares) 
                                                    - values['CosteDolares']) || 0)
                                                : ''
                                            }
                                            classes={{ root: classes.inputText }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MonetizationOnOutlined />
                                                    </InputAdornment>
                                                ),

                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                            InputLabelProps={
                                                {style: {fontSize: '0.8em'}}
                                            }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </Grid>
                                </>
                            )
                        }

                        

                           
                            <Grid  md={3} xs={6} 
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    name='TasaDolares'
                                    label='Tasa Dólares'
                                    variant='outlined'
                                    value={ values.TasaDolares }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MonetizationOnOutlined />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                    helperText={touched.TasaDolares && errors.TasaDolares}                   
                                />
                            </Grid>

                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                            <TextField 
                                fullWidth   
                                label='Tasa Euros'
                                size='small'
                                variant='outlined'
                                value={ 
                                    rate ?
                                    (obtenerPrecioEuros(values['TasaDolares'], rate?.CambioEuros, rate?.CambioDolares) || 0)
                                    : ''
                                 }
                                classes={{ root: classes.inputText }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EuroOutlined />
                                        </InputAdornment>
                                    ),

                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                disabled
                            />
                        </Grid>
                        


                    </Grid>
                    <Grid container spacing={3}> 
                    
                            <Grid xs={12}
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    name='PaginaWeb'
                                    label='Pagina Web'
                                    variant='outlined'
                                    size='small'
                                    value={ values.PaginaWeb }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Web />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={ handleChange }
                                    error={touched.PaginaWeb && Boolean(errors.PaginaWeb)}
                                    helperText={touched.PaginaWeb && errors.PaginaWeb}                   
                                />
                            </Grid>

                            <Grid  xs={12}
                                item  
                                classes={{root: classes.container }}
                            >
                                <TextField
                                    fullWidth
                                    name='Observaciones'
                                    label='Observaciones'
                                    variant='outlined'
                                    size='small'
                                    value={ values.Observaciones }
                                    inputProps={
                                        { readonly: true }
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <RemoveRedEye />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    multiline
                                    maxRows={5}
                                    minRows={5}
                                    onChange={ handleChange }
                                    error={touched.Observaciones && Boolean(errors.Observaciones)}
                                    helperText={touched.Observaciones && errors.Observaciones}                   
                                />
                            </Grid>



                    <Grid 
                        item
                        md={6}
                        xs={12}
                    >
                        <Field style={{fontSize: '0.8em'}} component={FormikDatePicker} label='Fecha Baja' textfield='date' name="date" isSubmitting={ isSubmitting } value={ values?.Fechabaja}/>
                    </Grid>

                    </Grid>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
            >

                    <Button 
                         style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                         onClick={ () => navigate('/services') }>
                             <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                     </Button>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        type='submit'
                        loading={ isSubmitting }
                        disabled={ !isValid || isSubmitting }
                        style={{
                            backgroundColor: '#E5824F',
        
                        }}
                    >
                            Actualizar 
                    </LoadingButton>



            </Box>
        </Form>
        </Grid>

        )}
        </Formik>
        
        </LocalizationProvider>
    </>
    )
}
