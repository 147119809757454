import { Button, Grid, InputAdornment, Typography, TextField, FormControl, InputLabel, MenuItem, ListItemIcon } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Box } from '@mui/system';
import { Field, Form, Formik } from 'formik';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadGridUser } from '../../helpers/loadGrid';
import { startUpdateUser } from '../../redux/actions/users';
import { useFormStyles } from '../../styles/formStyles';
import { validationSchemaUserEdit } from '../../utils/schemaValidationForm';
import { FormikDatePicker } from '../ui/FormikDatePicker';
import esLocale from 'date-fns/locale/es'
import { ApartmentOutlined, Euro } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { ROLREVENDEDOR } from '../../utils/constants';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SwitchMUI } from '../ui/Switch';
import { loadCurrencies } from '../../helpers/loadCurrencies';
import { CustomizedSelect } from '../../components/ui/CustomizedSelect';
import { ButtonFloatAgencia } from '../ui/ButtonFloatAgencia';

export const UserInfo = () => {
    const classes = useFormStyles();
    
    const dispatch = useDispatch()
    const { active } = useSelector(state => state.user);
    const { data } = loadGridUser(active?.EsAgencia);
    const result = data.filter( e => e?.type !== 'detail' && e?.filter === undefined && e?.filterdetail === undefined)
    
    const currencies = loadCurrencies();


    const handleSubmit = ( values, {setSubmitting} ) => {
       
        const user = {
            PkUsuarios: active.PkUsuarios,
            Email: values.email,
            Nombre: values.name,
            Apellidos: values.surname,
            Telefono: values.telefono,
            Telefono2: values.Telefono2 || '',
            Observaciones: values.observaciones ? values.observaciones : "",
            FechaBaja: !values.date ? null : values.date,
            VersionFila: active?.VersionFila,
            RazonSocial: values.razonSocial??'',
            DomicilioFiscal: values.DomicilioFiscal??'',
            NIF: values.NIF??'',
            DomicilioFiscalLinea2: values.DomicilioFiscalLinea2 || '',
            TextoPiePresupuesto: values.TextoPiePresupuesto || '',
            TextoTransferencias: values.TextoTransferencias || '',
            ImporteComision: +values.ImporteComision,
            ImporteComisionDescuento: +values.ImporteComisionDescuento,
            PorcentajeComision: +values.PorcentajeComision,
            ComisionaPorImporte: values.ComisionaPorImporte??false,
            Comisiona: values.Comisiona??false,
            Moneda: values.Moneda,
            MargenConfiguradoEnServicios: values.MargenConfiguradoEnServicios,
            CIF: values.CIF || '',
            CodigoLicencia: values.CodigoLicencia || '',
        }
        dispatch(startUpdateUser(user, setSubmitting));
        
    }
    
    return (


        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>

        <Formik 
            validationSchema={ validationSchemaUserEdit }
            enableReinitialize={ true } 
            initialValues={{
                    CIF: active?.CIF || '',
                    CodigoLicencia: active?.CodigoLicencia || '',
                    name: active?.Nombre,
                    surname: active?.Apellidos,
                    observaciones: active?.Observaciones,
                    telefono: active?.Telefono || '',
                    Telefono2: active?.Telefono2 || '',
                    email: active?.Email,
                    date: active?.FechaBaja,
                    razonSocial: active?.RazonSocial,
                    DomicilioFiscal: active?.DomicilioFiscal || '',
                    NIF: active?.NIF,
                    DomicilioFiscalLinea2: active?.DomicilioFiscalLinea2 || '',
                    TextoPiePresupuesto: active?.TextoPiePresupuesto || '',
                    TextoTransferencias: active?.TextoTransferencias || '',
                    ImporteComision: active?.ImporteComision??0,
                    ImporteComisionDescuento: active?.ImporteComisionDescuento??0,
                    PorcentajeComision: active?.PorcentajeComision??0,
                    Comisiona: active?.Comisiona ?? false,
                    ComisionaPorImporte: active?.ComisionaPorImporte ?? false,
                    Moneda: active?.Moneda,
                    MargenConfiguradoEnServicios: active?.MargenConfiguradoEnServicios,
                }
            } 
            onSubmit={handleSubmit}>
        {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid, setFieldValue }) => (
        <Grid md={6} xs={11}>
        <Form>
            <Box sx={{ p:2, mb: 4}}>
                <Typography variant='h1'>Usuario {active?.Rol.Nombre}</Typography>
            </Box>
            <Grid
                container
                spacing={3}
            >
                {
                    
                    result.map( (element) => {
                        if( element.name === 'surname' && active?.Rol?.PkRoles === ROLREVENDEDOR ){
                            return null;
                        }
                        else{
                            return (
                            
                                <Grid
                                    key={ element.name }
                                    item
                                    md={6}
                                    xs={12}
                                    >
                                   <TextField
                                            fullWidth
                                            name={element.name}
                                            size='small'
                                            classes={{ root: classes.inputText }}
                                            id={`outlined-basic-${element.name}`} 
                                            label={ element.label }
                                            variant='outlined'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        { element.startAdornment }
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    input: classes.resize
                                                }
                                            }}
                                            InputLabelProps={
                                                {style: {fontSize: '0.8em'}}
                                            }
                                            value={values[element.name]}
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched[element.name] && Boolean(errors[element.name])}
                                            helperText={touched[element.name] && errors[element.name]}
                                            disabled={ isSubmitting }
                                            multiline={ element.name === 'observaciones' ? true : false}
                                            maxRows={ element.name === 'observaciones' ? 10 : undefined }
                                            minRows={ element.name === 'observaciones' ? 5 : undefined }
                                        />
                                </Grid>
                            )
                        }
                        
                    })

                }

                <Grid
                item
                md={6}
                xs={12}
                >
                    <Field style={{fontSize: '0.8em'}} component={FormikDatePicker} label='Fecha Baja' textfield='date' name="date" isSubmitting={ isSubmitting } value={ active?.FechaBaja  } />
                </Grid>
                
                
                <Grid item 
                md={6}
                xs={12} classes={{root: classes.container }}>
                                <Box>
                                    <FormControl
                                        fullWidth
                                        style={{
                                            marginTop: '1rem'
                                        }}
                                        disabled={ isSubmitting }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        
                                                          
                                    >
                                    
                                    <InputLabel style={{fontSize: '1rem'}} id="select-label-exchange-rates">Moneda</InputLabel>
                                        <Field
                                            name="Moneda" 
                                            component={CustomizedSelect}
                                            >
                                                {
                                                    currencies.map( c => {
                                                        return (
                                                            <MenuItem style={{fontSize: '0.7em'}} value={ c.code }>
                                                            <ListItemIcon>
                                                                 {
                                                                    c.icon()
                                                                }
                                                            </ListItemIcon>
                                                            {
                                                                c.label
                                                            }
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                                
                                        </Field>
                                    </FormControl>
                                </Box>
                        </Grid>   


                { ( active?.EsAgencia)
                    &&
                    (
                    <>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='Telefono2'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-Telefono2`} 
                                        label='Teléfono 2'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentOutlined />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.Telefono2}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.Telefono2 && Boolean(errors.Telefono2)}
                                        helperText={touched.Telefono2 && errors.Telefono2}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='razonSocial'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-razonSocial`} 
                                        label='Razón Social'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentOutlined />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.razonSocial}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.razonSocial && Boolean(errors.razonSocial)}
                                        helperText={touched.razonSocial && errors.razonSocial}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='DomicilioFiscal'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-DomicilioFiscal`} 
                                        label='Domicilio Fiscal'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentOutlined />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.DomicilioFiscal}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.DomicilioFiscal && Boolean(errors.DomicilioFiscal)}
                                        helperText={touched.DomicilioFiscal && errors.DomicilioFiscal}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='DomicilioFiscalLinea2'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-DomicilioFiscal`} 
                                        label='Domicilio Fiscal línea 2'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentOutlined />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.DomicilioFiscalLinea2}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.DomicilioFiscalLinea2 && Boolean(errors.DomicilioFiscalLinea2)}
                                        helperText={touched.DomicilioFiscalLinea2 && errors.DomicilioFiscalLinea2}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='CIF'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-Telefono2`} 
                                        label='CIF'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentOutlined />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.CIF}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.CIF && Boolean(errors.CIF)}
                                        helperText={touched.CIF && errors.CIF}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='CodigoLicencia'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-razonSocial`} 
                                        label='Código de licencia'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentOutlined />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.CodigoLicencia}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.CodigoLicencia && Boolean(errors.CodigoLicencia)}
                                        helperText={touched.CodigoLicencia && errors.CodigoLicencia}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>


                            <Grid
                                item
                                md={4}
                                xs={12}
                            >

                                <SwitchMUI 
                                    label='Margen Configurado en servicios'
                                    name='MargenConfiguradoEnServicios'
                                    values={ values }
                                    setFieldValue={ setFieldValue }
                                />
                            </Grid>

                            <Grid
                                item
                                md={4}
                                xs={12}
                            >

                                <SwitchMUI 
                                    label='Comisiona'
                                    name='Comisiona'
                                    values={ values }
                                    setFieldValue={ setFieldValue }
                                />
                            </Grid>


                            <Grid
                                item
                                md={4}
                                xs={12}
                            >

                                <SwitchMUI 
                                    label='Comisiona por importe'
                                    name='ComisionaPorImporte'
                                    values={ values }
                                    setFieldValue={ setFieldValue }
                                    disabled={ isSubmitting || (values?.Comisiona === false) }
                                />
                            </Grid>


                            <Grid
                                key='ImporteComision'
                                item
                                md={4}
                                xs={12}
                                >
                                <TextField
                                    fullWidth
                                    name='ImporteComision'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-ImporteComision`} 
                                    label={ 'Importe Comisión' }
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Euro />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={values['ImporteComision']}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched['ImporteComision'] && Boolean(errors['ImporteComision'])}
                                    helperText={touched['ImporteComision'] && errors['ImporteComision']}
                                    disabled={ isSubmitting || (values?.Comisiona === false)  || (values?.ComisionaPorImporte === false) }
                                />
                            </Grid>


                    <Grid
                        key='ImporteComisionDescuento'
                        item
                        md={4}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='ImporteComisionDescuento'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-ImporteComisionDescuento`} 
                            label={ 'Importe Comisión con descuento' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Euro />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['ImporteComisionDescuento']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['ImporteComisionDescuento'] && Boolean(errors['ImporteComisionDescuento'])}
                            helperText={touched['ImporteComisionDescuento'] && errors['ImporteComisionDescuento']}
                            
                            disabled={ isSubmitting || (values?.Comisiona === false)  || (values?.ComisionaPorImporte === false) }
                        />
                    </Grid>


                    <Grid
                        key='PorcentajeComision'
                        item
                        md={4}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='PorcentajeComision'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-PorcentajeComision`} 
                            label={ '% Comisión' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Euro />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['PorcentajeComision']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['PorcentajeComision'] && Boolean(errors['PorcentajeComision'])}
                            helperText={touched['PorcentajeComision'] && errors['PorcentajeComision']}
                            
                            disabled={ isSubmitting || (values?.Comisiona === false)  || (values?.ComisionaPorImporte) }
                        />
                    </Grid>




                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                            marginBottom: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                                  
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h3"
                        >
                            Texto pie presupuesto
                        </Typography>
                            
                        <CKEditor                
                            config = {{
                                toolbar: {
                                    items: [ 'bold', 'italic', '|', 'undo', 'redo', '-', 'numberedList', 'bulletedList' ],
                            
                                    shouldNotGroupWhenFull: true
                                }
                            }
                            }
                            editor={ ClassicEditor }
                            data={ values.TextoPiePresupuesto }
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                
                            } }
                            onChange={ ( _, editor ) => {
                                const data = editor.getData();
                                setFieldValue('TextoPiePresupuesto', data);
                            } }
                            
                        />

 
            
                    </Grid>



                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                            marginBottom: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                                        
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h3"
                        >
                            Texto transferencias presupuesto
                        </Typography>  
                        <CKEditor                   
                            config = {{
                                toolbar: {
                                    items: [ 'bold', 'italic', '|', 'undo', 'redo', '-', 'numberedList', 'bulletedList' ],
                            
                                    shouldNotGroupWhenFull: true
                                }
                            }
                            }
                            editor={ ClassicEditor }
                            data={ values.TextoTransferencias }
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                
                            } }
                            onChange={ ( _, editor ) => {
                                const data = editor.getData();
                                setFieldValue('TextoTransferencias', data);
                            } }
                            
                        />

 
            
                    </Grid>

                    </>
)}



                
            </Grid>
            <Box
            sx={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',
                p: '2',
            
            }}
            >
           
           <Button 
                style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                onClick={ () => navigate('/users') }>
                    <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
            </Button>

           <LoadingButton
                variant="contained"
                type='submit'
                loading={ isSubmitting }
                disabled={ !isValid }
                style={{
                    backgroundColor: '#E5824F',

                }}
                >
                    Actualizar
            </LoadingButton>
            </Box>
        </Form>
        </Grid>
        )}
        </Formik>
 
        </LocalizationProvider>
    )
}
