import { Euro, MonetizationOn, Money } from '@material-ui/icons';
import React from 'react';


export const loadCurrencies = () => {

    const data = [
        {
            code: 'EUR',
            label: 'Euro',
            icon: () => (
                <>
                    <Euro />
                </>
            )
        },
        {
            code: 'USD',
            label: 'Dólar Americano',
            icon: () => (
                <>
                    <MonetizationOn />
                </>
            )
        },
        {
            code: 'MEX',
            label: 'Peso Mexicano',
            icon: () => (
                <>
                    <Money />
                </>
            )
        },

    ];

    return data;
}