/**
 * 
 * 
    {
        clients: []
        active: {
            id: '4',
            name: 'a.agra',
            email: 'agra@cubicacomunicacion.com',
        }
    }
 */

    import { types } from "../types/types";

    const initialState = {
        clients: [],
        active: null,
    }
    
    export const clientReducer = ( state = initialState, action ) => {
        switch (action.type) {
            case types.clientActive:
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            
            case types.clientsLoad:
                return {
                    ...state,
                    clients: [...action.payload]
                }
            
            case types.clientDelete:
                return {
                    ...state,
                    active: null,
                    clients: state.clients.filter( client => client.PkClientes !== action.payload )
                }
            case types.clientsLogOutCleaning:
                return initialState;
    
            default:
                return state;
        }
    }
    