import { makeStyles } from "@material-ui/core";

export const useInvoiceStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        padding: 50,
        justifyContent: 'space-between'
    },
    invoiceNumber: {
        textAlign: 'right',
        border: 'none',
        outline: 'none',
        fontSize: 15,
        color: 'gray',
    },

    invoiceLayout: {
        width: '60vw',
        height: '100vh',
        backgroundColor: '#fff',
        marginTop: '2rem',
        position: 'absolute',
        overflow: 'scroll',
        overflowX: 'hidden',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse'
    },
    addButton: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '15px',
        paddingTop: '10px',
        marginLeft: '2rem',
        marginTop: '1.2rem'
    },
    note: {
        marginTop: '20px',
        position: 'relative',
        border: '1px solid #000',
        padding: '1rem',
        width: '100%'
    },
    textarea: {
        height: '100px',
        marginTop: '1rem',
        outline: 'none',
        resize: 'none',
        border: 'none',
        position: 'relative',
        width: '100%'
      },
    tableHead: {
        backgroundColor: '#fff'
    }

    
}))