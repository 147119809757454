//Third-party packages
import React, { useEffect, useState } from 'react'
import { Button,
        Box,  
        FormControl, 
        Grid, 
        InputAdornment, 
        InputLabel, 
        MenuItem,
        Select,
        TextField, 
        Typography,
        ListItemIcon
} from '@material-ui/core'
import { useDispatch, useSelector} from 'react-redux';
import { Field, Form, Formik } from "formik";
import { LoadingButton } from '@mui/lab';

//Custom imports
import { startLoadingRols, startNewUser } from '../../redux/actions/users';
import { useFormStyles } from '../../styles/formStyles';

import showPassword from '../../images/show-password.svg';
import hidePassword from '../../images/hide-password.svg';
import { validationSchemaUser } from '../../utils/schemaValidationForm';
import { loadGridUser } from '../../helpers/loadGrid';

import { LocalizationProvider } from '@mui/x-date-pickers'


import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

import { CustomizedSelect } from '../ui/CustomizedSelect';
import { ROLREVENDEDOR, ROLVENDEDOR } from '../../utils/constants';
import { ApartmentOutlined, Code, HomeOutlined, Euro } from '@material-ui/icons';
import { startLogOutContentModal } from "../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../redux/actions/ui";
import { SwitchMUI } from '../ui/Switch';
import { loadCurrencies } from '../../helpers/loadCurrencies';

export const ContentModalUserAdd = () => {

    const { id: idLoggedUser } = useSelector(state => state.auth);
    const { roles } = useSelector(state => state.roles);
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const [visible, setVisible] = useState(false); //password type ('password' o 'text') state management    
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const { data } = loadGridUser();
    const result = data.filter( e => e?.type !== 'detail');
    const result_data = result.filter( e => e?.filter !== 'notAdd');
    const currencies = loadCurrencies();
    const tiposUsuarioValidos = {
        agencia: 2,
        particular: 1
    }

    useEffect(() => {
        dispatch( startLoadingRols() )
    }, [ dispatch ]);


    const initialValues =  {
        name: '',
        surname: '',
        email: '',
        password: '',
        rol: '',
        telefono: '',
        observaciones: '',
        date: null,
        tipoUsuario: 1,
        razonSocial: '',
        DomicilioFiscal: '',
        NIF: '',
        CIF: '',
        CodigoLicencia: '',
        Comisiona: false,
        ComisionaPorImporte: false,
        MargenConfiguradoEnServicios: false,
        Moneda: '',
        EsHotel: false
    }


    const handleSubmit = ( values, {setSubmitting} ) => {

        const user = {
            "Nombre": values.name,
            "Apellidos": values.surname,
            "Email": values.email,
            "FkRoles": parseInt( values.rol ),
            "PasswordHash": values.password,
            "CambioPassword": false,
            "AceptaPoliticas": false,
            "Telefono": values.telefono,
            "Observaciones": values.Observaciones ? values.Observaciones : "",
            "UsuarioAlta": idLoggedUser,
            "FechaBaja": values.date,
            "VersionFila": 0,
            "EsHotel": values.EsHotel
        }
        const payload ={
            ...user,
            "EsAgencia": values.tipoUsuario === 2 ? true: false,
            "RazonSocial": values.tipoUsuario === 2 ? values.razonSocial : "",
            "DomicilioFiscal": values.tipoUsuario === 2 ? values.DomicilioFiscal : "",
            "NIF": values.tipoUsuario === 2 ? values.NIF : "",
            "CIF": values.CIF ?? "",
            "CodigoLicencia": values.CodigoLicencia ?? "",
            "ImporteComision": values.tipoUsuario === 2 ? +values.ImporteComision : 0,
            "ImporteComisionDescuento": values.tipoUsuario === 2 ? +values.ImporteComisionDescuento : 0,
            "PorcentajeComision": values.tipoUsuario === 2 ? +values.PorcentajeComision : 0,
            "ComisionaPorImporte": values.tipoUsuario === 2 ? (values.ComisionaPorImporte??false) : false,
            "Comisiona": values.tipoUsuario === 2 ? (values.Comisiona??false) : false,
            
            "Moneda": values.Moneda,
            "MargenConfiguradoEnServicios": values.MargenConfiguradoEnServicios,
        }
        //console.log( payload )
        dispatch(startNewUser(payload, setSubmitting)); //we dispatch the action to create a new user

    }


    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };
    
    /**
     * The HTML is being rendered
     */
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik enableReinitialize initialValues={initialValues} validationSchema={ validationSchemaUser }   onSubmit={handleSubmit}>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, values, setFieldValue }) => (
            <Form>
                <Grid item style={{padding: '2rem'}}>
                    <Grid container direction='column'>
                        <Grid item classes={{root: classes.container }}>
                            <Typography variant='h3'>Agregar Usuario</Typography>
                        </Grid>
                            {
                                result_data.map( element => {
                                    if( element.name === 'surname' && values.tipoUsuario === 2 ){
                                        return null;
                                    }
                                    return(
                                        <Grid item  classes={{root: classes.container }}>
                                            <TextField
                                                fullWidth
                                                name={element.name}
                                                size='small'
                                                type={element.name==='password' ? (visible ? 'text' : 'password') : 'text'}
                                                classes={{ root: classes.inputText }}
                                                id={`outlined-basic-${element.name}`}  
                                                label={ element.label }
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            { element.startAdornment }
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        element?.endAdornment &&

                                                        (<InputAdornment position='end' onClick={ () => setVisible(!visible)}>
                                                            <img 
                                                                src={ visible ? showPassword : hidePassword}
                                                                alt='eye-password'
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </InputAdornment>)
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched[element.name] && Boolean(errors[element.name])}
                                                helperText={touched[element.name] && errors[element.name]}
                                                disabled={ isSubmitting }
                                                multiline={ element.name === 'observaciones' ? true : false}
                                                maxRows={ element.name === 'observaciones' ? 10 : undefined }
                                                minRows={ element.name === 'observaciones' ? 5 : undefined }
                                            />
                                        </Grid>
                                    )
                                })
                            }

                            
                <Grid item 
                md={6}
                xs={12} classes={{root: classes.container }}>
                                <Box>
                                    <FormControl
                                        fullWidth
                                        style={{
                                            marginTop: '1rem'
                                        }}
                                        disabled={ isSubmitting }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        
                                                          
                                    >
                                    
                                    <InputLabel style={{fontSize: '1rem'}} id="select-label-exchange-rates">Moneda</InputLabel>
                                        <Field
                                            name="Moneda" 
                                            component={CustomizedSelect}
                                            >
                                                {
                                                    currencies.map( c => {
                                                        return (
                                                            <MenuItem style={{fontSize: '0.7em'}} value={ c.code }>
                                                            <ListItemIcon>
                                                                 {
                                                                    c.icon()
                                                                }
                                                            </ListItemIcon>
                                                            {
                                                                c.label
                                                            }
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                                
                                        </Field>
                                    </FormControl>
                                </Box>
                        </Grid>   




                            
                              <Grid item  classes={{root: classes.container }}>
                                <Box>
                                    <FormControl
                                        fullWidth
                                        disabled={ isSubmitting }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.rol && Boolean(errors.rol)}
                                        helperText={touched.rol && errors.rol}
                                                          
                                    >
                                    
                                    <InputLabel style={{fontSize: '0.8em'}} id="select-label-exchange-rates">Roles</InputLabel>
                                        <Field
                                            name="rol" 
                                            component={CustomizedSelect}
                                            >
                                                {
                                                    roles.map( (rol, i) => {
                                                        return (
                                                            <MenuItem style={{fontSize: '0.7em'}} key={ i } value={ rol.PkRoles } variant='outlined'>{ rol.Nombre }</MenuItem>
                                                        )
                                                    })
                                                }
                                        </Field>
                                    </FormControl>
                                </Box>
                            </Grid> 

                            {
                                (values.rol === ROLREVENDEDOR )
                                &&
                                (
                                    <Grid item  classes={{root: classes.container }}>
                                        <FormControl
                                            fullWidth
                                            style={{
                                                marginBottom: '2rem'
                                            }}
                                            disabled={ isSubmitting }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched.tipoUsuario && Boolean(errors.tipoUsuario)}
                                            helperText={touched.tipoUsuario && errors.tipoUsuario}
                                                        
                                        >
                                            <InputLabel style={{fontSize: '0.8em'}} id="select-label-type-services">Tipo Usuario</InputLabel>
                                           
                                            <Field
                                                name="tipoUsuario" 
                                                component={CustomizedSelect}
                                                >
                                                <MenuItem style={{fontSize: '0.7em'}} value={2}>Agencia</MenuItem>
                                                <MenuItem style={{fontSize: '0.7em'}} value={1}>Particular</MenuItem>
                                            </Field>
                                        </FormControl>
                                    </Grid> 
                                )
                            }

                            {
                                values.tipoUsuario === tiposUsuarioValidos.agencia
                                && 
                                (
                                    <>

                                     
                            <Grid item  classes={{root: classes.container }}>
                                            <TextField
                                                fullWidth
                                                name='CIF'
                                                size='small'
                                                classes={{ root: classes.inputText }}
                                                label='CIF'
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <ApartmentOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.CIF && Boolean(errors.CIF)}
                                                helperText={touched.CIF && errors.CIF}
                                                disabled={ isSubmitting }
                                                
                                            />
                                        </Grid>


                                        <Grid item  classes={{root: classes.container }}>
                                            <TextField
                                                fullWidth
                                                name='CodigoLicencia'
                                                size='small'
                                                classes={{ root: classes.inputText }}
                                                label='Código de licencia'
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <ApartmentOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.CodigoLicencia && Boolean(errors.CodigoLicencia)}
                                                helperText={touched.CodigoLicencia && errors.CodigoLicencia}
                                                disabled={ isSubmitting }
                                                
                                            />
                                        </Grid>


                                    <Grid item  classes={{root: classes.container }}>
                                            <TextField
                                                fullWidth
                                                name='razonSocial'
                                                size='small'
                                                classes={{ root: classes.inputText }}
                                                label='Razón Social'
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <ApartmentOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.razonSocial && Boolean(errors.razonSocial)}
                                                helperText={touched.razonSocial && errors.razonSocial}
                                                disabled={ isSubmitting }
                                                
                                            />
                                        </Grid>

                                        <Grid item  classes={{root: classes.container }}>
                                            <TextField
                                                fullWidth
                                                name='DomicilioFiscal'
                                                size='small'
                                                classes={{ root: classes.inputText }}
                                                label='Domicilio Fiscal'
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <HomeOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.DomicilioFiscal && Boolean(errors.DomicilioFiscal)}
                                                helperText={touched.DomicilioFiscal && errors.DomicilioFiscal}
                                                disabled={ isSubmitting }
                                                
                                            />
                                        </Grid>

                                        {/* <Grid item  classes={{root: classes.container }}>
                                            <TextField
                                                fullWidth
                                                name='NIF'
                                                size='small'
                                                classes={{ root: classes.inputText }}
                                                label='NIF'
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Code />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.NIF && Boolean(errors.NIF)}
                                                helperText={touched.NIF && errors.NIF}
                                                disabled={ isSubmitting }
                                                
                                            />
                                        </Grid> */}



                <Grid
                    item
                    md={4}
                    xs={12}
                >

                    <SwitchMUI 
                        label='Hotel'
                        name='EsHotel'
                        values={ values }
                        setFieldValue={ setFieldValue }
                    />
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                >

                    <SwitchMUI 
                        label='Margen Configurado en servicios'
                        name='MargenConfiguradoEnServicios'
                        values={ values }
                        setFieldValue={ setFieldValue }
                    />
                </Grid>

                <Grid item  classes={{root: classes.container }}>

                    <SwitchMUI 
                        label='Comisiona'
                        name='Comisiona'
                        values={ values }
                        setFieldValue={ setFieldValue }
                    />
                </Grid>


                <Grid item  classes={{root: classes.container }} >

                    <SwitchMUI 
                        label='Comisiona por importe'
                        name='ComisionaPorImporte'
                        values={ values }
                        setFieldValue={ setFieldValue }
                        disabled={ isSubmitting || (values?.Comisiona === false) }
                    />
                </Grid>


                <Grid  classes={{root: classes.container }}
                        key='ImporteComision'
                        item
                        >
                        <TextField
                            fullWidth
                            name='ImporteComision'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-ImporteComision`} 
                            label={ 'Importe Comisión' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Euro />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['ImporteComision']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['ImporteComision'] && Boolean(errors['ImporteComision'])}
                            helperText={touched['ImporteComision'] && errors['ImporteComision']}
                            disabled={ isSubmitting || (values?.Comisiona === false)  || (values?.ComisionaPorImporte === false) }
                        />
                    </Grid>


                    <Grid  classes={{root: classes.container }}
                        key='ImporteComisionDescuento'
                        item
                        >
                        <TextField
                            fullWidth
                            name='ImporteComisionDescuento'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-ImporteComisionDescuento`} 
                            label={ 'Importe Comisión con descuento' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Euro />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['ImporteComisionDescuento']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['ImporteComisionDescuento'] && Boolean(errors['ImporteComisionDescuento'])}
                            helperText={touched['ImporteComisionDescuento'] && errors['ImporteComisionDescuento']}
                            
                            disabled={ isSubmitting || (values?.Comisiona === false)  || (values?.ComisionaPorImporte === false) }
                        />
                    </Grid>


                    <Grid  classes={{root: classes.container }}
                        key='PorcentajeComision'
                        item 
                        >
                        <TextField
                            fullWidth
                            name='PorcentajeComision'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-PorcentajeComision`} 
                            label={ '% Comisión' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Euro />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['PorcentajeComision']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['PorcentajeComision'] && Boolean(errors['PorcentajeComision'])}
                            helperText={touched['PorcentajeComision'] && errors['PorcentajeComision']}
                            
                            disabled={ isSubmitting || (values?.Comisiona === false)  || (values?.ComisionaPorImporte) }
                        />
                    </Grid>





                                    </>
                                        
                                ) 
                            }


                           
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >

                                <Button 
                                    style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                    onClick={ handleCancel }>
                                    <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                </Button>

                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    type='submit'
                                    loading={ isSubmitting }
                                    disabled={ !isValid }
                                    style={{
                                        backgroundColor: '#E5824F',
                    
                                    }}
                                >
                                        Añadir
                                </LoadingButton>

                            </Box>

                    </Grid>
                </Grid>
        </Form>
        )}
        </Formik>
        </LocalizationProvider>
    )
}
