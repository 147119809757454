import { makeStyles } from "@material-ui/core";

export const useTableStyles = makeStyles(theme => ({
    containerButtons:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
    },
    containerTable:{
        marginLeft: (matchesMD) => matchesMD ? undefined : '15rem',
        marginTop: '4rem',
        width: '80vw',
    },
    NameCell: {
        fontWeight: 400,
      },
}))