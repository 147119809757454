import {  useParams } from '@reach/router';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { ComisionistaInfo } from '../../components/comisionistas/comisionistaInfo';
import { Layout } from '../../components/ui/Layout'
import { actionEditComisionista } from '../../redux/actions/actions';

const ComisionistaDetailScreen = () => {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        dispatch(actionEditComisionista(params.PkComisionistas))
    }, [ dispatch, params.PkComisionistas ]);

    return (
        <Layout>
            <div style={{ marginTop: '2rem'}}/>
                <ComisionistaInfo />
            
        </Layout>
    )
}


export default ComisionistaDetailScreen;
