
//Third-party imports
import React from 'react'
import {useDispatch, useSelector } from 'react-redux';
import { Box, Grid, InputAdornment, TextField } from '@material-ui/core'

//Custom imports
import { useFormStyles } from '../../styles/formStyles';
import { startUpdateSettings } from '../../redux/actions/settings';
import { loadGridSettings } from '../../helpers/loadGrid';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { validationSchemaSettings } from '../../utils/schemaValidationForm';


/**
 * 
 * Author: QBK
 * Date: 04-10-2021
 */
export const ContentModalEditSettings = () => {
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const settings = useSelector(state => state.settings)
    const dispatch = useDispatch();
     const { data } = loadGridSettings();
    
    //we deconstruct the object

    const initialValues = {
      NombreEmpresa: settings.NombreEmpresa,
      RazonSocial: settings.RazonSocial,
      NombreSerieFacturacion: settings.NombreSerieFacturacion,
      UltimoNumeroFactura: settings.UltimoNumeroFactura,
      VersionFila: settings.VersionFila
    }

    const handleSubmit = ( values, {setSubmitting} ) => {

      const settings = {
        ...values,
      }
     dispatch( startUpdateSettings( settings, setSubmitting ) )

  }



    //HTML Grid Container
    return (
      <Formik initialValues={initialValues} validationSchema={ validationSchemaSettings }   onSubmit={handleSubmit}>
        {({ handleChange, values, errors, handleBlur, isSubmitting, touched, isValid }) => (
        <Form>
        <Grid item style={{ padding: '2rem'}}>
            <Grid container direction='column'>

              {
                data.map( (element, index) => {
                  return (
                    <Grid key={ index } item classes={{root: classes.container }}>
                      <TextField
                          name={ element.name }
                          id={`outlined-basic-${element.name}`} 
                          size='small'
                          label={ element.label }
                          value={values[element.name]}
                          onBlur={ handleBlur }
                          onChange={ handleChange }
                          error={touched[element.name] && Boolean(errors[element.name])}
                          helperText={touched[element.name] && errors[element.name]}
                          classes={{ root: classes.inputText }}
                          variant='outlined'
                          InputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                    { element.startAdornment }
                                  </InputAdornment>
                                ),
                                classes: {
                                  input: classes.resize
                                }
                          }}
                          disabled={
                            ((element.name === 'UltimoNumeroFactura' ) || isSubmitting) && true
                          }
                          
                        
                      />
                  </Grid>
                  )
                })
              }
             

              <Box
                  sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                  }}
              >

                  <LoadingButton
                      loadingPosition="start"
                      variant="contained"
                      type='submit'
                      loading={ isSubmitting }
                      disabled={ !isValid }
                      style={{
                          backgroundColor: '#E5824F',
      
                      }}
                  >
                          Editar
                  </LoadingButton>

              </Box>


            </Grid>
        </Grid>
        </Form>
        )}
        </Formik>
    )
}
