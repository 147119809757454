import { types } from "../types/types";


const initialState ={
    success: true,
    route: null,
}


export const routeReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.SUCCESS_API_REQUEST:
            return {
                ...state,
                success: true
            }
        
        case types.FAILED_API_REQUEST:
            return {
                ...state,
                success: false
            }
        case types.ROUTE_REDIRECT:
            return{
                ...state,
                route: `/${ action.payload }`
            }
        case types.ROUTE_REDIRECT_LOGOUT:
            return {
                ...state,
                route: null
            }
        default:
            return state;
    }
}