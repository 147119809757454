
//Third-party packages
import React from 'react'
import {  useDispatch, useSelector } from 'react-redux';
import { uiManageModalNotification } from '../../redux/actions/ui';


//Custom imports
import { Content } from './Content';


/**
 * 
    Author: QBK
    Date: 28-12-2021
 */
export const ConfirmSend = ( { action } ) => {
    const { activeEdit: active} = useSelector(state => state.invoice);
    const dispatch = useDispatch();
    const handleSuccess = () => {
        dispatch( action )
        dispatch(uiManageModalNotification())
    }
    const text = active?.PresupuestoEnviado ? '¿Desea reenviar el presupuesto?' : '¿Desea enviar el presupuesto?';


    //The HTML is being rendered
    return (
            <Content 
                title={ `${ text }` }
                text={ `Se enviará un correo a ${ active?.Cliente?.Email }` }
                action={ handleSuccess }
            />
    )
}
