//Third-party packages
import { createTheme } from '@material-ui/core/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { esES } from '@mui/material/locale';
//Colors
const orange = '#E6824F';
const info = '#606EB2';
const gray = '#e6e6ea';
const white = '#fff';


//App Theme
const theme = createTheme({
    palette: {
        primary: {
            main: orange
        },
        secondary: {
            main: orange
        },
        common: {
            info,
            gray,
            white
        }
    },
    typography: {
        h1:{
            fontSize: 28,
            fontFamily: "Roboto",
            fontWeight: 700,
            color: '#000',
        },
        h2:{
            fontSize: 22,
            fontFamily: 'Roboto',
            fontWeight: 700,
            color: '#000',
        },
        h3:{
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 500,
            color: '#000',
        },
        h4:{
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 700,
            color: '#000',

        },
        h5: {
            fontFamily: 'Roboto',
            fontSize: '0.5rem',
            fontWeight: 700,
            color: '#000',
        },
        body1: {
            fontFamily: "Roboto",
            fontSize: "18",
            fontWeight: 300,
        },
        body2: {
            fontFamily: "Roboto",
            fontSize: "1.3rem",
            fontWeight: 300,
            color: "#000",
        },
        subtitle1: {
            fontFamily: "Roboto",
            fontSize: 10,
            color: '#fff'
        }
    },
    overrides: {
        MUIDataTable: {
            paper: {
              boxShadow: 'none',
            },
          },
          MuiTableFooter: {
            root: {
              '& .MuiToolbar-root': {
                backgroundColor: 'white',
                fontSize: '0.9rem'
              },
            },
          },
          MUIDataTableBodyCell:{
              root:{
                  fontSize: '0.9rem'
              }
          },
          MUIDataTableHeadCell:{
              root:{
                  fontSize: '0.9rem'
              }
          },
          MUIDataTablePagination:{
              root:{
                  '& .MuiTypography-root':{
                  fontSize: '0.8rem'
                }
              }
          },
          MUIDataTableToolbar:{
              root:{
                  '& .MuiTypography-root':{
                  fontSize: '0.9rem'
                }
              }
          },
          MuiFormControlLabel:{
              label:{
                  color: '#000'
              }
          },
          MuiInputLabel:{
              root:{
                  color: '#000'
              }
          },
          MuiTableContainer:{
              root:{
                  width: '80vw'
              }
          },
          MuiCardHeader: {
              title: {
                fontFamily: 'Roboto',
                fontSize: '3rem'
              }
          },
          MuiTableHead: {
              root: {
                  fontSize: 14,
                  backgroundColor: '#FFF6F1',
              }
          }
          
        
        
    },

    "@global": {
    }
},
esES
);

export default theme;