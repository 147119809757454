// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-change-js": () => import("./../../../src/pages/auth/change.js" /* webpackChunkName: "component---src-pages-auth-change-js" */),
  "component---src-pages-auth-reset-password-index-js": () => import("./../../../src/pages/auth/resetPassword/index.js" /* webpackChunkName: "component---src-pages-auth-reset-password-index-js" */),
  "component---src-pages-clients-index-js": () => import("./../../../src/pages/clients/index.js" /* webpackChunkName: "component---src-pages-clients-index-js" */),
  "component---src-pages-clients-pk-clientes-js": () => import("./../../../src/pages/clients/[PkClientes].js" /* webpackChunkName: "component---src-pages-clients-pk-clientes-js" */),
  "component---src-pages-comisionistas-index-js": () => import("./../../../src/pages/comisionistas/index.js" /* webpackChunkName: "component---src-pages-comisionistas-index-js" */),
  "component---src-pages-comisionistas-pk-comisionistas-js": () => import("./../../../src/pages/comisionistas/[PkComisionistas].js" /* webpackChunkName: "component---src-pages-comisionistas-pk-comisionistas-js" */),
  "component---src-pages-exchanges-index-js": () => import("./../../../src/pages/exchanges/index.js" /* webpackChunkName: "component---src-pages-exchanges-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lines-index-js": () => import("./../../../src/pages/lines/index.js" /* webpackChunkName: "component---src-pages-lines-index-js" */),
  "component---src-pages-lines-pk-lineas-js": () => import("./../../../src/pages/lines/[PkLineas].js" /* webpackChunkName: "component---src-pages-lines-pk-lineas-js" */),
  "component---src-pages-login-directo-index-js": () => import("./../../../src/pages/loginDirecto/index.js" /* webpackChunkName: "component---src-pages-login-directo-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-me-js": () => import("./../../../src/pages/me.js" /* webpackChunkName: "component---src-pages-me-js" */),
  "component---src-pages-politicas-agencia-index-js": () => import("./../../../src/pages/politicas_agencia/index.js" /* webpackChunkName: "component---src-pages-politicas-agencia-index-js" */),
  "component---src-pages-politicas-index-js": () => import("./../../../src/pages/politicas/index.js" /* webpackChunkName: "component---src-pages-politicas-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sales-index-js": () => import("./../../../src/pages/sales/index.js" /* webpackChunkName: "component---src-pages-sales-index-js" */),
  "component---src-pages-sales-index-mod-js": () => import("./../../../src/pages/sales/index_mod.js" /* webpackChunkName: "component---src-pages-sales-index-mod-js" */),
  "component---src-pages-sales-invoice-index-js": () => import("./../../../src/pages/sales/invoice/index.js" /* webpackChunkName: "component---src-pages-sales-invoice-index-js" */),
  "component---src-pages-sales-invoice-pk-ventas-js": () => import("./../../../src/pages/sales/invoice/[PkVentas].js" /* webpackChunkName: "component---src-pages-sales-invoice-pk-ventas-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-pk-servicios-proveedores-js": () => import("./../../../src/pages/services/[PkServiciosProveedores].js" /* webpackChunkName: "component---src-pages-services-pk-servicios-proveedores-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-suppliers-index-js": () => import("./../../../src/pages/suppliers/index.js" /* webpackChunkName: "component---src-pages-suppliers-index-js" */),
  "component---src-pages-suppliers-pk-proveedores-js": () => import("./../../../src/pages/suppliers/[PkProveedores].js" /* webpackChunkName: "component---src-pages-suppliers-pk-proveedores-js" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test/index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-pages-users-index-js": () => import("./../../../src/pages/users/index.js" /* webpackChunkName: "component---src-pages-users-index-js" */),
  "component---src-pages-users-pk-usuarios-js": () => import("./../../../src/pages/users/[PkUsuarios].js" /* webpackChunkName: "component---src-pages-users-pk-usuarios-js" */)
}

