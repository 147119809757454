import { Grid, Typography, Button, InputAdornment, TextField } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validationSchemaOrder } from '../../../utils/schemaValidationForm'
import { FormikDatePicker } from '../../ui/FormikDatePicker';
import { handleSendBudget, handleSendFactura, startDownloadingFactura, startUpdateInvoice } from '../../../redux/actions/sales';
import esLocale from 'date-fns/locale/es'
import { Box } from '@mui/material';
import { SwitchMUI } from '../../ui/Switch';
import { startDownloadingPDFBudget } from '../../../redux/actions/sales';
import { startContentSendEmail } from '../../../redux/actions/contentModal';
import { NotificationModal } from '../../ui/NotificationModal';
import { actionFacturarContent } from '../../../redux/actions/actions';
import Swal from 'sweetalert2';
import { ROLADMIN, ROLVENDEDOR, ROLREVENDEDOR } from '../../../utils/constants';
import { navigate } from '@reach/router';
import { InvoiceForm } from './InvoiceForm';
import { Code } from '@material-ui/icons';
import { useFormStyles } from '../../../styles/formStyles';
import { useGetHoteles } from '../../../hooks/querys/useGetHoteles';
import Autocomplete from '@mui/material/Autocomplete';

const isAllConfirmed = ( venta ) => {
    let toret = true;
    venta?.LineasVentaCompuestas?.forEach( element => {
        if( !element?.ConfirmadaProveedor ){
            toret = false
        }

    })

    return toret
}


const isReservaCero = ( venta ) => {
    let toret = true;
    venta?.LineasVentaCompuestas?.forEach( element => {
        if( element?.ReservaLineaEuros !== 0 ){
            toret = false
        }

    })

    return toret
}



export const EstadoVenta = () => {
    const { activeEdit } = useSelector(state => state.invoice);
    const dispatch = useDispatch();
    const { idRol } = useSelector( state => state.auth);
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const { isLoading: isHotelesLoading, error: errorAgencia, data: hotelesDisponibles } = useGetHoteles()

    
    const initialValues = {
        "FechaVenta": activeEdit?.FechaVenta,
        "HotelCliente": activeEdit?.HotelCliente,
        "PorcDescuento": activeEdit?.PorcDescuento,
        "Observaciones":  activeEdit?.Observaciones,
        "FechaLimitePresupuesto": activeEdit?.FechaLimitePresupuesto,
        "FechaPagoReserva": activeEdit?.FechaPagoReserva,
        "FechaFactura": activeEdit?.FechaFactura,
        "PresupuestoEnviado": activeEdit?.PresupuestoEnviado,
        "FechaEnvioPresupuesto": activeEdit?.FechaEnvioPresupuesto,
        "ReservaPagada": isReservaCero(activeEdit) ? true : activeEdit?.ReservaPagada,
        "ReservaPagadaAgencia": activeEdit?.ReservaPagadaAgencia,
        "ImporteFactura": +activeEdit?.ImporteFactura,
        "VersionFila": activeEdit?.VersionFila,
        "PkVentas": activeEdit?.PkVentas,
        "ConceptoFactura": activeEdit?.ConceptoFactura,
        "UsaCabeceraAgencia": activeEdit?.UsaCabeceraAgencia,
        "FkAgenciaCabecera": activeEdit?.FkAgenciaCabecera
    }

    const handleSubmit = (values, {setSubmitting}) => {
        const payload = {
            ...values,
            HotelCliente: !values.HotelCliente ? "" : values.HotelCliente,
            FechaLimitePresupuesto: !values.FechaLimitePresupuesto ? null : values.FechaLimitePresupuesto,
            Observaciones: !values.Observaciones ? "" : values.Observaciones,
            ConceptoFactura: !values.ConceptoFactura ? "" : values?.ConceptoFactura
        }
        dispatch( startUpdateInvoice(activeEdit.PkVentas, payload, setSubmitting) )
    }
    const func = useCallback( () => {
        dispatch(handleSendBudget( activeEdit?.PkVentas))
    }, [ activeEdit?.PkVentas, dispatch ])

    const handleSendEmail = () => {
        dispatch(startContentSendEmail( func ))
    }

    const handleFacturar = () => {
        if(!isAllConfirmed(activeEdit)){
            Swal.fire('Error', 'No puede facturar una venta que tiene líneas sin confirmar', 'error');
            return;
        }
        dispatch(actionFacturarContent())
    }


    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={ validationSchemaOrder }
            onSubmit={ handleSubmit }
        >
        {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid, setFieldValue }) => (
        <Form> 
            <Grid container spacing={3} >

                <Grid
                    item
                    md={4}
                    xs={12}
                >

                    <SwitchMUI 
                        label='Presupuesto Enviado'
                        name='PresupuestoEnviado'
                        values={ values }
                        setFieldValue={ setFieldValue }
                    />
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                >
                    <Field component={FormikDatePicker} textfield='FechaEnvioPresupuesto' name='FechaEnvioPresupuesto' label="Fecha Envío Presupuesto" isSubmitting={ isSubmitting } value={ values?.FechaEnvioPresupuesto   }  />
                </Grid>

                <Grid
                    item
                    md={2}
                    xs={6}
                >
                    
                <LoadingButton
                    variant="contained"
                    style={{
                        backgroundColor: '#E5824F',
                    }}
                    onClick={ handleSendEmail }
                    
                >
                    Enviar presupuesto email
                </LoadingButton>

                </Grid>
                <Grid
                    item
                    md={2}
                    xs={6}
                >
                    
                <LoadingButton
                    variant="contained"
                    style={{
                        backgroundColor: '#E5824F',
                    }}                    
                    onClick={ () => dispatch(startDownloadingPDFBudget(activeEdit.PkVentas)) }                
                >
                    Descargar presupuesto 
                </LoadingButton>

                </Grid>



                <Grid
                    item
                    md={4}
                    xs={12}
                >

                    <SwitchMUI 
                        label='Reserva pagada'
                        name='ReservaPagada'
                        values={ values }
                        setFieldValue={ setFieldValue }
                    />
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                >
                    <Field disabled component={FormikDatePicker} textfield='FechaPagoReserva' name='FechaPagoReserva' label="Fecha pago reserva" isSubmitting={ isSubmitting } value={ values?.FechaPagoReserva   }  />
                </Grid>


                <Grid
                    item
                    md={4}
                    xs={12}
                >
                    {
                        (activeEdit.Usuario && activeEdit.Usuario.EsAgencia && idRol !== ROLREVENDEDOR)
                        &&
                        (<SwitchMUI 
                            label='Reserva pagada Agencia'
                            name='ReservaPagadaAgencia'
                            values={ values }
                            setFieldValue={ setFieldValue } 
                        />)
                    }
                </Grid>


                {
                    (!activeEdit.FechaFactura && idRol === ROLADMIN)
                    ?
                    (
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >

                        <LoadingButton
                            variant="contained"
                            style={{
                                backgroundColor: '#E5824F',
                            }}
                            onClick={ handleFacturar }
                            
                        >
                            Facturar venta
                        </LoadingButton>
                        </Grid>
                    )
                    :
                    <Grid
                            item
                            md={4}
                            xs={12}
                    ></Grid>
                }
                {
                    idRol === ROLADMIN
                    &&
                    <>
                        
                      <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <Field component={FormikDatePicker} textfield='FechaFactura' name='FechaFactura' label="Fecha Factura" isSubmitting={ isSubmitting } value={ values?.FechaFactura   } />
                    </Grid>
                    {
                        !activeEdit.FechaFactura 
                        &&
                        (
                            <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <TextField
                                    name='ConceptoFactura'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic`}  
                                    label='Concepto Factura'
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Code />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={ values?.ConceptoFactura }
                                    onChange={ (e) => {
                                        handleChange(e)
                                        localStorage.setItem('ConceptoFactura', e.target.value)
                                    } }
                                    onBlur={ handleBlur }

                            />
                        </Grid>
                        )
                    }
                   
                    </>
                }
                

                {
                    (activeEdit?.FechaFactura && idRol === ROLADMIN)
                    &&
                    (
                        <>
                            <Grid
                            item
                            md={2}
                            xs={6}
                            >
                                
                            <LoadingButton
                                variant="contained"
                                style={{
                                    backgroundColor: '#E5824F',
                                }}
                                onClick={ () => dispatch( handleSendFactura( activeEdit?.PkVentas))}
                            >
                                Enviar factura email
                            </LoadingButton>

                            </Grid>


                            <Grid
                                item
                                md={2}
                                xs={6}
                            >
                                
                            <LoadingButton
                                variant="contained"
                                style={{
                                    backgroundColor: '#E5824F',
                                }}
                                onClick={ () => dispatch( startDownloadingFactura( activeEdit?.PkVentas))}
                                
                            >
                                Descargar factura 
                            </LoadingButton>

                            </Grid>
                        </>
                    )
                }

                {
                    (idRol === ROLADMIN || idRol === ROLVENDEDOR ) &&
                    <>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <h5>Asociar venta a un hotel</h5>
                            {
                                isHotelesLoading &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <p>Cargando...</p>
                                </Grid>
                            }
                            {
                                !isHotelesLoading && errorAgencia &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <p>Ha ocurrido un error al intentar cargar los hoteles disponibles</p>
                                </Grid>
                            }
                            {
                                !isHotelesLoading && hotelesDisponibles &&
                                <>
                                    <SwitchMUI 
                                        label='Asociar hotel'
                                        name='UsaCabeceraAgencia'
                                        values={ values }
                                        setFieldValue={ setFieldValue } 
                                    />
                                    <Autocomplete 
                                        style={ { fontSize: '0.8em', marginTop: '16px' } }
                                        name='FkAgenciaCabecera'
                                        defaultValue={ 
                                            initialValues.FkAgenciaCabecera ?
                                            (() => { 
                                                const hotel = hotelesDisponibles.find( hotel => hotel.PkUsuarios === initialValues.FkAgenciaCabecera )
                                                return hotel ? hotel.Nombre : ''
                                            })() :
                                            '' 
                                        }
                                        classes={{ root: classes.inputText }}
                                        size='small'
                                        autoHighlight
                                        fullWidth
                                        disablePortal
                                        ListboxProps={{ style: { maxHeight: '10rem' } }}
                                        noOptionsText={ `No hay hoteles disponibles` }
                                        id="agencia-cabecera-autocomplete"
                                        renderInput={(params) => ( 
                                            <TextField 
                                                {...params}
                                                name='FkAgenciaCabecera' 
                                                size='small'
                                                variant='outlined' 
                                                label="Seleccionar hotel" 
                                                placeholder='Buscar...'
                                                onBlur={ handleBlur }
                                                onChange={ handleChange }
                                            />
                                        )}
                                        sx={{ marginBottom: '0.2rem'}}
                                        options= { hotelesDisponibles.map( hotel => hotel.Nombre ) } //{ idRol === ROLADMIN ? searchClientes : searchClientesNotAdmin }                                
                                        onChange={( _, value ) => {
                                            const hotelSeleccionado = hotelesDisponibles.find( hotel => hotel.Nombre === value )            
                                            setFieldValue(
                                                "FkAgenciaCabecera",
                                                hotelSeleccionado ?
                                                hotelSeleccionado.PkUsuarios :
                                                initialValues.FkAgenciaCabecera              
                                            );
                                        }}                    
                                    />
                                </>
                                
                            }
                        </Grid>
                    </>
                }
                  
             </Grid>
            


            

            <Grid container spacing={3} >

                <Grid item md={9} xs={1} >
                </Grid>

            </Grid>

            {
                activeEdit?.FechaFactura
                &&
                (
                    <>
                    <InvoiceForm />
                    </>
                )
            }


 
                    <>
                         <Box
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >

                            <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                    marginTop: '1rem',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                
                                <Button
                                    style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                    onClick={ () => navigate('/sales') }>
                                    <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                </Button>
                                {
                                !activeEdit?.FechaFactura
                                &&
                                (
                                <LoadingButton
                                    variant="contained"
                                    type='submit'
                                    style={{
                                        backgroundColor: '#E5824F',
                                    }}
                                    disabled={ isSubmitting || !isValid }

                                >
                                    Guardar Cambios
                                </LoadingButton>
                                )
    }
                            </Grid>
                        </Box>
                    </>
             

            
            
            
            </Form>
        )}
        </Formik>
        </LocalizationProvider>
        <NotificationModal />
        
        </>        
    )
}
