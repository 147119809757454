import {  Typography, useMediaQuery, useTheme } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTableParams } from '../../../hooks/useTableParams'
import { actionDeleteService, actionEditServiceModal } from '../../../redux/actions/actions'
import { NotificationModal } from '../../ui/NotificationModal'
import { TransitionsModal } from '../../ui/TransitionModal'
import { ROLADMIN } from '../../../utils/constants'
import { finishError } from '../../../redux/actions/ui'
import { obtenerPrecioEuros } from '../../../utils/exchange'


export const ServicesProvided = () => {
    const data = [];
    const showColumnAction = false;
    const dispatch = useDispatch()
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const { active } = useSelector(state => state.supplier );
    const { menuState } = useSelector(state => state.ui)
    const { idRol } = useSelector(state => state.auth);
    const {modal} = useSelector(state => state.modal)
    const serviceOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: `Servicios ${ active?.Nombre }`, rowsPerPage: 5};
    const serviceColumnsDeskTop =  [['Nombre', true], ['Reserva (€)', false], ['Coste ($)', false], ['Coste (€)', false]];
    const serviceColumnsMobile =  [['Nombre', true]];
    const actions = [
        { isShown: true, type:'delete', color: '#000',  eject: ( dataIndex, e ) => handleDelete( dataIndex, e )},
        // { isShown: true, type: 'edit', data: active?.ServiciosProveedor, link: 'services', key:'PkServiciosProveedores', color: '#000',  eject: ( dataIndex ) => handleEdit( dataIndex )},
    ]
    const { options, columns } = useTableParams( serviceOptions, !matchesXS ? serviceColumnsDeskTop : serviceColumnsMobile, actions, idRol !== ROLADMIN ? showColumnAction : true, (dataIndex, e) => handleEdit(dataIndex, e));

    useEffect(() => {
        dispatch(finishError())
    }, [ modal, dispatch])

    active?.ServiciosProveedor?.map( service => {
        let rate = 0;
        active?.TiposCambioProveedor?.map( exchange => {
            if( service.FkTiposServicio === exchange.FkTiposServicio){
                rate = exchange
            }
            return null;
        })
        data.push([service.Nombre, 
                service?.PrecioVentaEuros - obtenerPrecioEuros(service.CosteDolares, rate.CambioEuros, rate.CambioDolares), 
                service.CosteDolares, 
            obtenerPrecioEuros(service.CosteDolares, rate.CambioEuros, rate.CambioDolares)]);
        return null;
    })
   
    

    const handleEdit = ( dataIndex, e ) => {
        if (e) e.stopPropagation();
        dispatch( actionEditServiceModal( active?.ServiciosProveedor[ dataIndex ].PkServiciosProveedores ) )

    }

    const handleDelete = ( dataIndex, e ) => {
        if (e) e.stopPropagation();
        dispatch( actionDeleteService( active?.ServiciosProveedor[ dataIndex ].PkServiciosProveedores ) )
    }

    return (
        <>
            <div style={{
                width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
                marginBottom: '2rem'
            }}>
                {
                    !matchesXS
                    &&
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>SERVICIOS</Typography>
                }
            
                <MUIDataTable
                    title={ matchesXS && (<Typography variant='h2' >Servicios del proveedor</Typography>) }
                    {...{data, columns, options}}
                    
                />
                <TransitionsModal />
                <NotificationModal />

            
            

            </div>
    
        </>
    )
}
