import { Add, HotelOutlined, MonetizationOn } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Fab, Action } from 'react-tiny-fab';
import { actionAddRate, actionAddResellerServices, actionAddSupplierService } from '../../redux/actions/actions';
import { actionAddAgencySeller } from '../../redux/actions/agencyServices';

export const ButtonFloatAgencia = () => {
    const mainButtonStyles = {backgroundColor: '#E6824F'};
    const dispatch = useDispatch();
    const { modal } = useSelector(state => state.modal)


    const handleAddVendedorAgencia = () => {
      dispatch(actionAddAgencySeller())
  }
    const handleAddServiceReseller = () => {
        dispatch(actionAddResellerServices())
    }
    return (
      <>
      {
        modal
        ?
        <></>
        :
        <Fab
        mainButtonStyles={mainButtonStyles}
        icon={ <Add />}
        alwaysShowTitle={true}
      >

          <Action
              text="Añadir servicio"
              onClick={handleAddServiceReseller}
            >
              <MonetizationOn />
          </Action>
        

        <Action
            text="Nuevo vendedor"
            onClick={ handleAddVendedorAgencia }
          >
            <HotelOutlined />
        </Action>

        </Fab>

      }
      </>
         
    )
}
