import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid, InputAdornment, TextField, Typography, ListItemIcon, Button } from '@material-ui/core';
import { Euro, MonetizationOn, RemoveRedEyeRounded, AccountCircleRounded, Phone, VpnKey, AlternateEmail } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { startEditServiceReseller } from '../../../redux/actions/supplierServices';
import { useFormStyles } from '../../../styles/formStyles';
import { validationSchemaEditResellerService } from '../../../utils/schemaValidationForm';
import { calcularImporteReservaServicioRevendedor } from '../../../utils/utils';
import { startLogOutContentModal } from "../../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../../redux/actions/ui";
import { Spinner } from '../../ui/Spinner';
import { loadCurrencies } from '../../../helpers/loadCurrencies';
import { SwitchMUI } from '../../../components/ui/Switch';
import { startEditVendedorAgencia } from '../../../redux/actions/agencyServices';
import * as yup from 'yup'
import { CustomizedSelect } from '../../../components/ui/CustomizedSelect';

export const ContentEditVendedorAgencia = () => {
    const { active } = useSelector(state => state.user);
    const { active: activeVendedor } = useSelector(state => state.vendedoresAgencia)
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch();
    const { loadingModal } = useSelector(state => state.ui)
    const currencies = loadCurrencies();
    const c = currencies.filter( e => e.code === activeVendedor?.Moneda)
 
    const initialValues = {
        name: activeVendedor?.Nombre,
        surname: activeVendedor?.Apellidos,
        email: activeVendedor?.Email,
        //password: '',
        //password2: '',
        telefono: activeVendedor?.Telefono,
        comisiona: activeVendedor?.Comisiona,
        comisionaPorImporte: activeVendedor?.ComisionaPorImporte,
        importeComision: activeVendedor?.ImporteComision,
        Moneda: activeVendedor?.Moneda,

        FkUsuarios_Agencia: active.PkUsuarios,
        EsVendedor: true,
        MargenConfiguradoEnServicios: activeVendedor?.MargenConfiguradoEnServicios,
    };
           

    const submit = (values, {setSubmitting}) => {
        const user = {
            "PkUsuarios": activeVendedor?.PkUsuarios,
            "Nombre": values.name,
            "Apellidos": values.surname,
            "Email": values.email,
            //"FkRoles": ROLREVENDEDOR,
            //"PasswordHash": values.password,
            "Telefono": values.telefono,
            "VersionFila": activeVendedor.VersionFila
        }
        const payload ={
            ...user,
            //"EsAgencia": true,
            //"EsVendedor": true,
            "FkUsuarios_Agencia": activeVendedor?.FkUsuarios_Agencia,
            "Comisiona": values.comisiona,
            "ComisionaPorImporte": values.comisionaPorImporte,
            //"MargenConfiguradoEnServicios": values.MargenConfiguradoEnServicios,
            "ImporteComision": +values.importeComision ?? 0,
            "Moneda": values.Moneda,

        }
        dispatch(startEditVendedorAgencia( payload, setSubmitting));
    }
    
    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };


    const validationSchemaResellerService = () => {
        return yup.object({
           
            password2: yup
            .string()
            .oneOf([yup.ref('password')], 'Las contraseñas deben coincidir')
                    // moneda: yup.string().required('Moneda es obligatorio'),
                    })
    }

    return (
        <>
        {
            loadingModal
            ?
            <Spinner />
            :
            <>
                
            <Formik 
                validationSchema={ validationSchemaResellerService } 
                initialValues={initialValues} 
                onSubmit={submit}>
                {({ handleChange, errors, values, setFieldValue, handleBlur, isSubmitting, touched, isValid }) => (
                <Form>
                <Grid item style={{ padding: '2rem'}}>
                    <Grid container direction='column'>
                            
                            <Grid item classes={{root: classes.container }}>
                                <Typography variant='h3'>Vendedor: { activeVendedor?.Nombre } { activeVendedor?.Apellidos }</Typography>
                            </Grid>

                        <Grid container 
                                spacing={3}>
                                
                        


                        
                        <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='name'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-razonSocial`} 
                                        label='Nombre'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircleRounded />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.name}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.razonSocial && Boolean(errors.razonSocial)}
                                        helperText={touched.razonSocial && errors.razonSocial}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>
                            
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='surname'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-razonSocial`} 
                                        label='Apellidos'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircleRounded />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.surname}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.razonSocial && Boolean(errors.razonSocial)}
                                        helperText={touched.razonSocial && errors.razonSocial}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='email'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-razonSocial`} 
                                        label='Email'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AlternateEmail />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.email}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.razonSocial && Boolean(errors.razonSocial)}
                                        helperText={touched.razonSocial && errors.razonSocial}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='telefono'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-razonSocial`} 
                                        label='Teléfono'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Phone />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.telefono}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.razonSocial && Boolean(errors.razonSocial)}
                                        helperText={touched.razonSocial && errors.razonSocial}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='password'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-razonSocial`} 
                                        label='Contraseña'
                                        type='password'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <VpnKey />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.password}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.razonSocial && Boolean(errors.razonSocial)}
                                        helperText={touched.razonSocial && errors.razonSocial}
                                        disabled={ isSubmitting }
                                    />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                        fullWidth
                                        name='password2'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-razonSocial`} 
                                        label='Confirmar contraseña'
                                        type='password'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <VpnKey />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={values.password2}
                                        onBlur={ handleBlur }
                                        onChange={handleChange}

                                        error={touched.razonSocial && Boolean(errors.razonSocial)}
                                        helperText={touched.razonSocial && errors.razonSocial}
                                        disabled={ isSubmitting }
                                    />
                                    {errors.password2 && (<div>{errors.password2}</div>)}

                            </Grid>



                            <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >

                                <SwitchMUI 
                                    label='Comisiona'
                                    name='comisiona'
                                    values={ values }
                                    setFieldValue={ setFieldValue }
                                />
                            </Grid>


                            <Grid
                                key='ImporteComision'
                                item
                                md={6}
                                xs={12}
                                >
                                <TextField
                                    fullWidth
                                    name='importeComision'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-ImporteComision`} 
                                    label={ 'Importe Comisión' }
                                    variant='outlined'
                                    InputProps={{
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={values.importeComision}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched['ImporteComision'] && Boolean(errors['ImporteComision'])}
                                    helperText={touched['ImporteComision'] && errors['ImporteComision']}
                                    disabled={ isSubmitting || (values?.comisiona === false)  }
                                />
                            </Grid>




                            <Grid item 
                                md={6}
                                xs={12} classes={{root: classes.container }}>
                                <Box>
                                    <FormControl
                                        fullWidth
                                        style={{
                                            marginTop: '1rem'
                                        }}
                                        disabled={ true }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        
                                                            
                                    >
                                    
                                    <InputLabel style={{fontSize: '1rem'}} id="select-label-exchange-rates">Moneda</InputLabel>
                                        <Field
                                            name="Moneda" 
                                            component={CustomizedSelect}
                                            >
                                                {
                                                    currencies.map( c => {
                                                        return (
                                                            <MenuItem style={{fontSize: '0.7em'}} value={ c.code }>
                                                            <ListItemIcon>
                                                                    {
                                                                    c.icon()
                                                                }
                                                            </ListItemIcon>
                                                            {
                                                                c.label
                                                            }
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                                
                                        </Field>
                                    </FormControl>
                                </Box>
                            </Grid>   

    
                            </Grid>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        p: 2
                                    }}
                                >
                                     
                                    <Button
                                        style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                        onClick={ handleCancel }>
                                        <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                    </Button>

                                    <LoadingButton
                                        loadingPosition="start"
                                        variant="contained"
                                        type='submit'
                                        loading={ isSubmitting }
                                        disabled={ !isValid || isSubmitting }
                                        style={{
                                            backgroundColor: '#E5824F',
                        
                                        }}
                                    >
                                            Editar
                                    </LoadingButton>

                                </Box>


                                </Grid>
            </Grid>
 
                </Form>
                )}
                </Formik>
            </>
        }
        </>
        
    )
}
