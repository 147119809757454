import { types } from '../types/types';

const getInfo = () => {
    if (typeof window !== 'undefined') {
        const data = localStorage.getItem('currenFilter');
        console.log('data');
        console.log(data);
        const parsed_data = (data !== "undefined") ? JSON.parse(data) : [];
        console.log('parsed_data');
        console.log(parsed_data);
        return parsed_data;
    }    
}

const parsed_data = getInfo();

const initialState = {
    currentFilter: parsed_data ? parsed_data : [],
}

export const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.changeFilter:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
