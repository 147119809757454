import Swal from 'sweetalert2';
import { url } from '../api/api';
import { loadJWT } from './loadJWT';




export const loadServicesReseller = async( id ) => {
    let services = [];
    const { token } = loadJWT();
    await url.get(`/usuarios/${ id }?filter={"include": [{"relation": "ServiciosRevendedoresCompuesto","scope": {"include": [{"relation": "ServicioProveedor", "scope": {"include": [{"relation": "TipoServicio"}]}}]}}]}`, {
        headers:{
            Authorization: `Bearer ${ token }`
        }
    })
    .then( response => {
        if( !response?.data?.ServiciosRevendedoresCompuesto){
            return null;
        }
        services = response.data.ServiciosRevendedoresCompuesto.map( resp => resp);
        console.log( services );
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
    })
    return services;

}