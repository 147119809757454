
import { Typography, useMediaQuery, useTheme} from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonFloatNoOptions } from '../../components/ui/ButtonFloatNoOptions'
import { Layout } from '../../components/ui/Layout'
import { NotificationModal } from '../../components/ui/NotificationModal'
import { TransitionsModal } from '../../components/ui/TransitionModal'
import { useTableParams } from '../../hooks/useTableParams'
import { actionAddRate, actionDeleteRate } from'../../redux/actions/actions'
import { startLoadingRates } from '../../redux/actions/exchangeRate'
import { startLogOutCleanSuppliers } from '../../redux/actions/suppliers'
import { ROLADMIN } from '../../utils/constants'

const ExchangesRateScreen = () => {

    
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const showColumnAction = false;
    const { rates } = useSelector( state => state.exchangeRate );
    const { idRol } = useSelector(state => state.auth);
    const { menuState } = useSelector(state => state.ui)
    let data = [];
    const exchangeOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: 'Tipos Cambio', rowsPerPage: 5 };
    const ratesColumns =  [['Tipo Servicio', true], ['Cambio Euros',  false], ['Cambio Dólares', false], ['Proveedor', true]];
    const actions = [
        { isShown: true, type: 'delete', color: '#000', icon: <DeleteOutlineOutlined />, eject: ( dataIndex ) => handleDelete( dataIndex )},
    ]
    const { options, columns } = useTableParams(exchangeOptions, ratesColumns, actions, idRol !== ROLADMIN ? showColumnAction : true);
    
    useEffect(() => {
        dispatch( startLoadingRates() );
    }, [ dispatch ]);

    useEffect(() => {
        dispatch(startLogOutCleanSuppliers());
    }, [ dispatch ])

    
    data = rates.map( rate => { return [ rate?.TipoServicio?.Nombre, rate?.CambioEuros, rate?.CambioDolares, rate?.Proveedor?.Nombre, rate?.FechaBaja ]; });

    options.setRowProps = (row, dataIndex, rowIndex) => { 
        if (data[rowIndex][4] !== null) {
          return {
            style: { backgroundColor: 'salmon' }
          };
        }
      };
    
    const handleAddExchangeRate = () => {
        dispatch( actionAddRate() )
    }

    const handleDelete = ( dataIndex ) => {
        dispatch(actionDeleteRate( rates[ dataIndex ].PkTiposCambioProveedores ) )

    }

    
    return (
        <Layout>

            <Helmet>
                <title>Tipos de Cambio | Xplora</title>
            </Helmet>

            <div
                style={{
                    marginTop: '4rem',
                    width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}
            >
                {
                    idRol === ROLADMIN
                    &&
                    (
                            !matchesXS
                            &&
                            <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>TIPOS DE CAMBIO</Typography>
            
                            
                    )
                   
                }

                <MUIDataTable
                     title={  idRol === ROLADMIN && (matchesXS && 'Tipos de Cambio') }
                    {...{data, columns, options}}
                    
                />

                <TransitionsModal/>
                <NotificationModal />

                <ButtonFloatNoOptions 
                    action={ handleAddExchangeRate }
               />
            </div>
            


        </Layout>
    )
}


export default ExchangesRateScreen;