import Swal from "sweetalert2";
import { url } from "../api/api";
import {  NUMROWSTATEINVOICE, ROLADMIN, ROLCOLABORADOR, ROLREVENDEDOR, ROLVENDEDOR } from "../utils/constants";
import { loadJWT } from "./loadJWT";
import dateFormat from "dateformat";
import axios from "axios";


export const loadSales = async(page, rol, loggedInUser, dateRange) => {
    let sales = [];
    let total = 0;
    let sumTotal = 0;
    let sumInSituEuros = 0;
    let sumReservaEuros = 0;
    let sumDescuentoEuros = 0;
    let sumReventaEuros = 0;
    let sumBalanceDolares = 0;

    const { token } = loadJWT();
    const query = (rol === ROLADMIN || rol === ROLCOLABORADOR)
                ? `/ventas?filter={"where": {"FechaVenta": {"between": ["${ dateFormat(dateRange[0], "isoUtcDateTime")}", "${ dateFormat(dateRange[1], "isoUtcDateTime")}"]}},"include": [{"relation": "Usuario"}, {"relation": "Cliente"}], "skip": ${ page * NUMROWSTATEINVOICE}, "limit": ${ NUMROWSTATEINVOICE }}`
                : (
                    (rol === ROLVENDEDOR || rol === ROLREVENDEDOR)
                    &&
                    `/ventas?filter={"where": {"FechaVenta": {"between": ["${ dateFormat(dateRange[0], "isoUtcDateTime")}", "${ dateFormat(dateRange[1], "isoUtcDateTime")}"]}, "FkUsuarios": ${ loggedInUser }},"include": [{"relation": "Usuario"}, {"relation": "Cliente"}], "skip": ${ page * NUMROWSTATEINVOICE}, "limit": ${NUMROWSTATEINVOICE}}`
                )
    const countQuery = (rol === ROLADMIN || rol === ROLCOLABORADOR)
    ? `/ventas/count?filter={"where": {"FechaVenta": {"between": ["${ dateFormat(dateRange[0], "isoUtcDateTime")}", "${ dateFormat(dateRange[1], "isoUtcDateTime")}"]}},"include": [{"relation": "Usuario"}, {"relation": "Cliente"}], "skip": ${ page * NUMROWSTATEINVOICE}, "limit": ${ NUMROWSTATEINVOICE }}`
    : (
        (rol === ROLVENDEDOR || rol === ROLREVENDEDOR)
        &&
        `/ventas/count?filter={"where": {"FechaVenta": {"between": ["${ dateFormat(dateRange[0], "isoUtcDateTime")}", "${ dateFormat(dateRange[1], "isoUtcDateTime")}"]}, "FkUsuarios": ${ loggedInUser }},"include": [{"relation": "Usuario"}, {"relation": "Cliente"}], "skip": ${ page * NUMROWSTATEINVOICE}, "limit": ${NUMROWSTATEINVOICE}}`
    )
    
    const sumQuery = (rol === ROLADMIN || rol === ROLCOLABORADOR)
    ? `/ventas/sum?filter={"where": {"FechaVenta": {"between": ["${ dateFormat(dateRange[0], "isoUtcDateTime")}", "${ dateFormat(dateRange[1], "isoUtcDateTime")}"]}},"include": [{"relation": "Usuario"}, {"relation": "Cliente"}]}`
    : (
        (rol === ROLVENDEDOR || rol === ROLREVENDEDOR)
        &&
        `/ventas/sum?filter={"where": {"FechaVenta": {"between": ["${ dateFormat(dateRange[0], "isoUtcDateTime")}", "${ dateFormat(dateRange[1], "isoUtcDateTime")}"]}, "FkUsuarios": ${ loggedInUser }},"include": [{"relation": "Usuario"}, {"relation": "Cliente"}]}`
    )

    const invoices = url.get(`${ query }`, {headers:{
        Authorization: `Bearer ${ token }`,
    }})
    const count = url.get(`${ countQuery }`, {
        headers: {
            Authorization: `Bearer ${ token }`
        }
    })

    const sum = url.get(`${ sumQuery }`, {
        headers: {
            Authorization: `Bearer ${ token }`
        }
    })
    await axios.all([invoices, count, sum]).then(([res1, res2, res3]) => {
        if( res1.data ){
            sales = res1.data.map( resp => resp);            
        }
        total = res2.data.count
        sumTotal = res3.data.sum 
        sumInSituEuros = res3.data.sumInSituEuros;
        sumReservaEuros = res3.data.sumReservaEuros;
        sumDescuentoEuros = res3.data.sumDescuentoEuros;
        sumReventaEuros = res3.data.sumReventaEuros;
        sumBalanceDolares = res3.data.sumBalanceDolares;
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
    })

    return [sales, total, sumTotal, sumInSituEuros, sumReservaEuros, sumDescuentoEuros, sumReventaEuros, sumBalanceDolares];
}
