import { FormControl, InputLabel, Select, MenuItem, Grid, InputAdornment, TextField, Typography, ListItemIcon } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Euro, MonetizationOn, RemoveRedEyeRounded, AccountCircleRounded, Phone, VpnKey, AlternateEmail } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import {  useSelector, useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks/useDebounce';
import { useFormStyles } from '../../../styles/formStyles';
import { startAddServiceReseller } from '../../../redux/actions/supplierServices';
import { Box } from '@mui/system';
import { startLoadingServicesSupplier, supplierLogout } from '../../../redux/actions/suppliers';
import * as yup from 'yup'
import { useParams } from '@reach/router';
import { setActiverateResellerAndLoadExchangeRates } from '../../../redux/actions/actions';
import { obtenerPrecioEuros } from '../../../utils/exchange';
import { loadCurrencies } from '../../../helpers/loadCurrencies';
import { calcularImporteReservaServicioRevendedor } from '../../../utils/utils';
import { calculateFromDollarsToEuro, calculateFromEuroToDollars } from '../../../utils/utils';
import { CustomizedSelect } from '../../../components/ui/CustomizedSelect';
import { SwitchMUI } from '../../../components/ui/Switch';
import { startNewUser } from '../../../redux/actions/users';
import { ROLREVENDEDOR } from '../../../utils/constants';
import { startNewVendedorAgencia } from '../../../redux/actions/agencyServices';


export const ContentAddSellerAgency = () => {

    const { active } = useSelector(state => state.user);
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch();


    const [ search, debounceSearchResult ] = useDebounce('/proveedores?filter[where][Nombre][like]', {start: 'Nombre', end: 'Email', id: "PkProveedores"});
    const [proveedorValue, setProveedorValue] = useState(0);
    const { services } = useSelector(state => state.supplier);
    const { active: activeService } = useSelector(state => state.service)
    const servicesAutoComplete = [];
    const { active: activeRate } = useSelector(state => state.exchangeRate)

    const { modal } = useSelector(state => state.modal);
    const params = useParams();

    const refService = useRef();

    const currencies = loadCurrencies();
    

    const initialValues =  {
        name: '',
        surname: '',
        email: '',
        password: '',
        password2: '',
        telefono: '',
        comisiona: false,
        importeComision: 0,
        Moneda: active.Moneda,

        FkUsuarios_Agencia: active.PkUsuarios,
        EsVendedor: true,
        MargenConfiguradoEnServicios: active.MargenConfiguradoEnServicios,
    }



    const validationSchemaResellerService = () => {
        return yup.object({
           
            password2: yup
            .string()
            .oneOf([yup.ref('password')], 'Las contraseñas deben coincidir')
                    // moneda: yup.string().required('Moneda es obligatorio'),
                    })
    }

    const submit = (values, {setSubmitting}) => {
        const user = {
            "Nombre": values.name,
            "Apellidos": values.surname,
            "Email": values.email,
            "FkRoles": ROLREVENDEDOR,
            "PasswordHash": values.password,
            "CambioPassword": false,
            "AceptaPoliticas": false,
            "Telefono": values.telefono,
            "VersionFila": 0
        }
        const payload ={
            ...user,
            "EsAgencia": true,
            "EsVendedor": true,
            "FkUsuarios_Agencia": active.PkUsuarios,
            "Comisiona": values.comisiona,
            "ComisionaPorImporte": true,
            "MargenConfiguradoEnServicios": values.MargenConfiguradoEnServicios,
            "ImporteComision": +values.importeComision ?? 0,
            "Moneda": values.Moneda,

        }
        //console.log( payload )
        dispatch(startNewVendedorAgencia(payload, setSubmitting)); //we dispatch the action to create a new user

    }

    return (
        <Formik 
            validationSchema={ validationSchemaResellerService } 
            initialValues={initialValues} 
            onSubmit={submit}>
            {({ handleChange, errors, values, setFieldValue, handleBlur, isSubmitting, touched, isValid }) => (
            <Form>
            <Grid item style={{ padding: '2rem'}}>
                <Grid container direction='column'>
                    
                    <Grid item classes={{root: classes.container }}>
                        <Typography variant='h1'>Agregar Vendedor a { active?.Nombre }</Typography>
                    </Grid>

                    <Grid container spacing={3}>

                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                    fullWidth
                                    name='name'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-razonSocial`} 
                                    label='Nombre'
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleRounded />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={values.name}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.razonSocial && Boolean(errors.razonSocial)}
                                    helperText={touched.razonSocial && errors.razonSocial}
                                    disabled={ isSubmitting }
                                />
                        </Grid>
                        
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                    fullWidth
                                    name='surname'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-razonSocial`} 
                                    label='Apellidos'
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleRounded />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={values.surname}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.razonSocial && Boolean(errors.razonSocial)}
                                    helperText={touched.razonSocial && errors.razonSocial}
                                    disabled={ isSubmitting }
                                />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                    fullWidth
                                    name='email'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-razonSocial`} 
                                    label='Email'
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AlternateEmail />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={values.email}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.razonSocial && Boolean(errors.razonSocial)}
                                    helperText={touched.razonSocial && errors.razonSocial}
                                    disabled={ isSubmitting }
                                />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                    fullWidth
                                    name='telefono'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-razonSocial`} 
                                    label='Teléfono'
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Phone />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={values.telefono}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.razonSocial && Boolean(errors.razonSocial)}
                                    helperText={touched.razonSocial && errors.razonSocial}
                                    disabled={ isSubmitting }
                                />
                        </Grid>


                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                    fullWidth
                                    name='password'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-razonSocial`} 
                                    label='Contraseña'
                                    type='password'
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKey />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={values.password}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.razonSocial && Boolean(errors.razonSocial)}
                                    helperText={touched.razonSocial && errors.razonSocial}
                                    disabled={ isSubmitting }
                                />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                    fullWidth
                                    name='password2'
                                    size='small'
                                    classes={{ root: classes.inputText }}
                                    id={`outlined-basic-razonSocial`} 
                                    label='Confirmar contraseña'
                                    type='password'
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKey />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    value={values.password2}
                                    onBlur={ handleBlur }
                                    onChange={handleChange}

                                    error={touched.razonSocial && Boolean(errors.razonSocial)}
                                    helperText={touched.razonSocial && errors.razonSocial}
                                    disabled={ isSubmitting }
                                />
                                 {errors.password2 && (<div>{errors.password2}</div>)}

                        </Grid>



                        <Grid
                                item
                                md={6}
                                xs={12}
                            >

                            <SwitchMUI 
                                label='Comisiona'
                                name='comisiona'
                                values={ values }
                                setFieldValue={ setFieldValue }
                            />
                        </Grid>


                        <Grid
                            key='ImporteComision'
                            item
                            md={6}
                            xs={12}
                            >
                            <TextField
                                fullWidth
                                name='importeComision'
                                size='small'
                                classes={{ root: classes.inputText }}
                                id={`outlined-basic-ImporteComision`} 
                                label={ 'Importe Comisión' }
                                variant='outlined'
                                InputProps={{
                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                InputLabelProps={
                                    {style: {fontSize: '0.8em'}}
                                }
                                value={values.importeComision}
                                onBlur={ handleBlur }
                                onChange={handleChange}
                                error={touched['ImporteComision'] && Boolean(errors['ImporteComision'])}
                                helperText={touched['ImporteComision'] && errors['ImporteComision']}
                                disabled={ isSubmitting || (values?.comisiona === false)  }
                            />
                        </Grid>




                        <Grid item 
                            md={6}
                            xs={12} classes={{root: classes.container }}>
                            <Box>
                                <FormControl
                                    fullWidth
                                    style={{
                                        marginTop: '1rem'
                                    }}
                                    disabled={ true }
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    
                                                        
                                >
                                
                                <InputLabel style={{fontSize: '1rem'}} id="select-label-exchange-rates">Moneda</InputLabel>
                                    <Field
                                        name="Moneda" 
                                        component={CustomizedSelect}
                                        >
                                            {
                                                currencies.map( c => {
                                                    return (
                                                        <MenuItem style={{fontSize: '0.7em'}} value={ c.code }>
                                                        <ListItemIcon>
                                                                {
                                                                c.icon()
                                                            }
                                                        </ListItemIcon>
                                                        {
                                                            c.label
                                                        }
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                            
                                    </Field>
                                </FormControl>
                            </Box>
                        </Grid>   


                    </Grid>


                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >

                        <LoadingButton
                           loadingPosition="start"
                           variant="contained"
                           type='submit'
                           loading={ isSubmitting }
                           disabled={ !isValid || isSubmitting }
                           style={{
                               backgroundColor: '#E5824F',
           
                           }}
                        >
                                Agregar
                        </LoadingButton>

                        </Box>

                </Grid>
            </Grid>
            </Form>
            )}
            </Formik>
    )
}
