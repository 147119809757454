import React from 'react'
import { Avatar, IconButton } from '@material-ui/core';
import { ExitToAppOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { startLogOut } from '../../redux/actions/auth'
import { colors } from '../../utils/colorsProfile'
import { actionProfile } from '../../redux/actions/actions';
import { navigate } from '@reach/router';

export const MenuPopUp = () => {
    const dispatch = useDispatch();
    const { id } = useSelector(state => state.auth)
    const handleLogout = () => {
        dispatch(startLogOut()); // we clean the auth state
    }

    const handleActive = () => {
       dispatch( actionProfile( id ) )
       navigate('/me')
    }
    const { nombre } = useSelector(state => state.auth);
    let profile = nombre?.charAt( 0 ).toUpperCase();

    return (
            <div>
                 <IconButton onClick={ handleActive }>
                        <Avatar style={{ backgroundColor: (colors[profile] && colors[profile]) || 'inherit'}} sx={{ width: 25, height: 25 }}> { profile }</Avatar>
                </IconButton>
                <IconButton style={{ color: '#000'}} onClick={ handleLogout }>
                    <ExitToAppOutlined />
                </IconButton>
            </div>
    )
}
