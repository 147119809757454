import { obtenerPrecioDolares, obtenerPrecioEuros } from "./exchange";

export const isMatrixEmpty = ( matrix ) => {
    let toret = false;
    if ( matrix.length === 0 ){
      toret = true
    }
    else{
      matrix.map( element => {
        if( element.length !== 0){
          return toret;
        }
        return null;
      })
    }
  
    return toret;
  }


export const calculateFromEuroToDollars = ( rate, value ) => {
  return obtenerPrecioDolares(value, rate?.CambioEuros, rate?.CambioDolares)
}

export const calculateFromDollarsToEuro = ( rate, value ) => {
  return obtenerPrecioEuros(value, rate?.CambioEuros, rate?.CambioDolares)
}

export const getTotalLineFromDollarToEuros = ( reserva, rate, value) => {
  return obtenerPrecioEuros(value, rate.CambioEuros, rate.CambioDolares) + parseFloat(reserva)
}

export const getTotalLineFromEurosToDollars = ( reserva, rate, value) => {
  return obtenerPrecioDolares(reserva, rate.CambioEuros, rate.CambioDolares) + parseFloat(value)
}


export const getTotalInvoice =  ( activeEdit ) => {
    let total = 0;
    let totalReserva = 0;
    activeEdit?.LineasVentaCompuestas?.forEach( line => {
          total += line.ImporteLinea;
          totalReserva += line.ReservaEuros;
    });
    // TODO: Confirmar dto sobre reserva
    let dto = totalReserva * activeEdit?.PorcDescuento / 100.0;
    // let dto = total * activeEdit?.PorcDescuento / 100.0;   
    //console.log(activeEdit?.PorcDescuento);
    //console.log(dto); 
    return Math.round(100*(total-dto))/100;
}

export const getTotalInvoiceDolares =  ( activeEdit ) => {
  let total = 0;
  let totalReserva = 0;
  activeEdit?.LineasVentaCompuestas?.forEach( line => {
        total += line.ImporteLineaDolares;
        totalReserva += calculateFromDollarsToEuro(line, line.ReservaEuros);
  });
  // TODO: Confirmar dto sobre reserva
  let dto = totalReserva * activeEdit?.PorcDescuento / 100.0;
  // let dto = total * activeEdit?.PorcDescuento / 100.0;   
  //console.log(activeEdit?.PorcDescuento);
  //console.log(dto); 
    return Math.round(total-dto);
}


export const calcularImporteReservaServicioRevendedor = ( rate, costeDolares, precioVenta, importeReventa, moneda) => {
  let importeReserva = 0;

  if (moneda === 'USD') {
    importeReserva = +precioVenta - costeDolares - importeReventa;
  }
  else {
    importeReserva = precioVenta - obtenerPrecioEuros(costeDolares, rate.CambioEuros, rate.CambioDolares) - importeReventa;
  }
  return importeReserva;
}



export const calcularPrecioBaseServicioRevendedorEuros = ( 
  rate, costeDolares, 
  precioVenta, importeReventa, moneda) => {
  let importeReserva = 0;

  if (moneda === 'USD') {
    importeReserva = +obtenerPrecioEuros(precioVenta, rate.CambioEuros, rate.CambioDolares) 
      - obtenerPrecioEuros(importeReventa, rate.CambioEuros, rate.CambioDolares);
  }
  else {
    importeReserva = precioVenta  - importeReventa;
  }
  return importeReserva;
}

export const calcularPrecioBaseServicioRevendedorDolares = ( rate, costeDolares, precioVenta, importeReventa, moneda) => {
  let importeReserva = 0;

  if (moneda === 'USD') {
    importeReserva = precioVenta -importeReventa;
  }
  else {
    importeReserva = +obtenerPrecioDolares(+precioVenta, rate.CambioEuros, rate.CambioDolares) 
      - obtenerPrecioDolares(+importeReventa, rate.CambioEuros, rate.CambioDolares);
  }
  return importeReserva;
}


export const calcularImporteReservaServicioRevendedorEuros = ( 
  rate, costeDolares, 
  precioVenta, importeReventa, moneda) => {
  let importeReserva = 0;

  if (moneda === 'USD') {
    importeReserva = +obtenerPrecioEuros(precioVenta, rate.CambioEuros, rate.CambioDolares) - obtenerPrecioEuros(costeDolares, rate.CambioEuros, rate.CambioDolares)
      - obtenerPrecioEuros(importeReventa, rate.CambioEuros, rate.CambioDolares);
  }
  else {
    importeReserva = precioVenta - obtenerPrecioEuros(costeDolares, rate.CambioEuros, rate.CambioDolares) - importeReventa;
  }
  return importeReserva;
}

export const calcularImporteReservaServicioRevendedorDolares = ( rate, costeDolares, precioVenta, importeReventa, moneda) => {
  let importeReserva = 0;

  if (moneda === 'USD') {
    importeReserva = precioVenta - costeDolares -importeReventa;
  }
  else {
    importeReserva = +obtenerPrecioDolares(+precioVenta, rate.CambioEuros, rate.CambioDolares) - costeDolares 
      - obtenerPrecioDolares(+importeReventa, rate.CambioEuros, rate.CambioDolares);
  }
  return importeReserva;
}

export const calcularImporteVentaEuros = ( rate, precioVenta, moneda) => {
  let importeReserva = 0;

  if (moneda === 'USD') {
    importeReserva = +obtenerPrecioEuros(+precioVenta, rate.CambioEuros, rate.CambioDolares);
  }
  else {
    importeReserva = precioVenta;
  }
  return importeReserva;
}

export const calcularImporteVentaDolares = ( rate, precioVenta, moneda) => {
  let importeReserva = 0;

  if (moneda === 'EUR') {
    importeReserva = +obtenerPrecioDolares(+precioVenta, rate.CambioEuros, rate.CambioDolares);
  }
  else {
    importeReserva = precioVenta;
  }
  return importeReserva;
}


export const calcularInSituVentaRevendedorEuros = ( 
  rate, revendedor, precioVenta , suplementoTransporteEuros, 
  moneda, costeDolares, importeReventaEuros
  ) => {
  let importeInSitu = 0;

  if (revendedor.MargenConfiguradoEnServicios) {
    // EN ESTE CASO TODO EL PAGO VA IN SITU MENOS LA REVENTA
    if (moneda === 'USD') {
      importeInSitu = (+obtenerPrecioEuros(+precioVenta, rate.CambioEuros, rate.CambioDolares) + suplementoTransporteEuros) - +importeReventaEuros;
    }
    else {
      importeInSitu = precioVenta + suplementoTransporteEuros - +importeReventaEuros;
    }
  }
  else if (revendedor.Comisiona) {
    // EN ESTE CASO IN SITU = COSTE PROVEEDOR, RESERVA = IMPORTE XPLORA
    if (moneda === 'EUR') {
      importeInSitu = +obtenerPrecioEuros(costeDolares, rate.CambioEuros, rate.CambioDolares) + suplementoTransporteEuros;
    }
    else {
      importeInSitu = +obtenerPrecioEuros(costeDolares, rate.CambioEuros, rate.CambioDolares) + suplementoTransporteEuros;
    }
  }
  else { // REVENDEDOR NO COMISIONA
    // EN ESTE CASO IN SITU = COSTE PROVEEDOR, RESERVA = IMPORTE XPLORA
    if (moneda === 'EUR') {
      importeInSitu = +obtenerPrecioEuros(costeDolares, rate.CambioEuros, rate.CambioDolares) + suplementoTransporteEuros;
    }
    else {
      importeInSitu = +obtenerPrecioEuros(costeDolares, rate.CambioEuros, rate.CambioDolares) + suplementoTransporteEuros;
    }
  }
  return importeInSitu;
}
  
export const calcularInSituVentaRevendedorDolares = ( 
  rate, revendedor, precioVenta , suplementoTransporteDolares, moneda
  , costeDolares, importeReventaEuros) => {
  let importeInSitu = 0;

  if (revendedor.MargenConfiguradoEnServicios) {
    // EN ESTE CASO TODO EL PAGO VA IN SITU
    if (moneda === 'EUR') {
      importeInSitu = (+obtenerPrecioDolares(+precioVenta, rate.CambioEuros, rate.CambioDolares) + +suplementoTransporteDolares)
        - +obtenerPrecioDolares(+importeReventaEuros, rate.CambioEuros, rate.CambioDolares);
    }
    else {
      importeInSitu = +precioVenta + +suplementoTransporteDolares - +obtenerPrecioDolares(+importeReventaEuros, rate.CambioEuros, rate.CambioDolares);
    }
  }
  else if (revendedor.Comisiona) {
    // EN ESTE CASO IN SITU = COSTE PROVEEDOR, RESERVA = IMPORTE XPLORA
    if (moneda === 'EUR') {
      importeInSitu = costeDolares + +suplementoTransporteDolares;
    }
    else {
      importeInSitu = costeDolares + +suplementoTransporteDolares;
    }
  }
  else { // REVENDEDOR NO COMISIONA
    if (moneda === 'EUR') {
      importeInSitu = costeDolares + +suplementoTransporteDolares;
    }
    else {
      importeInSitu = costeDolares + +suplementoTransporteDolares;
    }
  }
  

  return importeInSitu;
}
  
export const calcularReservaLineaVentaRevendedorEuros = ( 
  rate, revendedor, precioVenta , costeDolares, moneda, reventaServicio, importeReventaEuros
  ) => {
  let importeReserva = 0;

  if (revendedor.MargenConfiguradoEnServicios) {
    // EN ESTE CASO TODO EL PAGO VA IN SITU
    if (moneda === 'USD') {
      importeReserva = importeReventaEuros;
    }
    else {
      importeReserva = importeReventaEuros;
    }
  }
  else if (revendedor.Comisiona) {
    // EN ESTE CASO IN SITU = COSTE PROVEEDOR, RESERVA = IMPORTE XPLORA
    if (moneda === 'EUR') {
      importeReserva = precioVenta- +obtenerPrecioEuros(+costeDolares, rate.CambioEuros, rate.CambioDolares);
    }
    else {
      importeReserva = +obtenerPrecioEuros(precioVenta - +costeDolares, rate.CambioEuros, rate.CambioDolares);
    }
  }
  else { // REVENDEDOR NO COMISIONA
    // EN ESTE CASO IN SITU = COSTE PROVEEDOR, RESERVA = IMPORTE XPLORA + margen
    if (moneda === 'EUR') {
      importeReserva = +importeReventaEuros + precioVenta - +reventaServicio
              - +obtenerPrecioEuros(+costeDolares, rate.CambioEuros, rate.CambioDolares);
    }
    else {
      importeReserva = +importeReventaEuros + +obtenerPrecioEuros(precioVenta - +reventaServicio - +costeDolares, rate.CambioEuros, rate.CambioDolares);
    }
  }

  return importeReserva;
}

export const calcularReservaLineaVentaRevendedorDolares = ( 
  rate, revendedor, precioVenta , costeDolares, moneda, reventaServicio, importeReventaDolares
  ) => {
  let importeReserva = 0;

  if (revendedor.MargenConfiguradoEnServicios) {
    // EN ESTE CASO TODO EL PAGO VA IN SITU
    if (moneda === 'USD') {
      importeReserva = importeReventaDolares;
    }
    else {
      importeReserva = importeReventaDolares;
    }
  }
  else if (revendedor.Comisiona) {
    // EN ESTE CASO IN SITU = COSTE PROVEEDOR, RESERVA = IMPORTE XPLORA
    if (moneda === 'USD') {
      importeReserva = precioVenta- costeDolares;
    }
    else {
      importeReserva = +obtenerPrecioEuros(precioVenta, rate.CambioEuros, rate.CambioDolares) - costeDolares;
    }
  }
  else { // REVENDEDOR NO COMISIONA
    // EN ESTE CASO IN SITU = COSTE PROVEEDOR, RESERVA = IMPORTE XPLORA
    if (moneda === 'USD') {
      importeReserva = +importeReventaDolares + precioVenta- costeDolares - +reventaServicio;
    }
    else {
      importeReserva = +importeReventaDolares + +obtenerPrecioEuros(precioVenta - +reventaServicio, rate.CambioEuros, rate.CambioDolares) - costeDolares;
    }
  }


  return importeReserva;
}



export  const getSimboloMoneda = (moneda) => { return moneda==='EUR' ? '€' : '$'; };
