import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Layout } from '../../components/ui/Layout'
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";
import {setActive as setActiveUser, startLoadingAllUsers, startLoadingUsers } from '../../redux/actions/users';
import { DeleteOutlineOutlined } from '@material-ui/icons';
import { TransitionsModal } from '../../components/ui/TransitionModal';
import { NotificationModal } from '../../components/ui/NotificationModal';
import { Helmet } from 'react-helmet';
import { useTableParams } from '../../hooks/useTableParams';
import { actionAddUser, actionDeleteUser, actionEdit } from '../../redux/actions/actions';
import { setServices } from '../../redux/actions/supplierServices';
import { setSuppliers, startLogOutCleanSuppliers } from '../../redux/actions/suppliers';
import { ROLADMIN } from '../../utils/constants';
import { startLogOutRedirect } from '../../redux/actions/routeManagement';
import { Box } from '@mui/system';
import { ButtonFloatNoOptions } from '../../components/ui/ButtonFloatNoOptions';





const UserScreen = () => {
    let data = [];
    const theme = useTheme();
    const showColumnAction = false;
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const { users } = useSelector(state => state.user );
    const { menuState } = useSelector(state => state.ui)
    const { idRol } = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const userOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: 'Usuarios', rowsPerPage: 5};
    const userColumnsDeskTop =  [['Nombre', true], ['Rol', true, !matchesXS], ['Teléfono', true, !matchesXS], ['Email', true, !matchesXS]];
    const actions = [
        { isShown: true, type: 'delete', color: '#000', icon: <DeleteOutlineOutlined />, eject: ( dataIndex, e ) => handleDelete( dataIndex, e )},
        // { isShown: true, type: 'edit', data: users, link: 'users', key: 'PkUsuarios', color: '#000', icon: <EditOutlined />, eject: ( dataIndex, e ) => handleEditUser( dataIndex, e )},
    ]
    const { options, columns } = useTableParams( userOptions, userColumnsDeskTop , actions, idRol !== ROLADMIN ? showColumnAction : true, (dataIndex, e) => handleEditUser(dataIndex, e));
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    
    useEffect(() => {
        dispatch(setActiveUser({}));
        dispatch(setServices([]));
        dispatch(setSuppliers([]));
        dispatch(startLogOutCleanSuppliers());
    }, [dispatch]);

    useEffect(() => {
        dispatch(startLogOutRedirect())
    }, [ dispatch]);

    useEffect(() => {
        if( checked ){
            dispatch(startLoadingAllUsers())
        }
        else{
            dispatch(startLoadingUsers())
        }
    }, [checked, dispatch])



    data = users.map( user => { return [user?.Nombre, user?.Rol?.Nombre, user?.Telefono, user?.Email, user?.FechaBaja]; });

    options.setRowProps = (row, dataIndex, rowIndex) => {       
        const email = row[3];
        const usuario = users.filter( user => user.Email==email);
        if (usuario.length>0 && usuario[0].FechaBaja) {
          return {
            style: { backgroundColor: 'salmon' }
          };
        }
      };
    
    const handleAddUser = () => {
        dispatch( actionAddUser() );
    }

    const handleDelete = (dataIndex, e ) => {
        if (e) e.stopPropagation();
        dispatch( actionDeleteUser( users[ dataIndex ].PkUsuarios ) )
    }

    const handleEditUser = (dataIndex, e ) => {
        if (e) e.stopPropagation();
       dispatch(actionEdit( users[ dataIndex ].PkUsuarios))  
    }

    


    return (
        <Layout>

            <Helmet>
                <title>Usuarios | Xplora</title>
            </Helmet>

            <div style={{
                    marginTop: '2rem',
                    width: `${ matchesXS ? '95vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
            }}>
                {
                    !matchesXS
                    &&
                    <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>USUARIOS</Typography>
                }
                
                
                


                <MUIDataTable
                    {...{data, columns, options}}
                    title={ !matchesXS ? 
                            (
                                <Box sx={{ display: 'flex'}}>
                                <FormControlLabel
                                    label='Incluir dados de baja'
                                    control={
                                        <Checkbox
                                            style={{ marginLeft: '1.7rem'}}
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled', 'label': 'Todos' }}
                                        />
                                    }
                                />
                                </Box>
                            )
                            : 
                            (
                                <Typography variant='h2' style={{ marginLeft: '0.7rem'}}>Usuarios</Typography>        
                            ) 
                        }
                    
                />

                <TransitionsModal/>
                <NotificationModal />

               <ButtonFloatNoOptions 
                action={ handleAddUser }
               />

            </div>
            


        </Layout>
    )
}

export default UserScreen;