import { Grid, InputAdornment, TextField, Typography, Button } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import Alert from '@mui/material/Alert';

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormStyles } from '../../../styles/formStyles';
import { LinesTable } from './LinesTable';
import { validationSchemaOrder } from '../../../utils/schemaValidationForm'
import { FormikDatePicker } from '../../ui/FormikDatePicker';
import { startUpdateInvoice } from '../../../redux/actions/sales';
import esLocale from 'date-fns/locale/es'
import { EuroOutlined, MonetizationOn } from '@material-ui/icons';
import { getTotalInvoice, getTotalInvoiceDolares } from '../../../utils/utils';
import { Box } from '@mui/material';
import { navigate } from '@reach/router';
import { ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR } from '../../../utils/constants';
import { obtenerPrecioEuros } from '../../../utils/exchange';



export const Detail = () => {
    const classes = useFormStyles();
    const { activeEdit } = useSelector(state => state.invoice);
    const dispatch = useDispatch();
    const { idRol, moneda: monedaUsuario, esAgencia } = useSelector( state => state.auth);
    
    const initialValues = {
        "FechaVenta": activeEdit?.FechaVenta,
        "HotelCliente": activeEdit?.HotelCliente,
        "PorcDescuento": activeEdit?.PorcDescuento,
        "Observaciones":  activeEdit?.Observaciones,
        "FechaEnvioPresupuesto": activeEdit?.FechaEnvioPresupuesto,
        "FechaPagoReserva": activeEdit?.FechaPagoReserva,
        "FechaFactura": activeEdit?.FechaFactura,
        "PresupuestoEnviado": activeEdit?.PresupuestoEnviado,
        "ReservaPagada": activeEdit?.ReservaPagada,
        "ImporteFactura": +activeEdit?.ImporteFactura,
        "VersionFila": activeEdit?.VersionFila,
        "PkVentas": activeEdit?.PkVentas
    }

    const getTotal = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
            total += +e.Descuento
        })
        return total;
    }

    const getTotalTasas = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
            if( e?.TasaDolares ) total += (
                obtenerPrecioEuros(e?.TasaDolares, e?.CambioEuros, e?.CambioDolares) * e?.Unidades);
        })
        return Math.round(total);
    }
    const getTotalTasasDolares = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
            if( e?.TasaDolares ) total += (
                e?.TasaDolares * e?.Unidades);
        })
        return Math.round(total);
    }

    // PAGO CLIENTE A AGENCIA
    const getPagoClienteAgencia = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
                total += (e?.ReservaLineaEuros * e?.Unidades);
        })
        return total;
    }
    // PAGO AGENCIA A XPLORA
    const getPagoAgenciaXploraEuros = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
            if (activeEdit?.Usuario?.MargenConfiguradoEnServicios) {
                // 0
                total += 0;
            }
            else if (activeEdit?.Usuario?.Comisiona) {
                // Margen Xplora
                total += (e?.ReservaEuros * e?.Unidades);
            }
            else {
                // Margen xplora
                total += (e?.ReservaEuros * e?.Unidades);
            }
        })
        return total;
    }
    
    const getPagoAgenciaXploraDolares = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
            if (activeEdit?.Usuario?.MargenConfiguradoEnServicios) {
                // 0
                total += 0;
            }
            else if (activeEdit?.Usuario?.Comisiona) {
                // Margen Xplora
                total += (e?.ReservaEuros * e?.CambioEuros / e?.CambioDolares) * e?.Unidades;
            }
            else {
                // Margen xplora
                total += (e?.ReservaEuros * e?.CambioEuros / e?.CambioDolares) * e?.Unidades;
            }
        })
        return Math.round(total);
    }


    // PAGO PROVEEDOR A XPLORA
    const getPagoProveedorXplora = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
            if (activeEdit?.Usuario?.MargenConfiguradoEnServicios) {
                // 0
                total += (e?.ReservaEuros * e?.Unidades);
            }
            else if (activeEdit?.Usuario?.Comisiona) {
                // Margen Xplora
                total += 0;
            }
            else {
                // Margen xplora
                total += 0;
            }
        })
        return total;
    }
    // INSITU + TRANSPORTE
    const getInSituConTransporte = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
                total += (e?.InSituConTransporteDolares * e?.Unidades);
        })
        return total;
    }
    const getInSituConTransporteEuros = () => {
        let total = 0;
        activeEdit?.LineasVentaCompuestas?.forEach( e => {
            total += (e?.InSituConTransporteEuros * e?.Unidades);
        })
        return total.toFixed(2);
    }


    const handleSubmit = (values, {setSubmitting}) => {
        const payload = {
            ...values,
            FechaEnvioPresupuesto: !values.FechaEnvioPresupuesto ? null : values.FechaEnvioPresupuesto,
            FechaEnvioVoucher: !values.FechaEnvioVoucher ? null : values.FechaEnvioVoucher,
            FechaFactura: !values.FechaFactura ? null : values.FechaFactura,
            FechaPagoReserva: !values.FechaPagoReserva ? null : values.FechaPagoReserva,
            PorcDescuento: !values.PorcDescuento ? 0 : +values.PorcDescuento

        }
        //console.log( payload )
        dispatch( startUpdateInvoice(activeEdit?.PkVentas, payload, setSubmitting) )
    }


    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={ validationSchemaOrder }
            onSubmit={ handleSubmit }
        >
        {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid, setFieldValue }) => (
        <Form> 
           <Grid container spacing={3} >


           <Grid md={3} xs={8} item    >
                    <Field style={{fontSize: '0.7em'}} component={FormikDatePicker} textfield='FechaVenta' name='FechaVenta' label="Fecha Venta" isSubmitting={ isSubmitting } value={ values?.FechaVenta   } />
            </Grid>


            <Grid  md={1} xs={4} item  >
                <TextField 
                    fullWidth   
                    label='Nº'
                    name='PkVentas'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ values.PkVentas}
                    InputProps={{
                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    error={touched.PkVentas && Boolean(errors.PkVentas)}
                    helperText={touched.PkVentas && errors.PkVentas}
                    disabled = { true }
                    inputProps={{ maxLength: 5 }}
                />
            </Grid>

            <Grid md={8} xs={12} item >
                <TextField 
                    fullWidth   
                    label='Cliente'
                    name='Cliente.Nombre'
                    size='small'
                    variant='outlined'
                    classes={{ root: classes.inputText }}
                    value={ `${activeEdit?.Cliente?.Nombre} ${activeEdit?.Cliente?.Apellidos}` }
                    InputProps={{
                        classes: {
                            input: classes.resize
                        }
                    }}
                    InputLabelProps={
                        {style: {fontSize: '0.8em'}}
                    }
                    onBlur={ handleBlur }
                    onChange={handleChange}
                    disabled={ true }
                    inputProps={{ maxLength: 5 }}
                />
                </Grid>

            

    </Grid>
            





            <Grid container spacing={3} >
                <LinesTable />
            </Grid>

            

            <Grid container spacing={3} >


                {
                    idRol !== ROLREVENDEDOR
                    &&
                    (
                        <>
                            <Grid item md={4} sm={6} xs={12} >
                                <TextField
                                    fullWidth   
                                    label='Dto'
                                    size='small'
                                    variant='outlined'
                                    style={{ marginTop: '2rem' }}
                                    classes={{ root: classes.inputText }}
                                    value={ getTotal() }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EuroOutlined />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    disabled                        
                                />
                            </Grid>
                        </>
                    )
                }



            { (idRol!==ROLREVENDEDOR || (idRol === ROLREVENDEDOR && monedaUsuario === 'EUR')) &&
                <Grid item md={4} sm={6} xs={12} >
                    <TextField
                        fullWidth   
                        label='Tasas'
                        size='small'
                        variant='outlined'
                        style={{ marginTop: '2rem' }}
                        classes={{ root: classes.inputText }}
                        value={ +getTotalTasas() }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EuroOutlined />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        disabled                        
                    />
                </Grid>
            }

            { (idRol!==ROLREVENDEDOR || (idRol === ROLREVENDEDOR && monedaUsuario === 'USD')) &&
                <Grid item md={4} sm={6} xs={12}  >
                    <TextField
                        fullWidth   
                        label='Tasas'
                        size='small'
                        variant='outlined'
                        style={{ marginTop: '2rem' }}
                        classes={{ root: classes.inputText }}
                        value={ +getTotalTasasDolares() }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MonetizationOn />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        disabled                        
                    />
                </Grid>
            }

            { (idRol!==ROLREVENDEDOR || (idRol === ROLREVENDEDOR && monedaUsuario === 'EUR')) &&
                <Grid item md={4} sm={6} xs={12} >
                    <TextField
                        name='ImporteTotal'
                        fullWidth
                        size='small'
                        style={{ marginTop: '2rem' }}
                        classes={{ root: classes.inputText }}
                        variant='outlined'
                        label='PVP Total'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EuroOutlined />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        disabled
                        value={ getTotalInvoice(activeEdit) }
                        
                    />
                </Grid>
            }
            
            { (idRol!==ROLREVENDEDOR || (idRol === ROLREVENDEDOR && monedaUsuario === 'USD')) &&
                <Grid item md={4} sm={6} xs={12} >
                    <TextField
                        name='ImporteTotal'
                        fullWidth
                        size='small'
                        style={{ marginTop: '2rem' }}
                        classes={{ root: classes.inputText }}
                        variant='outlined'
                        label='PVP Total'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MonetizationOn />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        disabled
                        value={ getTotalInvoiceDolares(activeEdit) }
                        
                    />
                </Grid>
            }
            </Grid>



    { (false && idRol===ROLADMIN && activeEdit?.Usuario?.FkRoles===ROLREVENDEDOR) &&
        <Alert severity="info">
            <p>INFORMACIÓN DE VENTA DE REVENDEDOR</p>
        </Alert>   
    }
            
            <Grid container spacing={3} >  

        { (  activeEdit?.Usuario?.FkRoles===ROLREVENDEDOR) &&
           <>

            { (activeEdit?.Usuario?.Moneda==='USD') &&
                <>
                    <Grid item md={4} sm={6} xs={12} >
                        <TextField
                            fullWidth   
                            label='Insitu + Supl. transporte'
                            size='small'
                            variant='outlined'
                            style={{ marginTop: '2rem' }}
                            classes={{ root: classes.inputText }}
                            value={ +getInSituConTransporte() }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MonetizationOn />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            disabled                        
                        />
                    </Grid>

                </>
            }
            { (activeEdit?.Usuario?.Moneda==='EUR') &&
                <>
                    <Grid item md={4} sm={6} xs={12} >
                        <TextField
                            fullWidth   
                            label='Insitu + Supl. transporte'
                            size='small'
                            variant='outlined'
                            style={{ marginTop: '2rem' }}
                            classes={{ root: classes.inputText }}
                            value={ +getInSituConTransporteEuros() }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EuroOutlined />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            disabled                        
                        />
                    </Grid>

                </>
            }

                <Grid item md={4} sm={6} xs={12} >
                    <TextField
                        fullWidth   
                        label='Pago cliente a Agencia'
                        size='small'
                        variant='outlined'
                        style={{ marginTop: '2rem' }}
                        classes={{ root: classes.inputText }}
                        value={ +getPagoClienteAgencia() }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EuroOutlined />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        disabled                        
                    />
                </Grid>
            </>
        }

        { 
        (activeEdit?.Usuario?.Moneda==='EUR' && (idRol===ROLADMIN || (idRol===ROLREVENDEDOR && esAgencia )) 
        && activeEdit?.Usuario?.FkRoles===ROLREVENDEDOR 
        && !activeEdit?.Usuario?.MargenConfiguradoEnServicios) &&
          
                <Grid item md={4} sm={6} xs={12} >
                    <TextField
                        fullWidth   
                        label='Pago Agencia a Xplora'
                        size='small'
                        variant='outlined'
                        style={{ marginTop: '2rem' }}
                        classes={{ root: classes.inputText }}
                        value={ +getPagoAgenciaXploraEuros().toFixed(2) }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EuroOutlined />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        disabled                        
                    />
                </Grid>
        }
        { 
            (activeEdit?.Usuario?.Moneda==='USD' && (idRol===ROLADMIN || (idRol===ROLREVENDEDOR && esAgencia )) 
            && activeEdit?.Usuario?.FkRoles===ROLREVENDEDOR 
            && !activeEdit?.Usuario?.MargenConfiguradoEnServicios) &&
              
                    <Grid item md={4} sm={6} xs={12} >
                        <TextField
                            fullWidth   
                            label='Pago Agencia a Xplora'
                            size='small'
                            variant='outlined'
                            style={{ marginTop: '2rem' }}
                            classes={{ root: classes.inputText }}
                            value={ +getPagoAgenciaXploraDolares() }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MonetizationOn />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            disabled                        
                        />
                    </Grid>
            }

        { (idRol===ROLADMIN && activeEdit?.Usuario?.FkRoles===ROLREVENDEDOR && activeEdit?.Usuario?.MargenConfiguradoEnServicios) &&
                <Grid item md={4} sm={6} xs={12} >
                    <TextField
                        fullWidth   
                        label='Pago Proveedor a Xplora'
                        size='small'
                        variant='outlined'
                        style={{ marginTop: '2rem' }}
                        classes={{ root: classes.inputText }}
                        value={ +getPagoProveedorXplora() }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EuroOutlined />
                                </InputAdornment>
                            ),
                            classes: {
                                input: classes.resize
                            }
                        }}
                        InputLabelProps={
                            {style: {fontSize: '0.8em'}}
                        }
                        disabled                        
                    />
                </Grid>
        }
            



        
            </Grid> 
    
    
            
            <>
                         <Box
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >

                            <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                    marginTop: '1rem',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                 <Button
                                    style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                    onClick={ () => navigate('/sales') }>
                                    <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                </Button>
                                {
                                !activeEdit?.FechaFactura
                                &&
                                (
                                <LoadingButton
                                    variant="contained"
                                    type='submit'
                                    style={{
                                        backgroundColor: '#E5824F',
                                    }}
                                    disabled={ isSubmitting || !isValid }

                                >
                                    Guardar Cambios
                                </LoadingButton>
                                 )
                                }
                            </Grid>
                        </Box> 
                    </>



            </Form>
        )}
        </Formik>
        </LocalizationProvider>

        
        </>        
    )
}
