import axios from "axios";

const apiUrl = {
    prod: 'https://rest.xplorariviera.com',
    local: 'http://localhost:4000',
    azure_dev: 'https://rest.xplorariviera.com:8080'
}

export const url = axios.create({
    baseURL: apiUrl.prod
})