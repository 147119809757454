import {  Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Box } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux'

export const ExchangesRate = () => {
    const { active } = useSelector(state => state.supplier);
    const theme = useTheme();
    const { menuState} = useSelector(state => state.ui)
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesXS = useMediaQuery( theme.breakpoints.down('xs'));


    
    return (
        <Grid item  xs={matchesMD ? (menuState ? 7 : 10) : 11} md={3} >
            <Box>
                <Typography variant='h3' >Tipos de Cambio:</Typography>               
            </Box>
            <Container style={{ border: '2px solid #000', height: !matchesXS && '80%'}}>
                
                    {active?.TiposCambioProveedor?.map((value) => {
                      
                            return (
                            <Typography style={{ fontSize: !matchesXS && '1.4rem'}}>                                
                                {`${ value.TipoServicio.Nombre }: ${value.CambioEuros}€ - ${ value.CambioDolares }$ `} 
                            </Typography>
                                
                            );
                    })}
            </Container>
        </Grid>
    )
}
