

export const loadJWT = () => {
    const string_user = typeof window !== `undefined` && localStorage.getItem('user');
    const user_parsed = JSON.parse(string_user);
    const { token } = user_parsed;

    return {
        token
    }
}
