//Third-party packages
import React from 'react'
import { 
        Grid, 
        InputAdornment, 
        TextField, 
        Typography
} from '@material-ui/core';

//Custom imports
import { useFormStyles } from '../../styles/formStyles';
import { useSelector } from 'react-redux';
import { loadGridUser } from '../../helpers/loadGrid';


/**
 * 
 * Author: QBK
 * Date: 28-09-2021
 */
export const ContentModalUserDetail = () => {

    const { active } = useSelector(state => state.user);
    const result = {  
            email: active.Email, 
            role: active.FkRoles, 
            created_at: active.FechaAlta, 
            updated_at: active.FechaMod, 
            name: active.Nombre, 
            surname: active.Apellidos, 
            fechaUltimoAcceso: active.FechaUltimoAcceso, 
            fechaAceptaPoliticas: active.FechaAceptacionPoliticas,  
            telefono: active.Telefono, 
            observaciones: active.Observaciones, 
            usuario_baja: active.UsuarioBaja, 
            fechaBaja: active.FechaBaja, 
            usuarioAlta: active.UsuarioAlta, 
            user_mod: active.UsuarioMod,
            aceptaPoliticas: active.AceptaPoliticas,
            aceptaPassword: active.CambioPassword,
        }
    const classes = useFormStyles();
    const { data }  = loadGridUser();
    const result_data = data.filter( e => !e?.filterdetail );
    return (
        <Grid item style={{padding: '2rem'}}>
            <Grid container direction='column'>

                <Grid item classes={{root: classes.container }}>
                    <Typography variant='h3'>Usuario { result?.name }</Typography>
                </Grid>

                {
                    result_data.map( element => {
                        return (
                            <Grid item classes={{root: classes.container }}>
                                <TextField
                                    fullWidth
                                    name={element.name}
                                    size='small'
                                    label={element.label}
                                    classes={{ root: classes.inputText }}
                                    value={ result[element.name] }
                                    variant='outlined'
                                    disabled
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                { element.startAdornment }
                                            </InputAdornment>
                                        )
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    multiline={ element.name === 'observaciones' ? true : false}
                                    maxRows={ element.name === 'observaciones' ? 10 : undefined }
                                    minRows={ element.name === 'observaciones' ? 5 : undefined }
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}
