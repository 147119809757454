import { types } from "../types/types";

const initialState = {
    modal: false
}

export const modalReducer = (state=initialState, action) => {

    switch (action.type) {
        case types.uiManageModalCollectionTypes:
            
            return {
                ...state,
                modal: !state.modal
            }
        default:
            return state;
    }
}