    import { types } from "../types/types";

    const initialState = {
        comisionistas: [],
        active: null,
    }
    
    export const comisionistasReducer = ( state = initialState, action ) => {
        switch (action.type) {
            case types.comisionistaActive:
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            
            case types.comisionistasLoad:
                return {
                    ...state,
                    comisionistas: [...action.payload]
                }
            
            case types.comisionistaDelete:
                return {
                    ...state,
                    active: null,
                    comisionistas: state.comisionistas.filter( comisionista => comisionista.PkComisionistas !== action.payload )
                }
            case types.comisionistasLogOutCleaning:
                return initialState;
    
            default:
                return state;
        }
    }
    