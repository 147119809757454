
//Third-party
import React from 'react'
import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { ExitToAppOutlined } from '@material-ui/icons';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import backImage from '../../images/back-login.png';


//Custom
import { startLogOut } from '../../redux/actions/auth';
import {  startUpdatePoliticas } from '../../redux/actions/users'
import { startDownloadTerms } from '../../redux/actions/settings';

const Politicas = () => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('md'))

    const handleLogout = () => {    
        dispatch(startLogOut()); // we clean the auth state
    }

    const handleAccept = () => {
        dispatch( startUpdatePoliticas());
        
        
    }

    const handleDownload = () => {
        dispatch( startDownloadTerms() );
    }



    return (
        <>
            <div style={{ marginTop: '5rem'}}/>
            <Helmet>
                <title>Politicas | Xplora</title>
            </Helmet>
            <IconButton style={{
                position: 'absolute',
                right: 0,
                top: 0,
                marginTop: '1rem',
                marginRight: '1rem',
                zIndex: 999,
            }} color="inherit" size='large' onClick={ handleLogout }>
                <ExitToAppOutlined />
            </IconButton>
            <div
                style={{
                    backgroundImage: `url(${ backImage }) `,
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
            <Box
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    borderRadius: 4,
                    height: '25vh',
                    width: '40vw',
                    paddingTop: '1rem',
                    minWidth: 330.4,
                    maxWidth: 700,
                    maxHeight: 300,
                    minHeight: 200,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}

            >
                        <Typography gutterBottom variant={ matchesXS ? 'h3' : 'h1'} component="div">
                            Política de Protección de Datos
                        </Typography>
                        <Typography variant="h3" style={{ textAlign: 'center'}}>
                            Para continuar, debe aceptar los términos y condiciones.
                        </Typography>
                        <Box sx={{ display: 'flex', marginTop: '1rem'}}>
                            <Button style={{ 
                                    backgroundColor: '#404040',
                                    color: '#fff',
                                    width: '7rem',
                                    height: '2.5rem',
                                    marginRight: '1rem'
                                }} size="medium" onClick={ handleDownload } >Descargar</Button>
                            <Button style={{
                                backgroundColor: '#E6824F',
                                marginLeft: '1rem',
                                color: '#fff',
                                width: '6rem',
                                height: '2.5rem',
                            }} size="medium" onClick={ handleAccept }>Aceptar</Button>
                        </Box>
            </Box>
            </div>
        </>
    )
}


export default Politicas;