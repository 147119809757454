import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { DeleteOutlineOutlined, Web } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTableParams } from '../../../hooks/useTableParams'
import { actionAddResellerServices, actionDeleteServicesReseller, actionEditServicesReseller } from '../../../redux/actions/actions'
import { startLoadingServicesReseller } from '../../../redux/actions/supplierServices'
import { ROLADMIN, ROLREVENDEDOR } from '../../../utils/constants'
import { ButtonFloatNoOptions } from '../../ui/ButtonFloatNoOptions'
import { NotificationModal } from '../../ui/NotificationModal'
import { TransitionsModal } from '../../ui/TransitionModal'
import { getSimboloMoneda } from '../../../utils/utils'

export const ServicesResellers = () => {
    const data = [];
    const dataReseller = [];
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const showColumnAction = true;
    const { active } = useSelector(state => state.user );
    const { services } = useSelector(state => state.service);
    const { menuState } = useSelector(state => state.ui)
    const { id, nombre, idRol, idAgencia } = useSelector(state => state.auth)
    const userOptions = { filter: true, filterType: 'textField', responsive: 'standard', tableBodyMaxHeight: '80vh', filename: `Servicios ${ active?.Nombre || nombre }`, rowsPerPage: 5};
    
    //const userColumnsDeskTop =  [['Nombre', true], ['Tipo de Servicio', true, !matchesXS], ['Coste($)', false, !matchesXS],
    //['Coste(€)', false, !matchesXS], ['Reserva(€)', false, !matchesXS], ['Precio Venta(€)', false, !matchesXS]];
    const userColumnsDeskTop =  [['Nombre', true], ['Tipo de Servicio', true, !matchesXS], ['Coste($)', false, !matchesXS],
    ['Moneda', false, !matchesXS], ['Reserva', false, !matchesXS], ['Margen', false, !matchesXS], ['Precio Venta', false, !matchesXS]];
    

    const userReseller =  [
        ['Nombre', true], ['Tipo de Servicio', true, !matchesXS], ['Precio Neto', false, !matchesXS],
        ['Margen', false, !matchesXS], ['Precio Venta', false, !matchesXS] //['Precio Venta(€)', false, !matchesXS], ['Precio Venta($)', false, !matchesXS]
        ];
    const actionsAdmin = [
        { isShown: true, type: 'delete', color: 'red', icon: <DeleteOutlineOutlined />, eject: ( dataIndex, e ) => handleDelete( dataIndex, e ) },
        { isShown: true, type: 'custom', color: '#000', icon: <Web />, eject: ( dataIndex, e ) => handleOpenNavigator( dataIndex, e )},
       // { isShown: true, color: 'blue', icon: <EditOutlined />, eject: ( dataIndex, e  ) => handleEditServiceReseller ( dataIndex, e  ) },
    ]
    const actionsReseller = [
        { isShown: true, type: 'custom', color: '#000', icon: <Web />, eject: ( dataIndex, e ) => handleOpenNavigator( dataIndex, e )},
       // { isShown: true, color: 'blue', icon: <EditOutlined />, eject: ( dataIndex, e  ) => handleEditServiceReseller ( dataIndex, e  ) },
    ]
    const { options, columns } = useTableParams(
        userOptions,
        idRol === ROLADMIN ? userColumnsDeskTop : userReseller, 
        idRol === ROLADMIN ? actionsAdmin : actionsReseller,
        idRol !== ROLADMIN ? showColumnAction : true,
        ( idRol === ROLADMIN && ( ( dataIndex, e ) => handleEditServiceReseller( dataIndex, e ) ) ),
        idRol === ROLADMIN ? '' : 'Itinerario',
        );

    useEffect(() => {
        if( idRol === ROLADMIN ){
            dispatch(startLoadingServicesReseller(active?.PkUsuarios))
        }
        else if( idRol === ROLREVENDEDOR ){
            dispatch(startLoadingServicesReseller( (idAgencia>0)?idAgencia:id ))
        }
       
    }, [ dispatch, idRol, id, active?.PkUsuarios ])

    const handleOpenNavigator = ( dataIndex, e) => {
        if (e) e.stopPropagation(); 
        if (services[dataIndex].PaginaWeb > '') window.open(services[dataIndex].PaginaWeb)
    } 

    console.log(services);

    services.forEach( servicio => { 
        
        data.push([servicio?.ServicioProveedor?.Nombre, servicio?.ServicioProveedor?.TipoServicio?.Nombre, 
            servicio?.CosteDolares, servicio?.Moneda , 

            (servicio?.Moneda==='EUR'?servicio?.ReservaEuros:servicio?.ReservaDolares) + ' ' + getSimboloMoneda(servicio?.Moneda), 

            (servicio?.Moneda==='EUR'?servicio?.ImporteReventaEuros:servicio?.ImporteReventaDolares) + ' ' + getSimboloMoneda(servicio?.Moneda),             
            
            servicio?.PrecioVenta + ' ' + getSimboloMoneda(servicio?.Moneda)
        ]);
        
       dataReseller.push([servicio?.ServicioProveedor?.Alias ? servicio?.ServicioProveedor?.Alias : servicio?.ServicioProveedor?.Nombre,
            servicio?.ServicioProveedor?.TipoServicio?.Nombre, 
            (servicio?.PrecioVenta-servicio?.ImporteReventa) + ' ' + getSimboloMoneda(servicio?.Moneda),
            servicio?.ImporteReventa + ' ' + getSimboloMoneda(servicio?.Moneda),
            servicio?.PrecioVenta + ' ' + getSimboloMoneda(servicio?.Moneda)
        ]);
    
    })


    const handleAddServiceReseller = () => {
        dispatch(actionAddResellerServices())
    }

    const handleDelete = ( dataIndex , e ) => {
        console.log('handleDelete service');
        console.log(e);
        if (e) e.stopPropagation();
        dispatch( actionDeleteServicesReseller( services[ dataIndex ].ServicioProveedor.PkServiciosProveedores ))
    }

    const handleEditServiceReseller = ( dataIndex, e ) => {
        console.log('handleEditServiceReseller');
        console.log(e);
        if (e) e.stopPropagation();
        dispatch( actionEditServicesReseller( services[ dataIndex ].ServicioProveedor.PkServiciosProveedores ))
    }
    return (
        <>
        <div style={{
            marginTop: '2rem',
            width: `${ matchesXS ? '88vw' : (matchesMD ? (menuState  ? '55vw' : '85vw') : ( menuState ? '82vw' : '91vw')) }`,
        }}>

         
                <Typography variant='h2' style={{ marginLeft: '1.7rem'}}>{`SERVICIOS`}</Typography>
         

        
            <MUIDataTable
                {...{ data: idRol === ROLADMIN ? data : dataReseller, columns, options}}
                
            />
            <TransitionsModal/>
            <NotificationModal />
            {
                idRol === ROLADMIN
                &&
                (
                    <ButtonFloatNoOptions 
                        action={ handleAddServiceReseller }
                    />
                )
            }
            
            

        </div>
        
    
    </>
    )
}
