
import { navigate } from "@reach/router";
import Swal from "sweetalert2";
import { ROLREVENDEDOR, SERVICE } from "../../utils/constants";
import { setActiveComisionista, setActiveComisionistaState } from "./comisionistas";
import { setActiveCustomer, setActiveCustomerState } from "./clients";
import { startContentModalAddComisionista, startDeleteComisionista, startAddInvoice, startContentDeleteOrderLineFromInvoice, startContentEditFreeLineFromState, startContentEditOrderLineState, startContentFacturar, startContentModalAddClient, startContentModalAddExchangeRate, startContentModalAddFreeLineToOrder, startContentModalAddLineToOrder, startContentModalAddProvider, startContentModalAddResellerService, startContentModalAddSupplierService, startContentModalAddSupplierToCoordinator, startContentModalAddUser, startContentModalEditLineReseller, startContentModalEditOrderLine, startContentModalEditResellerService, startDeleteClient, startDeleteExchangeRate, startDeleteResellerService, startDeleteSupplier, startDeleteSupplierService, startDeleteSupplierToCoordinator, startDeleteUser, startEditService, startEditSupplierToCoordinator, startLogOutContentModal } from "./contentModal";
import { setActiveExchangeRate, setActiveExchangeRateToSupplier } from "./exchangeRate";
import { setActiveLineFromInvoice } from "./lines";
import { setActiveLineFromInvoiceState } from "./sales";
import { setActiveSupplier, setActiveSupplierCoordinator, setActiveSupplierState } from "./suppliers";
import { setActiveService, setActiveServiceReseller, setActiveServiceState, startExchangeRateToSupplier } from "./supplierServices";
import { finishLoading, finishSuccess, uiManageModalCollectionTypes, uiManageModalNotification } from "./ui";
import { setActiveUser, setActiveUserState, setCurrentUserState} from "./users";

//Users



export const actionDeleteUser = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveUserState( id )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteUser()) )
        .catch( e => {
            Swal.fire('Error', 'Ha habido un problema', 'error')
        })
    }
}

export const actionProfile = ( id ) => {
    return dispatch => {
        dispatch( setActiveUserState( id ) )
       
    }
}

export const actionAddUser = () => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes()) )
        .then( () =>   dispatch(startContentModalAddUser()) )
    }
}

export const actionEdit = ( id ) => {
    return dispatch => {
        Promise.resolve(dispatch(setActiveUser( id )))
    }
}


export const actionSetCurrentUser = ( id ) => {
    return dispatch => {
        Promise.resolve(dispatch(setCurrentUserState( id )))
    }
}


//Suppliers

export const actionEditSupplier = ( id ) => {
    return dispatch => {
       Promise.resolve( dispatch(setActiveSupplier( id )))
    }
}

export const actionDeleteSupplier = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveSupplierState( id )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteSupplier()) )
    }
}

export const actionAddSupplier = () => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes()) )
        .then( () =>   dispatch(startContentModalAddProvider()) )
    }
}


//Exchange Rates

export const actionAddRate = () => {
    return dispatch => {
        Promise.resolve( dispatch( uiManageModalCollectionTypes() ) )
        .then( () => dispatch( startContentModalAddExchangeRate()))
    }
}



export const actionDeleteRate = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveExchangeRate( id )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteExchangeRate()) )
    }
}


//Suppliers Services

export const actionAddSupplierService = () => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes() ) )
        .then(() => dispatch( startContentModalAddSupplierService() ))
    }
}

export const actionDeleteService = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveServiceState( id )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteSupplierService()) )
    }
}

export const actionEditService = ( id ) => {
    return (dispatch) => {
        Promise.resolve( dispatch(setActiveService( id )) )
    }
}

export const actionEditServiceModal = ( id ) => {
    return (dispatch, getState) => {
        Promise.resolve( dispatch(setActiveServiceState( id )))
        .then( () => {
            const { active } = getState().service
            dispatch(startExchangeRateToSupplier(active.FkProveedores, active.FkTiposServicio))
        })
        .then( () => {
            dispatch(startEditService())
        })
        .then( () => {
            dispatch(uiManageModalCollectionTypes())
        })
    }
}

//Reseller Services

export const actionAddResellerServices = () => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes()))
        .then(() => {
            dispatch(startContentModalAddResellerService())
        })
    }
}


export const actionDeleteServicesReseller = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveServiceReseller( id, false )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteResellerService()) )
    }
}

export const actionEditServicesReseller = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch( setActiveServiceReseller( id, true )) 
        .then( () =>  dispatch(uiManageModalCollectionTypes()) )
        //.then(() => dispatch(startContentModalEditResellerService()) )
        )
    }
}
 

export const setActiverateResellerAndLoadExchangeRates = ( servicio, proveedor ) => {
    return (dispatch, getState) => {
        Promise.resolve(dispatch(setActiveServiceState(servicio)))
        .then(() => {
            const { active } = getState().service;
            dispatch(setActiveExchangeRateToSupplier(proveedor, active.TipoServicio.PkTiposServicio))
        })
        .catch( () => {
           dispatch(actionCloseModal())
        })
    }
}

export const actionCloseModal = () => {
    return dispatch => {
        Promise.resolve(dispatch(uiManageModalCollectionTypes()))
        .then( () => {
            dispatch(startLogOutContentModal())
        })
        .then( () => {
            Swal.fire('Error', 'No se han podido cargar los datos. Inténtelo de nuevo más tarde', 'error')
        })
    }
}
//Coordinator Suppliers


export const actionAddSupplierToCoordinator = () => {
    return dispatch => {
        Promise.resolve( dispatch( uiManageModalCollectionTypes() ))
        .then( () => {
            dispatch( startContentModalAddSupplierToCoordinator() )
        })
    }
}

export const actionDeleteCoordinatorSupplier = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveSupplierCoordinator( id )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteSupplierToCoordinator()) )
        
    }
}

export const actionEditSupplierCoordinator = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch( setActiveSupplierCoordinator( id )) )
        .then( () =>  dispatch(uiManageModalCollectionTypes()) )
        .then(() => dispatch(startEditSupplierToCoordinator()) )
    }
}


// Comisionistas


export const actionAddComisionista = (simple) => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes() ) )
        .then(() => dispatch( startContentModalAddComisionista(simple) ))
    }
}

export const actionEditComisionista = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveComisionista( id )) )
        .then( () => {
            navigate(`/comisionistas/${ id }`);
            dispatch(finishLoading());
        })
    }
}

export const actionDeleteComisionista = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveComisionistaState( id )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteComisionista()) )
    }
}


//Customers

export const actionAddCustomer = (simple) => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes() ) )
        .then(() => dispatch( startContentModalAddClient(simple) ))
    }
}

export const actionEditCustomer = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveCustomer( id )) )
        .then( () => {
            navigate(`/clients/${ id }`);
            dispatch(finishLoading());
        })
    }
}

export const actionDeleteCustomer = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveCustomerState( id )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteClient()) )
    }
}


export const actionAddOrderLineToOrder = () => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes() ) )
        .then(() => dispatch( startContentModalAddLineToOrder() ))
        .then(() => {
            dispatch(finishSuccess())
        })
    }
}

export const actionAddFreeOrderLineToOrder = () => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes() ) )
        .then(() => dispatch( startContentModalAddFreeLineToOrder() ))
    }
}



//Modal

export const actionLogOutModal = () => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes()))
        .then(() => {
            dispatch(startLogOutContentModal())
        })
    }
}



//Invoices

export const actionAddInvoice = ( payload, setSubmitting ) => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startAddInvoice( payload, setSubmitting )) )
    }
}


export const actionDeleteOrderLineFromInvoice = ( id ) => {
    return dispatch => {
        Promise.resolve(  dispatch(setActiveLineFromInvoice( id ))  )
        .then(() => dispatch(startContentDeleteOrderLineFromInvoice()) )  
        .then (() => {
            dispatch(uiManageModalNotification()) 
        })
       
        
        
        
    }
}



export const actionEditOrderLineFromInvoice = ( line ) => {
    return (dispatch, getState) => {
        const { idRol } = getState().auth;
        Promise.resolve(dispatch(setActiveLineFromInvoice( line )))
        .then(() => { 
            if( idRol !== ROLREVENDEDOR ){
                const { activeLine } = getState().lines.active;
                if( activeLine.ReventaLineaEuros === null || activeLine.ReventaLineaEuros === 0 ){
                    dispatch(startContentModalEditOrderLine())
                }
                else{
                    dispatch(startContentModalEditLineReseller())
                }
            }
            else{
                dispatch(startContentModalEditLineReseller())
            }
        }   
        ).then( () => {
            dispatch(uiManageModalCollectionTypes())
        })  
            
       
        
        
        
    }
}

export const actionEditOrderLineFromState = ( payload, index ) => {
    return( dispatch, getState ) => {
        Promise.resolve( dispatch( setActiveLineFromInvoiceState( payload ) ) )
        .then( () => {
            const lineType = getState().invoice.active.activeLine.FkTiposLineaVenta
            if( lineType === SERVICE ){
                dispatch(startContentEditOrderLineState())
            }
            else{
                dispatch(startContentEditFreeLineFromState())
            }
            
        })
        .then( () => {
            dispatch(uiManageModalCollectionTypes())
        })
        .then( () => {
            localStorage.setItem('indexOrderLine', index)
        })
    }
}
export const startRedirectingFromLineToSupplier = () => {
    return async( dispatch ) => {
        Promise.resolve( dispatch( uiManageModalCollectionTypes))
    }
}


export const actionContentSendingEmailBudget = () => {
    Promise.resolve()
}


export const actionFacturarContent = () => {
    return async( dispatch ) => {
        Promise.resolve( dispatch( startContentFacturar() ) )
        .then( () => {
            dispatch( uiManageModalNotification() )
        }
        )
    }
}