import { Add, HotelOutlined, MonetizationOn } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Fab, Action } from 'react-tiny-fab';
import { actionAddRate, actionAddSupplierService } from '../../redux/actions/actions';

export const ButtonFloatSupplier = () => {
    const mainButtonStyles = {backgroundColor: '#E6824F'};
    const dispatch = useDispatch();
    const { modal } = useSelector(state => state.modal)

    const handleAdd = () => {
        dispatch(actionAddSupplierService());
    }
    const handleAddExchangeRate = () => {
        dispatch(actionAddRate())
    }
    return (
      <>
      {
        modal
        ?
        <></>
        :
        <Fab
        mainButtonStyles={mainButtonStyles}
        icon={ <Add />}
        alwaysShowTitle={true}
      >

          <Action
              text="Nuevo tipo de cambio"
              onClick={handleAddExchangeRate}
            >
              <MonetizationOn />
          </Action>
        

        <Action
            text="Nuevo servicio"
            onClick={ handleAdd }
          >
            <HotelOutlined />
        </Action>

        </Fab>

      }
      </>
         
    )
}
