import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeleteService } from '../../redux/actions/supplierServices';
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';

export const ConfirmDeleteServicesSupplier = () => {
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { active } = useSelector(state => state.service) //we get the users from the state 

    
    const handleSuccess = () => {
        dispatch(uiManageModalNotification())
        dispatch(startDeleteService(active.PkServiciosProveedores)); //We dispatch the action passing as a parameter the user id
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ `¿Quieres borrar el servicio?` }
                text={ `El servicio quedará eliminado definitivamente del sistema` }
                action={ handleSuccess }
            />
    )
}
