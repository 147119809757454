import { types } from '../types/types';

const getInfo = () => {
    if (typeof window !== 'undefined') {
        const data = localStorage.getItem('user');
        const parsed_data = JSON.parse(data)
        return parsed_data
    }
    
}

const parsed_data = getInfo();

const initialState = {
    id: parsed_data ? parsed_data.PkUsuarios : null,
    nombre: parsed_data ? parsed_data.Nombre : null,
    rol: parsed_data ? parsed_data.rol.Nombre : null,
    idRol: parsed_data ? parsed_data.rol.PkRoles : null,
    isAuthenticated: parsed_data ? true : false,
    aceptaPoliticas: parsed_data ? parsed_data.AceptaPoliticas : null,
    cambiaPassword: parsed_data ? parsed_data.CambioPassword : null,
    esAgencia: parsed_data ? parsed_data.EsAgencia : null,
    aceptaContratoTratamientoDatos: parsed_data ? parsed_data.AceptaContratoTratamientoDatos : null,
    moneda: parsed_data ? parsed_data.Moneda : null,
    margenConfiguradoEnServicios: parsed_data ? parsed_data.MargenConfiguradoEnServicios : null,
    idAgencia: parsed_data ? parsed_data.FkUsuarios_Agencia : null,
    
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.authLogin:
            return {
                ...state,
                ...action.payload
            }
        case types.authLogOut:
            return {
                isAuthenticated: false
            }
        case types.userAcceptPoliticas:
            return {
                ...state,
                aceptaPoliticas: true,
            }
        case types.userAcceptContratoTratamientoDatos:
            return {
                ...state,
                aceptaContratoTratamientoDatos: true,
            }
        case types.userChangePassword:
            return {
                ...state,
                cambiaPassword: true,
            }
        default:
            return state;
    }
}
