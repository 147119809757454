import { types } from '../types/types';

const initialState = {}

export const settingsReducer = ( state = initialState, action) => {
    switch (action.type) {
        case types.settingsLoad:
            return {
                ...action.payload
            }
        default:
            return state;
    }
}
