
import { getDateDayPlusTwo2, getDateMonthMinusOne2 } from "../../utils/dates";
import { obtenerPrecioEuros } from "../../utils/exchange";
import { types } from "../types/types";
import { useSelector } from 'react-redux';
import { ROLCOLABORADOR } from "../../utils/constants";

const { year, month, day } = getDateDayPlusTwo2();
const { yearY, monthY, dayY } = getDateMonthMinusOne2();


const initialState = {
    lines: {
        data: [],
        count: 0,
        page: 0,
        sum: 0,
        currentFilter: [[],[],[],[],[],
                        [],[],[],[],[],
                        [],['Pendiente de Proveedor'],[],[],[],
                    [],[]] ,
        dateRange:[new Date(yearY, monthY, dayY).toISOString(), new Date( year, month, day ).toISOString()],
    },
    active: null
}

export const linesReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case types.changeLinesFilter:
            return {
                ...state,
                lines: {
                    ...state.lines,
                    currentFilter: action.payload,
                }
            }
        case types.changeLinesFilterDates:
            return {
                ...state,
                lines: {
                    ...state.lines,
                    dateRange: action.payload,
                }
            }
        case types.linesLoad:
            return {
                ...state,
                lines: {
                    ...state.lines,
                    data: [...action.payload],
                    page: action.page
                }
            }
        case types.lineActive:
            return {
                ...state,
                active: {
                    activeLine: {
                        ...action.payload[0]
                    },
                    services: [...action.payload[1]],
                    suppliers: [...action.payload[2]],
                }
            }
        case types.lineCurrentActive:
            return{
                ...state,
                active: {
                    activeLine: {
                        ...action.payload
                    }
                }
            }
        case types.lineActiveUpdatePrices:
            return {
                ...state,
                active: {
                    ...state.active,
                    services: [...action.payload]
                }
            }
        
        case types.lineChangeDataPricesAndLine:
            return {
                ...state,
                active: {
                    ...state.active,
                    activeLine: {
                        ...state.active.activeLine,
                        CosteDolares: action.payload[0],
                        ReservaEuros: action.payload[1],
                        InSituLineaDolares: action.payload[0],
                        ReservaLineaEuros: action.payload[1],
                        TipoCambioProveedor: {
                            ...action.payload[2]
                        },
                        Proveedor: {
                            ...action.payload[3]
                        },
                        ServicioProveedor: {
                            ...action.payload[4]
                        },
                        Concepto: action.payload[5],
                        Unidades: 1,
                        ReventaEuros: action.payload[6],
                        ReventaLineaEuros: action.payload[6],
                        ImportalTotal: +action.payload[6] + obtenerPrecioEuros(action.payload[0], action.payload[2].CambioEuros, action.payload[2].CambioDolares) + action.payload[1],
                        TasaDolares: +action.payload[7],
                    },
                }
            }
        case types.resetPageLine:
            return {
                ...state,
                lines: {
                    ...state.lines,
                    page: 0,
                }
            }
        case types.lineSumTotal:
            return {
                ...state,
                lines: {
                    ...state.lines,
                    sum: action.payload.sum,
                    sumInSituEuros: action.payload.sumInSituEuros,
                    sumReservaEuros: action.payload.sumReservaEuros,
                    sumDescuentoEuros: action.payload.sumDescuentoEuros,
                    sumReventaEuros: action.payload.sumReventaEuros,
                    sumBalanceDolares: action.payload.sumBalanceDolares,
                }
            }
        case types.lineActiveEditLogOut:
            return {
                ...state,
                active: {
                    ...state.active,
                    activeLine: {
                        PkLineasVenta: state.active.activeLine.PkLineasVenta,
                        VersionFila: state.active.activeLine.VersionFila,
                    }
                }
            }
        case types.getNumberOfLines:
            return {
                ...state,
                lines: {
                    ...state.lines,
                    count: action.payload
                }
            }
        case types.loadServicesResellerEdit: 
            return {
                ...state,
                active: {
                    ...state.active,
                    services: [...action.payload]
                }
            }
        case types.linesLogOutCleaning:
            return initialState;

        default:
            return state;
    }
}
    