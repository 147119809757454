import Swal from 'sweetalert2';
import { url } from '../api/api';
import { loadJWT } from './loadJWT';




export const loadUsers = async() => {
    let users = [];
    const { token } = loadJWT();
    // EXCLUIMOS LOS USUARIOS VENDEDORES DE AGENCIA (SE GESTIONAN DESDE LA FICHA DE LA AGENCIA)
    await url.get(
        `/usuarios?filter={"include": [{"relation":"Rol"}], 
            "where": {"and": [{"FechaBaja": {"eq": null}},{"EsVendedor": {"eq": 0}}]} 
        }`
        , {headers:{
        'Authorization': `Bearer ${ token }`,
    }})
    .then( response => {
        users = response.data.map( resp => resp);
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
    })
    return users;

}