import React from 'react';
import { Payments } from './Payments';
import { Bar  } from 'react-chartjs-2';

import { Container, Grid } from '@material-ui/core';
import { ConfirmedLinesCard } from './ConfirmedLinesCard';
import { useCollaboratorDataCharts } from '../../../hooks/useDataCharts';


export const DashboardColaborator = () => {
    const { 
        dataBar,
        optionsBar } = useCollaboratorDataCharts();
    return (
        <Container maxWidth={false}>
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    <Payments />
                </Grid>
                <Grid
                    item
                    xl={3}
                    lg={3}
                    sm={6}
                    xs={12}
                >
                    <ConfirmedLinesCard />
                </Grid>
                
            </Grid>
            <Grid
                container
                spacing={3}
            >

            {/**Graficas */}
            <Grid
                item
                lg={8}
                md={6}
                xl={9}
                xs={12}
            >
                <Bar
                    options={optionsBar}
                    data={ dataBar }
                />
            </Grid>
            <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
                style={{ marginTop: '2rem' }}
            >
                {/*<LogActions /> */}
            </Grid>
            </Grid>
        </Container>
    );
};
