import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Euro, MonetizationOn, RemoveRedEyeRounded } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { startEditServiceReseller } from '../../../redux/actions/supplierServices';
import { useFormStyles } from '../../../styles/formStyles';
import { validationSchemaEditResellerService } from '../../../utils/schemaValidationForm';
import { calcularImporteReservaServicioRevendedor } from '../../../utils/utils';
import { startLogOutContentModal } from "../../../redux/actions/contentModal";
import { uiManageModalCollectionTypes } from "../../../redux/actions/ui";
import { Spinner } from '../../ui/Spinner';
import { loadCurrencies } from '../../../helpers/loadCurrencies';

export const ContentEditService = () => {
    const { active } = useSelector(state => state.user);
    const { active: activeService } = useSelector(state => state.service)
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const dispatch = useDispatch();
    const { loadingModal } = useSelector(state => state.ui)
    const currencies = loadCurrencies();
    const c = currencies.filter( e => e.code === activeService?.Moneda)
 
    const initialValues = {
        servicio: activeService?.FkServiciosProveedores,
        importeReventa: activeService?.ImporteReventa,
        observaciones: activeService?.Observaciones,
        precioVenta: activeService?.PrecioVenta,
        moneda: activeService?.Moneda
      };
           

    const submit = (values, {setSubmitting}) => {
        const instance = {
            "FkUsuarios": active?.PkUsuarios,
            "FkServiciosProveedores": values.servicio,
            "ImporteReventa": +values.importeReventa,
            "Observaciones": values.observaciones,
            "VersionFila": activeService?.VersionFila,
            "PrecioVenta": +values.precioVenta,
            "Moneda": values.moneda
        }
        dispatch(startEditServiceReseller( instance, setSubmitting));
    }
    
    const handleCancel = () => {
        dispatch(startLogOutContentModal());
        dispatch(uiManageModalCollectionTypes());
    };

    return (
        <>
        {
            loadingModal
            ?
            <Spinner />
            :
            <>
                <Formik enableReinitialize validationSchema={ validationSchemaEditResellerService } initialValues={initialValues} onSubmit={submit}>
                    {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid, setFieldValue }) => (
                    <Form>
                    <Grid item style={{ padding: '2rem'}}>
                            
                            <Grid item classes={{root: classes.container }}>
                                <Typography variant='h3'>Servicio: { activeService?.Servicio?.Nombre } - Revendedor: { active?.Nombre }</Typography>
                            </Grid>

                        <Grid container 
                                spacing={3}>
                                                
                                <Grid md={4} xs={12} item classes={{root: classes.container }}>
                                <FormControl fullWidth>
                                        <InputLabel style={{fontSize: '0.7em'}}  id="demo-simple-select-label">Moneda</InputLabel>
                                        <Select style={{fontSize: '0.8em'}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue={c[0]?.code}
                                            label="Moneda"
                                            name="moneda"
                                            onChange={ ( e ) => {
                                                setFieldValue('moneda', e.target.value);
                                                //handleChange(e);
                                            }}
                                            variant="outlined"
                                            value={ values.moneda }
                                        >
                                            {
                                                currencies.map( aux => {
                                                    return (
                                                        <MenuItem selected={true} style={{fontSize: '0.7em'}}  value={aux.code}>{ aux.label }</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid md={4} xs={12} item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-importeReventa`}  
                                        label='Coste Dólares'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MonetizationOn />
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={ activeService?.Servicio?.CosteDolares }
                                        
                                    />
                                </Grid>
                    
                                <Grid md={4} xs={12} item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth 
                                        size='small'
                                        name='importeReserva'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-importeReventa`}  
                                        label='Reserva Xplora'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                   { (values.moneda==='EUR') ? <Euro /> : <MonetizationOn /> }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={ activeService ? +calcularImporteReservaServicioRevendedor(
                                            activeService,
                                            activeService?.Servicio?.CosteDolares,
                                            values.precioVenta,
                                            values.importeReventa,
                                            values.moneda) : 0 }
                                        onBlur={ handleBlur }
                                        error={touched.importeReventa && Boolean(errors.importeReventa)}
                                        helperText={touched.importeReventa && errors.importeReventa}
                                        disabled
                                        
                                    />
                                </Grid>
                                {
                                    (!active.Comisiona || active.MargenConfiguradoEnServicios) &&
                                    (
                                <Grid md={4} xs={12} item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name='importeReventa'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-precioVenta`}  
                                        label='Margen revendedor'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { (values.moneda==='EUR') ? <Euro /> : <MonetizationOn /> }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={ values.importeReventa }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.importeReventa && Boolean(errors.importeReventa)}
                                        helperText={touched.importeReventa && errors.importeReventa}
                                        disabled={ isSubmitting }
                                        
                                    />
                                </Grid>
                                )}

                                <Grid md={4} xs={12} item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name='precioVenta'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-precioVenta`}  
                                        label='Precio Venta'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { (values.moneda==='EUR') ? <Euro /> : <MonetizationOn /> }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={ values.precioVenta }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.precioVenta && Boolean(errors.precioVenta)}
                                        helperText={touched.precioVenta && errors.precioVenta}
                                        disabled={ isSubmitting }
                                        
                                    />
                                </Grid>

                                <Grid xs={12}  item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name='observaciones'
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-observaciones`}  
                                        label='Observaciones'
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <RemoveRedEyeRounded />
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        InputLabelProps={
                                            {style: {fontSize: '0.8em'}}
                                        }
                                        value={ values.observaciones }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.observaciones && Boolean(errors.observaciones)}
                                        helperText={touched.observaciones && errors.observaciones}
                                        disabled={ isSubmitting }
                                        multiline
                                        maxRows={ 10 }
                                        minRows={ 5 }
                                        
                                    />
                                </Grid>
                                   
                        </Grid>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        p: 2
                                    }}
                                >
                                     
                                    <Button 
                                        style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                        onClick={ handleCancel }>
                                        <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                    </Button>

                                    <LoadingButton
                                        loadingPosition="start"
                                        variant="contained"
                                        type='submit'
                                        loading={ isSubmitting }
                                        disabled={ !isValid || isSubmitting || (activeService ? +calcularImporteReservaServicioRevendedor(
                                            activeService,
                                            activeService?.Servicio?.CosteDolares,
                                            values.precioVenta,
                                            values.importeReventa,
                                            values.moneda) : 0)<0 }
                                        style={{
                                            backgroundColor: '#E5824F',
                        
                                        }}
                                    >
                                            Editar
                                    </LoadingButton>

                                </Box>



                    </Grid>
                    </Form>
                    )}
                    </Formik>
            </>
        }
        </>
        
    )
}
