    import { LocalizationProvider } from '@mui/x-date-pickers'
    import { LoadingButton } from '@mui/lab'

    import { 
        AccountCircle, 
        AccountCircleRounded,  
        AddBox,  
        AlternateEmail, 
        ApartmentOutlined, 
        ApartmentRounded, 
        AttachMoney, 
        BeachAccess, 
        Business, 
        CardTravel, 
        Code, 
        CodeOutlined, 
        CodeRounded, 
        DateRange, 
        DateRangeOutlined, 
        DragIndicator, 
        Email, 
        EmailOutlined, 
        Euro, 
        EuroOutlined, 
        FormatListNumbered, 
        FormatListNumberedOutlined, 
        FormatListNumberedRounded, 
        Hotel, 
        InfoOutlined, 
        LibraryAdd, 
        LocalOffer, 
        LocalPhone, 
        MenuOutlined,  
        MonetizationOn,   
        PersonAdd, 
        Phone, 
        Policy, 
        RemoveRedEyeRounded, 
        Reorder, 
        ThumbsUpDown, 
        ThumbUpAlt, 
        VpnKey, 
        Web,
        
} from "@material-ui/icons"
import { SwitchMUI } from '../ui/Switch';

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Box } from '@mui/system'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadGridComisionista } from '../../helpers/loadGrid'
import { startUpdateComisionista } from '../../redux/actions/comisionistas'
import { useFormStyles } from '../../styles/formStyles'
import { validationSchemaComisionista } from '../../utils/schemaValidationForm'
import { FormikDatePicker } from '../ui/FormikDatePicker'
import esLocale from 'date-fns/locale/es'
import { Spinner } from '../ui/Spinner'
import { navigate } from '@reach/router';

export const ComisionistaInfo = () => {
    const classes = useFormStyles();
    const { active } = useSelector(state => state.comisionistas);
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui)
    
    const initialValues =  {
        Nombre: active?.Nombre,
        Email: active?.Email,
        Telefono: active?.Telefono,
        Observaciones: active?.Observaciones,
        date: active?.FechaBaja,
        ImporteComision: active?.ImporteComision??0,
        ImporteComisionDescuento: active?.ImporteComisionDescuento??0,
        PorcentajeComision: active?.PorcentajeComision??0,
        ComisionaPorImporte: active?.ComisionaPorImporte ?? false,
    }

    const { data } = loadGridComisionista();

    const handleSubmit = ( values, {setSubmitting} ) => {
        const comisionista = {
            "PkComisionistas": active?.PkComisionistas,
            "VersionFila": active?.VersionFila,
            'Nombre': values.Nombre,
            "Email": values.Email,
            "Telefono": values.Telefono,
            "FechaBaja": !values.date ? null : values.date,
            "Observaciones": values.Observaciones ?? '',
            "ImporteComision": +values.ImporteComision,
            "ImporteComisionDescuento": +values.ImporteComisionDescuento,
            "PorcentajeComision": +values.PorcentajeComision,
            "ComisionaPorImporte": values.ComisionaPorImporte??false,
        }
       dispatch(startUpdateComisionista( comisionista, setSubmitting ))
        
    }

    return (
        loading
        ?
        <Spinner />
        :
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <Formik
                    validationSchema={ validationSchemaComisionista }
                    enableReinitialize={ true } 
                    initialValues={ initialValues } 
                    onSubmit={handleSubmit}>
                {({ handleChange, errors, values, handleBlur, isSubmitting, touched, isValid, setFieldValue }) => (
                <Grid md={8} xs={11}>
                <Form>
                    <Box sx={{ p:2, mb: 4}}>
                        <Typography variant='h1'>Comisionista</Typography>
                    </Box>
                    <Grid
                        container
                        spacing={3}
                    >
                       
                    <Grid
                        key='Nombre'
                        item
                        md={6}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='Nombre'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-nombre`} 
                            label={ 'Nombre' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['Nombre']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['Nombre'] && Boolean(errors['Nombre'])}
                            helperText={touched['Nombre'] && errors['Nombre']}
                            disabled={ isSubmitting }
                        />
                    </Grid>
                    
                    <Grid
                        key='Email'
                        item
                        md={6}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='Email'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-Email`} 
                            label={ 'Email' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailOutlined />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['Email']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['Email'] && Boolean(errors['Email'])}
                            helperText={touched['Email'] && errors['Email']}
                            disabled={ isSubmitting }
                        />
                    </Grid>

                    <Grid
                        key='Telefono'
                        item
                        md={6}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='Telefono'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-Telefono`} 
                            label={ 'Telefono' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Phone />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['Telefono']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['Telefono'] && Boolean(errors['Telefono'])}
                            helperText={touched['Telefono'] && errors['Telefono']}
                            disabled={ isSubmitting }
                        />
                    </Grid>




                    <Grid
                    item
                    md={4}
                    xs={12}
                >

                    <SwitchMUI 
                        label='Comisiona por importe'
                        name='ComisionaPorImporte'
                        values={ values }
                        setFieldValue={ setFieldValue }
                    />
                </Grid>


                <Grid
                        key='ImporteComision'
                        item
                        md={4}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='ImporteComision'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-ImporteComision`} 
                            label={ 'Importe Comisión' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Euro />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['ImporteComision']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['ImporteComision'] && Boolean(errors['ImporteComision'])}
                            helperText={touched['ImporteComision'] && errors['ImporteComision']}
                            disabled={ isSubmitting || (values?.ComisionaPorImporte === false) }
                        />
                    </Grid>


                    <Grid
                        key='ImporteComisionDescuento'
                        item
                        md={4}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='ImporteComisionDescuento'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-ImporteComisionDescuento`} 
                            label={ 'Importe Comisión con descuento' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Euro />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['ImporteComisionDescuento']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['ImporteComisionDescuento'] && Boolean(errors['ImporteComisionDescuento'])}
                            helperText={touched['ImporteComisionDescuento'] && errors['ImporteComisionDescuento']}
                            
                            disabled={ isSubmitting || (values?.ComisionaPorImporte === false) }
                        />
                    </Grid>


                    <Grid
                        key='PorcentajeComision'
                        item
                        md={4}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='PorcentajeComision'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-PorcentajeComision`} 
                            label={ '% Comisión' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Euro />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['PorcentajeComision']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['PorcentajeComision'] && Boolean(errors['PorcentajeComision'])}
                            helperText={touched['PorcentajeComision'] && errors['PorcentajeComision']}
                            
                            disabled={ isSubmitting || (values?.ComisionaPorImporte) }
                        />
                    </Grid>

                    <Grid
                        key='Observaciones'
                        item
                        md={12}
                        xs={12}
                        >
                        <TextField
                            fullWidth
                            name='Observaciones'
                            size='small'
                            classes={{ root: classes.inputText }}
                            id={`outlined-basic-Observaciones`} 
                            label={ 'Observaciones' }
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <RemoveRedEyeRounded />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: classes.resize
                                }
                            }}
                            InputLabelProps={
                                {style: {fontSize: '0.8em'}}
                            }
                            value={values['Observaciones']}
                            onBlur={ handleBlur }
                            onChange={handleChange}
                            error={touched['Observaciones'] && Boolean(errors['Observaciones'])}
                            helperText={touched['Observaciones'] && errors['Observaciones']}
                            disabled={ isSubmitting }
                            multiline={ true}
                            maxRows={ 3 }
                            minRows={ 5 }
                        />
                    </Grid>


                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                        <Field style={{fontSize: '0.8em'}} component={FormikDatePicker} name="date" label='Fecha Baja' textfield='date' isSubmitting={ isSubmitting } value={ values?.date } />
                    </Grid>
                        
                    </Grid>
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                    >
           
                    <Button 
                         style={{  marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                         onClick={ () => navigate('/comisionistas') }>
                             <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                     </Button>

                            <LoadingButton
                                        variant="contained"
                                        type='submit'
                                        loading={ isSubmitting }
                                        disabled={ !isValid }
                                        style={{
                                            backgroundColor: '#E5824F',
                        
                                        }}
                                >
                                    Actualizar
                            </LoadingButton>
                    </Box>
                
                </Form>
                </Grid>
                )}
                </Formik>
                </LocalizationProvider>
        
        
    )
}
