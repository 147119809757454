import { types } from "../types/types";

export const apiFailed = () => ({
    type: types.FAILED_API_REQUEST
}) 

export const apiSuccess = () => ({
    type: types.SUCCESS_API_REQUEST
})


export const startChangeRoute = ( link ) => {
    return async( dispatch ) => {
        dispatch(routeChange(link))
    }
}


export const startLogOutRedirect = () => {
    return async( dispatch ) => {
        dispatch(logoutRedirect())
    }
}
const routeChange = ( route ) =>({
    type: types.ROUTE_REDIRECT,
    payload: route
})

const logoutRedirect = () => ({
    type: types.ROUTE_REDIRECT_LOGOUT,
})