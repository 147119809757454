
import { navigate } from "@reach/router";
import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadJWT } from "../../helpers/loadJWT";
import { loadRols } from "../../helpers/loadRols";
import { loadUsers } from "../../helpers/loadUsers"
import { types } from "../types/types";
import { startLogOutContentModal } from "./contentModal";
import { startChangeRoute, startLogOutRedirect } from "./routeManagement";
import { finishLoading, startLoading, uiManageModalCollectionTypes } from "./ui";





export const startNewUser = ( user, setSubmitting ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.post('/usuarios', user, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch(startLoadingUsers());
            Swal.fire('Éxito', 'Usuario añadido', 'success');
            return;
        })
        .catch( ( e ) => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false )
            dispatch(uiManageModalCollectionTypes());
            dispatch(startLogOutContentModal())
        })

    }
}


export const startDelete = ( id ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.delete(`/usuarios/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            dispatch((deleteUser(id)));
            Swal.fire('Éxito', 'Usuario eliminado', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error')
            return;
        })
    }
}

export const startLoadUser = (id) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await  url.get(`/usuarios/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch(setActive(resp.data))
        }).catch(e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error');
        })
    }
}


export const startUpdatePoliticas = () => {
    return async( dispatch, getState ) => {
        const { cambiaPassword, esAgencia, aceptaContratoTratamientoDatos } = getState().auth
        const string_user = localStorage.getItem('user');
        const user_parsed = JSON.parse(string_user);
        await url.put(`/auth/aceptarPoliticas`, {}, {
            headers:{
                Authorization: `Bearer ${ user_parsed.token }`
            }
        })
        .then( ( resp ) => {
            dispatch( aceptaPoliticas() );
            user_parsed.AceptaPoliticas = true;
            user_parsed.token = resp.data.token;
            localStorage.setItem('user', JSON.stringify(user_parsed));
            if( cambiaPassword ){
                navigate('/')
            }
            else if(esAgencia && !aceptaContratoTratamientoDatos){
                navigate("/politicas_agencia");
            }
            else{
                navigate('/auth/change');
            }
            
        })
        .catch(( e ) => {
                Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error')
                return;
        })
    }
}


export const startUpdateContratoTratamientoDatos = () => {
    return async( dispatch, getState ) => {
        const { cambiaPassword } = getState().auth
        const string_user = localStorage.getItem('user');
        const user_parsed = JSON.parse(string_user);
        await url.put(`/auth/aceptarContratoTratamientoDatos`, {}, {
            headers:{
                Authorization: `Bearer ${ user_parsed.token }`
            }
        })
        .then( ( resp ) => {
            dispatch( aceptarContratoTratamientoDatos() );
            user_parsed.AceptaContratoTratamientoDatos = true;
            user_parsed.token = resp.data.token;
            localStorage.setItem('user', JSON.stringify(user_parsed));
            if( cambiaPassword ){
                navigate('/')
            }
            else{
                navigate('/auth/change');
            }
            
        })
        .catch(( e ) => {
            console.log(e);
                Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error')
                return;
        })
    }
}

export const startUpdateFieldUser = ( user ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.put(`/usuarios/${ user.id }`, user ,{
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .catch(( e ) => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error')
            .then( () => {
                dispatch(startChangeRoute('login'))
            })
        })
        .finally( () =>{
            dispatch(startLogOutRedirect())
        })
    }
}

export const startChangePassword = ( user ) => {
    const string_user = localStorage.getItem('user');
    const user_parsed = JSON.parse(string_user);
    return async( dispatch ) => {
        await url.put(`/auth/cambiarPassword`, user ,{
            headers:{
                Authorization: `Bearer ${ user_parsed.token }`
            }
        })
        .then(() => {
            dispatch( changePassword() );
            user_parsed.CambioPassword = true;
            localStorage.setItem('user', JSON.stringify(user_parsed));
            if( user_parsed.CambioPassword ){
                navigate('/');
            }
            
        })
        .catch(( e ) => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error').then( () => {
                dispatch(startChangeRoute('login'))
            })
            return;
            
        })
        .finally( () => {
            dispatch(startLogOutRedirect())
        })
    }
}

export const startChangePasswordProfile = ( user, setOpenDialog ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.put(`/auth/cambiarPassword`, user ,{
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            setOpenDialog( false )
            Swal.fire('Éxito', 'Contraseña Actualizada', 'success');
            return;
            
        })
        .catch(( e ) => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error').then( () => {
                dispatch(startChangeRoute('login'))
            })
        })
        .finally( () => {
            dispatch(startLogOutRedirect())
        })
    }
}

export const startUpdateUser = (user, setSubmitting) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.put(`/usuarios/${ user.PkUsuarios }`, user ,{
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            Swal.fire('Success', 'Usuario modificado con éxito', 'success')
            .then( () => {
                navigate('/users')
            })
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false )
        })
    }
}


export const startUpdateProfile = (user, setSubmitting) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        await url.put(`/usuarios/${ user.PkUsuarios }`, user ,{
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( () => {
            Swal.fire('Success', 'Usuario modificado con éxito', 'success')
            .then( () => {
                navigate('/me')
            })
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false )
        })
    }
}


export const setActiveUser = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/usuarios/${ id }?filter[include][]=Rol`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            }
        })
        .then( ( resp ) => {
            if( resp.status === 204){
                navigate('/users')
                dispatch(finishLoading())
                return;
            }
            dispatch( setActive( resp.data ));
            navigate(`/users/${ id }`)
            dispatch(finishLoading());
            
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error')
            .then( () => {
                navigate('/users')
            }).finally( () =>{
                dispatch(finishLoading())
            })
        })
    }
}


export const setActiveUserState = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/usuarios/${ id }?filter[include][]=Rol`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            }
        })
        .then( ( resp ) => {
            dispatch( setActive( resp.data ));
            
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error')
        })
        .finally( () => {
            dispatch(finishLoading())
        }
        )
    }
}


export const setActive = ( user ) => ({
    type: types.userActive,
    payload: user,
})



export const setCurrentUserState = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/usuarios/${ id }?filter[include][]=Rol`, {
            headers: {
                Authorization: `Bearer ${ token }`,
            }
        })
        .then( ( resp ) => {
            if( resp.status === 204){
                dispatch(finishLoading())
                return;
            }
            dispatch( setCurrentUser( resp.data ));
            dispatch(finishLoading());
            
        })
        .catch( e => {
            /*Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error')
            .then( () => {
                //navigate('/users')
            }).finally( () =>{
                dispatch(finishLoading())
            })*/
            dispatch(finishLoading());
        })
    }
}
 

export const setCurrentUser = ( user ) => ({
    type: types.currentUser,
    payload: user,
})




export const startLoadingUsers = () => {
    return async(dispatch) => {
        dispatch(startLoading())
        const users = await loadUsers();
        dispatch(setUsers(users));
        setTimeout( () => {
            dispatch(finishLoading())
        }, 2000)
        
    }
}

export const startLoadingAllUsers = () => {
    return async(dispatch) => {
        const { token } = loadJWT();
        dispatch(startLoading())
        // EXCLUIMOS LOS USUARIOS VENDEDORES DE AGENCIA (SE GESTIONAN DESDE LA FICHA DE LA AGENCIA)
        await url.get('/usuarios?filter={"include": [{"relation":"Rol"}], "where": {"EsVendedor": {"eq": 0}}}', {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( ( resp ) => {
            dispatch(setUsers(resp.data));
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error')
        })
        .finally( () => {
            dispatch(finishLoading())
        })
        
        
    }
}

export const startLoadingRols = () => {
    return async( dispatch ) => {
        const rols = await loadRols();
        dispatch( setRoles( rols ));
    }
}

export const startLogOutUsers = () => {
    return async( dispatch ) => {
        dispatch(logOutUsers());
    }
}



export const setRoles = ( roles ) => ({
    type: types.rolesLoad,
    payload: roles
})
export const setUsers = (users) => ({
    type: types.usersLoad,
    payload: users,
})

export const userLogout = () => ({
    type: types.usersLogOutCleaning
})

export const deleteUser = (id) =>({
    type: types.usersDelete,
    payload: id
})
 
const aceptaPoliticas = () =>({
    type: types.userAcceptPoliticas,
})
const aceptarContratoTratamientoDatos = () =>({
    type: types.userAcceptContratoTratamientoDatos,
})

const changePassword = () => ({
    type: types.userChangePassword,
})

const logOutUsers = () => ({
    type: types.usersLogOutCleaning
})


