import Swal from 'sweetalert2';
import { url } from '../api/api';
import { loadJWT } from './loadJWT';




export const loadSellersAgency = async( id ) => {
    let vendedores = [];
    const { token } = loadJWT();
    await url.get(`/usuarios?filter={"where": {"FechaBaja": null, "FkUsuarios_Agencia": {"eq": ${id}}}}`, {
        headers:{
            Authorization: `Bearer ${ token }`
        }
    })
    .then( response => {
        if( !response?.data){
            return null;
        }
        vendedores = response.data;
        console.log( 'vendedores' );
        console.log( vendedores );
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
    })
    return vendedores;

}