
import * as yup from 'yup'

export const validationSchemaUser = () =>{

    

    return yup.object({
        name: yup
            .string("El nombre es obligatorio")
            .min(3,"El nombre debe tener un mínimo de 3 caracteres")
            .required('El nombre es obligatorio'),
        surname: yup
            .string("Los apellidos son obligatorios")
            .min(3,'Los apellidos deben tener un mínimo de 3 caracteres')
            .optional('Los apellidos son obligatorios'),
        email: yup
          .string("El email es obligatorio")
          .email("El email no es válido")
          .required("El email es obligatorio"),
        telefono: yup
            .string('El teléfono es obligatorio')
            .required('El teléfono es obligatorio'),
        date: yup
            .date().nullable(),
        password: yup
            .string('La contraseña es obligatoria')
            .required('La contraseña es obligatoria')
            .min(8, 'La contraseña debe tener un mínimo de 8 caracteres'),
        rol: yup
            .number()
            .required('El rol es requerido'),
        tipoUsuario: yup.number().required(),
        razonSocial: yup.string().optional(),
    
    });
}


export const validationSchemaUserEdit = () => {
    return yup.object({
        name: yup
            .string("El nombre es obligatorio")
            .min(3,"El nombre debe tener un mínimo de 3 caracteres")
            .required('El nombre es obligatorio'),
        /*surname: yup
            .string("Los apellidos son obligatorios")
            .min(3,'Los apellidos deben tener un mínimo de 3 caracteres')
            .optional(),*/
        email: yup
          .string("El email es obligatorio")
          .email("El email no es válido")
          .required("El email es obligatorio"),
        telefono: yup
            .string('El teléfono es obligatorio')
            .required('El teléfono es obligatorio'),
        date: yup
            .date().nullable(),
        razonSocial: yup.string().optional(),
    
    });
}


export const validationSchemaSupplier = () => {
    return yup.object({
        Nombre: yup
            .string("El nombre es obligatorio")
            .min(3,"El nombre debe tener un mínimo de 3 caracteres")
            .required('El nombre es obligatorio'),
        Email: yup
          .string("El email es obligatorio")
          .email("El email no es válido")
          .required("El email es obligatorio"),
        Telefono: yup.number().optional().positive().integer('Debe ser un entero'),
        date: yup.date().nullable(),
        PaginaWeb: yup.string().optional(),
        /*nif: yup.string().required('El NIF es requerido'),
        codigo: yup.string().required('El código es requerido'),
        ciudad: yup.string().required('La ciudad es requerida'),
        localizacion: yup.string().required('La localización es requerida')*/
    })
}


export const validationSchemaExchangeRate = () => {
    return yup.object({
        proveedor: yup
            .string('Seleccione el proveedor')
            .required('Seleccione el proveedor'),
        tipo_servicio: yup
            .string().required('Seleccione el tipo de servicio'),
        cambioEuros: yup
            .number().required('Seleccione el cambio en euros'),
        cambioDolares: yup
            .number().required('Seleccione el cambio en dólares'),

    })
}


export const validationSchemaSettings = () => {
    return yup.object({
        NombreEmpresa: yup.string('Indique el nombre de la empresa').required('Indique el nombre de la empresa'),
        RazonSocial: yup.string('La razón social es requerida').required('La razón social es requerida'),
    })
}

export const validationSchemaProfile = () => {
    return yup.object({
        name: yup.string('El nombre es obligatorio').required('El nombre es obligatorio'),
        surname: yup.string('Los apellidos son obligatorios').required('Los apellidos obligatorios'),
        email: yup.string('El email es obligatorio').required('El email es obligatorio'),
        phone: yup.string('El teléfono es obligatorio').required('El teléfono es obligatorio'),
    })
}


export const validationSchemaSupplierService = () => {
    return yup.object({
        proveedor: yup.string("Seleccione el proveedor")
        .required('Seleccione el proveedor'),
        tipo_servicio: yup.string("Seleccione el tipo de servicio")
        .required('Seleccione el tipo de servicio'),
        Nombre: yup
            .string("Indique el nombre del servicio")
            .min(3,"El nombre del servicio debe tener un mínimo de 3 caracteres")
            .required('Indique el nombre del servicio'),
        CosteDolares: yup.number('El coste debe ser un valor numérico').required('El coste en dólares es obligatorio'),
        PrecioVentaEuros: yup.number('El precio de venta debe ser un valor numérico').required('El precio de venta en euros es obligatorio'),
        PaginaWeb: yup.string().optional(),
        Observaciones: yup.string().optional(),
    
    });
}

export const validationSchemaSupplierServiceEdit = () => {
    return yup.object({
        FkProveedores: yup.string("Seleccione el proveedor")
        .required('Seleccione el proveedor'),
        FkTiposServicio: yup.string("Seleccione el tipo de servicio")
        .required('Seleccione el tipo de servicio'),
        Nombre: yup
            .string("Seleccione el nombre de servicio")
            .min(3,"El nombre del servicio debe tener un mínimo de 3 caracteres")
            .required('Seleccione el nombre de servicio'),
        CosteDolares: yup.number('El coste en dólares debe ser un valor numérico').required('El coste en dólares es obligatorio'),
        PrecioVentaEuros: yup.number('El precio de venta en euros debe ser un valor numérico').required('El precio de venta en euros es obligatorio'),
        PaginaWeb: yup.string().optional(),
        Observaciones: yup.string().optional(),
    
    });
}




export const validationSchemaEditResellerService = () => {
    return yup.object({
        servicio: yup.number("Seleccione el servicio").required('Seleccione el servicio'),
        precioVenta: yup.number().required('Precio venta es obligatorio'),
        moneda: yup.string().required('Moneda es obligatorio'),
        //importeReserva: yup.number().moreThan(0, 'El importe de reserva debe ser positivo').required('reserva es obligatorio'),
       // observaciones: yup.string().optional(),
    })
}

export const validationSchemaSupplpierToCoordinator = () => {
    return yup.object({
        proveedor: yup.string().required(),
    })
}

export const validationSchemaCustomer = () => {
    return yup.object({
        Nombre: yup.string('El nombre es obligatorio').required('El nombre es obligatorio'),
        Apellidos: yup.string('Los apellidos son obligatorios').required('Los apellidos son obligatorios'),
        Email: yup.string().email('Formato de email incorrecto').notRequired().nullable(),
        Telefono: yup.string('El teléfono es obligatorio').required('El teléfono es obligatorio')
            .matches(/^\+/, "El teléfono debe comenzar por + (prefijo internacional)"),
        NumeroIdentificacionFiscal: yup.string().notRequired().nullable(),
        Pais: yup.string().notRequired().nullable(),
        Provincia: yup.string().notRequired().nullable(),
        Localidad: yup.string().notRequired().nullable(),
        CodigoPostal: yup.number('Código postal requerido').nullable(),
        Direccion: yup.string().notRequired().nullable(),
        date: yup.date().notRequired().nullable(),
        Observaciones: yup.string().notRequired().nullable(),
    })
}


export const validationSchemaComisionista = () => {
    return yup.object({
        Nombre: yup.string('El nombre es obligatorio').required('El nombre es obligatorio'),
        Email: yup.string().email('Formato de email incorrecto').notRequired().nullable(),
        Telefono: yup.string().notRequired().nullable(),
        Observaciones: yup.string().notRequired().nullable(),
    })
}

export const validationSchemaLines = () => {
    return yup.object({
        Concepto: yup.string('El concepto es obligatorio').required('El concepto es obligatorio'),
        ReservaLineaEuros: yup.number('La reserva es obligatoria').required('Reserva es obligatoria'),
        ReventaLineaEuros: yup.number('La reventa es obligatoria').required('La reventa es obligatoria'),
        InSituLineaEuros: yup.number('El pago en situ es obligatorio').required('El pago en situ es obligatorio'),
        Unidades: yup.number('Indique el número de personas').required('Indique el número de personas'),
        ConfirmadaProveedor: yup.boolean('Confirmada proveedor es requerido').required('Confirmada proveedor es requerido'),
        CodigoReservaProveedor: yup.string('Código proveedor es requerido').required('Código proveedor es requerido'),
        FechaRecogida: yup.date('Fecha recogida es requerido').required('Fecha recogida es requerido'),
        Observaciones: yup.string().optional(),
        ObservacionesRecogida: yup.string().optional(),


    })
}

export const validationSchemaOrder = () => {
    return yup.object({
        FechaVenta: yup.date('Fecha venta es requerido').nullable(),
        HotelCliente: yup.string('Hotel cliente es requerido').optional('Hotel cliente es requerido'),
        PorcDescuento: yup.number('Porcentaje de descuento es requerido').optional('Porcentaje de descuento es requerido'),
        PresupuestoEnviado: yup.boolean('Es requerido indicar si se ha enviado el presupuesto').optional('Es requerido indicar si se ha enviado el presupuesto'),
        FechaEnvioPresupuesto: yup.date('Formato invalido').nullable(),
        ReservaPagada: yup.boolean('Es requerido indicar si se ha pagado la reserva').optional('Es requerido indicar si se ha pagado la reserva'),
        FechaPagoReserva:  yup.date('Fecha pago es requerida').nullable(),
        VoucherEnviado: yup.boolean('Es requerido indicar si se ha enviado el voucher').optional('Es requerido indicar si se ha enviado el voucher'),
        FechaEnvioVoucher: yup.date('Fecha envío voucher es requerida').nullable(),
        FechaFactura: yup.date('Fecha factura es requerido').nullable(),
        ImporteFactura: yup.number('Importe factura es requerido').optional('Importe factura es requerido'),
        Observaciones: yup.string().nullable(),
    })
}


export const validationSchemaBudget = () => {
    return yup.object({
        FechaPresupuesto: yup.date('Fecha presupuesto es requerido').required('Fecha presupuesto es requerido'),
        FechaLimite: yup.date('Fecha limite es requerido').required('Fecha limite es requerido').min(yup.ref('FechaPresupuesto'), "Fecha límite debe ser mayor que la fecha de presupuesto"),
        HotelCliente: yup.string().optional(), //('Hotel cliente es requerido').required('Hotel cliente es requerido'),
        Observaciones: yup.string().optional(),
        Cliente: yup.number().required('Cliente es requerido'),
        Idioma: yup.string().optional(),
        Moneda: yup.string().optional(),
        
    })
}

export const validationSchemaConfirmLine = () => {
    return yup.object({
        FechaRecogida: yup.date('Fecha recogida es requerido').required('Debe indicar la fecha de la actividad'), //.optional().nullable(),
        CodigoReservaProveedor: yup.string().nullable(),
        ObservacionesRecogida: yup.string().nullable(),
        
        
    })
}

export const validationSchemaEditLine = () => {
    return yup.object({
        FkProveedores: yup.number().required('Proveedor es requerido'),
        FkServiciosProveedores: yup.number().required('Servicio es requerido'),
        ReservaLineaEuros: yup.number().required('Reserva es requerida'),
        InSituLineaDolares: yup.number().required('Coste es requerido'),
        Unidades: yup.number().required('Personas es requerido'),
        Concepto: yup.string().optional(),
        //Observaciones: yup.string().optional(),
        FechaRecogida: yup.date().nullable(),
        PorcDescuento: yup.number('Debe ser un entero').integer('Debe ser un entero').min(0, 'El descuento no puede ser negativo').max(100, 'El descuento no puede ser superior al 100%').optional(),
    })
}

export const validationSchemaEditLineReseller = () => {
    return yup.object({
        FkProveedores: yup.number().required('Proveedor es requerido'),
        FkServiciosProveedores: yup.number().required('Servicio es requerido'),
        ReventaLineaEuros: yup
                            .number()
                            .required('El importe de reventa es requerido')
                            .min(0,'El margen no puede ser negativo'),
        Unidades: yup.number().required('Personas es requerido'),
        Concepto: yup.string().optional(),
        //Observaciones: yup.string().optional(),
        FechaRecogida: yup.date().nullable(),
        //PorcDescuento: yup.number('Debe ser un entero').integer('Debe ser un entero').min(0, 'El descuento no puede ser negativo').max(100, 'El descuento no puede ser superior al 100%').optional(),
    })
}


export const validationSchemaEditFreeLine = () => {
    return yup.object({
        FkProveedores: yup.number().required('Proveedor es requerido'),
        FkTipoServicio: yup.number().required('Tipo de servicio es requerido'),
        ReservaLineaEuros: yup.number().required('Reserva es requerida'),
        InSituLineaDolares: yup.number().required('Coste es requerido'),
        Unidades: yup.number().required('Personas es requerido'),
        Concepto: yup.string().required(),
        Observaciones: yup.string().optional(),
    })
}