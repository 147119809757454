import { Container, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import backImage from '../../images/back-login.png';

import { useLoginStyles } from '../../styles/loginStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import showPassword from '../../images/show-password.svg';
import hidePassword from '../../images/hide-password.svg';
import {  ExitToAppOutlined, VpnKey } from '@material-ui/icons'
import * as yup from 'yup';
import { useFormik } from "formik";
import { startLogOut } from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { loadGridChangePassword } from '../../helpers/loadGrid';
import { startChangePassword } from '../../redux/actions/users';

const ChangePassword = () => {

    const classes = useLoginStyles();
    const [visible, setVisible] = useState(false);
    const [visiblePC, setVisiblePC] = useState(false);
    const dispatch = useDispatch();
    const { data } = loadGridChangePassword();
    const { id: idLoggedIn } = useSelector(state => state.auth);

    const validationSchema = yup.object().shape({
        password: yup.string().required("La contraseña es requerida").min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres'),
        passwordConfirm: yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf(
            [yup.ref("password")],
            "Ambas contraseñas deben ser iguales"
            )
        }).required('La contraseña es requerida').min(8, 'La contraseña debe tener una longitud mínima de 8 caracteres'),
      });

      const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            const user = {
                id: idLoggedIn,
                password: values.password,
                passwordConfirm: values.passwordConfirm,
            }

           dispatch( startChangePassword( user ) );

        }
    });

    const handleLogout = () => {    
        dispatch(startLogOut()); // we clean the auth state
    }

    return (
        <>
            <div style={{ marginTop: '5rem'}}/>
            <Helmet>
                <title>Cambio Contraseña | Xplora</title>
            </Helmet>
                <IconButton
                     color="inherit"
                     size="large"
                     onClick={ handleLogout }
                     style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        marginTop: '1rem',
                        marginRight: '1rem',
                        zIndex: 999,
                    }}
                >
                    <ExitToAppOutlined />
                </IconButton>
            <div
                style={{
                    backgroundImage: `url(${ backImage }) `,
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
            <Box
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    borderRadius: 4,
                    height: '35vh',
                    width: '40vw',
                    paddingTop: '1rem',
                    minWidth: 330.4,
                    maxWidth: 700,
                    maxHeight: 400,
                    minHeight: 300,
                }}

            >
                <Container maxWidth="sm">
                    
                    <form onSubmit={formik.handleSubmit}>
                        {
                            data.map( element => {
                                return(
                                    <TextField
                                        fullWidth
                                        label={ element.label }
                                        margin="normal"
                                        name={ element.name }
                                        type={ element.name === 'password' ? (visible ? 'text' : 'password') : ( visiblePC ? 'text' : 'password') }
                                        variant="outlined"
                                        value={formik.values[element.name]}
                                        onBlur={ formik.handleBlur }
                                        onChange={formik.handleChange}
                                        error={formik.touched[element.name] && Boolean(formik.errors[element.name])}
                                        helperText={formik.touched[element.name] && formik.errors[element.name]}
                                        disabled={ formik.isSubmitting }
                                        InputProps={{
                                            startAdornment:(
                                                <InputAdornment position='start'>
                                                    <VpnKey />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                element.name === 'password'
                                                ?
                                                <InputAdornment position='end' onClick={ () => setVisible(!visible)}>
                                                    <img 
                                                        src={ visible ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>
                                                :
                                                <InputAdornment position='end' onClick={ () => setVisiblePC(!visiblePC)}>
                                                    <img 
                                                        src={ visiblePC ? showPassword : hidePassword}
                                                        alt='eye-password'
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                    />
                                )
                            })
                        }
                        
                        <Box sx={{ display: 'flex' }}>
                        <Typography variant='h3'>
                            Por políticas de la empresa es necesario cambiar la contraseña en el primer inicio de sesión
                        </Typography>
                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            type='submit'
                            loading={ formik.isSubmitting }
                            disabled={ !formik.isValid }
                            style={{
                                backgroundColor: '#E6824F',
                                color: '#fff',
                                borderRadius: 7
                            }}
                        >
                            Enviar
                        </LoadingButton>
                        </Box>
                    </form>
                </Container>
            </Box>
            </div>
        </>
    )
}


export default ChangePassword;