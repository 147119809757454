import React from 'react'
import { ContentModalClientAdd } from '../../components/clients/contentModalClientAdd';
import { ContentModalComisionistaAdd } from '../../components/comisionistas/contentModalComisionistaAdd';
import { ConfirmDeleteComisionista } from '../../components/notifications/ConfirmDeleteComisionista';
import { ConfirmDelete } from '../../components/notifications/ConfirmDelete';
import { ConfirmDeleteClient } from '../../components/notifications/ConfirmDeleteClient';
import { ConfirmDeleteSupplier } from '../../components/notifications/ConfirmDeleteSupplier';
import { ContentModalEditSettings } from '../../components/settings/contentModalEditSettings';
import { ContentModalSupplierAdd } from '../../components/suppliers/contentModalSupplierAdd';
import { ContentModalSupplierDetail } from '../../components/suppliers/contentModalSupplierDetail';
import { ContentModalSupplierEdit } from '../../components/suppliers/contentModalSupplierEdit';
import { ContentModalUserAdd } from '../../components/users/contentModalUserAdd';
import { ContentModalUserDetail } from '../../components/users/contentModalUserDetail';
import { ContentModalUserEdit } from '../../components/users/contentModalUserEdit';
import { ContentModalExchangeRateAdd } from '../../components/exchange-rate/contentModalExchangeRateAdd';

import { types } from '../types/types';
import { ConfirmDeleteExchangeRate } from '../../components/notifications/ConfirmDeleteExchangeRate';
import { EditorConvert } from '../../components/ui/WSIG';
import { ContentModalServiceSupplierAdd } from '../../components/services/contentModalServiceSupplierAdd';
import { ContentEditService } from '../../components/services/resellers/contentEditService';
import { ContentAddService } from '../../components/services/resellers/contentAddService';
import { ContentModalAddSupplierToCoordinator } from '../../components/suppliers/coordinators/contentModalAddSupplierToCoordinator';
import { ConfirmDeleteServicesSupplier } from '../../components/notifications/ConfirmDeleteServicesSupplier';
import { ConfirmDeleteServicesReseller } from '../../components/notifications/ConfirmDeleteServicesReseller';
import { ConfirmDeleteSupplierCoordinator } from '../../components/notifications/ConfirmDeleteSupplierCoordinator';
import { ContentModalEditSupplierToCoordinator } from '../../components/suppliers/coordinators/contentModalEditSupplierToCoordinator'
import { ContentModalAddOrderLine } from '../../components/invoices/lines/contentModalAddOrderLine';
import { ContentModalEditOrderLine } from '../../components/invoices/lines/contentModalEditOrderLine';
import { ContentModalAddFreeOrderLine } from '../../components/invoices/lines/freeOrderLines/contentModalAddFreeOrderLine';
import { ConfirmAddInvoice } from '../../components/notifications/ConfirmAddInvoice';
import { ConfirmDeleteLineFromInvoice } from '../../components/notifications/ConfirmDeleteLineFromInvoice';
import { AccordionInforLine } from '../../components/lines/AccordionInforLine';
import { ConfirmSend } from '../../components/notifications/ConfirmSendEmail';
import { ConfirmSendVoucher } from '../../components/notifications/ConfirmSendEmailVoucher';

import { ContentModalEditLineState } from '../../components/invoices/lines/contentModalEditLineState';
import { ContentModalEditStateFreeLine } from '../../components/invoices/lines/freeOrderLines/contentModalEditStateLine';
import { ConfirmDownload } from '../../components/notifications/ConfirmDownload';
import { ConfirmDeleteInvoice } from '../../components/notifications/ConfirmDeleteInvoice';
import { ContentModalEditOrderLineReseller } from '../../components/invoices/lines/contentModalEditOrderLineReseller';
import { ContentModalSupplerServiceEdit } from '../../components/services/contentModalSupplerServiceEdit';
import { ConfirmDownloadVoucher } from '../../components/notifications/ConfirmDownloadVoucher';
import { ConfirmFacturar } from '../../components/notifications/ConfirmFacturar';
import { ContentAddSellerAgency } from '../../components/services/resellers/contentAddSellerAgency';
import { ConfirmDeleteSellerAgency } from '../../components/notifications/ConfirmDeleteSellerAgency';
import { ContentEditVendedorAgencia } from '../../components/services/resellers/ContentEditVendedorAgencia';


const initialState = {
    content: null
}
export const contentModalReducer = (state= initialState, action) => {
    switch (action.type) {
        case types.userAddContentModal:
            return {
                ...state,
                content: (<ContentModalUserAdd />)
            }
        case types.userEditContentModal:
            return {
                ...state,
                content: (<ContentModalUserEdit />)
            }
        case types.userDetailContentModal:
            return {
                ...state,
                content: (<ContentModalUserDetail />)
            }
        case types.uiNotificationDeleteModal:
            return {
                ...state,
                content: (<ConfirmDelete />)
            }
        case types.supplierAddContentModal:
            return {
                ...state,
                content: (<ContentModalSupplierAdd />)
            }
        case types.supplierEditContentModal:
            return {
                ...state,
                content: (<ContentModalSupplierEdit />)
            }
        case types.supplierDetailContentModal: 
            return {
                ...state,
                content: (<ContentModalSupplierDetail />)
            } 


        case types.uiNotificationDeleteModalSupplier:
            return {
                ...state,
                content: (<ConfirmDeleteSupplier />)
            }
            
        case types.vendedorAgenciaDeleteContentModal:
            return {
                ...state,
                content: (<ConfirmDeleteSellerAgency />)
            }

            
    
        case types.vendedorAgenciaEditContentModal:
            return {
                ...state,
                content: <ContentEditVendedorAgencia />
            }

        
        case types.comisionistaAddContentModal:
            return {
                ...state,
                content: (<ContentModalComisionistaAdd simple={action.payload} />)
            }
            
        case types.uiNotificationDeleteModalComisionista:
            return {
                ...state,
                content: (<ConfirmDeleteComisionista />)
            }
        
        case types.clientAddContentModal:
            return {
                ...state,
                content: (<ContentModalClientAdd simple={action.payload} />)
            }
            
        case types.uiNotificationDeleteModalClient:
            return {
                ...state,
                content: (<ConfirmDeleteClient />)
            }
        case types.settingsEditContentModal:
            return {
                ...state,
                content: (<ContentModalEditSettings />)
            }
        case types.exchangeRateAddContentModal:
            return {
                ...state,
                content: (<ContentModalExchangeRateAdd />)
            }
        case types.uiNotificationDeleteExchangeRate:
            return {
                ...state,
                content: (<ConfirmDeleteExchangeRate />)
            }
        
        case types.serviceSupplierAddContentModal:
            return {
                ...state,
                content: (<ContentModalServiceSupplierAdd />)
            }
        
        case types.serviceSupplierDeleteContentModal:
            return {
                ...state,
                content: (<ConfirmDeleteServicesSupplier />)
            }
        case types.serviceSupplierEditContentModal:
            return {
                ...state,
                content: (<ContentModalSupplerServiceEdit />)
            }
        
        case types.serviceResellerAddContentModal:
            return {
                ...state,
                content: <ContentAddService />
            }
            


            

        case types.agencySellerAddContentModal:
            return {
                ...state,
                content: <ContentAddSellerAgency />
            }

        


        case types.serviceResellerEditContentModal:
            return {
                ...state,
                content: <ContentEditService />
            }
        case types.serviceResellerDeleteContentModal:
            return {
                ...state,
                content: (<ConfirmDeleteServicesReseller />)
            }
        case types.supplierCoordinatorAddContentModal:
            return {
                ...state,
                content: <ContentModalAddSupplierToCoordinator />
            }
        case types.supplierCoordinatorDeleteContentModal:
            return {
                ...state,
                content: (<ConfirmDeleteSupplierCoordinator />)
            }
        case types.supplierCoordinatorEditContentModal:
            return {
                ...state,
                content: (<ContentModalEditSupplierToCoordinator />)
            }
        case types.orderLineAddContentModal:
            return {
                ...state,
                content: (<ContentModalAddOrderLine />)
            }
        case types.orderLineEditContentModal:
            return {
                ...state,
                content: (<ContentModalEditOrderLine />)
            }
        case types.uiNotificationDeleteLineFromInvoice:
            return {
                ...state,
                content: (<ConfirmDeleteLineFromInvoice />)
            }
        case types.freeOrderLineAddContentModal:
            return {
                ...state,
                content: (<ContentModalAddFreeOrderLine />)
            }
        case types.lineDetailContentModal:
            return {
                ...state,
                content: (<AccordionInforLine />)
            }
        case types.settingsEditVoucher:
            return {
                ...state,
                content: (<EditorConvert text={ action.payload.texto } field={ action.payload.field }/>)
            }
        case types.invoiceAddContentModalConfirm:
            return {
                ...state,
                content: (<ConfirmAddInvoice payload={ action.payload } setSubmitting={ action.setSubmitting}/>)
            }

        case types.uiNotificationSendEmailModal:
            return {
                ...state,
                content: (<ConfirmSend action={ action.payload } />)
            }

            case types.uiNotificationSendEmailModalVoucher:
                return {
                    ...state,
                    content: (<ConfirmSendVoucher action={ action.payload } />)
                }
        
        case types.orderLineEditStateContentModal:
            return {
                ...state,
                content: (<ContentModalEditLineState />)
            }
        case types.orderLineEditResellerContentModal:
            return {
                ...state,
                content: (<AccordionInforLine />)
            }
        case types.orderFreeLineStateContentModal:
            return {
                ...state,
                content: (<ContentModalEditStateFreeLine />)
            }
        case types.pdfContentInvoice:
            return {
                ...state,
                content: (<ConfirmDownload />)
            }
        case types.invoiceDeleteContentModal:
            return {
                ...state,
                content: (<ConfirmDeleteInvoice />)
            }
        case types.lineContentGetVoucher:
            return {
                ...state,
                content: (<ConfirmDownloadVoucher />)
            }
        case types.uiFacturarContentModal:
            return {
                ...state,
                content: (<ConfirmFacturar />)
            }
        case types.logOutContentModal:
            return initialState;
        default:
            return state;
    }
}
