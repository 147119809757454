import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Field, Form, Formik } from 'formik'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormStyles } from '../../../../styles/formStyles'
import { validationSchemaEditFreeLine } from '../../../../utils/schemaValidationForm'
import Autocomplete from '@mui/material/Autocomplete';
import { Euro, EuroOutlined, Info, MonetizationOn, People, RemoveRedEye } from '@material-ui/icons'
import { setActiveExchangeRateToSupplier, startLogOutActiveRate } from '../../../../redux/actions/exchangeRate'
import Alert from '@mui/material/Alert';
import { startLogOutActiveLineEdit, startUpdateLineActive } from '../../../../redux/actions/lines'
import { FormikDatePicker } from '../../../ui/FormikDatePicker'
import esLocale from 'date-fns/locale/es'
import { startLogOutContentModal } from '../../../../redux/actions/contentModal'
import { uiManageModalCollectionTypes } from '../../../../redux/actions/ui'
import { calculateFromDollarsToEuro } from '../../../../utils/utils'
import { obtenerPrecioDolares, obtenerPrecioEuros } from '../../../../utils/exchange'



const isEmpty = (obj) => {
    return Object.keys( obj || {} ).length === 0;
}

export const ContentModalEditFreeOrderLine = () => {
    const { active: activeOrderLine }  = useSelector(state => state.lines);
    const { activeEdit } = useSelector(state => state.invoice)
    const refServiceType = useRef();
    const classes = useFormStyles(); //the classes variable to handle the form styles
    const [suppliers, setSuppliers] = useState([]);
    const [valueSupplier, setValueSupplier] = useState(activeOrderLine?.activeLine?.Proveedor?.PkProveedores);
    const [typeService, setTypeService] = useState(activeOrderLine?.activeLine?.FkTiposServicio);
    const dispatch = useDispatch();
    const { active: activeRate } = useSelector(state => state.exchangeRate)




    useEffect(() => {
        setSuppliers([])
        activeOrderLine.suppliers.map( supplier => {
            setSuppliers( prev => prev.concat({label: supplier?.Nombre, value: supplier?.PkProveedores }))
            return null;
        })
        if(activeOrderLine){
            dispatch(setActiveExchangeRateToSupplier(activeOrderLine?.activeLine?.Proveedor?.PkProveedores, activeOrderLine?.activeLine?.FkTiposServicio))
        }
    }, [ dispatch ]);

    useEffect(() => {
        if( valueSupplier && typeService ) {
            dispatch(setActiveExchangeRateToSupplier( valueSupplier, typeService ))

        }
    }, [ valueSupplier, typeService, dispatch ])
    
    const initialValues = {
        FkProveedores: activeOrderLine?.activeLine?.Proveedor?.PkProveedores,
        FkTipoServicio: activeOrderLine?.activeLine?.FkTiposServicio,
        ReservaLineaEuros: activeOrderLine?.activeLine?.ReservaLineaEuros,
        
        InSituLineaDolares: activeOrderLine?.activeLine?.InSituLineaDolares,
        Unidades: activeOrderLine?.activeLine?.Unidades,
        Concepto: activeOrderLine?.activeLine?.Concepto,
        Observaciones: activeOrderLine?.activeLine?.Observaciones,
        FechaRecogida: activeOrderLine?.activeLine?.FechaRecogida,
        TasaDolares: activeOrderLine?.activeLine?.TasaDolares,
        SuplementoTransporteDolares: activeOrderLine?.activeLine?.SuplementoTransporteDolares,
    }

    const handleSubmit = ( values, {setSubmmiting} ) => {
        const payload = {
            "Concepto": values.Concepto,
            "CosteDolares":  +values.InSituLineaDolares,
            "ReservaEuros": +values.ReservaLineaEuros,
            "InSituLineaDolares": +values.InSituLineaDolares,
            "ReservaLineaEuros": +values.ReservaLineaEuros,
            "Unidades": +values.Unidades,
            "Observaciones": values.Observaciones??'',
            "FkTiposCambioProveedores": activeRate.PkTiposCambioProveedores,
            "FkProveedores": valueSupplier,
            "FkTiposServicio": values.FkTipoServicio,
            "FkVentas": activeEdit.PkVentas,
            "PkLineasVenta": activeOrderLine?.activeLine?.PkLineasVenta,
            "VersionFila": activeOrderLine?.activeLine?.VersionFila,
            "FechaRecogida": values?.FechaRecogida,
            "TasaDolares": values?.TasaDolares,
            "SuplementoTransporteDolares": +values.SuplementoTransporteDolares,
        }

        dispatch(startUpdateLineActive( payload ));
    }

    



    return (
            <Fragment>
             <Formik enableReinitialize initialValues={ initialValues } validationSchema={ validationSchemaEditFreeLine } onSubmit={ handleSubmit }>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, values, setFieldValue }) => (
                <Form>
                    <Grid item style={{padding: '2rem'}}>
                        <Grid 
                            container
                            spacing={3}
                        >
                            <Grid xs={12} item classes={{root: classes.container }}>
                                <Typography variant='h3'>Editar Línea de Venta</Typography>
                            </Grid>

                            { 
                                isEmpty(activeRate) && values.FkProveedores && values.FkTipoServicio && (<Alert sx={{ marginBottom: '2rem' }} variant="outlined" severity="error">
                                                    Este proveedor no tiene tipo de cambio para el tipo de servicio seleccionado
                                                </Alert>
                                )
                            }

                            <Grid xs={12} item  classes={{root: classes.container }}>
                                <Autocomplete style={{fontSize: '0.8em'}}
                                    options={suppliers}
                                    noOptionsText={"No hay proveedores"}
                                    getOptionLabel={option => option.label}
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    renderInput={(params) => ( 
                                        <TextField 
                                            {...params}
                                            name='FkProveedores' 
                                            variant='outlined' 
                                            label="Proveedor" 
                                            placeholder='Busca...'
                                            disabled={ isSubmitting }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched.FkProveedores && Boolean(errors.FkProveedores)}
                                            helperText={touched.FkProveedores && errors.FkProveedores}
                                            

                                        />
                                    )}
                                    name="FkProveedores"
                                    defaultValue={{label: activeOrderLine?.activeLine?.Proveedor?.Nombre, value: activeOrderLine?.activeLine?.Proveedor?.PkProveedores }}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                        "FkProveedores",
                                        value?.value !== null ? value?.value : values.FkProveedores
                                        );
                                        if(!value?.value){
                                            Object.entries(values).map(([key, value]) => {
                                                setFieldValue(key, "")
                                                return null;
                                            })
                                            if( values.FkTipoServicio ){
                                                refServiceType.current.getElementsByClassName('MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input').value = '';

                                            }
                                            dispatch(startLogOutActiveLineEdit())
                                            dispatch(startLogOutActiveRate())

                                        }
                                        setValueSupplier(value?.value)

                                    }}
                                />
                            </Grid>
                            {
                                values.FkProveedores
                                &&
                                <Grid xs={12} item  classes={{root: classes.container }}>
                                <FormControl  
                                    style={{
                                        width: '100%',
                                        fontSize: '0.8em'
                                    }}
                                    disabled={ isSubmitting }
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.FkTipoServicio && Boolean(errors.FkTipoServicio)}
                                    helperText={touched.FkTipoServicio && errors.FkTipoServicio}
                                                
                                >
                                    <InputLabel id="select-label-type-services">Tipo Servicio</InputLabel>
                                    <Select
                                        ref={ refServiceType }
                                        name='FkTipoServicio'
                                        displayEmpty
                                        defaultValue={values.FkTipoServicio}
                                        onChange={e => {
                                            setFieldValue("FkTipoServicio", e.target.value);
                                            setTypeService(e.target.value);
                                        }}
                                        variant='outlined'
                                    >
                                        <MenuItem value={2}>Parque</MenuItem>
                                        <MenuItem value={1}>Tour</MenuItem>

                                    </Select>
                                </FormControl>
                                </Grid>
                            }

                            <Grid xs={12} item  classes={{root: classes.container }}>
                                <TextField 
                                    fullWidth   
                                    label='Concepto'
                                    size='small'
                                    variant='outlined'
                                    name='Concepto'
                                    value={ values.Concepto }
                                    classes={{ root: classes.inputText }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Info />
                                            </InputAdornment>
                                        ),

                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    InputLabelProps={
                                        {style: {fontSize: '0.8em'}}
                                    }
                                    onBlur={ handleBlur }
                                    onChange={handleChange}
                                    error={touched.Concepto && Boolean(errors.Concepto)}
                                    helperText={touched.Concepto && errors.Concepto}
                                    disabled={ isSubmitting }
                                />
                            </Grid>

                            {
                                values.FkProveedores && values.FkTipoServicio
                                &&
                                (
                                    <>
                                        <Grid xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth
                                                name='TasaDolares' 
                                                label='Tasa Dólares'
                                                size='small'
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.TasaDolares && Boolean(errors.TasaDolares)}
                                                helperText={touched.TasaDolares && errors.TasaDolares}
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ values.TasaDolares }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        <Grid xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth
                                                label='Tasa Euros'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ +calculateFromDollarsToEuro( activeRate, values.TasaDolares) }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Euro />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled
                                            />
                                        </Grid>
                                   
                                   
                                        <Grid xs={12} item  classes={{root: classes.container }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ esLocale }>
                                                <Field style={{fontSize: '0.8em'}} component={FormikDatePicker} textfield='FechaRecogida' name='FechaRecogida' label="Fecha Actividad" isSubmitting={ isSubmitting } value={ values?.FechaRecogida   }  />
                                                </LocalizationProvider>
                                                </Grid>

                                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Coste Línea'
                                                size='small'
                                                name='InSituLineaDolares'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.InSituLineaDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.InSituLineaDolares && Boolean(errors.InSituLineaDolares)}
                                                helperText={touched.InSituLineaDolares && errors.InSituLineaDolares}
                                                disabled={ isSubmitting }
                                            />
                                    </Grid>
                                        
                                    {
                                        values.InSituLineaDolares
                                        && !isEmpty( activeRate )
                                        &&
                                        (
                                            <>
                                                <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                    <TextField 
                                                        fullWidth   
                                                        label='Coste Línea'
                                                        size='small'
                                                        name='CosteEuros'
                                                        variant='outlined'
                                                        classes={{ root: classes.inputText }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EuroOutlined />
                                                                </InputAdornment>
                                                            ),

                                                            classes: {
                                                                input: classes.resize
                                                            }
                                                        }}
                                                        InputLabelProps={
                                                            {style: {fontSize: '0.8em'}}
                                                        }
                                                        value={ obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares ) || 0 }
                                                        disabled={ true }
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }

                                    <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Reserva Línea'
                                                name='ReservaLineaEuros'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ values.ReservaLineaEuros}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EuroOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.ReservaLineaEuros && Boolean(errors.ReservaLineaEuros)}
                                                helperText={touched.ReservaLineaEuros && errors.ReservaLineaEuros}
                                                disabled={ isSubmitting }
                                            />
                                     </Grid>

                                     {
                                         !isEmpty( activeRate ) &&
                                         values.InSituLineaDolares &&
                                         values.ReservaLineaEuros &&
                                         (
                                            <>
                                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Reserva Línea'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                value={ obtenerPrecioDolares(values.ReservaLineaEuros, activeRate?.CambioEuros, activeRate?.CambioDolares) || 0}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                disabled
                                            />
                                        </Grid>
                                            </>
                                         )
                                     }


                                        <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Personas'
                                                size='small'
                                                value={ values.Unidades }
                                                name='Unidades'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <People />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Unidades && Boolean(errors.Unidades)}
                                                helperText={touched.Unidades && errors.Unidades}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>

                                        <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Supl. Transp.'
                                                name='SuplementoTransporteDolares'
                                                size='small'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MonetizationOn />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ values.SuplementoTransporteDolares}
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.SuplementoTransporteDolares && Boolean(errors.SuplementoTransporteDolares)}
                                                helperText={touched.SuplementoTransporteDolares && errors.SuplementoTransporteDolares}
                                                disabled={ isSubmitting }
                                            />
                                        </Grid>
                                        
                                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Supl. Transp.'
                                                    size='small'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EuroOutlined />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    value={ +calculateFromDollarsToEuro( activeRate, values.SuplementoTransporteDolares??0) }
                                                    disabled
                                                />
                                            </Grid>

                                            
                                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Venta'
                                                size='small'
                                                name='venta'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EuroOutlined />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                value={ 
                                                    (obtenerPrecioEuros(values.InSituLineaDolares, activeRate?.CambioEuros, activeRate?.CambioDolares) + 
                                                    +values.ReservaLineaEuros) || 0}
                                                disabled={ true }
                                                />
                                            </Grid>

                                            <Grid md={3} xs={6} item  classes={{root: classes.container }}>
                                                <TextField 
                                                    fullWidth   
                                                    label='Venta'
                                                    size='small'
                                                    name='venta'
                                                    variant='outlined'
                                                    classes={{ root: classes.inputText }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <MonetizationOn />
                                                            </InputAdornment>
                                                        ),

                                                        classes: {
                                                            input: classes.resize
                                                        }
                                                    }}
                                                    InputLabelProps={
                                                        {style: {fontSize: '0.8em'}}
                                                    }
                                                    value={ (+values.InSituLineaDolares  +  
                                                        obtenerPrecioDolares(values.ReservaLineaEuros, activeRate?.CambioEuros, activeRate?.CambioDolares)) || 0}
                                                    disabled={ true }
                                                />
                                            </Grid>
                                        
                                        <Grid xs={12} item  classes={{root: classes.container }}>
                                            <TextField 
                                                fullWidth   
                                                label='Observaciones'
                                                size='small'
                                                value={ values.Observaciones }
                                                name='Observaciones'
                                                variant='outlined'
                                                classes={{ root: classes.inputText }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <RemoveRedEye />
                                                        </InputAdornment>
                                                    ),

                                                    classes: {
                                                        input: classes.resize
                                                    }
                                                }}
                                                InputLabelProps={
                                                    {style: {fontSize: '0.8em'}}
                                                }
                                                onBlur={ handleBlur }
                                                onChange={handleChange}
                                                error={touched.Observaciones && Boolean(errors.Observaciones)}
                                                helperText={touched.Observaciones && errors.Observaciones}
                                                disabled={ isSubmitting }
                                                multiline
                                                maxRows={ 10 }
                                                minRows={ 5 }
                                            />
                                        </Grid>
                                    </>
                                    
                                )
                            }
                            
                            
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <Button
                                    style={{ marginRight:'20px', backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} 
                                    onClick={ () => {
                                        dispatch(uiManageModalCollectionTypes());
                                        dispatch(startLogOutContentModal());
                                    } }>
                                    <Typography style={{fontWeight: 'bold'}} >Cancelar </Typography>
                                </Button>
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    type='submit'
                                    loading={ isSubmitting }
                                    disabled={ !isValid || isEmpty(activeRate) }
                                    style={{
                                        backgroundColor: '#E5824F',
                    
                                    }}
                                >
                                        Editar
                                </LoadingButton>

                            </Box>

                        </Grid>
                    </Grid>
            </Form>
            )}
            </Formik>
        </Fragment>
    )
}
