import { types } from "../types/types";

const initialState = {
    rates: [],
    active: null
}


export const exchangeRateReducer = ( state = initialState, action) => {
    switch ( action.type ) {
        case types.exchangeRateActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        case types.exchangeRateLoad:
            return {
                ...state,
                rates: [...action.payload]
            }
        case types.exchangeRateDelete:
            return {
                ...state,
                active: null,
                rates: state.rates.filter( rate => rate.PkTiposCambioProveedores !== action.payload )
            }
        case types.exchangeRateLogOutActive:
            return {
                ...state,
                active: null
            }
        
        default:
            return state
    }
}
