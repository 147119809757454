import Swal from "sweetalert2";
import { url } from "../api/api";
import { loadJWT } from "./loadJWT";




export const loadExchangeRates = async() => {
    let rates = [];
    const { token } = loadJWT();
    await url.get('/tipos-cambio-proveedores?filter[include][]=TipoServicio&filter[include][]=Proveedor', {headers:{
        'Authorization': `Bearer ${ token }`,
    }})
    .then( response => {
        rates = response.data.map( resp => resp);
    })
    .catch( e => {
        Swal.fire('Error',  e?.response?.data?.error?.message || 'Error. Contacte con el administrador', 'error')
        return;
    })
    
    return rates;

}




