import { makeStyles } from "@material-ui/core";

export const useLoginStyles = makeStyles(theme => ({
    containerTitle:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    inputText:{
        width: '50wv'
    },
    logo:{
        width: '35rem',
        height: '10rem',
    },
    resize: {
        [theme.breakpoints.down('md')]: {
            fontSize: 18
        }
    }
}))