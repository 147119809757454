//Third-party packages
import React, { forwardRef } from 'react'
import { Dialog, Slide } from '@material-ui/core';
import { useSelector } from 'react-redux';

/**
 * Author: QBK
 * Date: 28-09-2021
 */
// Transition component: returns an Slide component. The props reflect its reference and direction
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

//Notification component
export const NotificationModal = () => {
    const { modalNotification } = useSelector(state => state.notificationModal); //we get the modalNotification state
    const { content } = useSelector(state => state.contentModal) //we get the component that is active when the modal is opened
    
    /** Rendered HTML container */
    return (
        <Dialog
        open={modalNotification}
        TransitionComponent={Transition}
        style={{
            width: '100vw',
            border: '1px solid'
        }}

      >
            { content }
        </Dialog>
    )
}
