/**
 * 
 * 
    {
        users: []
        active: null,
        active: {
            id: '4',
            username: 'a.agra',
            email: 'agra@cubicacomunicacion.com',
            confirmed: 'true',
        }
    }
 */

import { types } from "../types/types";

const initialState = {
    users: [],
    active: null
}

export const userReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case types.currentUser:
            return {
                ...state,
                current: {
                    ...action.payload
                },
            }
        case types.userActive:
            return {
                ...state,
                active: {
                    ...action.payload
                },
            }
        case types.usersLoad:
            return {
                ...state,
                users: [...action.payload]
            }
        
        case types.usersDelete:
            return {
                ...state,
                active: null,
                users: state.users.filter( user => user.PkUsuarios !== action.payload )
            }
        case types.usersLogOutCleaning:
            return initialState;

        default:
            return state;
    }
}
