import Swal from "sweetalert2";
import { url } from "../../api/api";
import { loadJWT } from "../../helpers/loadJWT";
import { loadServicesReseller } from "../../helpers/loadServicesReseller";
import { loadServicesSupplier } from "../../helpers/loadServicesSupplier"
import { types } from "../types/types";
import { finishError, finishLoading, finishLoadingModal, startError, startLoading, startLoadingModal, uiManageModalCollectionTypes, uiManageModalNotification } from "./ui";
import { actionEditSupplier } from './actions'
import { startContentModalAddAgencySeller, startContentModalEditVendedorAgencia, startDeleteVendedorAgencia, startLogOutContentModal } from "./contentModal";
import { navigate } from "@reach/router";
import { setActiveSupplierState } from "./suppliers";
import { startContentModalEditResellerService } from "./contentModal";
import { loadSellersAgency } from "../../helpers/loadSellersAgency";



export const startLoadingSellersReseller = ( id ) => {
    return async( dispatch ) => {
        dispatch(startLoading())
        const services = await loadSellersAgency( id );
        dispatch( setSellersAgency( services ));
        dispatch(finishLoading())
       
    }
}


export const setSellersAgency = ( vendedores ) => ({
    type: types.sellersAgencyLoad,
    payload: vendedores
})



export const actionAddAgencySeller = () => {
    return dispatch => {
        Promise.resolve( dispatch(uiManageModalCollectionTypes()))
        .then(() => {
            dispatch(startContentModalAddAgencySeller())
        })
    }
}


export const startNewVendedorAgencia = ( user, setSubmitting ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.post('/usuarios', user, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch(startLoadingSellersReseller(user?.FkUsuarios_Agencia));
            Swal.fire('Éxito', 'Usuario añadido', 'success');
            return;
        })
        .catch( ( e ) => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error. Contacte con el administrador.', 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false )
            dispatch(uiManageModalCollectionTypes());
            dispatch(startLogOutContentModal())
        })

    }
}



export const actionDeleteVendedorAgencia = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch(setActiveVendedorAgenciaState( id )) )
        .then( () =>  dispatch(uiManageModalNotification()) )
        .then(() => dispatch(startDeleteVendedorAgencia()) )
    }
}

export const startDeleteAgencySeller = ( idUsuario ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.delete(`/usuarios/${ idUsuario }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then(() => {
            dispatch((deleteVendedorAgencia(idUsuario)));
            Swal.fire('Éxito', 'Vendedor eliminado', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error')
            return;
        })
    }
}


const deleteVendedorAgencia = (id) =>({
    type: types.vendedorAgenciaDelete,
    payload: id
})


export const actionEditVendedorAgencia = ( id ) => {
    return dispatch => {
        Promise.resolve( dispatch( setActiveVendedorAgenciaEdit( id )) 
        .then( () =>  dispatch(uiManageModalCollectionTypes()) )
        )
    }
}

export const setActiveVendedorAgenciaEdit = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/usuarios/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch((setActiveVendedorAgencia(resp.data)));
            dispatch(startContentModalEditVendedorAgencia())
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al cargar el vendedor', 'error')
            return;
        })
    }
    
}


export const setActiveVendedorAgenciaState = ( id ) => {
    const { token } = loadJWT();
    return async( dispatch ) => {
        dispatch(startLoading());
        await url.get(`/usuarios/${ id }`, {
            headers:{
                Authorization: `Bearer ${ token }`
            }
        })
        .then( resp => {
            dispatch((setActiveVendedorAgencia(resp.data)));
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message || 'Error al cargar el vendedor', 'error')
            return;
        })
    }
    
}

export const setActiveVendedorAgencia = ( vendedor ) => ({
    type: types.vendedorAgenciaActivo,
    payload: vendedor,
})


export const startEditVendedorAgencia = ( vendedor, setSubmitting ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.put(`/usuarios/${ vendedor.PkUsuarios }`, vendedor, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch( startLoadingSellersReseller( vendedor.FkUsuarios_Agencia ) );
            Swal.fire('Éxito', 'Vendedor Editado', 'success');
            return;
        })
        .catch( (e) => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(uiManageModalCollectionTypes())
            dispatch(startLogOutContentModal())
            dispatch(setActiveVendedorAgencia(null))
        })

    }
}

/*
export const startAddServiceReseller = ( service, setSubmitting ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.post('/servicios-revendedores', service, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch(startLoadingServicesReseller(service.FkUsuarios));
            Swal.fire('Éxito', 'Servicio Añadido al Vendedor Externo', 'success');
            return;
        })
        .catch( e => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false )
            dispatch(uiManageModalCollectionTypes()); // we close the modal
            dispatch(startLogOutContentModal())
        })

    }
}

export const startEditServiceReseller = ( service, setSubmitting ) => {
    const { token } = loadJWT();
    return async(dispatch) => {
        await url.put(`/servicios-revendedores`, service, {
            headers: {
                Authorization: `Bearer ${ token }`
            }
        } )
        .then( () => {
            dispatch( startLoadingServicesReseller( service.FkUsuarios ) );
            Swal.fire('Éxito', 'Servicio Revendedor Editado', 'success');
            return;
        })
        .catch( (e) => {
            Swal.fire('Error', e?.response?.data?.error?.message, 'error');
            return;
        })
        .finally( () => {
            setSubmitting( false );
            dispatch(uiManageModalCollectionTypes())
            dispatch(startLogOutContentModal())
            dispatch(setActive(null))
        })

    }
}


const gatherDataRate = ( rate ) => ({
    type: types.setActiveRate,
    payload: rate
})

const deleteService = (id) =>({
    type: types.servicesDelete,
    payload: id
})

const deleteServiceReseller = (id) =>({
    type: types.servicesResellerDelete,
    payload: id
})

const logOutRate = () => ({
    type: types.serviceRateLogOut
})

export const startLogOutServicesActive = () => {
    return async( dispatch ) => {
        dispatch(logOutServices());
    }
}

const logOutServices = () => ({
    type: types.servicesLogOutCleaning
})

 */




/*
const getUpdated = ( id, service ) => ({
    type: types.servicesResellerUpdate,
    payload: {
        id,
        service: {
            id,
            ...service
        }
    }
})
*/
