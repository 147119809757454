import moment from 'moment'

export const getCurrentDate = () => {
    
    let now = new Date();
    var dateString = moment(now).format('YYYY-MM-DD');

    return dateString;
}

export const getCurrentSeparateDate = () => {
    var d = new Date();
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();

    return {
        day,
        month,
        year
    }
}

export const getDateMonthMinusOne = () => {
    var d = new Date();
    var dayY = d.getDate() - 4;
    var monthY = d.getMonth();
    var yearY = d.getFullYear();

    return {
        dayY,
        monthY,
        yearY
    }
}

export const getDateDayPlusTwo = () => {
    var d = new Date();
    var day = d.getDate() + 2;
    var month = d.getMonth();
    var year = d.getFullYear();

    return {
        day,
        month,
        year
    }
}


export const getDateMonthMinusOne2 = () => {
    var d = new Date();
    var dayY = d.getDate(); // - 4;
    var monthY = d.getMonth();
    var yearY = d.getFullYear();

    return {
        dayY,
        monthY,
        yearY
    }
}

export const getDateDayPlusTwo2 = () => {
    var d = new Date();
    var day = d.getDate(); //2;
    var month = d.getMonth();
    var year = d.getFullYear() + 1;

    return {
        day,
        month,
        year
    }
}

export const getDateYesterday = () => {
    var d = new Date();
    var dayY = d.getDate() - 2;
    var monthY = d.getMonth();
    var yearY = d.getFullYear();

    return {
        dayY,
        monthY,
        yearY
    }
}


export const dateNow = () => {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const date = hoy.toISOString(); // "2020-06-13T18:30:00.000Z"
    return date
} 